// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyBSQEt-CRNtzRNwbj-utkm8W8oTAbeIc54",
  authDomain: "fir-903a8.firebaseapp.com",
  databaseURL: "https://fir-903a8-default-rtdb.firebaseio.com",
  projectId: "fir-903a8",
  storageBucket: "fir-903a8.appspot.com",
  messagingSenderId: "19943479769",
  appId: "1:19943479769:web:bfb197adcbd8db2a4861c8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db=getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();

export default firebaseConfig;