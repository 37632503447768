import React, { Component } from "react";
import "../faac.css";
import Header from "../../header";

const productImage = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/104906.jpg";
const ExamplePdf = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/9908486004_DEPLIANT_Automatic_Barriers_GB_Rev24_LOWRES.pdf";
const connectionDaigram = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/615BPRinstallazione.jpg";
const controlunit = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/790281.jpg";
const skirt2m = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/428441.jpg";
const skirt3m = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/428441.jpg";
const fork = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/728487.jpg";
const supportplate = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/737621.jpg";
const antivandalismValve = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/401066.jpg";
const relaseKey = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/713002.jpg";
const foundationplate = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/490073.jpg";
const roundBeam = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/428045.jpg";
const fixedbracketroundbeam = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/428445.jpg";
const luminouscord = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/390993.jpg";
const adhesiveReflector = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/490117.jpg";
const beamLightconnection = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/390992.jpg";
const rectangulaBeam = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/428087.jpg";
const beamBracket = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/428342.jpg";
const ArticulationKitHmax = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/428137.jpg";
const beamlightRectangular = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/390184_light_connection_kit.jpg";
const Beamlightconnectionkit615 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/390081.jpg";
const articolate = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/615BPR/615BPR_articolata.jpg";

export default class BPR615AutomaticBarrier230V extends Component {
  constructor(props) {
    super(props);
  }

  toggle = (id, id2) => {
    var n = document.getElementById(id);
    if (n.style.display != "none") {
      n.style.display = "none";
      document.getElementById(id2).setAttribute("aria-expanded", "false");
    } else {
      n.style.display = "";
      document.getElementById(id2).setAttribute("aria-expanded", "true");
    }
  };
  render() {
    return (
      <div>
          <Header />
        <div className="faacbarriers"  style={{marginTop:100}}>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3">
              <img
                className="card-img-top"
                src={productImage}
                // //onClick={()=>{ history.push('/automations-for-gates')}}
                // //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                style={{ cursor: "pointer" }}
                alt="Product1"
              />
            </div>
            <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
              <div
                className="col-lg-12 col-sm-12 col-md-12 col-xs-12"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "50px" }}>615BPR</h3>
                <h4 style={{ fontSize: "20px" }}>Automatic Barrier 230V</h4>
              </div>
              <div className="row">
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "initial", margin: 20 }}
                >
                  <p style={{ fontSize: "12px" }}>Max beam length</p>
                  <b>5 m</b>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "initial", margin: 20 }}
                >
                  <p style={{ fontSize: "12px" }}>Opening time</p>
                  <b>2,5 – 5,7 s</b>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "initial", margin: 20 }}
                >
                  <p style={{ fontSize: "12px" }}>Use frequency</p>
                  <b>40% – 50%</b>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3">
                  <a
                    href={ExamplePdf}
                    download="615BPR_articolata"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      style={{
                        marginLeft: 30,
                        marginTop: 30,
                        borderColor: "#cecece",
                        borderRadius: 3,
                      }}
                    >
                      Download Broucher
                    </button>
                  </a>
                  <br />
                  <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      margin: 20,
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  >
                    Get Quote
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

            <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
              <ul style={{ listStyleType: "disc" }}>
                <li class="Bullet_List">
                  <span>Hydraulic operator with opening and closing lock</span>
                </li>
                <li class="Bullet_List">
                  <span>
                    Ideal for medium transit frequency and for controlling small
                    and medium private areas.
                  </span>
                </li>
                <li class="Bullet_List">
                  <span>
                    Standard version equipped with limit switches and
                    anti-crushing hydraulic safety device
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink1"
                onClick={(e) => this.toggle("navbar", "TLink1")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Family models</span>
                </h5>
              </a>
              <div id="navbar" style={{ display: "none" }}>
                <div class="table-3">
                  <table>
                    <thead>
                      <tr>
                        <th colspan="1" rowspan="1">
                          <p>
                            <span>Model</span>
                          </p>
                        </th>
                        <th colspan="1" rowspan="1">
                          <p>
                            <span>Item code</span>
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>615BPR Standard Orange RAL 2004</span>
                          </p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>104906</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>615BPR Standard Grey RAL 9006</span>
                          </p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>104910</span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{ marginTop: "0px", marginBottom: "15px" }}>
                  <h5
                    class="title-heading-left fusion-responsive-typography-calculated"
                    style={{ fontSize: 20, lineHeight: 1.2 }}
                    data-fontsize="20"
                    data-lineheight="24px"
                  >
                    <span>Packs</span>
                  </h5>
                </div>
                <div
                  class="fusion-text fusion-text-6 Models__Package_Description"
                  // style="transform:translate3d(0,0,0);"
                >
                  <p>
                    <span class="Highlight">615BPR</span>
                    <span class="Package">&nbsp;</span>
                    <span class="Highlight">includes</span>
                    <span class="Highlight">:</span>
                    <span>&nbsp;</span>
                    <span>
                      barrier body, hydraulic transmission system complete with
                      hydraulic control unit and double-acting piston,
                      integrated 615BPR control unit, installation accessories,
                      a triangular release key.
                    </span>
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink2"
                onClick={(e) => this.toggle("dec2", "TLink2")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Dimensions and technical specifications</span>
                </h5>
              </a>
              <div id="dec2" style={{ display: "none" }}>
                <div class="table-4">
                  <table>
                    <thead>
                      <tr>
                        <th contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Model</span>
                          </p>
                        </th>
                        <th contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>615BPR Standard Orange RAL 2004</span>
                          </p>
                        </th>
                        <th contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>615BPR Standard Grey RAL 9006</span>
                          </p>
                        </th>
                        <th contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>615BPR Rapid</span>
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Power supply voltage</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>220-240V~ 50/60 Hz</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Electric motor</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>Asynchronous single phase</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Motor rotation speed</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>1.400 rpm (*)</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>2.800 rpm (*)</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Max. power</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>220 W</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Max. torque</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>0 ÷ 400 Nm (*)</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>0 ÷ 300 Nm (*)</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Motor-pump unit capacity</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>1,5 lpm (*)</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>3 lpm (*)</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Material type</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>Steel</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Type of treatment</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>
                              100 micron protective primer + polyester painting
                              RAL 2004 – RAL 9006
                            </span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>
                              100 micron zinc epoxy galvanisation + polyester
                              painting RAL 2004
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Encoder</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>
                              No – anti-crushing hydraulic safety device
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Type of deceleration</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>Electronic – Adjustable</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Type of beam</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>
                              Rectangular – Rectangular with skirt – Articulated
                              – Round with lights
                            </span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>
                              Rectangular – Rectangular with skirt – Rectangular
                              articulated – Round
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Operating ambient temperature</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>-20°C ÷ +55°C</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Thermal protection</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>120°C</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Cooling</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>–</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Protection class</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>IP44</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Weight</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>34 Kg</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Type of oil</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>FAAC HP OIL</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Dimensions (LxDxH)</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>270 x 140 x 1015 mm</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Beam max length</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>5 m</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>2.5 m</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Opening time</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>5,7 s (90°)</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>3 s (90°)</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Use frequency</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>50%</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>40%</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Electronic equipment</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>615BPR incorporated</span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>(*) data referred to 230V~ 50Hz.</p>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink3"
                onClick={(e) => this.toggle("dec3", "TLink3")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Example of a typical installation</span>
                </h5>
              </a>
              <div id="dec3" style={{ display: "none" }}>
                <div>
                  <img
                    decoding="async"
                    src={connectionDaigram}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                </div>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                    <table>
                      <thead>
                        <tr>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Ref</span>
                            </p>
                          </th>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Q.ty</span>
                            </p>
                          </th>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Description</span>
                            </p>
                          </th>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Code</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>615BPR Standard</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>104906</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Foundation plate</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>490073</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>
                                Balancing springs for rectangual beams
                              </span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>721018</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Plug in receiver 1 channel RP 433 SLH</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>787852</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Beam bracket for rectangular beam</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>428342</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>
                                Rectangular standard beam – Length 4,815 mm
                              </span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>428091</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>3</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>FAACLIGHT 230V flashing light</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>410013</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>3</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Key operated button T10-E</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>401019001</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>5</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Pair of photocells XP 30</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>785105</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>5</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Adaptors for wall columns</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>401065</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>5</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Low column in aluminium (2 pcs. pack)</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>401028</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>5</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Foundation plate for aluminium column</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>737630</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2 channels transmitter XT2 433 SLH LR</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>787007</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink4"
                onClick={(e) => this.toggle("dec4", "TLink4")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Electronic control units</span>
                </h5>
              </a>
              <div id="dec4" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={controlunit}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      615BPR control unit (incorporated in automation)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      790281
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink5"
                onClick={(e) => this.toggle("dec5", "TLink5")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Common accessories for all applications</span>
                </h5>
              </a>
              <div id="dec5" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={skirt2m}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>Skirt kit length 2 m</p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      428441
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={skirt3m}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>Skirt kit length 3 m</p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      428446
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={fork}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Fork support Discontinuing
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      728487
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={supportplate}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Support plate for fork
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      737621
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={antivandalismValve}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>Anti-vandalism valve</p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      401066
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={relaseKey}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Supplementary triangular release key (10 pcs. pack)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      713002
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={foundationplate}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>Foundation plate</p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      490073
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink6"
                onClick={(e) => this.toggle("dec6", "TLink6")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Round beams S and specific accessories</span>
                </h5>
              </a>
              <div id="dec6" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={roundBeam}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Round beams S – Ø 75 mm (adhesive labels not included)
                    </p>
                    <table>
                      <thead>
                        <tr>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Length (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Item code</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428045</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428042</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>4.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428043</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>5.000</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428002</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={fixedbracketroundbeam}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Fixing bracket for round beams S 615/620/B614
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      428445
                    </p>
                  </div>

                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={Beamlightconnectionkit615}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Beam light connection kit 615
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      390081
                    </p>
                  </div>

                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={luminouscord}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Luminous cord 12 m pack
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      390993
                    </p>
                  </div>

                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={adhesiveReflector}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Adhesive reflector kit (6 pcs.)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      490117
                    </p>
                  </div>

                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <h2>Balancing springs for round S beams</h2>
                    <table>
                      <thead>
                        <tr>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Ø (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Round beam S (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Round beam S</span>
                            </p>
                            <p>
                              <span>with end foot (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Round beam S</span>
                            </p>
                            <p>
                              <span>with skirt (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Round beam S</span>
                            </p>
                            <p>
                              <span>with skirt and end foot (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Item code</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>5,5</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>0 ÷ 2.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>0 ÷ 2.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>0 ÷ 2.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>721008</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>6,0</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.310 ÷ 3.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>0 ÷ 2.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>721005</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>7,0</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3.310 ÷ 4.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.310 ÷ 3.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.310 ÷ 3.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.310 ÷ 3.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>721006</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>8,0</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>4.310 ÷ 5.000</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3.310 ÷ 4.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3.310 ÷ 4.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>721018</span>
                            </p>
                          </td>
                        </tr>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <h2>Balancing springs for round S beams with lights</h2>
                    <table>
                      <thead>
                        <tr>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Ø (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Round beam S</span>
                            </p>
                            <p>
                              <span>with lights (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Round beam S</span>
                            </p>
                            <p>
                              <span>with lights and end foot (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Round beam S</span>
                            </p>
                            <p>
                              <span>with lights and skirt (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Round beam S</span>
                            </p>
                            <p>
                              <span>with lights, skirt and end foot (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Item code</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>5,5</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>0 ÷ 2.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>0 ÷ 2.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>721008</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>6,0</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.310 ÷ 3.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>0 ÷ 2.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>0 ÷ 2.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>721005</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>7,5</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3.310 ÷ 4.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.310 ÷ 3.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.310 ÷ 3.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.310 ÷ 3.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>721007</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>8,0</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>4.310 ÷ 5.000</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3.310 ÷ 4.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3.310 ÷ 4.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>721018</span>
                            </p>
                          </td>
                        </tr>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink8"
                onClick={(e) => this.toggle("dec8", "TLink8")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Rectangular beams and specific accessories</span>
                </h5>
              </a>
              <div id="dec8" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={rectangulaBeam}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Rectangular standard beam
                    </p>
                    <table>
                      <thead>
                        <tr>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Length (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Item code</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.315</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428088</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.815</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428089</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3.815</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428090</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>4.815</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428091</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={beamBracket}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Beam bracket for rectangular beam
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      428342
                    </p>
                  </div>

                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={ArticulationKitHmax}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Articulation kit – H max ceiling 3 m (only for rectangular
                      standard beams)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      428137
                    </p>
                  </div>

                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={beamlightRectangular}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Rectangular beam light connection kit
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      390184
                    </p>
                  </div>

                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={luminouscord}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Luminous cord 12 m pack
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      390993
                    </p>
                  </div>

                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={beamLightconnection}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Beam light connection kit
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      390992
                    </p>
                  </div>
                  <div
                    className="col-lg-12 col-sm-12 col-md-12 col-xs-12"
                    style={{ textAlign: "center" }}
                  >
                    <p>Balancing springs for rectangual beams</p>
                    <table>
                      <thead>
                        <tr>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Ø (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Rectangular beams</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Rect. beams with lights (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Rect. beams with skirt (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Rect. Beams with end foot (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>
                                Rect. beams with skirt and end foot (mm)
                              </span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Item code</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>5,5</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>1.315 ÷ 2.315</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>1.315 ÷ 2.315</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>1.315 ÷ 2.315</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>1.315 ÷ 1.815</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>1.315 ÷ 1.815</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>721008</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>6,0</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.316 ÷ 2.815</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.316 ÷ 2.815</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.316 ÷ 2.815</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>1.816 ÷ 2.315</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>1.816 ÷ 2.315</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>721005</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>7,0</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.816 ÷ 3.815</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.816 ÷ 3.670</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.316 ÷ 2.815</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>721006</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>7,5</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.816 ÷ 3.815</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.316 ÷ 3.315</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>721007</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>8,0</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3.816 ÷ 4.815</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3.671 ÷ 4.815</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.816 ÷ 3.815</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>721018</span>
                            </p>
                          </td>
                        </tr>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink9"
                onClick={(e) => this.toggle("dec9", "TLink9")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Articulated beams</span>
                </h5>
              </a>
              <div id="dec9" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-10 col-sm-10 col-md-10 col-xs-10"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      style={{ height: "100%", width: "100%" }}
                      src={articolate}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
        </div>
        <div
          class="modal fade"
          id="exampleModal10"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Product Details
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ padding: 20 }}>
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <iframe
                      scrolling="no"
                      src="https://forms.zohopublic.in/sales80/form/ContactUs/formperma/o3sKh3PHKyQ5GmkDmlz4rY0NM2edU5IF_pl9MYAjDoA?zf_rszfm=1"
                      id="gform"
                      width="100%"
                      height="990"
                      frameborder="0"
                      marginheight="0"
                      marginwidth="0"
                    >
                      Loading…
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
