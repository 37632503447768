import React, { Component } from "react";
import "../../faac.css";
import Header from "../../../header";
import { Helmet } from 'react-helmet';


const product1 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/104577_boxed.jpg";
const product2 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/104570montato_boxed.jpg";
const product3 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/104470_boxed.jpg";
const product4 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/104413_boxed.jpg";
const product5 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/104415_boxed.jpg";
const product6 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/104415_boxed.jpg";
const product7 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/104301_boxed.jpg";
const product8 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/104468_boxed.jpg";
const product9 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/104100_boxed.jpg";
const product10 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/104208_boxed.jpg";
const product11 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/104203_boxed.jpg";

export default class ExternalSwingMotors extends Component {
  render() {
    return (
      <div>
        <Helmet>
        <title>Automatic Swing Gate Operator | Automation | Quick Service | Remote | FAAC </title>
        <meta property="og:title" content="Automatic Swing Gate Operator | Automation | Quick Service | Remote | FAAC"/>
        <meta property="og:url" content="https://www.gaido.in/external-swing-gate-operators" />
      </Helmet>
        <div>
          <Header />
        </div>

        <div>
          <section className="product" style={{ marginTop: 100 }}>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-9 col-sm-9 col-md-9 col-xs-9">
                <h1
                  style={{
                    position: "relative",
                    margin: "20px",
                    fontSize: "35px",
                    lineHeight: "1",
                    dataLineheight: "40px",
                  }}
                >
                  <b> External automated swing gate systems</b>
                </h1>
                <p
                  style={{
                    margin: "20px",
                    fontSize: "15px",
                    display: "inline-block",
                  }}
                >
                  <span>
                    The different types of swing gates that exist can be
                    “transformed” into FAAC automatic electric gates, courtesy
                    of a wide range of automation systems for external swing
                    gates. The main criteria for choosing an automated system
                    are the dimensions and weight of the lead and the structure
                    of the swing gate as a whole.
                  </span>
                </p>
              </div>
            </div>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/faac391gates">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product1}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product1"
                  />
                </a>
                <button
                  class=" btn-secondary"
                  data-toggle="modal"
                  data-target="#exampleModal10"
                  style={{
                    marginTop: 10,
                    backgroundColor: "#35965c",
                    height: 30,
                    width: "100%",
                    color: "white",
                    border: "1px solid white",
                    borderRadius: 50,
                  }}
                >
                  Get Quote
                </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/faac391gates">391</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>
                  24V Electromechanical actuator with articulated arm
                </h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                <b>2 – 2,5 m (with electric lock)</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency (cycles/day)</p>
                <b>80</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/faac390230vgates">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product2}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product2"
                  />
                </a>
                <button
                  class=" btn-secondary"
                  data-toggle="modal"
                  data-target="#exampleModal10"
                  style={{
                    marginTop: 10,
                    backgroundColor: "#35965c",
                    height: 30,
                    width: "100%",
                    color: "white",
                    border: "1px solid white",
                    borderRadius: 50,
                  }}
                >
                  Get Quote
                </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/faac390230vgates">390 230V</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>
                  230V Electromechanical actuator with articulated arm
                </h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                <b>1,8 – 3 m (with electric lock)</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>S3 – 30%</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/faac412gates">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product3}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product3"
                  />
                </a>
                <button
                  class=" btn-secondary"
                  data-toggle="modal"
                  data-target="#exampleModal10"
                  style={{
                    marginTop: 10,
                    backgroundColor: "#35965c",
                    height: 30,
                    width: "100%",
                    color: "white",
                    border: "1px solid white",
                    borderRadius: 50,
                  }}
                >
                  Get Quote
                </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/faac412gates">412</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>
                  230V Electromechanical operator
                </h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                <b>1.8 m</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency (cycles/hour)</p>
                <b>18</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>{" "}
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/faac413230vgates">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product4}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product3"
                  />
                </a>
                <button
                  class=" btn-secondary"
                  data-toggle="modal"
                  data-target="#exampleModal10"
                  style={{
                    marginTop: 10,
                    backgroundColor: "#35965c",
                    height: 30,
                    width: "100%",
                    color: "white",
                    border: "1px solid white",
                    borderRadius: 50,
                  }}
                >
                  Get Quote
                </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/faac413230vgates">413 230V</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>
                  230V Electromechanical operator
                </h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                <b>1,8 – 2,5 m (with electric lock)</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency (cycles/hour)</p>
                <b>30%</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>{" "}
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/faac415230vgates">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product5}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product5"
                  />
                </a>
                <button
                  class=" btn-secondary"
                  data-toggle="modal"
                  data-target="#exampleModal10"
                  style={{
                    marginTop: 10,
                    backgroundColor: "#35965c",
                    height: 30,
                    width: "100%",
                    color: "white",
                    border: "1px solid white",
                    borderRadius: 50,
                  }}
                >
                  Get Quote
                </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/faac415230vgates"> 415 230V</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>
                  230V Electromechanical operator
                </h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                <b>2,5 – 4 m (with electric lock)</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency (cycles/hour)</p>
                <b>S3 – 30%</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/faac41524vgates">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product6}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product6"
                  />
                </a>
                <button
                  class=" btn-secondary"
                  data-toggle="modal"
                  data-target="#exampleModal10"
                  style={{
                    marginTop: 10,
                    backgroundColor: "#35965c",
                    height: 30,
                    width: "100%",
                    color: "white",
                    border: "1px solid white",
                    borderRadius: 50,
                  }}
                >
                  Get Quote
                </button>
              </div>

              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/faac41524vgates">415 24V</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>
                  24V Electromechanical operator
                </h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                <b>2,3 – 2,7 m (with electric lock)</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency </p>
                <b>Continuous use</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/faacS418gates">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product7}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product7"
                  />
                </a>
                <button
                  class=" btn-secondary"
                  data-toggle="modal"
                  data-target="#exampleModal10"
                  style={{
                    marginTop: 10,
                    backgroundColor: "#35965c",
                    height: 30,
                    width: "100%",
                    color: "white",
                    border: "1px solid white",
                    borderRadius: 50,
                  }}
                >
                  Get Quote
                </button>
              </div>

              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/faacS418gates">S418</a>
                </h3>

                <h4 style={{ fontSize: "15px" }}>
                  24V Electromechanical operator
                </h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                <b>2,3 – 2,7 m (with electric lock)</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency (cycles/hour)</p>
                <b>Continuous use</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/faac402gates">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product8}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="8"
                  />
                </a>
                <button
                  class=" btn-secondary"
                  data-toggle="modal"
                  data-target="#exampleModal10"
                  style={{
                    marginTop: 10,
                    backgroundColor: "#35965c",
                    height: 30,
                    width: "100%",
                    color: "white",
                    border: "1px solid white",
                    borderRadius: 50,
                  }}
                >
                  Get Quote
                </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/faac402gates">402</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>230V Hydraulic operator</h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                <b>1,8 – 3 m</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency (cycles/hour) </p>
                <b>55</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/faacS450hgates">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product9}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product9"
                  />
                  <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  >
                    Get Quote
                  </button>
                </a>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/faacS450hgates">S450H</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>24V Hydraulic operator</h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                <b>2 – 3 m</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>Continuous use</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

              <div className="col-lg-2 col-sm-2 col-md-2 col-xs-2">
                <a href="/faac422pedgates">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product10}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product5"
                  />
                </a>
                <button
                  class=" btn-secondary"
                  data-toggle="modal"
                  data-target="#exampleModal10"
                  style={{
                    marginTop: 10,
                    backgroundColor: "#35965c",
                    height: 30,
                    width: "100%",
                    color: "white",
                    border: "1px solid white",
                    borderRadius: 50,
                  }}
                >
                  Get Quote
                </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/faac422pedgates">422</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>
                  230V Electromechanical operator
                </h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                <b>1,8 – 3 m (with electric lock)</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency (cycles/hour)</p>
                <b>55</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/faac400gates">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product11}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product11"
                  />
                </a>
                <button
                  class=" btn-secondary"
                  data-toggle="modal"
                  data-target="#exampleModal10"
                  style={{
                    marginTop: 10,
                    backgroundColor: "#35965c",
                    height: 30,
                    width: "100%",
                    color: "white",
                    border: "1px solid white",
                    borderRadius: 50,
                  }}
                >
                  Get Quote
                </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/faac400gates">400</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>230V Hydraulic operator</h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                <b>2,2 – 7 m (with electric lock)</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency (cycles/hour)</p>
                <b>50 – 70</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
          </section>
        </div>
        <div
          class="modal fade"
          id="exampleModal10"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Product Details
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ padding: 20 }}>
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <iframe
                      scrolling="no"
                      src="https://forms.zohopublic.in/sales80/form/ContactUs/formperma/o3sKh3PHKyQ5GmkDmlz4rY0NM2edU5IF_pl9MYAjDoA?zf_rszfm=1"
                      id="gform"
                      width="100%"
                      height="990"
                      frameborder="0"
                      marginheight="0"
                      marginwidth="0"
                    >
                      Loading…
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer id="footer"></footer>
      </div>
    );
  }
}
