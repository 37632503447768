import React, { Component } from 'react'
// import "./testimonial.css"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
export default class Testimonials extends Component {
    responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    render() {
        return (
            <div class="container " style={{ marginBottom: 40 }}>
                <div className="section-header">
                    <h2>testimonials</h2>
                </div>
               
               
                <div style={{ border: "1px solid #7AA641" }}>
                    <Carousel
                        responsive={this.responsive}
                        infinite={true}
                        autoPlaySpeed={20000}

                    >
                        <div className='row'>
                        <div className='col-lg-3 col-sm-3 col-md-3 col-xs-3' ></div>

                            <div class="card " style={{ width: "700px", height: "100%", fontStyle: "italic", border: "none" }}>

                                <div class="card-body ">

                                    <div className='col-lg-9 col-sm-8 col-md-8 col-xs-8 text-center' >
                                        <div className='row' style={{ paddingTop: 40 }} ></div>

                                        <p class="text-center" ><i class="fa fa-quote-left"></i> I was searching for an automated gate dealer on Indiamart in random. Fortunately, I found Gaido over there. I was truly blown out of proportions with the kind of service they have provided along with the best possible price. There couldn't be any better dealer as per my experience. Absolutely 5 star deserving.&nbsp;<i class="fa fa-quote-right"></i>
                                        </p>
                                        <footer class="blockquote-footer text-center" style={{
                                            textTransform: "uppercase",
                                            color: "#7AA641"
                                        }}>Sai Krishna <cite title="Source Title"></cite></footer>
                                        <p class="client-review-stars text-center" style={{ color: "#FDCC0D" }}>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>

                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='row'>
                        <div className='col-lg-3' ></div>

                            <div class="card" style={{ width: "700px", minHeight: "100%", fontStyle: "italic", border: "none" }}>

                                <div class="card-body">
                                    <div className='col-lg-9 col-sm-8 col-md-8 col-xs-8'>
                                        <div className='row' style={{ paddingTop: 50 }} ></div>

                                        <p class="text-center" ><i class="fa fa-quote-left"></i>  Quick Response & Good Service. All the very Best from M/s Powersol Infra Services Pvt Ltd- Hyderabad.&nbsp;<i class="fa fa-quote-right"></i>
                                        </p>
                                        <footer class="blockquote-footer text-center" style={{
                                            textTransform: "uppercase",
                                            color: "#7AA641"
                                        }}>PowerSol Purchase <cite title="Source Title"></cite></footer>
                                        <p class="client-review-stars text-center" style={{ color: "#FDCC0D" }}>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>

                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='row'>
                        <div className='col-lg-3' ></div>

                            <div class="card" style={{ width: "700px", height: "100%", fontStyle: "italic", border: "none" }}>

                                <div class="card-body">
                                    <div className='col-lg-9 col-sm-8 col-md-8 col-xs-8'>
                                        <div className='row' style={{ paddingTop: 40 }} ></div>

                                        <p class="text-center" ><i class="fa fa-quote-left"></i> At Gandhi Heights we have installed FAAC Boom barrier in association with GAIDO, We contacted Kapli Jain and told him our requirements.. total delivery and Installation took less than 15 days from receipt of advance amount. We are happy with FAAC Boom barrier and service provided.&nbsp;<i class="fa fa-quote-right"></i>
                                        </p>
                                        <footer class="blockquote-footer text-center" style={{
                                            textTransform: "uppercase",
                                            color: "#7AA641"
                                        }}>Gandhi Heights Vivekananda Nagar <cite title="Source Title">,Kukatpally</cite></footer>
                                        <p class="client-review-stars text-center" style={{ color: "#FDCC0D" }}>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>

                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Carousel>
                </div>
              
            </div>
        )
    }
}
