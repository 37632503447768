import React, { useState } from 'react';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { productdata } from "./productdata.js";
import './product.css'


export default function CarouselItem(props) {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };
    const [productName, setProductName] = useState("")
    const [image, setImage] = useState("")
    const [specs, setSpecs] = useState([])


    return (
        <div>
            <Carousel

                responsive={responsive}
                autoPlaySpeed={5000}
                autoPlay={true}
                showDots={true}
            >
                {productdata.map((data, key) => {
                    return (

                        <div style={{
                            height: "100%", width: "100%", borderRadius: 20,
                            boxShadow:
                                "inset 0 -3em 3em rgba(0,0,0,0.1),0 0  0 2px rgb(255,255,255),0.3em 0.3em 1em rgba(0,0,0,0.3)"
                        }}>
                            <div key={key}>
                                <div>
                                    <img className="show-large" src={`${data.image}`} alt="placeholder"
                                        data-toggle="modal" data-target="#exampleModal10"
                                        onClick={() => {
                                            setImage(data.image);

                                            setProductName(data.product);
                                            setSpecs(data.specs)
                                        }}
                                        onMouseEnter={() => {
                                            document.getElementById(`${data.productId}`).style.display = "block"
                                        }}
                                        onMouseLeave={() => {
                                            document.getElementById(`${data.productId}`).style.display = "none"
                                        }}
                                        style={{ height: 250, width: "100%", padding: 10, cursor: "pointer" }}


                                    />
                                    <button className="show-large" style={{
                                        height: 80, width: "100%", color: "white",
                                        backgroundColor: "#35965c", border: "1px solid white", borderRadius: 10,

                                    }}
                                        onMouseEnter={() => {
                                            document.getElementById(`${data.productId}`).style.display = "block"
                                        }}
                                        onMouseLeave={() => {
                                            document.getElementById(`${data.productId}`).style.display = "none"
                                        }}
                                    >
                                        {data.product}<br></br>
                                        <button class=" btn-secondary" id={data.productId} data-toggle="modal" data-target="#exampleModal10"
                                            style={{ display: "none", marginTop: 10, backgroundColor: "#35965c", height: 30, width: "100%", color: "white", border: "1px solid white", borderRadius: 50 }}
                                            onClick={() => {
                                                setImage(data.image);
                                                setProductName(data.product);
                                                setSpecs(data.specs)
                                            }}
                                        >
                                            Get Quote
                                        </button>


                                    </button>

                                    <img className="show-mobile" src={`${data.image}`} alt="placeholder"
                                        data-toggle="modal" data-target="#exampleModal10"
                                        onClick={() => {
                                            setProductName(data.product);
                                            setSpecs(data.specs)
                                        }}
                                        style={{ height: 250, width: "100%", padding: 10 }}

                                    />
                                    <button className="show-mobile" data-toggle="modal" data-target="#exampleModal10" style={{
                                        height: 50, width: "100%", color: "white",
                                        backgroundColor: "#35965c", border: "1px solid white", borderRadius: 10,

                                    }}
                                        onClick={() => {
                                            setProductName(data.product);
                                            setSpecs(data.specs)
                                        }}

                                    >
                                        {data.product}
                                    </button>

                                </div>



                            </div>
                            {/* <div>
                                        <div class="modal fade" id="exampleModal10" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-lg" role="document" >
                                                <div class="modal-content" style={{ height: 600 }} >
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">{data.product}</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body" style={{ padding: 20, backgroundColor: "ButtonFace" }}>
                                                        <img src={require(`${data.image}`)} alt="placeholder"
                                                            style={{ height: 250, width: 250, border: "1px solid black" }} /><br></br>
                                                        <br></br>
                                                        <br></br>
                                                        <h4><b>{data.product}</b></h4>


                                                    </div>

                                                </div>
                                            </div>

                                        </div>


                                    </div> */}
                            <div>


                            </div>
                        </div>




                    );
                })}
            </Carousel>
            <div>
                <div class="modal fade" id="exampleModal10" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document" >
                        <div class="modal-content"  >
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Product Details</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{ padding: 20 }}>
                                <div className='row'>
                                    <div className='col-lg-6 col-sm-6 col-md-6 col-xs-6' style={{ paddingLeft: 60 }}   >
                                        <img src={image} alt="placeholder" className="show-large"
                                            style={{
                                                height: 250, width: 250, borderRadius: 15, boxShadow: "5px 5px 5px #999"
                                            }} />
                                        <br></br>

                                        <h4><b>{productName}{specs[4] !== "" ? <h4>({specs[4]})</h4> : ""} </b></h4>
                                        <h6><b>Specification:</b></h6>
                                        {specs[0]}<br></br>
                                        {specs[1]}<br></br>
                                        {specs[2]}<br></br>
                                        {specs[3]}<br></br><br></br>
                                        {/* {specs[4]}<br></br> */}
                                        <b> {specs[5]} <i class="fa-solid fa-phone"></i> </b> <br></br>


                                    </div>
                                    <br />

                                    <div className='col-lg-6 col-sm-6 col-md-6 col-xs-6'  >
                                        <br />
                                        <iframe class="embed-responsive-item"
                                            src="https://forms.zohopublic.in/sales80/form/KnowMore/formperma/Bxd9ffNXHg4LXDPgA4AQ3WezARUlyFtl56X2IBlfNoI?zf_rszfm=1" style={{ textAlign: "center" }} width="100%" height="500" frameborder="0" marginheight="0" marginwidth="">Loading…</iframe>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );

}



