import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer id="footer">
                <div className="row px-sm-5">
                    <div className="col-md-9 col-sm-9">
                        <div className="row" >
                            <div className="col-6 col-sm-2 border-rg">
                                <div className="mb-4">
                                    <h5 className="h5 font-weight-bold color-blue-2">
                                        <a href="/about-company" >Company</a>
                                    </h5>
                                    <ul className="footer-social list-unstyled">

                                        <li className="footer-item"><a className="footer-link font-14" href={"/about-company"}>About Us</a>
                                        </li>
                                        <li className="footer-item">
                                            <a className="footer-link font-14" href="/about-company#team">The Team</a>
                                        </li>
                                        <li className="footer-item">
                                            <a className="footer-link font-14" href="/about-company#partner-network">Partner Network</a>
                                        </li>
                                        {/* <li className="footer-item">
                                            <a className="footer-link font-14" href="https://www.indiamart.com/gaidotechnologies/" rel="noopener noreferrer" target="_blank">Online Store</a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4 border-rg">
                                <div className="mb-4">
                                    <h5 className="h5 font-weight-bold color-blue-2"><a href="/solutions">Solutions</a></h5>
                                    <ul className="footer-social list-unstyled">
                                        <li className="footer-item">
                                            <a className="footer-link font-14" href="/solutions/ticketless-parking-management">Ticketless Parking Management</a>
                                        </li>
                                        <li className="footer-item">
                                            <a className="footer-link font-14" href="/solutions/parking-guidance-system">Parking Guidance System</a>
                                        </li>
                                        <li className="footer-item">
                                            <a className="footer-link font-14" href="/solutions/valet-parking-system">Valet Parking System</a>
                                        </li>
                                        <li className="footer-item">
                                            <a className="footer-link font-14" href="/solutions/entry-gate-system">Entry Gate System</a>
                                        </li>
                                        <li className="footer-item">
                                            <a className="footer-link font-14" href="/solutions/cloud-based-analytics-platform">Cloud-Based Analytics Platform</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-6 col-sm-2">
                                <div className="mb-4 offe">
                                    <h5 className="h5 font-weight-bold color-blue-2 "><a href="/offerings">Offerings</a></h5>
                                    <ul className="footer-social list-unstyled">
                                        <li className="footer-item">
                                            <a className="footer-link font-14" href="/offerings/airports">Airports</a>
                                        </li>
                                        <li className="footer-item">
                                            <a className="footer-link font-14" href="/offerings/business-centers">Business Centers</a>
                                        </li>
                                        <li className="footer-item">
                                            <a className="footer-link font-14" href="/offerings/hospitals">Hospitals</a>
                                        </li>
                                        <li className="footer-item">
                                            <a className="footer-link font-14" href="/offerings/hotels">Hotels</a>
                                        </li>
                                        <li className="footer-item">
                                            <a className="footer-link font-14" href="/offerings/municipalities">Municipalities</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4  border-rg">
                                <div className="mb-4 cdc">
                                    <h5 className="h5 font-weight-bold color-blue-2 "><a href="/offerings"><br /></a></h5>
                                    <ul className="footer-social list-unstyled">
                                        <li className="footer-item">
                                            <a className="footer-link font-14" href="/offerings/educational-institutes">Educational Institutes</a>
                                        </li>
                                        <li className="footer-item">
                                            <a className="footer-link font-14" href="/offerings/residential-area">Residential Area</a>
                                        </li>
                                        <li className="footer-item">
                                            <a className="footer-link font-14" href="/offerings/shopping-centers">Shopping Centers</a>
                                        </li>
                                        <li className="footer-item">
                                            <a className="footer-link font-14" href="/offerings/sports-exhibitions">Sports and Exhibition Venues</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                        <div className="container">
                            <div className="row" >
                                <div className="col-12 col-sm-12 footer-offices">
                                    <div claPssName="mb-4">
                                        <h5 className="h5 font-weight-bold color-blue-2"><a href="/gaido-connect">Corporate Head Office</a></h5>
                                        <ul className="footer-social list-unstyled">
                                            <li className="footer-item font-14"><i className="fas fa-map-marker-alt" style={{ paddingRight: '5px' }} />#682, 3rd Floor, Babukhan Rasheed Plaza, Road No. 36, Jubilee Hills, Hyderabad, TS -500033 INDIA</li>
                                            <li className="footer-item font-14" style={{ marginTop: 10 }}><a href="mailto:info@gaido.in"><i className="fa fa-envelope" style={{ paddingRight: '5px' }} />info@gaido.in</a></li>
                                            <li className="footer-item font-14" style={{ marginTop: 10 }}><a href="tel:+91 7373735668"><i className="fa fa-phone" style={{ paddingRight: '5px' }} />+91 7373735668</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "37%" }} className="border-mobile-footer">
                        <h3 style={{ marginLeft: "15px" }} className="followus">Follow Us </h3>
                        <hr style={{ borderColor: '#ACC1D4', marginLeft: "15px" }} />
                        <ul className="socail-footer">
                            <li>
                                <a href="https://www.facebook.com/gaidoindia" target="_blank" rel="noopener noreferrer">   <img width={32} height={32} style={{ borderRadius: 5 }} title="Facebook" alt="Facebook" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/fb1.png" />
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/gaidoindia" target="_blank" rel="noopener noreferrer">   <img width={32} height={32} style={{ borderRadius: 5 }} title="Twitter" alt="Twitter" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/tw1.png" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/gaidoindia/" target="_blank" rel="noopener noreferrer">   <img width={32} height={32} style={{ borderRadius: 5 }} title="Linkedin" alt="Linkedin" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/li1.png" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/gaidoindia/" target="_blank" rel="noopener noreferrer">   <img width={32} height={32} style={{ borderRadius: 5 }} title="Instagram" alt="Instagram" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/ins1.png" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/channel/UClA2PlrSEZ5iJuoy4YXaBkQ" target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Youtube" style={{ borderRadius: 5 }} alt="Youtube" src={require('./youtube.png')} />
                                </a>
                            </li>
                            {/* <li>
                                <a href="https://www.indiamart.com/gaidotechnologies/" target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Youtube" alt="Youtube" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/indiamart.png" />
                                </a>
                            </li> */}
                        </ul>
                    </div>
                    <div className="col-md-12">
                        <hr style={{ borderColor: '#ACC1D4' }} />
                    </div>
                </div>
                <div className="copyright">
                    Copyright <span role="img" aria-label="donut">©️</span>2021<strong> Gaido Technologies Pvt Ltd.</strong> All Rights Reserved
                </div>

                <a className="float show-mobile" id="floatbutton" style={{ width: "100%", marginLeft: -25 }}>
                    <button class=" btn-secondary" data-toggle="modal" data-target="#get" style={{
                        textAlignlign: "center", marginTop: 10, border: "none", width: 300, height: 40, backgroundColor: "#1bc760",
                        border: "none",
                        borderRadius: "45px",
                        boxShadow: "0px 8px 15px rgb(0 0 0 / 10%)",
                        transition: "all 0.3s ease 0s"
                    }} >Get Price</button>
                </a>

                <div >
                    <div class="modal fade" id="get" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg" role="document" >
                            <div class="modal-content"  >
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Connect Us</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" style={{ padding: 20 }}>
                                    <div className='row'>


                                        <div className='col-lg-6 col-sm-6 col-md-6 col-xs-6'  >
                                            <br />
                                            <iframe class="embed-responsive-item"
                                                src="https://forms.zohopublic.in/sales80/form/KnowMore/formperma/Bxd9ffNXHg4LXDPgA4AQ3WezARUlyFtl56X2IBlfNoI?zf_rszfm=1" style={{ textAlign: "center" }} width="100%" height="500" frameborder="0" marginheight="0" marginwidth="">Loading…</iframe>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>


                </div>
            </footer>
        );
    }
}

export default Footer;