// import { useState } from "react";
// import {
//   createUserWithEmailAndPassword,
//   signInWithEmailAndPassword,
//   onAuthStateChanged,
//   signOut,
// } from "firebase/auth";
// import { auth } from "../firebaseConfig";

// function SignUp() {
//   const [registerEmail, setRegisterEmail] = useState("");
//   const [registerPassword, setRegisterPassword] = useState("");
//   const [loginEmail, setLoginEmail] = useState("");
//   const [loginPassword, setLoginPassword] = useState("");

//   const [user, setUser] = useState({});

//   onAuthStateChanged(auth, (currentUser) => {
//     setUser(currentUser);
//   });

//   const register = async () => {
//     try {
//       const user = await createUserWithEmailAndPassword(
//         auth,
//         registerEmail,
//         registerPassword
//       );
//       console.log(user);
//     } catch (error) {
//       console.log(error.message);
//     }
//   };

//   const login = async () => {
//     try {
//       const user = await signInWithEmailAndPassword(
//         auth,
//         loginEmail,
//         loginPassword
//       );
//       console.log(user);
//     } catch (error) {
//       console.log(error.message);
//     }
//   };

//   const logout = async () => {
//     await signOut(auth);
//   };

//   return (
//     <div className="App">
//       <div>
//         <h3> Register User </h3>
//         <input
//           placeholder="Email..."
//           onChange={(event) => {
//             setRegisterEmail(event.target.value);
//           }}
//         />
//         <input
//           placeholder="Password..."
//           onChange={(event) => {
//             setRegisterPassword(event.target.value);
//           }}
//         />

//         <button onClick={register}> Create User</button>
//       </div>

//       <div>
//         <h3> Login </h3>
//         <input
//           placeholder="Email..."
//           onChange={(event) => {
//             setLoginEmail(event.target.value);
//           }}
//         />
//         <input
//           placeholder="Password..."
//           onChange={(event) => {
//             setLoginPassword(event.target.value);
//           }}
//         />

//         <button onClick={login}> Login</button>
//       </div>

//       <h4> User Logged In: </h4>
//       {user?.email}

//       <button onClick={logout}> Sign Out </button>
//     </div>
//   );
// }

// export default SignUp;


import React, { Component } from 'react';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
} from "firebase/auth";
import { auth } from "../firebaseConfig";
import { Alert } from 'reactstrap';
export default class SignUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: '',
            success: false,
            successMessage: "",
            loading: false
        }

    }

    register = async () => {
        try {
            const user = await createUserWithEmailAndPassword(
                auth,
                this.state.email,
                this.state.password
            );
            this.setState({ success: true, successMessage: "User Registered Successfully" });
            setTimeout(() => { this.setState({ success: false }) }, 3000);
        } catch (error) {
            this.setState({ loading: true, error: error.message });
            setTimeout(() => { this.setState({ loading: false }) }, 3000);
        }
    };
    render() {
        return (
            <div className="loginPage">
                <div className="container" ></div>
                <div className="container">

                    <div className="row">

                        <div className='col-lg-4 col-sm-12 col-md-12 col-xs-12'></div>
                        <div className='col-lg-4 col-sm-12 col-md-12 col-xs-12'>
                            {this.state.success ? <Alert color="danger">
                                {this.state.successMessage}
                            </Alert> : ""}
                            {this.state.loading ? <Alert color="danger">
                                {this.state.error}
                            </Alert> : ""}
                            <h3>Register</h3>
                            <div className="mb-3">
                                <label>Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    onChange={(event) => {
                                        this.setState({ email: event.target.value });
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Enter password"
                                    onChange={(event) => {
                                        this.setState({ password: event.target.value });
                                    }}
                                />
                            </div>
                            {/* <div className="mb-3">
                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customCheck1"
                                />
                                <label className="custom-control-label" htmlFor="customCheck1">
                                    Remember me
                                </label>
                            </div>
                        </div> */}
                            <div className="d-grid">
                                <button type="submit" className="btn btn-primary" onClick={this.register}>
                                    Create User
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}