import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';

class AboutCompany extends Component {

    componentDidMount() {
        document.getElementById("floatbutton").style.display="none";

    }
    
    render() {
        return (
            <div>
                <Helmet>
        <title>About Gaido - No. 1 Dealer in India | Boom Barriers | FAAC Distributor</title>
        <meta property="og:title" content="About Gaido - No. 1 Dealer in India | Boom Barriers | FAAC Distributor" />
        <meta property="og:url" content="https://gaido.in/about-company" />
      </Helmet>
                <Header />
                <div className="inner-banner">
                    <h2>About the Company</h2>
                </div>
                <section id="about" className="wow fadeInLeft">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-none d-sm-block content">
                                <p><b>Gaido Technologies Pvt. Ltd.</b>&nbsp; is a Hyderabad based Smart Parking Management company. The company is headed by experienced leadership from the IT & Business Management Space, with a vision to revolutionize the Smart parking industry. The mission of the company is two-pronged. On one hand, the company aims to simplify the parking management system for parking lot operators, and on the other, ensure that parking for
                                 drivers is achieved with admirable ease. The company provides end to end customized solutions based on the client’s requirement.</p>
                                <p><b>Gaido Technologies</b>&nbsp;provides solutions using parking technology that has led the industry in Parking
                technology innovation, delivering the most comprehensive product range and solutions in the parking
                industry. As a result of the high-quality products and outstanding customer service, is capable of providing
                solutions around the globe. And now,&nbsp;Gaido Technologies, along with its unique offerings, is all set to
                provide this world-class solution to clients in India. </p>
                            </div>
                            <div className="col-lg-6 content">
                                <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/about.jpg" alt="Gaido technologies" className="img-responsive" style={{ width: '100%', }} />
                            </div>
                            <div className="col-lg-6 col-md-block d-sm-none content">
                                <p><b>Gaido Technologies Pvt. Ltd.</b>&nbsp; is a Hyderabad based Smart Parking Management company. The company is headed by experienced leadership from the IT & Business Management Space, with a vision to revolutionize the Smart parking industry. The mission of the company is two-pronged. On one hand, the company aims to simplify the parking management system for parking lot operators, and on the other, ensure that parking for
                                 drivers is achieved with admirable ease. The company provides end to end customized solutions based on the client’s requirement.</p>
                                <p><b>Gaido Technologies</b>&nbsp;provides solutions using parking technology that has led the industry in Parking
                technology innovation, delivering the most comprehensive product range and solutions in the parking
                industry. As a result of the high-quality products and outstanding customer service, is capable of providing
                solutions around the globe. And now,&nbsp;Gaido Technologies, along with its unique offerings, is all set to
                provide this world-class solution to clients in India. </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 content"><br />
                                <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/vision.jpg" alt="Gaido technologies" className="img-responsive" style={{ width: '100%' }} />
                            </div>
                            <div className="col-lg-6 content">
                                <h2><br />Our Mission</h2>
                                <p>⇒ Equip operators to make data-driven decisions and maximize security, profitability, management and
                control. </p>
                                <p>⇒ Ensure that the experience in the parking space is hassle free.</p>
                                <h2>Our Vision</h2>
                                <p>⇒ Use data and technology to create a sustainable parking ecosystem.</p>
                                <h2>Our Values</h2>
                                <ul className="ul-col-2">
                                    <li>Passion for technology.</li>
                                    <li>Openness and respect.</li>
                                    <li>Pursuit of Excellence.</li>
                                    <li>Discovering advanced ways to meet and exceed expectations.</li>
                                </ul>
                                <ul className="ul-col-2">
                                    <li>Integrity and honesty.</li>
                                    <li>Taking on big challenges and seeing them through.</li>
                                    <li>Constructive self-criticism, self-improvement, and personal excellence.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="partner-network" style={{ backGroundColor: "#393939" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 content mobilepartner" >
                                <div className="text" style={{ padding: "95px 0px 21px 0px" }}>
                                    <h3 style={{ color: "#fff" }}>PARTNER NETWORK</h3>
                                </div>
                                <p style={{
                                    textAlign: 'justify', lineHeight: "24px",
                                    fontFamilty: "Montserrat", color: "#fff"
                                }}>Solving parking issues across India, we are on a mission to bring high-quality and high-performance Parking Management Hardware and enable it with our state of the art software to all the cities in India. Our partners are our strength, and have played a key role in empowering us to take our solution to cities (tier 1, tire 2 & tire 3 cities) PAN India & transform the car parking experience one parking lot at a time.</p>
                            </div>
                            <div className="col-lg-6 content">
                                <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/gaidoIndiamap.png" alt="Gaido technologies" className="img-responsive" style={{ width: '100%' }} />
                            </div>
                        </div>
                    </div>
                </section>
                <section id="team" className="f-nav wow fadeInLeft our-webcoderskull padding-lg" style={{ marginTop: 5 }}>
                    <div className=" container">
                        <div className="section-header">
                            <h2>THE TEAM</h2>
                            <p style={{ color: "#d1c6c6" }}>We are a team of technology driven enthusiast, ably guided by following senior leadership</p>
                        </div>
                        <ul className="row team">
                            <li className="col-12 col-md-6 col-lg-3">
                                <div className="cnt-block equal-hight" >
                                    <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/coo.png" className="img-responsive" />
                                    <div className="overlay">
                                        <div className="text">
                                            <h3>Rajesh Jain</h3>
                                        </div>
                                    </div>
                                    <p style={{ textAlign: 'center' }}>Director and COO</p>
                                </div>
                            </li>
                            <li className="col-12 col-md-6 col-lg-3">
                                <div className="cnt-block equal-hight" >
                                    <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/cto.png" className="img-responsive" />
                                    <div className="overlay">
                                        <div className="text">
                                            <h3>Rambabu Addala</h3>
                                        </div>
                                    </div>
                                    <p style={{ textAlign: 'center' }}>CTO</p>
                                </div>
                            </li>
                            <li className="col-12 col-md-6 col-lg-3">
                                <div className="cnt-block equal-hight" >
                                    <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/ceo.png" className="img-responsive" />
                                    <div className="overlay">
                                        <div className="text">
                                            <h3>Kapil Jain</h3>
                                        </div>
                                    </div>
                                    <p style={{ textAlign: 'center' }}>Founder &amp; CEO</p>
                                </div>
                            </li>
                            <li className="col-12 col-md-6 col-lg-3">
                                <div className="cnt-block equal-hight" >
                                    <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/software.png" className="img-responsive" />
                                    <div className="overlay">
                                        <div className="text">
                                            <h3>Vijay Munagala </h3>
                                        </div>
                                    </div>
                                    <p style={{ textAlign: 'center' }}>Software Engineer </p>
                                </div>
                            </li>
                            {/* <li className="col-12 col-md-6 col-lg-3">
                                <div className="cnt-block equal-hight" >
                                    <img alt="gaidologos" style={{borderRadius:100,color:"red",border:"8px solid white"}} src={require("../products/img/user.png")} className="img-responsive" />
                                    <div className="overlay">
                                        <div className="text">
                                            <h3>Ashish  </h3>
                                        </div>
                                    </div>
                                    <p style={{ textAlign: 'center' }}>BDM</p>
                                </div>
                            </li>
                            <li className="col-12 col-md-6 col-lg-3">
                                <div className="cnt-block equal-hight" >
                                    <img alt="gaidologos" style={{borderRadius:100,color:"red",border:"8px solid white"}} src={require("../products/img/user2.webp")} className="img-responsive" />
                                    <div className="overlay">
                                        <div className="text">
                                            <h3>Ashish  </h3>
                                        </div>
                                    </div>
                                    <p style={{ textAlign: 'center' }}>BDM</p>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}

export default AboutCompany;