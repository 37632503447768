import Header from "../header";
import faaclogo from "../../src/FaacLogo.png";
import FaacBarriers from "./FaccBarriers/FaacBarriers";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Helmet } from 'react-helmet';


const  product1 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/EXTERNAL_SWING_GATES_OPERATORS.jpg";
const product2 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/AUTOMATIC_HYDRAULIC_ELECTROMECHANICAL_BARRIERS.jpg";

export default function Products() {
  let history = useHistory();

  return (
    <div>
      <div>
      <Helmet>
        <title>Automatic Gate Opener | Sliding Gate | Gate Automation, Gate Motor, Remote </title>
        <meta property="og:title" content="Automatic Gate Opener | Sliding Gate | Gate Automation, Gate Motor, Remote" />
        <meta property="og:url" content="https://www.gaido.in/automations-for-gates" />
      </Helmet>
        <Header />
        <div
          className="inner-banner"
          style={{ marginBottom: 30, textAlign: "center" }}
        >
          <img src={faaclogo} style={{ width: "250px" }} alt="faac" />

          <h2>Most Trusted Supplier across INDIA</h2>
        </div>
      </div>

      <div>
        <section className="product">
          <div className="section-header">
            <h2>
              <br />
              Products
            </h2>
          </div>
          <div className="row" style={{ marginBottom: "50px" }}>
            <div className="col-lg-3"></div>
            <div
              className="col-lg-3"
              style={{ alignContent: "center", display: "inline" }}
            >
              <div className="card mb-2">
                <img
                  className="card-img-top"
                  width="255"
                  height="250"
                  src={product1}
                  onClick={() => {
                    history.push("/automations-for-gates");
                  }}
                  //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                  style={{ cursor: "pointer" }}
                  alt="Product1"
                />
                <div className="card-body">
                  <h5 className="card-title" style={{ textAlign: "center" }}>
                    Automation Systems for Gates
                  </h5>
                  <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3"
              style={{ alignContent: "center", display: "inline" }}
            >
              <div className="card mb-2">
                <img
                  className="card-img-top"
                  width="255"
                  height="250"
                  src={product2}
                  //  onClick={FaacBarriers}
                  onClick={() => {
                    history.push("/automations-for-barriers");
                  }}
                  style={{ cursor: "pointer" }}
                  alt="Product2"
                />
                <div className="card-body" style={{ textAlign: "center" }}>
                  <h5 className="card-title">Vehicle Barriers</h5>
                  <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <div
          class="modal fade"
          id="exampleModal10"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Product Details
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ padding: 20 }}>
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <iframe
                      scrolling="no"
                      src="https://forms.zohopublic.in/sales80/form/ContactUs/formperma/o3sKh3PHKyQ5GmkDmlz4rY0NM2edU5IF_pl9MYAjDoA?zf_rszfm=1"
                      id="gform"
                      width="100%"
                      height="990"
                      frameborder="0"
                      marginheight="0"
                      marginwidth="0"
                    >
                      Loading…
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer id="footer"></footer>
    </div>
  );
}
