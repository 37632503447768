import React, { Component } from "react";
import Header from "../header";
import "../FAAC/Aboutus.css";
// import "../FAAC/Aboutus.css";
import { Helmet } from 'react-helmet';

const Image1 =
  "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/img-141.jpg";
const Image2 =
  "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/img-142.jpg";
const Image3 =
  "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/img-143.jpg";
const Image4 =
  "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/img-144.jpg";
const Image5 =
  "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/img-164.jpg";
const Image6 =
  "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/img-145.jpg";
const Image7 =
  "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/img-146.jpg";
const Image8 =
  "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/img-147.jpg";
const Image9 =
  "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/img-148.jpg";
const Image10 =
  "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/img-149.jpg";
const Image11 =
  "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/img-150.jpg";
const Image12 =
  "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/img-163.jpg";

export default class AboutUs extends Component {
  constructor(props) {
    super(props);
  }

  toggle = (id, id2) => {
    var n = document.getElementById(id);
    if (n.style.display != "none") {
      n.style.display = "none";
      document.getElementById(id2).setAttribute("aria-expanded", "false");
    } else {
      n.style.display = "";
      document.getElementById(id2).setAttribute("aria-expanded", "true");
    }
  };
  render() {
    return (
      <div>
        <Helmet>
        <title>Gaido Technologies | FAAC, Italy | FAAC Boom Barrier | India | Best Quality</title>
        <meta property="og:title" content="Gaido Technologies | FAAC, Italy | FAAC Boom Barrier | India | Best Quality" />
        <meta property="og:url" content="https://www.gaido.in/faac/aboutus" />
      </Helmet>
        <div>
          {" "}
          <Header />
        </div>

        <div className="desktop" >
          <div className="sector3">
            {/* <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 "></div>
            <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 "></div>
          </div> */}
            <div className="row">
              <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

              <div className="col-lg-5 col-sm-5 col-md-5 col-xs-5 ">
                <div className="white-block3">
                  <h1 className="Header" style={{ fontWeight: "bold" }}>
                    5 DECADES, A STORY THAT CHANGED HISTORY.&nbsp; &nbsp;
                  </h1>
                  <br />
                  <p>
                    Our milestone 50 years of business, for example, lead us to
                    head towards even more ambitious aims and increasingly more
                    significant results.
                  </p>
                </div>
              </div>
              <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
              <div className="col-lg-4 col-sm-4 col-md-4 col-xs-4">
                <img
                  className="img-fluid "
                  //    style={{width:"1250px",height:"600px"}}
                  src="https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/img-145.jpg"
                ></img>
              </div>
            </div>
          </div>
          <div>
            {" "}
            <div className="history">
              <div className="row history-row">
                <div
                  className="col-lg-2 col-sm-2 col-md-2 col-xs-2 history-content"
                  style={{ height: 50, backgroundColor: "gray" }}
                >
                  1965/1974{" "}
                </div>
                {/* <div
                className="col-lg-1 col-sm-1 col-md-1 col-xs-1 "
              >
              </div> */}
                <div
                  className="col-lg-9 col-sm-9 col-md-9 col-xs-9 history-content"
                  style={{ height: 50, backgroundColor: "gray" }}
                >
                  <a
                    aria-expanded="true"
                    className="link"
                    id="TLink1"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.toggle("content1", "TLink1")}
                  >
                    AN INGENIOUS SPIRIT OF OBSERVATION! &nbsp; &nbsp;
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white" }}
                    ></i>{" "}
                  </a>
                </div>
              </div>
              <div className="row">
                {/* <div
              style={{
                height: 25,
                borderLeft: "2px solid black",
                marginLeft: 50,
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div> */}
                <div
                  id="content1"
                  className="col-lg-12 col-sm-12 col-md-12 col-xs-12 imagecontent "
                  style={{
                    backgroundImage: `url(${Image1})`,
                    backgroundSize: "cover",
                    height: "100vh",
                    fontSize: 20,
                    marginTop: 30,
                    color: "white",
                    display: "none",
                    // backgroundPositionX: "center",
                    // backgroundPositionY: "center",
                  }}
                >
                  {/* data-fontsize="30" style="--fontSize: 30; line-height: 1.2;" data-lineheight="36px"  */}

                  <div className="row">
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                    <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6 text">
                      <p></p>
                      <p>
                        In <b>1965 Giuseppe Manini</b>, then a building
                        contractor, noticed that the gates of blocks of flats
                        were always left open. You needed to get out of the car
                        to open them, but no-one ever went back to close them
                        afterwards.
                      </p>
                      <h4>The solution? He’s got it.</h4>
                      <p></p>
                      <p>
                        In keeping with the <strong>great inventors</strong>{" "}
                        that preceded him, Manini crafts{" "}
                        <strong>
                          little masterpieces of hydraulic technology
                        </strong>{" "}
                        himself. The result is the forebear of the FAAC product
                        family:{" "}
                        <strong>
                          the 750 underground operator, the first automatic gate
                          movement system
                        </strong>
                        .<br />
                        The 750 is followed by the{" "}
                        <strong>400, the first on-swing-gate model</strong>, and
                        the main products in the current hydraulic range, which
                        necessitate the creation of a genuine{" "}
                        <strong>sales network</strong> for their distribution.{" "}
                      </p>
                      <p></p>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  height: 25,
                  borderLeft: "2px solid black",
                  marginLeft: 50,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></div>
              <div className="row">
                <div
                  className="col-lg-2 col-sm-2 col-md-2 col-xs-2 history-content"
                  style={{ height: 50, backgroundColor: "gray" }}
                >
                  1975/1984
                </div>
                <div
                  className="col-lg-9 col-sm-9 col-md-9 col-xs-9 history-content"
                  style={{ height: 50, backgroundColor: "gray" }}
                >
                  <a
                    aria-expanded="true"
                    className="link"
                    id="TLink2"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.toggle("content2", "TLink2")}
                  >
                    EXPANSION AND CONSOLIDATION &nbsp; &nbsp;
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white" }}
                    ></i>{" "}
                  </a>
                </div>
              </div>
              <div className="row">
                {/* <div
              style={{
                height: 25,
                borderLeft: "2px solid black",
                marginLeft: 50,
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div> */}
                <div id="content2" style={{ display: "none" }}>
                  <div
                    className="col-lg-12 col-sm-12 col-md-12 col-xs-12 "
                    style={{
                      backgroundImage: `url(${Image2})`,
                      fontSize: 20,
                      height: "100vh",
                      color: "black",
                      backgroundSize: "cover",
                      backgroundPositionX: "center",
                      backgroundPositionY: "center",
                    }}
                  >
                    {/* data-fontsize="30" style="--fontSize: 30; line-height: 1.2;" data-lineheight="36px"  */}

                    <div className="row">
                      <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                      <div
                        className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                        style={{ paddingTop: 100, paddingBottom: 100 }}
                      >
                        <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                          <p></p>
                          <h3
                            style={{
                              textAlign: "center",
                              textTransform: "uppercase",
                            }}
                          >
                            The idea’s a real hit!
                          </h3>
                          <br />
                          <p>
                            These were the years of the first great "
                            <strong>gamble</strong>", when our company’s
                            original small-scale production system was
                            transformed into an{" "}
                            <strong>industrial mass production system</strong>{" "}
                            capable of maintaining{" "}
                            <strong>high quality standards</strong>.
                          </p>
                          <p>
                            The <strong>402</strong> was the first product
                            created by the new series production system.{" "}
                            <strong>Die-cast aluminium moulds</strong> were
                            introduced, new materials like{" "}
                            <strong>POM for valves</strong> were developed, and{" "}
                            <strong>Gerotor pumps</strong>, quieter and more
                            efficient than gear pumps, were adopted: these and
                            other components all helped{" "}
                            <strong>improve performance</strong> and product
                            innovation.
                          </p>
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-12 col-sm-12 col-md-12 col-xs-12  "
                    style={{
                      backgroundImage: `url(${Image3})`,
                      fontSize: 20,
                      color: "black",
                      backgroundSize: "cover",
                      backgroundPositionX: "center",
                      backgroundPositionY: "center",
                    }}
                  >
                    {/* data-fontsize="30" style="--fontSize: 30; line-height: 1.2;" data-lineheight="36px"  */}

                    <div className="row">
                      <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                      <div
                        className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                        style={{ paddingTop: 100, paddingBottom: 100 }}
                      >
                        <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                          <p></p>
                          <h3
                            style={{
                              textAlign: "center",
                              textTransform: "uppercase",
                            }}
                          >
                            Expansion and consolidation.
                          </h3>
                          <br />
                          <p>
                            <strong>The first subsidiaries opened</strong>.
                            Connected directly to the central plant, they
                            ensured distribution of products abroad.{" "}
                          </p>
                          <p>
                            <strong>The first training courses began</strong> in
                            parallel. These were essential for building and
                            above all "educating" the first{" "}
                            <strong>specialised sales force</strong>.
                          </p>
                          <p>
                            <strong>Success</strong> extended outside Italy and
                            led to the opening of{" "}
                            <strong>two affiliates</strong>. At the same time,
                            in <strong>Zola Predosa</strong>, what in future
                            years was to become the{" "}
                            <strong>headquarters</strong> of an increasingly
                            forward-looking group was opened.{" "}
                          </p>
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: 25,
                  borderLeft: "2px solid black",
                  marginLeft: 50,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></div>
              <div>
                <div className="row">
                  <div
                    className="col-lg-2 col-sm-2 col-md-2 col-xs-2 history-content"
                    style={{ height: 50, backgroundColor: "gray" }}
                  >
                    1985/1994
                  </div>
                  <div
                    className="col-lg-9 col-sm-9 col-md-9 col-xs-9 history-content"
                    style={{ height: 50, backgroundColor: "gray" }}
                  >
                    <a
                      aria-expanded="true"
                      className="link"
                      id="TLink3"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.toggle("content3", "TLink3")}
                    >
                      THE START OF THE ELECTRONIC ERA &nbsp; &nbsp;
                      <i
                        class="fa-solid fa-angle-down"
                        style={{ color: "white" }}
                      ></i>{" "}
                    </a>
                  </div>
                </div>
                <div className="row">
                  {/* <div
              style={{
                height: 25,
                borderLeft: "2px solid black",
                marginLeft: 50,
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div> */}
                  <div id="content3" style={{ display: "none" }}>
                    <div
                      className="col-lg-12 col-sm-12 col-md-12 col-xs-12 "
                      style={{
                        backgroundImage: `url(${Image4})`,
                        fontSize: 20,
                        height: "200vh",

                        color: "black",
                        // filter: "grayscale(100%)",
                        backgroundSize: "cover",
                        backgroundPositionX: "center",
                        backgroundPositionY: "top",
                      }}
                    >
                      {/* data-fontsize="30" style="--fontSize: 30; line-height: 1.2;" data-lineheight="36px"  */}

                      <div className="row">
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                        <div
                          className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                          style={{ paddingTop: 200, paddingBottom: 200 }}
                        >
                          <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                            <p></p>
                            <h3
                              style={{
                                textAlign: "center",
                                textTransform: "uppercase",
                              }}
                            >
                              The start of the electronic era
                            </h3>
                            <p>
                              <strong>The development of electronics </strong>
                              and the implementation of technologies previously
                              only found in the field of personal computers
                              heralded a <strong>new era</strong> for{" "}
                              <strong>FAAC</strong> products. The introduction
                              of{" "}
                              <strong>
                                microprocessor electronic control cards
                              </strong>{" "}
                              permitted the development of installations with
                              far more functionalities than simple opening and
                              closing.
                            </p>
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-12 col-sm-12 col-md-12 col-xs-12  "
                      style={{
                        backgroundImage: `url(${Image5})`,
                        fontSize: 20,
                        color: "black",
                        height: "150vh",
                        backgroundSize: "cover",
                        backgroundPositionX: "center",
                        backgroundPositionY: "top",
                      }}
                    >
                      {/* data-fontsize="30" style="--fontSize: 30; line-height: 1.2;" data-lineheight="36px"  */}

                      <div className="row">
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                        <div
                          className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                          style={{ paddingTop: 100, paddingBottom: 100 }}
                        >
                          <div class="delay0 eds-on-scroll eds-scroll-visible animated bounceInRight duration2">
                            <h3
                              style={{
                                textAlign: "center",
                                textTransform: "uppercase",
                              }}
                            >
                              Production crosses the Channel{" "}
                            </h3>
                            <br />
                            <div class="text-wrap">
                              <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                                <p></p>
                                <p>
                                  FAAC’s <strong>quest for quality</strong> in
                                  all areas led to the start of electronic
                                  component production in{" "}
                                  <strong>Ireland</strong>, where the{" "}
                                  <strong>world’s best components</strong> were
                                  produced, serving the needs of some of the
                                  largest international players in the
                                  electronics market. This same period saw the
                                  arrival of <strong>BLUE LINE</strong>, the
                                  first ever range of accessories with a{" "}
                                  <strong>standard design</strong>.
                                </p>
                                <p></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-lg-12 col-sm-12 col-md-12 col-xs-12  "
                      style={{
                        backgroundImage: `url(${Image6})`,
                        fontSize: 20,
                        color: "black",
                        height: "150vh",
                        // width: "100%",
                        // filter: "grayscale(100%)",
                        backgroundSize: "cover",

                        // backgroundPositionX: "center",
                        // backgroundPositionY: "center",
                      }}
                    >
                      {/* data-fontsize="30" style="--fontSize: 30; line-height: 1.2;" data-lineheight="36px"  */}
                      <div className="row">
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>

                        <div
                          className="col-lg-6 col-sm-6 col-md-6 col-xs-6 productimage"
                          style={
                            {
                              // paddingTop: 600,
                            }
                          }
                        >
                          <div
                            class="text-bg"
                            style={{
                              backgroundColor: "rgba(207, 207, 207, 0.8)",
                              width: "100%",
                              height: "100%",
                              padding: 30,
                            }}
                          >
                            <div class="delay0 eds-on-scroll eds-scroll-visible animated bounceInRight duration2">
                              <h3
                                style={{
                                  textAlign: "center",
                                  textTransform: "uppercase",
                                }}
                                // data-fontsize="37"
                                // style="--fontSize: 37; line-height: 1.14;"
                                // data-lineheight="42.18px"
                                // class="fusion-responsive-typography-calculated"
                              >
                                New solutions and yet more patents.
                              </h3>
                            </div>{" "}
                            <div class="text-wrap">
                              <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                                <p></p>
                                <p>
                                  The introduction of electromechanical products
                                  extended the user base by offering{" "}
                                  <strong>cheaper technology</strong> without{" "}
                                  <strong>compromising on quality</strong>. In
                                  parallel, FAAC continued to protect its ideas
                                  with a <strong>long list of patents</strong>,
                                  including a doubly futuristic{" "}
                                  <strong>
                                    transmitter with Self Learning technology
                                    (today’s SLH)
                                  </strong>
                                  .
                                </p>
                                <p>
                                  This invention made it possible to equip
                                  systems with transmitters to{" "}
                                  <strong>
                                    eliminate the need to introduce rolling
                                    codes on site
                                  </strong>
                                  . The automatic generation of these codes by
                                  algorithm made it{" "}
                                  <strong>
                                    far harder to duplicate the transmitter
                                  </strong>
                                  , significantly improving system security.
                                </p>
                                <p></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: 25,
                    borderLeft: "2px solid black",
                    marginLeft: 50,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></div>
                <div className="row">
                  <div
                    className="col-lg-2 col-sm-2 col-md-2 col-xs-2 history-content"
                    style={{ height: 50, backgroundColor: "gray" }}
                  >
                    1995/2004{" "}
                  </div>
                  <div
                    className="col-lg-9 col-sm-9 col-md-9 col-xs-9 history-content"
                    style={{ height: 50, backgroundColor: "gray" }}
                  >
                    <a
                      aria-expanded="true"
                      className="link"
                      id="TLink4"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.toggle("content4", "TLink4")}
                    >
                      ADVANCED TECHNOLOGY, COMPACT DIMENSIONS &nbsp; &nbsp;
                      <i
                        class="fa-solid fa-angle-down"
                        style={{ color: "white" }}
                      ></i>{" "}
                    </a>
                  </div>
                </div>

                <div className="row">
                  {/* <div
              style={{
                height: 25,
                borderLeft: "2px solid black",
                marginLeft: 50,
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div> */}
                  <div id="content4" style={{ display: "none" }}>
                    <div
                      className="col-lg-12 col-sm-12 col-md-12 col-xs-12 "
                      style={{
                        backgroundImage: `url(${Image7})`,
                        fontSize: 20,
                        height: "200vh",

                        color: "black",
                        // filter: "grayscale(100%)",
                        backgroundSize: "cover",
                        backgroundPositionX: "center",
                        backgroundPositionY: "top",
                      }}
                    >
                      {/* data-fontsize="30" style="--fontSize: 30; line-height: 1.2;" data-lineheight="36px"  */}

                      <div className="row">
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                        <div
                          className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                          style={{ paddingTop: 200, paddingBottom: 200 }}
                        >
                          <div class="text-bg">
                            <div class="delay0 eds-on-scroll eds-scroll-visible animated bounceInRight duration2">
                              <h2
                                style={{
                                  textAlign: "center",
                                  textTransform: "uppercase",
                                }}

                                // data-fontsize="37"
                                // style="--fontSize: 37; line-height: 1.14;"
                                // data-lineheight="42.18px"
                                // class="fusion-responsive-typography-calculated"
                              >
                                Advanced Technology, Compact Dimensions.
                              </h2>
                              <br />
                            </div>{" "}
                            <div class="text-wrap">
                              <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                                <p></p>
                                <br></br>
                                <p>
                                  {" "}
                                  With the arrival of{" "}
                                  <strong>
                                    SMT (Surface Mount Technology)
                                  </strong>{" "}
                                  electronic control cards, as well the products
                                  that used them,{" "}
                                  <strong>became a lot smaller</strong>.
                                  Miniaturised transmitters paved the way for
                                  the{" "}
                                  <strong>pocket size remote controls</strong>{" "}
                                  we are so familiar with today.{" "}
                                </p>
                                <p>
                                  Major investments in design led to the
                                  development of{" "}
                                  <strong>increasingly complex</strong> and{" "}
                                  <strong>smart</strong> control cards as well
                                  as the technology needed to manufacture them.
                                  The <strong>BUS 2easy</strong> two-wire system
                                  for dialogue between control cards and
                                  accessories was introduced, dramatically{" "}
                                  <strong>simplifying the installation</strong>{" "}
                                  of systems and peripherals.
                                </p>
                                <p></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-12 col-sm-12 col-md-12 col-xs-12  "
                      style={{
                        backgroundImage: `url(${Image8})`,
                        fontSize: 20,
                        color: "black",
                        height: "150vh",
                        backgroundSize: "cover",
                        backgroundPositionX: "center",
                        backgroundPositionY: "top",
                      }}
                    >
                      {/* data-fontsize="30" style="--fontSize: 30; line-height: 1.2;" data-lineheight="36px"  */}

                      <div className="row">
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                        <div
                          className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                          style={{ paddingTop: 100, paddingBottom: 100 }}
                        >
                          <div
                            class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3"
                            style={{
                              backgroundColor: "rgba(207, 207, 207, 0.8)",
                              width: "100%",
                              height: "100%",
                              padding: 30,
                            }}
                          >
                            <p></p>
                            <p>
                              Commitment to quality demanded greater control
                              over the product design process.{" "}
                              <strong>The introduction of a 3D CAD</strong>{" "}
                              system speeded up prototyping and the development
                              of tools for components. It also became possible
                              to calculate and{" "}
                              <strong>
                                avoid potential points of interference
                              </strong>{" "}
                              on assembly, something that was simply not
                              possible with the old 2D design system.
                            </p>
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: 25,
                    borderLeft: "2px solid black",
                    marginLeft: 50,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></div>

                <div className="row">
                  <div
                    className="col-lg-2 col-sm-2 col-md-2 col-xs-2 history-content"
                    style={{ height: 50, backgroundColor: "gray" }}
                  >
                    2005/2014{" "}
                  </div>
                  <div
                    className="col-lg-9 col-sm-9 col-md-9 col-xs-9 history-content"
                    style={{ height: 50, backgroundColor: "gray" }}
                  >
                    <a
                      aria-expanded="true"
                      className="link"
                      id="TLink5"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.toggle("content5", "TLink5")}
                    >
                      INNOVATION AND SAFETY &nbsp; &nbsp;&nbsp; &nbsp;
                      <i
                        class="fa-solid fa-angle-down"
                        style={{ color: "white" }}
                      ></i>{" "}
                    </a>
                  </div>
                </div>
                <div className="row">
                  {/* <div
              style={{
                height: 25,
                borderLeft: "2px solid black",
                marginLeft: 50,
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div> */}
                  <div id="content5" style={{ display: "none" }}>
                    <div
                      className="col-lg-12 col-sm-12 col-md-12 col-xs-12 "
                      style={{
                        backgroundImage: `url(${Image9})`,
                        fontSize: 20,
                        height: "200vh",

                        color: "black",
                        // filter: "grayscale(100%)",
                        backgroundSize: "cover",
                        backgroundPositionX: "center",
                        backgroundPositionY: "top",
                      }}
                    >
                      {/* data-fontsize="30" style="--fontSize: 30; line-height: 1.2;" data-lineheight="36px"  */}

                      <div className="row">
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                        <div
                          className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                          style={{ paddingTop: 100, paddingBottom: 200 }}
                        >
                          <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                            <p></p>
                            <h3
                              style={{
                                textAlign: "center",
                                textTransform: "uppercase",
                              }}
                            >
                              Innovation and safety.
                            </h3>
                            <br />
                            <p>
                              The implementation of{" "}
                              <strong>24 V technology </strong>not only{" "}
                              <strong>improved motor control</strong> but also
                              allowed systems to conform to the latest safety
                              standards without the need for additional safety
                              devices.
                            </p>
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-12 col-sm-12 col-md-12 col-xs-12  "
                      style={{
                        backgroundImage: `url(${Image10})`,
                        fontSize: 20,
                        color: "black",
                        height: "150vh",
                        backgroundSize: "cover",
                        backgroundPositionX: "center",
                        backgroundPositionY: "top",
                      }}
                    >
                      {/* data-fontsize="30" style="--fontSize: 30; line-height: 1.2;" data-lineheight="36px"  */}

                      <div className="row">
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                        <div
                          className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                          style={{ paddingTop: 100, paddingBottom: 100 }}
                        >
                          <div
                            class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3"
                            style={{
                              backgroundColor: "rgba(207, 207, 207, 0.8)",
                              width: "100%",
                              height: "100%",
                              padding: 30,
                            }}
                          >
                            <h2 style={{ textAlign: "center" }}>
                              CUSTOM SOLUTIONS
                            </h2>
                            <p></p>
                            <p>
                              {" "}
                              Though extremely efficient, mass production was no
                              longer able to satisfy the growing demand for
                              customised products. <strong>FAAC</strong>{" "}
                              therefore adopted the{" "}
                              <strong>TOYOTA SYSTEM</strong>. Production lines
                              were organised according to the{" "}
                              <strong>ONE PIECE FLOW</strong> principle. Each
                              product{" "}
                              <strong>was assembled in a single cycle</strong>{" "}
                              to the benefit of{" "}
                              <strong>better flexibility</strong>,{" "}
                              <strong>
                                higher quality and greater production capacity
                              </strong>
                              .
                            </p>
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-lg-12 col-sm-12 col-md-12 col-xs-12  "
                      style={{
                        backgroundImage: `url(${Image11})`,
                        fontSize: 20,
                        color: "black",
                        height: "150vh",
                        // width: "100%",
                        // filter: "grayscale(100%)",
                        backgroundSize: "cover",

                        // backgroundPositionX: "center",
                        // backgroundPositionY: "center",
                      }}
                    >
                      {/* data-fontsize="30" style="--fontSize: 30; line-height: 1.2;" data-lineheight="36px"  */}
                      <div className="row">
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>

                        <div
                          className="col-lg-6 col-sm-6 col-md-6 col-xs-6 productimage"
                          style={{
                            backgroundColor: "rgba(207, 207, 207, 0.8)",
                            width: "100%",
                            height: "100%",
                            paddingTop: 10,
                          }}
                        >
                          <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                            <p></p>
                            <h2 style={{ textAlign: "center" }}>
                              SMART LOGISTICS
                            </h2>
                            <p>
                              <strong>Logistics</strong> were reviewed in a
                              similar way: the small warehouses dotted around
                              the country were replaced by{" "}
                              <strong>larger units</strong> capable of stocking
                              more products and{" "}
                              <strong>processing requests more quickly</strong>.
                            </p>
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: 25,
                    borderLeft: "2px solid black",
                    marginLeft: 50,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></div>
                <div className="row">
                  <div
                    className="col-lg-2 col-sm-2 col-md-2 col-xs-2 history-content"
                    style={{ height: 50, backgroundColor: "gray" }}
                  >
                    2015....{" "}
                  </div>
                  <div
                    className="col-lg-9 col-sm-9 col-md-9 col-xs-9 history-content"
                    style={{ height: 50, backgroundColor: "gray" }}
                  >
                    <a
                      aria-expanded="true"
                      className="link"
                      id="TLink7"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.toggle("content7", "TLink7")}
                    >
                      BUILDING A SOLID FUTURE &nbsp; &nbsp;
                      <i
                        class="fa-solid fa-angle-down"
                        style={{ color: "white" }}
                      ></i>{" "}
                    </a>
                  </div>
                </div>
                <div className="row">
                  {/* <div
              style={{
                height: 25,
                borderLeft: "2px solid black",
                marginLeft: 50,
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div> */}
                  <div id="content7" style={{ display: "none" }}>
                    <div
                      className="col-lg-12 col-sm-12 col-md-12 col-xs-12 "
                      style={{
                        fontSize: 20,

                        color: "black",
                      }}
                    >
                      {/* data-fontsize="30" style="--fontSize: 30; line-height: 1.2;" data-lineheight="36px"  */}

                      <div className="row">
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                        <div
                          className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                          style={{ paddingTop: 100, paddingBottom: 100 }}
                        >
                          <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                            <p></p>
                            <h1 style={{ textAlign: "center" }}>
                              WHEN IDEAS CAN CHANGE THE WORLD
                            </h1>
                            <br></br>
                            <p>
                              <strong>
                                Innovation, expertise and the courage
                              </strong>{" "}
                              to pioneer new roads: these{" "}
                              <strong>values</strong> have characterised the
                              entire history of the <strong>AAC Group</strong>.
                              Every step has been typified by a{" "}
                              <strong>forward-looking</strong> approach and a
                              pioneering spirit – essential attributes for a
                              company committed to building a{" "}
                              <strong>solid future.</strong>
                            </p>
                            <p></p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>

                        {/* <hr></hr> */}
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                        <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                          <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                            <p></p>
                            <h1 style={{ textAlign: "center" }}>
                              50 YEARS OF SUCCESS
                            </h1>
                            <br></br>
                            <p>
                              50 years after its formation, FAAC wishes to pay
                              homage to these values by celebrating the life of
                              Giuseppe Manini, the first to seek out new
                              horizons and the first to adopt the approach that
                              now characterises our company, our employees and
                              our partners.
                            </p>
                            <p></p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                      </div>
                    </div>

                    <div
                      className="col-lg-12 col-sm-12 col-md-12 col-xs-12  "
                      style={{
                        backgroundImage: `url(${Image12})`,
                        fontSize: 20,
                        color: "black",
                        height: "100%",
                        // width: "100%",
                        // filter: "grayscale(100%)",
                        backgroundSize: "cover",
                        // backgroundPositionX: "center",
                        // backgroundPositionY: "center",
                      }}
                    >
                      {/* data-fontsize="30" style="--fontSize: 30; line-height: 1.2;" data-lineheight="36px"  */}
                      <div className="row">
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>

                        <div
                          className="col-lg-6 col-sm-6 col-md-6 col-xs-6 productimage"
                          style={{
                            backgroundColor: "rgba(207, 207, 207, 0.8)",
                            width: "100%",
                            height: "100%",
                            paddingTop: 10,
                          }}
                        >
                          <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                            <p></p>
                            <h2 style={{ textAlign: "center" }}>COMPANIES </h2>
                            <div className="row">
                              <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                                <dl class="date-list">
                                  <dt>1979</dt>
                                  <dd>
                                    <ul>
                                      <li>FAAC Switzerland</li>
                                    </ul>
                                  </dd>
                                  <dt>1981</dt>
                                  <dd>
                                    <ul>
                                      <li>FAAC France</li>
                                    </ul>
                                  </dd>
                                  <dt>1984</dt>
                                  <dd>
                                    <ul>
                                      <li>FAAC GMBH</li>
                                    </ul>
                                  </dd>
                                  <dt>1985</dt>
                                  <dd>
                                    <ul>
                                      <li>FAAC U.K</li>
                                    </ul>
                                  </dd>
                                  <dt>1988</dt>
                                  <dd>
                                    <ul>
                                      <li>FAAC U.S.A.</li>
                                    </ul>
                                  </dd>
                                  <dt>1989</dt>
                                  <dd>
                                    <ul>
                                      <li>FAAC Electronics Ltd. (FEL)</li>
                                    </ul>
                                  </dd>
                                  <dt>1990</dt>
                                  <dd>
                                    <ul>
                                      <li>FAAC Spain</li>
                                    </ul>
                                  </dd>
                                  <dt>1994</dt>
                                  <dd>
                                    <ul>
                                      <li>Genius</li>
                                    </ul>
                                  </dd>
                                  <dt>1999</dt>
                                  <dd>
                                    <ul>
                                      <li>Spazio Italia</li>
                                    </ul>
                                  </dd>
                                  <dt>2002</dt>
                                  <dd>
                                    <ul>
                                      <li>
                                        <p>FAAC Poland</p>
                                        <p>FAAC Scandinavia</p>
                                        <p>FAAC Benelux</p>
                                      </li>
                                    </ul>
                                  </dd>
                                </dl>
                              </div>
                              <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                                <dl class="date-list">
                                  <dt>2006</dt>
                                  <dd>
                                    <ul>
                                      <li>Altron</li>
                                    </ul>
                                  </dd>
                                  <dt>2007</dt>
                                  <dd>
                                    <ul>
                                      <li>
                                        <p>DAAB</p>
                                        <p>FAAC Australia</p>
                                        <p>FAAC Dubai</p>
                                        <p>FAAC Turkey</p>
                                        <p>FAAC Russia</p>
                                      </li>
                                    </ul>
                                  </dd>
                                  <dt>2010</dt>
                                  <dd>
                                    <ul>
                                      <li>Clemsa</li>
                                    </ul>
                                  </dd>
                                  <dt>2011</dt>
                                  <dd>
                                    <ul>
                                      <li>Kemko</li>
                                    </ul>
                                    <ul>
                                      <li>DSS Automatic Doors</li>
                                      <li>National Automation Ltd.</li>
                                    </ul>
                                  </dd>
                                  <dt>2012</dt>
                                  <dd>
                                    <ul>
                                      <li>
                                        <p>
                                          Industrias Rossi Eletromecanica SA
                                        </p>
                                      </li>
                                    </ul>
                                  </dd>
                                  <dt>2016</dt>
                                  <dd>
                                    <ul>
                                      <li>
                                        <p>CENTURION Systems Pty Ltd</p>
                                      </li>
                                    </ul>
                                  </dd>
                                </dl>
                              </div>
                            </div>
                            <div className="row">
                              <div class="delay0 eds-on-scroll eds-scroll-visible animated bounceInRight duration2">
                                <h3
                                  style={{ textAlign: "center" }}
                                  // style="--fontSize: 37; line-height: 1.14;"
                                  // class="fusion-responsive-typography-calculated"
                                >
                                  PRODUCT LINES
                                </h3>
                                <div
                                  class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3"
                                  style={{ margin: 50 }}
                                >
                                  <dl class="date-list">
                                    <dt>1995/2004</dt>
                                    <dd>
                                      <ul>
                                        <li>Access control range</li>
                                        <li>
                                          The 930 was the forerunner of our
                                          current range of AUTOMATIC DOORS.
                                        </li>
                                        <li>Roller shutter motor range</li>
                                      </ul>
                                    </dd>
                                    <dt>2005/2014</dt>
                                    <dd>
                                      <ul>
                                        <li>
                                          The FAAC City range was launched
                                          (precursors of our current J range of
                                          bollards)
                                        </li>
                                        <li>Tubular motors</li>
                                      </ul>
                                    </dd>
                                  </dl>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: 25,
                    marginLeft: 50,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></div>

                {/* <div
                className="row"
                style={{ height: 50, backgroundColor: "gray" }}
              ></div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="mobile" >
          <div className="sector3">
            {/* <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 "></div>
            <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 "></div>
          </div> */}
            <div className="row">
              <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

              <div className="col-lg-5 col-sm-5 col-md-5 col-xs-5 ">
                <div className="white-block3">
                  <h1 className="Header" style={{ fontWeight: "bold",fontSize:"20px" }}>
                    5 DECADES, A STORY THAT CHANGED HISTORY.&nbsp; &nbsp;
                  </h1>
                  <br />
                  <p>
                    Our milestone 50 years of business, for example, lead us to
                    head towards even more ambitious aims and increasingly more
                    significant results.
                  </p>
                </div>
              </div>
              <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
              <div className="col-lg-4 col-sm-4 col-md-4 col-xs-4">
                <img
                  className="img-fluid"
                  //    style={{width:"1250px",height:"600px"}}
                  src="https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/img-145.jpg"
                ></img>
              </div>
            </div>
          </div>
          <div>
            {" "}
            <div className="history">
              <div className="row history-row">
                <div
                  className="col-lg-2 col-sm-2 col-md-2 col-xs-2 history-content"
                  style={{ height: 50, backgroundColor: "gray" }}
                >
                  1965/1974{" "}
                </div>
                {/* <div
                className="col-lg-1 col-sm-1 col-md-1 col-xs-1 "
              >
              </div> */}
                <div
                  className="col-lg-9 col-sm-9 col-md-9 col-xs-9 history-content"
                  style={{ height: 50, backgroundColor: "gray" }}
                >
                  <a
                    aria-expanded="true"
                    className="link"
                    id="TLinkA"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.toggle("contentA", "TLinkA")}
                  >
                    AN INGENIOUS SPIRIT OF OBSERVATION! &nbsp; &nbsp;
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white" }}
                    ></i>{" "}
                  </a>
                </div>
              </div>
              <div className="row">
                {/* <div
              style={{
                height: 25,
                borderLeft: "2px solid black",
                marginLeft: 50,
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div> */}
                <div id="contentA" style={{ display: "none" }}>
                  <div className="row">
                    <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
                      <img className="img-fluid " src={Image1}></img>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                    <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6 text">
                      <p></p>
                      <p>
                        In <b>1965 Giuseppe Manini</b>, then a building
                        contractor, noticed that the gates of blocks of flats
                        were always left open. You needed to get out of the car
                        to open them, but no-one ever went back to close them
                        afterwards.
                      </p>
                      <h4>The solution? He’s got it.</h4>
                      <p></p>
                      <p>
                        In keeping with the <strong>great inventors</strong>{" "}
                        that preceded him, Manini crafts{" "}
                        <strong>
                          little masterpieces of hydraulic technology
                        </strong>{" "}
                        himself. The result is the forebear of the FAAC product
                        family:{" "}
                        <strong>
                          the 750 underground operator, the first automatic gate
                          movement system
                        </strong>
                        .<br />
                        The 750 is followed by the{" "}
                        <strong>400, the first on-swing-gate model</strong>, and
                        the main products in the current hydraulic range, which
                        necessitate the creation of a genuine{" "}
                        <strong>sales network</strong> for their distribution.{" "}
                      </p>
                      <p></p>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  height: 25,
                  borderLeft: "2px solid black",
                  marginLeft: 50,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></div>
              <div className="row">
                <div
                  className="col-lg-2 col-sm-2 col-md-2 col-xs-2 history-content"
                  style={{ height: 50, backgroundColor: "gray" }}
                >
                  1975/1984
                </div>
                <div
                  className="col-lg-9 col-sm-9 col-md-9 col-xs-9 history-content"
                  style={{ height: 50, backgroundColor: "gray" }}
                >
                  <a
                    aria-expanded="true"
                    className="link"
                    id="TLinkB"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.toggle("contentB", "TLinkB")}
                  >
                    EXPANSION AND CONSOLIDATION &nbsp; &nbsp;
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white" }}
                    ></i>{" "}
                  </a>
                </div>
              </div>
              <div className="row">
                {/* <div
              style={{
                height: 25,
                borderLeft: "2px solid black",
                marginLeft: 50,
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div> */}
                <div id="contentB" style={{ display: "none" }}>
                  <div className="row">
                    <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
                      <img className="img-fluid " src={Image2}></img>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                    <div
                      className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                     // style={{ paddingTop: 100, paddingBottom: 100 }}
                    >
                      <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                        <p></p>
                        <h3
                          style={{
                            textAlign: "center",
                            textTransform: "uppercase"
                            ,fontSize:"25px"
                          }}
                        >
                          The idea’s a real hit!
                        </h3>
                        <br />
                        <p>
                          These were the years of the first great "
                          <strong>gamble</strong>", when our company’s original
                          small-scale production system was transformed into an{" "}
                          <strong>industrial mass production system</strong>{" "}
                          capable of maintaining{" "}
                          <strong>high quality standards</strong>.
                        </p>
                        <p>
                          The <strong>402</strong> was the first product created
                          by the new series production system.{" "}
                          <strong>Die-cast aluminium moulds</strong> were
                          introduced, new materials like{" "}
                          <strong>POM for valves</strong> were developed, and{" "}
                          <strong>Gerotor pumps</strong>, quieter and more
                          efficient than gear pumps, were adopted: these and
                          other components all helped{" "}
                          <strong>improve performance</strong> and product
                          innovation.
                        </p>
                        <p></p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
                      <img className="img-fluid " src={Image3}></img>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                    <div
                      className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                      //style={{ paddingTop: 100, paddingBottom: 100 }}
                    >
                      <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                        <p></p>
                        <h3
                          style={{
                            textAlign: "center",
                            textTransform: "uppercase",
                            fontSize:"25px"
                          }}
                        >
                          Expansion and consolidation.
                        </h3>
                        <br />
                        <p>
                          <strong>The first subsidiaries opened</strong>.
                          Connected directly to the central plant, they ensured
                          distribution of products abroad.{" "}
                        </p>
                        <p>
                          <strong>The first training courses began</strong> in
                          parallel. These were essential for building and above
                          all "educating" the first{" "}
                          <strong>specialised sales force</strong>.
                        </p>
                        <p>
                          <strong>Success</strong> extended outside Italy and
                          led to the opening of <strong>two affiliates</strong>.
                          At the same time, in <strong>Zola Predosa</strong>,
                          what in future years was to become the{" "}
                          <strong>headquarters</strong> of an increasingly
                          forward-looking group was opened.{" "}
                        </p>
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: 25,
                  borderLeft: "2px solid black",
                  marginLeft: 50,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              ></div>
              <div>
                <div className="row">
                  <div
                    className="col-lg-2 col-sm-2 col-md-2 col-xs-2 history-content"
                    style={{ height: 50, backgroundColor: "gray" }}
                  >
                    1985/1994
                  </div>
                  <div
                    className="col-lg-9 col-sm-9 col-md-9 col-xs-9 history-content"
                    style={{ height: 50, backgroundColor: "gray" }}
                  >
                    <a
                      aria-expanded="true"
                      className="link"
                      id="TLinkC"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.toggle("contentC", "TLinkC")}
                    >
                      THE START OF THE ELECTRONIC ERA &nbsp; &nbsp;
                      <i
                        class="fa-solid fa-angle-down"
                        style={{ color: "white" }}
                      ></i>{" "}
                    </a>
                  </div>
                </div>
                <div className="row">
                  {/* <div
              style={{
                height: 25,
                borderLeft: "2px solid black",
                marginLeft: 50,
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div> */}
                  <div id="contentC" style={{ display: "none" }}>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
                        <img className="img-fluid " src={Image4}></img>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                      <div
                        className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                        // style={{ paddingTop: 200, paddingBottom: 200 }}
                      >
                        <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                          <p></p>
                          <h3
                            style={{
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontSize:"25px"
                            }}
                          >
                            The start of the electronic era
                          </h3>
                          <p>
                            <strong>The development of electronics </strong>
                            and the implementation of technologies previously
                            only found in the field of personal computers
                            heralded a <strong>new era</strong> for{" "}
                            <strong>FAAC</strong> products. The introduction of{" "}
                            <strong>
                              microprocessor electronic control cards
                            </strong>{" "}
                            permitted the development of installations with far
                            more functionalities than simple opening and
                            closing.
                          </p>
                          <p></p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
                        <img className="img-fluid " src={Image5}></img>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                      <div
                        className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      >
                        <div class="delay0 eds-on-scroll eds-scroll-visible animated bounceInRight duration2">
                          <h3
                            style={{
                              textAlign: "center",
                              textTransform: "uppercase",
                              fontSize:"25px"
                            }}
                          >
                            Production crosses the Channel{" "}
                          </h3>
                          <br />
                          <div class="text-wrap">
                            <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                              <p></p>
                              <p>
                                FAAC’s <strong>quest for quality</strong> in all
                                areas led to the start of electronic component
                                production in <strong>Ireland</strong>, where
                                the <strong>world’s best components</strong>{" "}
                                were produced, serving the needs of some of the
                                largest international players in the electronics
                                market. This same period saw the arrival of{" "}
                                <strong>BLUE LINE</strong>, the first ever range
                                of accessories with a{" "}
                                <strong>standard design</strong>.
                              </p>
                              <p></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
                        <img className="img-fluid " src={Image6}></img>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>

                      <div
                        className="col-lg-6 col-sm-6 col-md-6 col-xs-6 productimage"
                        style={
                          {
                            // paddingTop: 600,
                          }
                        }
                      >
                        <div
                          class="text-bg"
                          style={{
                            backgroundColor: "rgba(207, 207, 207, 0.8)",
                            width: "100%",
                            height: "100%",
                            padding: 30,
                          }}
                        >
                          <div class="delay0 eds-on-scroll eds-scroll-visible animated bounceInRight duration2">
                            <h3
                              style={{
                                textAlign: "center",
                                textTransform: "uppercase",
                                fontSize:"25px"
                              }}
                              // data-fontsize="37"
                              // style="--fontSize: 37; line-height: 1.14;"
                              // data-lineheight="42.18px"
                              // class="fusion-responsive-typography-calculated"
                            >
                              New solutions and yet more patents.
                            </h3>
                          </div>{" "}
                          <div class="text-wrap">
                            <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                              <p></p>
                              <p>
                                The introduction of electromechanical products
                                extended the user base by offering{" "}
                                <strong>cheaper technology</strong> without{" "}
                                <strong>compromising on quality</strong>. In
                                parallel, FAAC continued to protect its ideas
                                with a <strong>long list of patents</strong>,
                                including a doubly futuristic{" "}
                                <strong>
                                  transmitter with Self Learning technology
                                  (today’s SLH)
                                </strong>
                                .
                              </p>
                              <p>
                                This invention made it possible to equip systems
                                with transmitters to{" "}
                                <strong>
                                  eliminate the need to introduce rolling codes
                                  on site
                                </strong>
                                . The automatic generation of these codes by
                                algorithm made it{" "}
                                <strong>
                                  far harder to duplicate the transmitter
                                </strong>
                                , significantly improving system security.
                              </p>
                              <p></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: 25,
                    borderLeft: "2px solid black",
                    marginLeft: 50,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></div>
                <div className="row">
                  <div
                    className="col-lg-2 col-sm-2 col-md-2 col-xs-2 history-content"
                    style={{ height: 50, backgroundColor: "gray" }}
                  >
                    1995/2004{" "}
                  </div>
                  <div
                    className="col-lg-9 col-sm-9 col-md-9 col-xs-9 history-content"
                    style={{ height: 50, backgroundColor: "gray" }}
                  >
                    <a
                      aria-expanded="true"
                      className="link"
                      id="TLinkD"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.toggle("contentD", "TLinkD")}
                    >
                      ADVANCED TECHNOLOGY, COMPACT DIMENSIONS &nbsp; &nbsp;
                      <i
                        class="fa-solid fa-angle-down"
                        style={{ color: "white" }}
                      ></i>{" "}
                    </a>
                  </div>
                </div>

                <div className="row">
                  {/* <div
              style={{
                height: 25,
                borderLeft: "2px solid black",
                marginLeft: 50,
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div> */}
                  <div id="contentD" style={{ display: "none" }}>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
                        <img className="img-fluid " src={Image7}></img>
                      </div>

                      <div className="row">
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                        <div
                          className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                         // style={{ paddingTop: 200, paddingBottom: 200 }}
                        >
                          <div class="text-bg">
                            <div class="delay0 eds-on-scroll eds-scroll-visible animated bounceInRight duration2">
                              <h2
                                style={{
                                  textAlign: "center",
                                  textTransform: "uppercase",
                                  fontSize:"25px"
                                  ,padding:10
                                }}

                                // data-fontsize="37"
                                // style="--fontSize: 37; line-height: 1.14;"
                                // data-lineheight="42.18px"
                                // class="fusion-responsive-typography-calculated"
                              >
                                Advanced Technology, Compact Dimensions.
                              </h2>
                              <br />
                            </div>{" "}
                              <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3" style={{padding:20}}>
                                <p></p>
                                <p>
                                  {" "}
                                  With the arrival of{" "}
                                  <strong>
                                    SMT (Surface Mount Technology)
                                  </strong>{" "}
                                  electronic control cards, as well the products
                                  that used them,{" "}
                                  <strong>became a lot smaller</strong>.
                                  Miniaturised transmitters paved the way for
                                  the{" "}
                                  <strong>pocket size remote controls</strong>{" "}
                                  we are so familiar with today.{" "}
                                </p>
                                <p>
                                  Major investments in design led to the
                                  development of{" "}
                                  <strong>increasingly complex</strong> and{" "}
                                  <strong>smart</strong> control cards as well
                                  as the technology needed to manufacture them.
                                  The <strong>BUS 2easy</strong> two-wire system
                                  for dialogue between control cards and
                                  accessories was introduced, dramatically{" "}
                                  <strong>simplifying the installation</strong>{" "}
                                  of systems and peripherals.
                                </p>
                                <p></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
                        <img className="img-fluid " src={Image8}></img>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                      <div
                        className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                       // style={{ paddingTop: 100, paddingBottom: 100 }}
                      >
                        <div
                          class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3"
                          style={{
                            backgroundColor: "rgba(207, 207, 207, 0.8)",
                            width: "100%",
                            height: "100%",
                            padding: 30,
                          }}
                        >
                          <p></p>
                          <p>
                            Commitment to quality demanded greater control over
                            the product design process.{" "}
                            <strong>The introduction of a 3D CAD</strong> system
                            speeded up prototyping and the development of tools
                            for components. It also became possible to calculate
                            and{" "}
                            <strong>
                              avoid potential points of interference
                            </strong>{" "}
                            on assembly, something that was simply not possible
                            with the old 2D design system.
                          </p>
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: 25,
                    borderLeft: "2px solid black",
                    marginLeft: 50,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></div>

                <div className="row">
                  <div
                    className="col-lg-2 col-sm-2 col-md-2 col-xs-2 history-content"
                    style={{ height: 50, backgroundColor: "gray" }}
                  >
                    2005/2014{" "}
                  </div>
                  <div
                    className="col-lg-9 col-sm-9 col-md-9 col-xs-9 history-content"
                    style={{ height: 50, backgroundColor: "gray" }}
                  >
                    <a
                      aria-expanded="true"
                      className="link"
                      id="TLinkE"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.toggle("contentE", "TLinkE")}
                    >
                      INNOVATION AND SAFETY &nbsp; &nbsp;&nbsp; &nbsp;
                      <i
                        class="fa-solid fa-angle-down"
                        style={{ color: "white" }}
                      ></i>{" "}
                    </a>
                  </div>
                </div>
                <div className="row">
                  {/* <div
              style={{
                height: 25,
                borderLeft: "2px solid black",
                marginLeft: 50,
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div> */}
                  <div id="contentE" style={{ display: "none" }}>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
                        <img className="img-fluid " src={Image9}></img>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                      <div
                        className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                       // style={{ paddingTop: 100, paddingBottom: 200 }}
                      >
                        <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                          <p></p>
                          <h3
                            style={{
                              textAlign: "center",
                              textTransform: "uppercase",
                            }}
                          >
                            Innovation and safety.
                          </h3>
                          <br />
                          <p>
                            The implementation of{" "}
                            <strong>24 V technology </strong>not only{" "}
                            <strong>improved motor control</strong> but also
                            allowed systems to conform to the latest safety
                            standards without the need for additional safety
                            devices.
                          </p>
                          <p></p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
                        <img className="img-fluid " src={Image10}></img>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                      <div
                        className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                        // style={{ paddingTop: 100, paddingBottom: 100 }}
                      >
                        <div
                          class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3"
                          style={{
                            backgroundColor: "rgba(207, 207, 207, 0.8)",
                            width: "100%",
                            height: "100%",
                            padding: 30,
                          }}
                        >
                          <h2 style={{ textAlign: "center" }}>
                            CUSTOM SOLUTIONS
                          </h2>
                          <p></p>
                          <p>
                            {" "}
                            Though extremely efficient, mass production was no
                            longer able to satisfy the growing demand for
                            customised products. <strong>FAAC</strong> therefore
                            adopted the <strong>TOYOTA SYSTEM</strong>.
                            Production lines were organised according to the{" "}
                            <strong>ONE PIECE FLOW</strong> principle. Each
                            product{" "}
                            <strong>was assembled in a single cycle</strong> to
                            the benefit of <strong>better flexibility</strong>,{" "}
                            <strong>
                              higher quality and greater production capacity
                            </strong>
                            .
                          </p>
                          <p></p>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
                        <img className="img-fluid " src={Image11}></img>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>

                      <div
                        className="col-lg-6 col-sm-6 col-md-6 col-xs-6 productimage"
                        style={{
                          backgroundColor: "rgba(207, 207, 207, 0.8)",
                          width: "100%",
                          height: "100%",
                          paddingTop: 10,
                        }}
                      >
                        <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                          <p></p>
                          <h2 style={{ textAlign: "center" }}>
                            SMART LOGISTICS
                          </h2>
                          <p>
                            <strong>Logistics</strong> were reviewed in a
                            similar way: the small warehouses dotted around the
                            country were replaced by{" "}
                            <strong>larger units</strong> capable of stocking
                            more products and{" "}
                            <strong>processing requests more quickly</strong>.
                          </p>
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: 25,
                    borderLeft: "2px solid black",
                    marginLeft: 50,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></div>
                <div className="row">
                  <div
                    className="col-lg-2 col-sm-2 col-md-2 col-xs-2 history-content"
                    style={{ height: 50, backgroundColor: "gray" }}
                  >
                    2015....{" "}
                  </div>
                  <div
                    className="col-lg-9 col-sm-9 col-md-9 col-xs-9 history-content"
                    style={{ height: 50, backgroundColor: "gray" }}
                  >
                    <a
                      aria-expanded="true"
                      className="link"
                      id="TLinkF"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.toggle("contentF", "TLinkF")}
                    >
                      BUILDING A SOLID FUTURE &nbsp; &nbsp;
                      <i
                        class="fa-solid fa-angle-down"
                        style={{ color: "white" }}
                      ></i>{" "}
                    </a>
                  </div>
                </div>
                <div className="row">
                  {/* <div
              style={{
                height: 25,
                borderLeft: "2px solid black",
                marginLeft: 50,
                marginTop: 10,
                marginBottom: 10,
              }}
            ></div> */}
                  <div id="contentF" style={{ display: "none" }}>
                    <div
                      className="col-lg-12 col-sm-12 col-md-12 col-xs-12 "
                      style={{

                        color: "black",
                      }}
                    >
                      {/* data-fontsize="30" style="--fontSize: 30; line-height: 1.2;" data-lineheight="36px"  */}

                      <div className="row">
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                        <div
                          className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                        >
                          <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                            <p></p>
                            <h1 style={{ textAlign: "center",fontSize:"25px" }}>
                              WHEN IDEAS CAN CHANGE THE WORLD
                            </h1>
                            <br></br>
                            <p>
                              <strong>
                                Innovation, expertise and the courage
                              </strong>{" "}
                              to pioneer new roads: these{" "}
                              <strong>values</strong> have characterised the
                              entire history of the <strong>AAC Group</strong>.
                              Every step has been typified by a{" "}
                              <strong>forward-looking</strong> approach and a
                              pioneering spirit – essential attributes for a
                              company committed to building a{" "}
                              <strong>solid future.</strong>
                            </p>
                            <p></p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>

                        {/* <hr></hr> */}
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                        <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                          <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                            <p></p>
                            <h1 style={{ textAlign: "center",fontSize:"25px" }}>
                              50 YEARS OF SUCCESS
                            </h1>
                            <br></br>
                            <p>
                              50 years after its formation, FAAC wishes to pay
                              homage to these values by celebrating the life of
                              Giuseppe Manini, the first to seek out new
                              horizons and the first to adopt the approach that
                              now characterises our company, our employees and
                              our partners.
                            </p>
                            <p></p>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
                        <img className="img-fluid " src={Image12}></img>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>

                      <div
                        className="col-lg-6 col-sm-6 col-md-6 col-xs-6 productimage"
                        style={{
                          backgroundColor: "rgba(207, 207, 207, 0.8)",
                          width: "100%",
                          height: "100%",
                          paddingTop: 10,
                        }}
                      >
                        <div class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3">
                          <p></p>
                          <h2 style={{ textAlign: "center" }}>COMPANIES </h2>
                          <div className="row">
                            <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                              <dl class="date-list">
                                <dt>1979</dt>
                                <dd>
                                  <ul>
                                    <li>FAAC Switzerland</li>
                                  </ul>
                                </dd>
                                <dt>1981</dt>
                                <dd>
                                  <ul>
                                    <li>FAAC France</li>
                                  </ul>
                                </dd>
                                <dt>1984</dt>
                                <dd>
                                  <ul>
                                    <li>FAAC GMBH</li>
                                  </ul>
                                </dd>
                                <dt>1985</dt>
                                <dd>
                                  <ul>
                                    <li>FAAC U.K</li>
                                  </ul>
                                </dd>
                                <dt>1988</dt>
                                <dd>
                                  <ul>
                                    <li>FAAC U.S.A.</li>
                                  </ul>
                                </dd>
                                <dt>1989</dt>
                                <dd>
                                  <ul>
                                    <li>FAAC Electronics Ltd. (FEL)</li>
                                  </ul>
                                </dd>
                                <dt>1990</dt>
                                <dd>
                                  <ul>
                                    <li>FAAC Spain</li>
                                  </ul>
                                </dd>
                                <dt>1994</dt>
                                <dd>
                                  <ul>
                                    <li>Genius</li>
                                  </ul>
                                </dd>
                                <dt>1999</dt>
                                <dd>
                                  <ul>
                                    <li>Spazio Italia</li>
                                  </ul>
                                </dd>
                                <dt>2002</dt>
                                <dd>
                                  <ul>
                                    <li>
                                      <p>FAAC Poland</p>
                                      <p>FAAC Scandinavia</p>
                                      <p>FAAC Benelux</p>
                                    </li>
                                  </ul>
                                </dd>
                              </dl>
                            </div>
                            <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                              <dl class="date-list">
                                <dt>2006</dt>
                                <dd>
                                  <ul>
                                    <li>Altron</li>
                                  </ul>
                                </dd>
                                <dt>2007</dt>
                                <dd>
                                  <ul>
                                    <li>
                                      <p>DAAB</p>
                                      <p>FAAC Australia</p>
                                      <p>FAAC Dubai</p>
                                      <p>FAAC Turkey</p>
                                      <p>FAAC Russia</p>
                                    </li>
                                  </ul>
                                </dd>
                                <dt>2010</dt>
                                <dd>
                                  <ul>
                                    <li>Clemsa</li>
                                  </ul>
                                </dd>
                                <dt>2011</dt>
                                <dd>
                                  <ul>
                                    <li>Kemko</li>
                                  </ul>
                                  <ul>
                                    <li>DSS Automatic Doors</li>
                                    <li>National Automation Ltd.</li>
                                  </ul>
                                </dd>
                                <dt>2012</dt>
                                <dd>
                                  <ul>
                                    <li>
                                      <p>Industrias Rossi Eletromecanica SA</p>
                                    </li>
                                  </ul>
                                </dd>
                                <dt>2016</dt>
                                <dd>
                                  <ul>
                                    <li>
                                      <p>CENTURION Systems Pty Ltd</p>
                                    </li>
                                  </ul>
                                </dd>
                              </dl>
                            </div>
                          </div>
                          <div className="row">
                            <div class="delay0 eds-on-scroll eds-scroll-visible animated bounceInRight duration2">
                              <h3
                                style={{ textAlign: "center" }}
                                // style="--fontSize: 37; line-height: 1.14;"
                                // class="fusion-responsive-typography-calculated"
                              >
                                PRODUCT LINES
                              </h3>
                              <div
                                class="delay0 eds-on-scroll eds-scroll-visible animated fadeIn duration3"
                                style={{ margin: 50 }}
                              >
                                <dl class="date-list">
                                  <dt>1995/2004</dt>
                                  <dd>
                                    <ul>
                                      <li>Access control range</li>
                                      <li>
                                        The 930 was the forerunner of our
                                        current range of AUTOMATIC DOORS.
                                      </li>
                                      <li>Roller shutter motor range</li>
                                    </ul>
                                  </dd>
                                  <dt>2005/2014</dt>
                                  <dd>
                                    <ul>
                                      <li>
                                        The FAAC City range was launched
                                        (precursors of our current J range of
                                        bollards)
                                      </li>
                                      <li>Tubular motors</li>
                                    </ul>
                                  </dd>
                                </dl>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: 25,
                    marginLeft: 50,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></div>

                {/* <div
                className="row"
                style={{ height: 50, backgroundColor: "gray" }}
              ></div> */}
              </div>
            </div>
          </div>
        </div>
        <footer id="footer"></footer>
      </div>
    );
  }
}
