import React, { Component } from "react";
import Header from "./header";
import Footer from "./Footer";
import DropusLIne from "./GlobalConsants/DropusLIne";
import GaidoEdge from "./GaidoEdge/GaidoEdge";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as CorouselHome } from "react-responsive-carousel";
import image1 from "./image6.jpg";
import image2 from "./image9.jpg";
import image3 from "./image13.jpg";
import image4 from "./image6-mobile.jpeg";
import image5 from "./image9-mobile.jpeg";
import image6 from "./image13-mobile-1.jpg";

import Fade from "react-reveal/Fade";
import Roll from "react-reveal/Roll";
import { productdata } from "../src/products/productdata";
import { Backdrop } from "@material-ui/core";
import styled from "styled-components";

const BlurredComponent = styled.a`
  text-decoration: none;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  opacity: 4;
  position: relative;

  .text {
    background-color: rgba(207, 207, 207, 0.4);
    width: 100%;
    height: 200px;
    margin-bottom: 300px;
    margin-right: 10px;
    padding: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .carousel-heading {
    color: black;
  }
`;

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      randomnumbber: Math.floor(100000 + Math.random() * 900000),
      typenumber: "",
      falgerror: false,
      falgerror1: false,
      falgerror2: false,
      falgerror3: false,
      name: "",
      email: "",
      message: "",
      modalOpen: false,
      productName: "",
      image: "",
      specs: "",
    };
  }

  componentDidMount() {
    document.getElementById("floatbutton").style.display = "none";
  }
  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    return (
      <div>
        <div>
          <Header />
        </div>
        <div style={{ marginTop: 100 }}>
          <div
            // className="inner-banner"
            style={{ backgroundImage: "none" }}
          >
            <section className="desktop-view video">
              <div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <CorouselHome
                      //responsive={responsive}
                      interval={6000}
                      autoPlay={true}
                      showDots={true}
                      showThumbs={false}
                      showStatus={false}
                      dynamicHeight={true}
                      height={10}
                      infiniteLoop={true}
                    >
                      <div>
                        <a
                          href="#"
                          className="a-block d-flex align-items-center height-lg backgroung-carsole"
                          style={{
                            textDecoration: "none",
                            backgroundImage: `url('https://gaidoweb.s3.us-east-2.amazonaws.com/img/image6.jpg')`,
                            backgroundRepeat: "no-repeat",
                            opacity: 4,
                          }}
                        >
                          <div
                            className="text"
                            style={{
                              backgroundColor: "#999999",
                              width: "700px",
                              borderTopRightRadius: "50px",
                              borderBottomRightRadius: "50px",
                              marginBottom: "-50px",
                              marginRight: "10px",
                            }}
                          >
                            <Fade left>
                              <h1
                                style={{
                                  color: "black",
                                  textAlign: "left",
                                  paddingLeft: 20,
                                }}
                              >
                                Intelligent Parking Solutions
                                <h1> Backed by Data and Technology</h1>
                                
                              </h1>
                            </Fade>
                          </div>
                        </a>
                      </div>
                      <div>
                        {/* <a href="#" className="a-block d-flex align-items-center height-lg backgroung-carsole" style={{ textDecoration: "none", backgroundImage: `url(${image2})`, backgroundRepeat: 'no-repeat' }}>
                      <div className="text" style={{ backgroundColor: "#cfcfcf", width: "100%", borderTopRightRadius: "50px", borderBottomRightRadius: "50px", marginBottom: "400px", marginRight: "10px",opacity: .4
 }}>
                        <Fade right>
                          <h1 className="carousel-heading" style={{ color: "black",opacity:10 }}>Used by all Industrial units, corporate parks
                            <br />and factories with complex processes & security need</h1>
                        </Fade>
                      </div>
                    </a> */}
                        <BlurredComponent
                          image="https://gaidoweb.s3.us-east-2.amazonaws.com/img/image9.jpg"
                          href="#"
                          className="a-block d-flex align-items-center height-lg backgroung-carsole"
                        >
                          <div className="text">
                            <Fade right>
                              <h1 className="carousel-heading">
                                Simple Solutions for Complex
                                
                                <h1>Parking Problems</h1>
                              </h1>
                            </Fade>
                          </div>
                        </BlurredComponent>
                      </div>
                      <div>
                        <a
                          href="#"
                          className="a-block d-flex align-items-center height-lg backgroung-carsole"
                          style={{
                            textDecoration: "none",
                            backgroundImage: `url('https://gaidoweb.s3.us-east-2.amazonaws.com/img/image13.jpg')`,
                            backgroundRepeat: "no-repeat",
                            opacity: 4,
                          }}
                        >
                          <div
                            className="text"
                            style={{
                              backgroundColor: "#999999",
                              width: "700px",
                              borderTopRightRadius: "50px",
                              borderBottomRightRadius: "50px",
                              marginBottom: "-50px",
                              marginRight: "10px",
                            }}
                          >
                            <Roll right>
                              <h1
                                style={{
                                  color: "black",
                                  textAlign: "left",
                                  paddingLeft: 20,
                                }}
                                className="carousel-heading"
                              >
                                High-Quality Hardware Backed by Smart Software
                              </h1>
                            </Roll>
                          </div>
                        </a>
                      </div>
                    </CorouselHome>
                  </div>
                </div>
              </div>
            </section>
            <section className="mobile-view" syle={{ marginBottom: "62px" }}>
              <div>
                <div className="row">
                  <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                    <CorouselHome
                      autoPlaySpeed={5000}
                      autoPlay={true}
                      showDots={true}
                      showThumbs={false}
                      showStatus={false}
                      //  dynamicHeight={true}
                      // height={10}
                    >
                      <div>
                        <a
                          href="#"
                          className="a-block d-flex align-items-center height-lg backgroung-carsole"
                          style={{
                            textDecoration: "none",
                            backgroundImage: `url('https://gaidoweb.s3.us-east-2.amazonaws.com/img/image6-mobile.jpg')`,
                            backgroundRepeat: "no-repeat",
                            height: "100%",
                          }}
                        >
                          <div className="text">
                            <Fade left>
                              <h6 style={{ color: "white" }}>
                                Intelligent Parking Solutions <br /> Backed by
                                Data and Technology
                              </h6>
                            </Fade>
                          </div>
                        </a>
                      </div>
                      <div>
                        <a
                          href="#"
                          className="a-block d-flex align-items-center height-lg backgroung-carsole"
                          style={{
                            textDecoration: "none",
                            backgroundImage: `url('https://gaidoweb.s3.us-east-2.amazonaws.com/img/image9-mobile.jpeg')`,
                            backgroundRepeat: "no-repeat",
                            height: "100%",
                          }}
                        >
                          <div className="text">
                            <Fade left>
                              <h6
                                className="carousel-heading"
                                style={{ color: "white" }}
                              >
                                Used by all Industrial units, corporate parks
                                <br />
                                and factories with complex processes & security
                                need
                              </h6>
                            </Fade>
                          </div>
                        </a>
                      </div>
                      <div>
                        <a
                          href="#"
                          className="a-block d-flex align-items-center "
                          style={{
                            textDecoration: "none",
                            backgroundImage: `url('https://gaidoweb.s3.us-east-2.amazonaws.com/img/image13-mobile-1.jpg')`,
                            backgroundRepeat: "no-repeat",
                            height: "100%",
                          }}
                        >
                          <div className="text">
                            <Fade left>
                              <h6
                                className="carousel-heading"
                                style={{ color: "white" }}
                              >
                                High-Quality Hardware Backed by Smart Software
                                <br />
                              </h6>
                            </Fade>
                          </div>
                        </a>
                      </div>
                    </CorouselHome>
                  </div>
                </div>
              </div>
            </section>{" "}
          </div>
        </div>

        <br />
        <section>
          <div className="container">
            <div className="section-header">
              <h2 itemprop="headline" >Products</h2>
            </div>

            <div className="row">
              {productdata.slice(1, 5).map((data, key) => (
                <div
                  className="col-lg-3"
                  key={key}
                  style={{ alignContent: "center", display: "inline" }}
                >
                  <div className="card mb-2">
                    <img
                      className="card-img-top"
                      width="255"
                      data-toggle="modal"
                      data-target="#exampleModal10"
                      height="220"
                      src={`${data.image}`}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          productName: data.product,
                          image: data.image,
                          specs: data.specs,
                        });
                      }}
                      alt="Product Image"
                    />
                    <div className="card-body">
                      <h5 className="card-title">{data.product}</h5>
                      {/* <br /> */}
                      <button
                        class=" btn-secondary"
                        id={data.productId}
                        data-toggle="modal"
                        data-target="#exampleModal10"
                        style={{
                          marginTop: 10,
                          backgroundColor: "#35965c",
                          height: 30,
                          width: "100%",
                          color: "white",
                          border: "1px solid white",
                          borderRadius: 50,
                        }}
                        onClick={() => {
                          this.setState({
                            productName: data.product,
                            image: data.image,
                            specs: data.specs,
                          });
                        }}
                      >
                        Get Quote
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-9 "></div>
            <div id="more2">
              <a href="/products" style={{ color: "green", padding: 20 }}>
                view more Products --&gt;
              </a>
            </div>
          </div>
        </section>

        <div>
          <div
            class="modal fade"
            id="exampleModal10"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Product Details
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body" style={{ padding: 20 }}>
                  <div className="row">
                    <div
                      className="col-lg-6 col-sm-6 col-md-6 col-xs-6"
                      style={{ paddingLeft: 60 }}
                    >
                      <img
                        src={this.state.image}
                        alt="placeholder"
                        className="show-large"
                        style={{
                          height: 250,
                          width: 250,
                          borderRadius: 15,
                          boxShadow: "5px 5px 5px #999",
                        }}
                      />
                      <br></br>
                      <h4>
                        <b>
                          {this.state.productName}
                          {this.state.specs[4] !== "" ? (
                            <h4>({this.state.specs[4]})</h4>
                          ) : (
                            ""
                          )}{" "}
                        </b>
                      </h4>
                      <h6>
                        <b>Specification:</b>
                      </h6>
                      {this.state.specs[0]}
                      <br></br>
                      {this.state.specs[1]}
                      <br></br>
                      {this.state.specs[2]}
                      <br></br>
                      {this.state.specs[3]}
                      <br></br>
                      <br></br>
                      {/* {specs[4]}<br></br> */}
                      <b>
                        {" "}
                        {
                          this.state.specs[5]
                        } <i class="fa-solid fa-phone"></i>{" "}
                      </b>{" "}
                      <br></br>
                    </div>
                    <br />

                    <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                      <br />
                      <iframe
                        class="embed-responsive-item"
                        src="https://forms.zohopublic.in/sales80/form/KnowMore/formperma/Bxd9ffNXHg4LXDPgA4AQ3WezARUlyFtl56X2IBlfNoI?zf_rszfm=1"
                        style={{ textAlign: "center" }}
                        width="100%"
                        height="500"
                        frameborder="0"
                        marginheight="0"
                        marginwidth=""
                      >
                        Loading…
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <section id="about" className="wow fadeInLeft desktop-view">
          <div className="container">
            <div className="section-header">
              <h2 itemprop="headline" >Our Offerings</h2>
            </div>
            <div className="row text-center">
              <p className="text-center" style={{ width: '100%' }}><b>Gaido Technologies Pvt. Ltd. is a Hyderabad based Smart Parking
                Management company. </b></p>
              <p className="text-center" style={{ width: '100%' }}><em>We serve the following parking industry segments – Airports,
                Municipalities, Hospitals, Hotels, Shopping centers, Educational Institutes, Business Centers, and Sports
                and Exhibition Venues, with custom tailored and turn-key solutions. </em></p>
            </div>
          </div>
          <div className="row offering-border">
            <div className="col-lg-4 offering">
              <a href="/offerings/airports">
                <div className="icon-solution">
                  <i className="fa fa-plane" />
                </div>
                <div className="text-solution">
                  <h5>Airports</h5>
                  <p>Irrespective of how busy an airport is, travelers always want to be on time... </p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 offering">
              <a href="/offerings/business-centers">
                <div className="icon-solution">
                  <i className="fa fa-briefcase" />
                </div>
                <div className="text-solution">
                  <h5>Business Centers</h5>
                  <p>Parking is often referred to as a trivial chore – the start and finish of an experience...</p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 offering">
              <a href="/offerings/educational-institutes">
                <div className="icon-solution">
                  <i className="fa fa-graduation-cap" />
                </div>
                <div className="text-solution">
                  <h5>Educational Institutes</h5>
                  <p>With strong growth in student attendance and ever-escalating pressure to minimize the carbon footprint...
                  </p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 offering">
              <a href="/offerings/hospitals">
                <div className="icon-solution">
                  <i className="fa fa-plus" />
                </div>
                <div className="text-solution">
                  <h5>Hospitals</h5>
                  <p>Every health facility is different, unique in its own way. While some have simple procedural
                    formalities...</p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 offering">
              <a href="/offerings/hotels">
                <div className="icon-solution">
                  <i className="fa fa-building" />
                </div>
                <div className="text-solution">
                  <h5>Hotels</h5>
                  <p>You’ve done it all - Clean and tidy rooms, world-class amenities, the best marketing...</p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 offering">
              <a href="/offerings/municipalities">
                <div className="icon-solution">
                  <i className="fa fa-university" />
                </div>
                <div className="text-solution">
                  <h5>Municipalities</h5>
                  <p>Throughout the globe, municipalities are working relentlessly to deliver on the high expectations...</p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 offering">
              <a href="/offerings/residential-area">
                <div className="icon-solution">
                  <i className="fa fa-home" />
                </div>
                <div className="text-solution">
                  <h5>Residential Area</h5>
                  <p>The urban agglomeration is constantly growing. Following the rising populations...</p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 offering">
              <a href="/offerings/shopping-centers">
                <div className="icon-solution">
                  <i className="fa fa-shopping-bag" />
                </div>
                <div className="text-solution">
                  <h5>Shopping Centers</h5>
                  <p>For many companies, parking management basically means making sure the ticket machines ...</p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 offering">
              <a href="/offerings/sports-exhibitions">
                <div className="icon-solution">
                  <i className="fa fa-futbol-o" />
                </div>
                <div className="text-solution">
                  <h5>Sports and Exhibition Venues</h5>
                  <p>Every year major sporting events and Exhibition Venues draw thousands of fans to stadiums...</p>
                </div>
              </a>
            </div>
          </div>
        </section> */}
        {/* <section id="about3" className="wow fadeInLeft mobile-view">
          <div className="container">
            <div className="section-header">
              <h2 itemprop="headline" >Our Offerings</h2>
            </div>
            <div className="row text-center">
              <p className="text-center" style={{ width: '100%' }}><b>Gaido Technologies Pvt. Ltd. is a Hyderabad based Smart Parking
                Management company. </b></p>
              <p className="text-center" style={{ width: '100%' }}><em>We serve the following parking industry segments – Airports,
                Municipalities, Hospitals, Hotels, Shopping centers, Educational Institutes, Business Centers, and Sports
                and Exhibition Venues, with custom tailored and turn-key solutions. </em></p>
            </div>
          </div>
          <div className="row offering-border">
            <div className="col-lg-3 col-sm-6 col-md-6 offerings">
              <a href="/offerings/airports">
                <div className="icon-solution">
                  <i className="fa fa-plane" />
                </div>
                <div className="text-solution">
                  <h5>Airports</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6 col-xs-6 offerings">
              <a href="/offerings/business-centers">
                <div className="icon-solution">
                  <i className="fa fa-briefcase" />
                </div>
                <div className="text-solution">
                  <h5>Business Centers</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 offerings">
              <a href="/offerings/educational-institutes">
                <div className="icon-solution">
                  <i className="fa fa-graduation-cap" />
                </div>
                <div className="text-solution">
                  <h5>Educational Institutes</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 offerings">
              <a href="/offerings/hospitals">
                <div className="icon-solution">
                  <i className="fa fa-plus" />
                </div>
                <div className="text-solution">
                  <h5>Hospitals</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 offerings">
              <a href="/offerings/hotels">
                <div className="icon-solution">
                  <i className="fa fa-building" />
                </div>
                <div className="text-solution">
                  <h5>Hotels</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 offerings">
              <a href="/offerings/municipalities">
                <div className="icon-solution">
                  <i className="fa fa-university" />
                </div>
                <div className="text-solution">
                  <h5>Municipalities</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 offerings">
              <a href="/offerings/residential-area">
                <div className="icon-solution">
                  <i className="fa fa-home" />
                </div>
                <div className="text-solution">
                  <h5>Residential Area</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 offerings">
              <a href="/offerings/shopping-centers">
                <div className="icon-solution">
                  <i className="fa fa-shopping-bag" />
                </div>
                <div className="text-solution">
                  <h5>Shopping Centers</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 offerings">
              <a href="/offerings/sports-exhibitions">
                <div className="icon-solution">
                  <i className="fa fa-futbol-o" />
                </div>
                <div className="text-solution">
                  <h5>Sports and Exhibition Venues</h5>
                </div>
              </a>
            </div>
          </div>
        </section> */}
        <section id="services">
          <div className="sol-container">
            <div className="section-header">
              <h2 itemprop="headline" >Our Solutions</h2>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <div className="box wow fadeInLeft">
                  <div className="icon">
                    {" "}
                    <img
                      alt="gaidologos"
                      src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/Ticketless.jpg"
                    />
                  </div>
                  <h4 className="title">
                    <a href="solutions/ticketless-parking-management/">
                      Ticketless Parking Management
                    </a>
                  </h4>
                  <p className="description">
                    Artificial intelligence based ANPR technology is used to
                    manage boom barriers, making your parking lot a generation
                    smarter.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="box wow fadeInLeft" data-wow-delay="0.2s">
                  <div className="icon">
                    {" "}
                    <img
                      alt="gaidologos"
                      src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/parking-guidance.jpg"
                    />
                  </div>
                  <h4 className="title">
                    <a href="solutions/parking-guidance-system/">
                      Parking Guidance System
                    </a>
                  </h4>
                  <p className="description">
                    Convenient and Visual guidance, having close to 100%
                    accuracy, is deployed in your parking lot for a world-class
                    customer experience.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="box wow fadeInRight">
                  <div className="icon">
                    {" "}
                    <img
                      alt="gaidologos"
                      src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/valet.jpg"
                    />
                  </div>
                  <h4 className="title">
                    <a href="solutions/valet-parking-system/">
                      Valet Parking System
                    </a>
                  </h4>
                  <p className="description">
                    State of the art technology replaces the conventional
                    paper-ticket based Valet System to provide a highly secure
                    and hassle-free experience to customers.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="box wow fadeInRight" data-wow-delay="0.2s">
                  <div className="icon">
                    {" "}
                    <img
                      alt="gaidologos"
                      src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/boom-barriergate.jpg"
                    />
                  </div>
                  <h4 className="title">
                    <a href="solutions/entry-gate-system/">Entry Gate System</a>
                  </h4>
                  <p className="description">
                    Vision-based optical scanning and LPR technology is used to
                    securely track any vehicle, parked in the lot, on-demand.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="box wow fadeInRight">
                  <div className="icon">
                    {" "}
                    <img
                      alt="gaidologos"
                      src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/cloud.jpg"
                    />
                  </div>
                  <h4 className="title">
                    <a href="solutions/cloud-based-analytics-platform/">
                      Cloud-Based Analytics Platform
                    </a>
                  </h4>
                  <p className="description">
                    Combining Data and Technology to provide a comprehensive
                    cloud-based platform that aids parking lot operators with
                    analytics...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="section-header">
            <h2 style={{ fontSize: "30px" }}>Clients Who Believed in Gaido</h2>
          </div>
          <div style={{ paddingRight: 20, paddingLeft: 20 }}>
            <div className="row">
              {" "}
              <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3"></div>
              <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                <img
                  src={require("./ClientImage.png")}
                  alt="Gaido technologies"
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
          </div>
        </section>
        <GaidoEdge />

        <DropusLIne />

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.481909864123!2d78.3988787143539!3d17.436633805973653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91f051dd281f%3A0x5b0c424523df2634!2sGaido%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1566908995748!5m2!1sen!2sin"
          title="gaidogooglemaps"
          width="100%"
          height={450}
          frameBorder={0}
          style={{ border: 0 }}
          allowFullScreen
        />

        <Footer></Footer>
      </div>
    );
  }
}
