import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';
class EducationalInstitutes extends Component {


  render() {
    return (
      <div>
        <Helmet>
        <title>Education Institutes Boom Barriers | Gaido Technologies | Barrier Gate</title>
        <meta property="og:title" content="Education Institutes Boom Barriers | Gaido Technologies | Barrier Gate" />
        <meta property="og:url" content="https://www.gaido.in/offerings/educational-institutes" />
      </Helmet>
        <Header />
        <div className="inner-banner3 education">
          <div className="row">
            <div className="col-md-5">
              <div className="hid-img">
                   <img alt ="gaidologos"src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/education2.jpg" style={{ width: '100%' }} />
              </div>
              <h2>Educational Institutes</h2>
              <p>With strong growth in student attendance and ever-escalating pressure to minimize the
              carbon footprint, parking provisions at Universities, Colleges and Education Campuses have
                taken on considerable importance. ...</p>
              <p className="read-more"><a href="#about"> READ MORE <i className="fa fa-plus" /> </a></p>
            </div>
          </div>
        </div>
        {/*==========================
    Solutions Section
  ============================*/}
        <section id="about" className="f-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 content offerings2">
                <h1 className="hd2"> Offering for Educational Institutes</h1>
                <p>With strong growth in student attendance and ever-escalating pressure to minimize the
                carbon footprint, parking provisions at Universities, Colleges and Education Campuses have
                taken on considerable importance. For students, parking is something they want as little or
                no involvement. So, let’s provide them with the most convenient, contactless or hands-free
                access solution, for straightforward, fast and easy access to parking.
                </p>
                <p>On facility/campus security is a major issue today, and it is pivotal for these institutions to
                have the necessary security in place to proactively prevent any crimes from occurring, as
                well as to give stakeholders a sense of security. With our Ticketless Management System,
                we are able to provide a perfect solution in this matter. Restricting access only to authorized
                vehicles provides additional security.
                </p>
                <p>No matter how large your institute’s size is, our analytics platform helps you get a Hawkeye
                view of the entire system. With its highly scalable Cloud-based SaaS product,
                particularly customizable for schools, colleges, universities and other educational institutes,
                Gaido Technologies is capable of providing solutions that will help your institute’s parking
                facility accomplish maximum security, profitability, management, and control.
                </p>
              </div>
              <p className="read-more" style={{margin: "65px 65px 0px 65px"}}><a href="/products"> VIEW PRODUCTS <i className="fa fa-plus" /> </a></p>

            </div>
          </div>

        </section>
        <Footer />
        <a href={() => false} className="back-to-top"><i className="fa fa-chevron-up" /></a>
      </div>
    );
  }
}

export default EducationalInstitutes;