import React, { Component } from "react";
import Header from "../../header";
import "../faac.css";
const productImage = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/104614.jpg";
const E614Controlunit = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/63001034.jpg";
const skirt2m = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/428441.jpg";
const skirt3m = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/428441.jpg";
const fork = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/737621.jpg";
const beam = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/428806.jpg";
const adjustablebeam = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/428805.jpg";
const trafficLight = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/410031.jpg";
const releaseKey = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/713002.jpg";
const foundationplate = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/490183.jpg";
const springfitting = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/490186.jpg";
const batteryKit = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/390923.jpg";
const roundBeam = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/428045.jpg";
const fixedbracketroundbeam = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/428445.jpg";
const ArticulatedKit = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/428444.jpg";
const luminouscord = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/390993.jpg";
const adhesiveReflector = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/490117.jpg";
const beamLightconnection = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/390992.jpg";
const balancedSpring = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/721209.jpg";
const rectangulaBeam = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/428087.jpg";
const beamBracket = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/428342.jpg";
const ArticulationKitHmax = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/428137.jpg";
const beamlightRectangular = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/390184_light_connection_kit.jpg";
const ExamplePdf = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B614Images/9908363004_B614_EN_LOWRES.pdf"

export default class B614AutomaticBarrier24V extends Component {
  constructor(props) {
    super(props);
  }

  toggle = (id, id2) => {
    var n = document.getElementById(id);
    if (n.style.display != "none") {
      n.style.display = "none";
      document.getElementById(id2).setAttribute("aria-expanded", "false");
    } else {
      n.style.display = "";
      document.getElementById(id2).setAttribute("aria-expanded", "true");
    }
  };

  render() {
    return (
      <div>
          <Header />
        <div className="faacbarriers" style={{marginTop:100}}>
          <div className="row" >
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3">
              <img
                className="card-img-top"
                src={productImage}
                // //onClick={()=>{ history.push('/automations-for-gates')}}
                // //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                style={{ cursor: "pointer" }}
                alt="Product1"
              />
            </div>
            <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
              <div
                className="col-lg-12 col-sm-12 col-md-12 col-xs-12"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "50px" }}>B614</h3>
                <h4 style={{ fontSize: "20px" }}>Automatic barrier 24V</h4>
              </div>
              <div className="row">
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "initial", margin: 20 }}
                >
                  <p style={{ fontSize: "12px" }}>Max beam length</p>
                  <b>5 m</b>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "initial", margin: 20 }}
                >
                  <p style={{ fontSize: "12px" }}>Opening time</p>
                  <b>&#60; 2 s (80° with 3 m beam)</b>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "initial", margin: 20 }}
                >
                  <p style={{ fontSize: "12px" }}>Use frequency</p>
                  <b>Continuous use</b>
                </div>
              </div>
              <div className="row">
              <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3">
                <a
                  href={ExamplePdf}
                  download="9908363004_B614_EN_LOWRES"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button style={{ marginLeft: 30 , marginTop: 30 ,borderColor: "#cecece", borderRadius: 3}} >Download Broucher</button>
                </a>
                <br/>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      backgroundColor: "#35965c",
                      height: 30,
                      width:"100%",
                      margin: 20 ,
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
                  </div>
              </div>
            </div>
          </div>
          <hr
            style={{ borderColor: "#cecece", borderWidth: 10 }}
          ></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

            <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
              <ul style={{ listStyleType: "disc" }}>
                <li class="Bullet_List">
                  <span>
                    Maximum Anti-crushing safety and motor with integrated
                    encoder
                  </span>
                </li>
                <li class="Bullet_List">
                  <span>Speed adjustment and smooth operation.</span>
                </li>
                <li class="Bullet_List">
                  <span>
                    Integrated control unit in the upper part of the beam body,
                    i.e easy maintenace and intuitive programming
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <hr
            style={{ borderColor: "#cecece", borderWidth: 10 }}
          ></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink1"
                onClick={(e) => this.toggle("navbar", "TLink1")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Family models</span>
                </h5>
              </a>
              <div id="navbar" style={{ display: "none" }}>
                <div class="table-3">
                  <table

                  // class="table table-striped Models__EKR_NoPrice_SmartTable__NoPrice_Table rowpreset_Models colpreset_Models label_TABLE"
                  // _id="216593"
                  // xml:lang="it-IT"
                  // mode="w"
                  // created_by="EKR"
                  // modified_by="EKR"
                  // minocc="0"
                  // maxocc="2147483647"
                  // storicocount="0"
                  // controllo=""
                  >
                    <thead>
                      <tr>
                        <th colspan="1" rowspan="1">
                          <p>
                            <span>Model</span>
                          </p>
                        </th>
                        <th colspan="1" rowspan="1">
                          <p>
                            <span>Item code</span>
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>B614</span>
                          </p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>104614</span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{ marginTop: "0px", marginBottom: "15px" }}>
                  <h5
                    class="title-heading-left fusion-responsive-typography-calculated"
                    style={{ fontSize: 20, lineHeight: 1.2 }}
                    data-fontsize="20"
                    data-lineheight="24px"
                  >
                    <span>Packs</span>
                  </h5>
                </div>
                <div
                  class="fusion-text fusion-text-6 Models__Package_Description"
                  // style="transform:translate3d(0,0,0);"
                >
                  <p>
                    <span class="Highlight">B614</span>
                    <span class="Package">&nbsp;</span>
                    <span class="Highlight">includes</span>
                    <span class="Highlight">:</span>
                    <span>&nbsp;</span>
                    <span>
                      barrier cabinet, electromechanical gearmotor and
                      transmission unit, electronic control unit, installation
                      accessories, a triangular release key.
                    </span>
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <hr
            style={{  borderColor: "#cecece", borderWidth: 10 }}
          ></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink2"
                onClick={(e) => this.toggle("dec2", "TLink2")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Dimensions and technical specifications</span>
                </h5>
              </a>
              <div id="dec2" style={{ display: "none" }}>
                <div class="table-4">
                  <table>
                    <thead>
                      <tr>
                        <th contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Model</span>
                          </p>
                        </th>
                        <th contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>B614</span>
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Power supply voltage</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>220-240V ~ 50/60 Hz</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Electric motor</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>With brushes 24V</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Max. power</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>165 W</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Max. torque</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>300 Nm</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Material type</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Steel</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Type of treatment</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>
                              Pre-hot dip galvanized, 20μm thick + polyester
                              powder coated
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Encoder</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Incremental integrated in the motor</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Type of deceleration</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Electronic + mechanic</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Type of beam</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Rectangular and round</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Operating ambient temperature</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>-20°C ÷ +55°C</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Protection class</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>IP55 (Electronic control unit) – IP44</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Weight</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>40 Kg</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Dimensions (LxDxH)</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>
                              360 x 250 x 1163 mm ( installation base 305 x 230
                              mm)
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Beam max length</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>5 m</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Opening time</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>&lt; 2 s (80°- 3m)</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Use frequency</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Continuous use</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Electronic equipment</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>E614</span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>(*) data referred to 230V~ 50Hz.</p>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink3"
                onClick={(e) => this.toggle("dec3", "TLink3")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Example of a typical installation</span>
                </h5>
              </a>
              <div id="dec3" style={{ display: "none" }}>
                <div>
                  <img
                    decoding="async"
                    src="https://faacindia.com/wp-content/uploads/Assets/Low/FAAC/GATES/B614/Installation_drawings/614installazione.jpg"
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                </div>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                    <table>
                      <thead>
                        <tr>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Ref</span>
                            </p>
                          </th>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Q.ty</span>
                            </p>
                          </th>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Description</span>
                            </p>
                          </th>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Code</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>B614 Barrier</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>104614</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>B614 Foundation plate</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>490183</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>XF 433 MHz receiver</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>787831</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Beam bracket for rectangular beam</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>428342</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>
                                Rectangular standard beam – Length 4,815 mm
                              </span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>428091</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>3</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>XLED flashing light</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>410029</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>4</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Key operated button XK10B</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>401304</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>4</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Lock with key</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>71275101</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>5</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Pair of photocells XP 30B</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>785106</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>5</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Adaptors for wall columns</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>401065</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>5</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Low column in aluminium (2 pcs. pack)</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>401028</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>5</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Foundation plate for aluminium column</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>737630</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>6</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Integrated flashing light</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>410032</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2 channels transmitter XT2 433 SLH LR</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>787007</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{  borderColor: "#cecece" }}></hr>
          <hr
            style={{  borderColor: "#cecece", borderWidth: 10 }}
          ></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink4"
                onClick={(e) => this.toggle("dec4", "TLink4")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Electronic control units</span>
                </h5>
              </a>
              <div id="dec4" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={E614Controlunit}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      E614 Control unit (built-in)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      63001034
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <hr
            style={{borderColor: "#cecece", borderWidth: 10 }}
          ></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10" >
              <a
                aria-expanded="true"
                id="TLink5"
                onClick={(e) => this.toggle("dec5", "TLink5")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                  
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Common accessories for all applications</span>
                </h5>
              </a>
              <div id="dec5" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={skirt2m}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>Skirt kit length 2 m</p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      428441
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={skirt3m}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>Skirt kit length 3 m</p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      428446
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={fork}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Support plate for fork
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      737621
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={beam}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Adjustable fork for beam support
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      428806
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={adjustablebeam}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Adjustable end foot for beam support
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      428805
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={trafficLight}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      B614 integrated flashing traffic light
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      410032
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={releaseKey}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Supplementary triangular release key (10 pcs. pack)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      713002
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={foundationplate}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>Foundation plate</p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      490183
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={springfitting}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Double spring fittings pack
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      490186
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={batteryKit}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      XBAT 24 emergency battery kit
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      390923
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink6"
                onClick={(e) => this.toggle("dec6", "TLink6")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Round beams S and specific accessories</span>
                </h5>
              </a>
              <div id="dec6" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={roundBeam}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Round beams S – Ø 75 mm (adhesive labels not included)
                    </p>
                    <table>
                      <thead>
                        <tr>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Length (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Item code</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428045</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428042</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>4.300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428043</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>5.000</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428002</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={fixedbracketroundbeam}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Fixing bracket for round beams S 615/620/B614
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      428445
                    </p>
                  </div>

                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={ArticulatedKit}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Articulated kit for round beam S (max 4 m)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      428444
                    </p>
                  </div>

                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={luminouscord}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Luminous cord 12 m pack
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      390993
                    </p>
                  </div>

                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={adhesiveReflector}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Adhesive reflector kit (6 pcs.)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      490117
                    </p>
                  </div>

                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={beamLightconnection}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Beam light connection kit
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      390992
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink7"
                onClick={(e) => this.toggle("dec7", "TLink7")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Balancing springs for round beams S</span>
                </h5>
              </a>
              <div id="dec7" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={balancedSpring}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Balancing spring for B614
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      721209
                    </p>
                  </div>

                  <div
                    className="col-lg-12 col-sm-12 col-md-12 col-xs-12"
                    style={{ textAlign: "center" }}
                  >
                    <table>
                      <thead>
                        <tr>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Number of springs</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Beam (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>With lights (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>With skirt (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Beam with end foot (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>With lights and end foot (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Beam with lights and skirt (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Beam with skirt and end foot (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>
                                Beam with lights, skirt and end foot (mm)
                              </span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>With articulation kit (mm)</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="3">
                            <p>
                              <span>1 </span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="3">
                            <p>
                              <span>1900 ÷ 4400</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="3">
                            <p>
                              <span>1800 ÷ 4150</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="3">
                            <p>
                              <span>1700 ÷ 3740</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="3">
                            <p>
                              <span>1400 ÷ 3750</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="3">
                            <p>
                              <span>1400 ÷ 3650</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="3">
                            <p>
                              <span>1550 ÷ 3550</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="3">
                            <p>
                              <span>1750 ÷ 3250</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="3">
                            <p>
                              <span>1750 ÷ 2540</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2300 ÷ 3300</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3310 ÷ 3800</span>
                            </p>
                            <p>
                              <span>(if A≤2500)</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3810 ÷ 4800</span>
                            </p>
                            <p>
                              <span>(if A≤2200)</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="2">
                            <p>
                              <span>2 </span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="2">
                            <p>
                              <span>4410 ÷ 4900</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="2">
                            <p>
                              <span>4160 ÷ 4900</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="2">
                            <p>
                              <span>3750 ÷ 4900</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="2">
                            <p>
                              <span>3760 ÷ 4900</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="2">
                            <p>
                              <span>3660 ÷ 4900</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="2">
                            <p>
                              <span>3560 ÷ 4900</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="2">
                            <p>
                              <span>3260 ÷ 4550</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="2">
                            <p>
                              <span>2550 ÷ 4100</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3310 ÷ 3800</span>
                            </p>
                            <p>
                              <span>(if A&gt;2500)</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3810 ÷ 4800</span>
                            </p>
                            <p>
                              <span>(if A&gt;2200)</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{  borderColor: "#cecece" }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink8"
                onClick={(e) => this.toggle("dec8", "TLink8")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Rectangular beams and specific accessories</span>
                </h5>
              </a>
              <div id="dec8" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={rectangulaBeam}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Rectangular standard beam
                    </p>
                    <table>
                      <thead>
                        <tr>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Length (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Item code</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.315</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428088</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2.815</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428089</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3.815</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428090</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>4.815</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428091</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={beamBracket}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Beam bracket for rectangular beam
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      428342
                    </p>
                  </div>

                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={ArticulationKitHmax}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Articulation kit – H max ceiling 3 m (only for rectangular
                      standard beams)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      428137
                    </p>
                  </div>

                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={beamLightconnection}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Beam light connection kit
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      390992
                    </p>
                  </div>

                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={luminouscord}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Luminous cord 12 m pack
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      390993
                    </p>
                  </div>

                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={beamlightRectangular}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Rectangular beam light connection kit
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      390184
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink9"
                onClick={(e) => this.toggle("dec9", "TLink9")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Balancing springs for rectangual beams</span>
                </h5>
              </a>
              <div id="dec9" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={balancedSpring}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Balancing spring for B614
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      721209
                    </p>
                  </div>

                  <div
                    className="col-lg-12 col-sm-12 col-md-12 col-xs-12"
                    style={{ textAlign: "center" }}
                  >
                    <table>
                      <thead>
                        <tr>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Number of springs</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Beam (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>With lights (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>With skirt (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Beam with end foot (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Beam with skirt and end foot (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>With articulation kit (mm)</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="3">
                            <p>
                              <span>1 </span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="3">
                            <p>
                              <span>1850 ÷ 4300</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="3">
                            <p>
                              <span>1800 ÷ 4100</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="3">
                            <p>
                              <span>1650 ÷ 3800</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="3">
                            <p>
                              <span>1350 ÷ 3700</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="3">
                            <p>
                              <span>1750 ÷ 3350</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>1850 ÷ 2815</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2816 ÷ 3315</span>
                            </p>
                            <p>
                              <span>(if A≤ 1600)</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3316 ÷ 3815</span>
                            </p>
                            <p>
                              <span>(if A≤ 1250)</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="2">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="2">
                            <p>
                              <span>4310 ÷ 4810</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="2">
                            <p>
                              <span>4110 ÷ 4810</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="2">
                            <p>
                              <span>3810 ÷ 4810</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="2">
                            <p>
                              <span>3700 ÷ 4810</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="2">
                            <p>
                              <span>3360 ÷ 4700</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>2816 ÷ 3315</span>
                            </p>
                            <p>
                              <span>(if A&gt; 1600)</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3316 ÷ 3815</span>
                            </p>
                            <p>
                              <span>(if A&gt; 1250)</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{  borderColor: "#cecece" }}></hr>
          <hr
            style={{ borderColor: "#cecece", borderWidth: 10 }}
          ></hr>
        </div>
        <div
              class="modal fade"
              id="exampleModal10"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Product Details
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{ padding: 20 }}>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                        <iframe
                          scrolling="no"
                          src="https://forms.zohopublic.in/sales80/form/ContactUs/formperma/o3sKh3PHKyQ5GmkDmlz4rY0NM2edU5IF_pl9MYAjDoA?zf_rszfm=1"
                          id="gform"
                          width="100%"
                          height="990"
                          frameborder="0"
                          marginheight="0"
                          marginwidth="0"
                        >
                          Loading…
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    );
  }
}
