import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';
class ResidentialArea extends Component {
  
    render() {
        return (
            <div>
              <Helmet>
        <title>Residential Area Boom Barriers | Gaido Technologies | Barrier Gate</title>
        <meta property="og:title" content="Residential Area Boom Barriers | Gaido Technologies | Barrier Gate" />
        <meta property="og:url" content="https://www.gaido.in/offerings/residential-area" />
      </Helmet>
                <Header /> 
                   <div className="inner-banner3 residential-area">
          <div className="row">
            <div className="col-md-5">
              <div className="hid-img">
                   <img alt ="gaidologos"src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/residence2.jpg" style={{width: '100%'}} />
              </div>
              <h2>Residential Area</h2>
              <p>The urban agglomeration is constantly growing. Following the rising populations in the
                cities, is the demand for dedicated parking spaces for residents and necessity to restrict
                non-residents from parking in these spots. Also, in today’s world, security in residential
                apartments has become an issue of primary concern....</p>
              <p className="read-more"><a href="#about"> READ MORE <i className="fa fa-plus" /> </a></p>
            </div>
          </div>
        </div>
        {/*==========================
    Solutions Section
  ============================*/}
        <section id="about" className="f-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 content offerings2">
                <h1 className="hd2"> Offering for Residential Area</h1>
                <p>The urban agglomeration is constantly growing. Following the rising populations in the
                  cities, is the demand for dedicated parking spaces for residents and necessity to restrict
                  non-residents from parking in these spots. Also, in today’s world, security in residential
                  apartments has become an issue of primary concern. On one hand, residents do not like to 
                  be asked to stop at the entry and on the other, there are complete strangers entering the
                  residence. 
                </p>
                <p>Gaido Technologies provides attractive contactless and hands-free access solutions to
                  residents and provides access only to residents using its Ticketless Management System.
                  This system not only provides ease of use for users but also provides an additional layer of
                  security to your residential space. 
                </p>
                <p>With its highly scalable Cloud-based SaaS product, particularly customizable to cater to the
                  needs of hotels, Gaido Technologies is capable of providing solutions that will help your
                  home’s parking facility accomplish maximum security, profitability, management, and
                  control.
                </p>
              </div>
              <p className="read-more" style={{margin: "65px 65px 0px 65px"}}><a href="/products"> VIEW PRODUCTS <i className="fa fa-plus" /> </a></p>

            </div>
          </div>

        </section>
        <Footer />
        <a href={() => false} className="back-to-top"><i className="fa fa-chevron-up" /></a>
            </div>
        );
    }
}

export default ResidentialArea;