import React, { Component } from 'react';

class Blogsingle extends Component {
  render() {
    return (
      <div>
           <header role="banner">
          <div className="sticky-container">
            <ul className="sticky">
              <li>
                <a href="https://www.facebook.com/gaidoindia"  target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Facebook" alt="Facebook" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/fb1.png" />
                  <p>Facebook</p></a>
              </li>
              <li>
                <a href="https://twitter.com/gaidoindia"  target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Twitter" alt="Twitter" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/tw1.png" />
                  <p>Twitter</p></a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/gaido-in/"  target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Linkedin" alt="Linkedin" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/li1.png" />
                  <p>Linkedin</p></a>
              </li>
              <li>
                <a href="https://www.pinterest.com/gugaliya0755/"  target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Pinterest" alt="Pinterest" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/pin1.png" />
                  <p>Pinterest</p></a>
              </li>
              <li>
                <a href="https://www.instagram.com/gaidoindia/"  target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Instagram" alt="Instagram" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/ins1.png" />
                  <p>Instagram</p></a>
              </li>
            </ul>
          </div>    
          <div className="top-bar">
            <div className="container">
              <div className="row">
                <div className="col-9 social">
                  <a href="https://twitter.com/gaidoindia"  target="_blank" rel="noopener noreferrer"><span className="fa fa-twitter" /></a>
                  <a href="https://www.facebook.com/gaidoindia"  target="_blank" rel="noopener noreferrer"><span className="fa fa-facebook" /></a>
                  <a href="https://www.instagram.com/gaidoindia/"  target="_blank" rel="noopener noreferrer"><span className="fa fa-instagram" /></a>
                  <a href="https://www.linkedin.com/company/gaido-in/"  target="_blank" rel="noopener noreferrer"><span className="fa fa-linkedin" /></a>
                </div>
                <div className="col-3 search-top">
                  {/* <a href={() => false}><span class="fa fa-search"></span></a> */}
                  <form action="#" className="search-top-form">
                    <span className="icon fa fa-search" />
                    <input type="text" id="s" placeholder="Type keyword to search..." />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="container logo-wrap">
            <div className="row pt-5">
              <div className="col-12 text-center">
                <a className="absolute-toggle d-block d-md-none" data-toggle="collapse" href="#navbarMenu" role="button" aria-expanded="false" aria-controls="navbarMenu"><span className="burger-lines" /></a>
                <h1 className="site-logo"><a href="/blog">   <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/logo.png" alt="Gaido Technologies Pvt. Ltd." /></a></h1>
              </div>
            </div>
          </div>
          <nav className="navbar navbar-expand-md  navbar-light bg-light">
            <div className="container">
              <div className="collapse navbar-collapse" id="navbarMenu">
                <ul className="navbar-nav mx-auto">
                  <li className="nav-item">
                    <a className="nav-link active" href="/blog">Blogs</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/blog/about">About</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/blog/contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav></header>
        {/* END header */}
        <section className="site-section py-lg">
          <div className="container">
            <div className="row blog-entries">
              <div className="col-md-12 col-lg-8 main-content">
                <h1 className="mb-4">A step into the future of valet parking</h1>
                <div className="post-meta">
                  <span className="category">Gaido</span>
                  <span className="mr-2">April 06, 2020 </span> 
                </div>
                <div className="post-content-body">
                  <p>Valet parking is a parking service offered by some hotels, restaurants, stores and other businesses and events. In contrast to "self-parking", where customers find a parking space on their own, in valet parking, customers' vehicles are parked for them by a person called a valet.</p>
                  <p>It is routinely used for efficiency, convenience, safety, and luxury.
                    We, at Gaido, have elevated this service to be more efficient, safer, convenient, and most importantly, eco-friendly.</p>
                  <p>We are a software company based out of Hyderabad and fall in the Smart Parking Management Industry. We provide web-based parking solutions such as Ticketless Parking Management System, Valet Parking System, Cloud-based Analytics platform and so on.</p>
                  <div className="row mb-5">
                    <div className="col-md-12 mb-4 ">
                         <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_7.jpg" alt=" placeholder" className="img-fluid" />
                    </div>
                  </div>
                  <h4>We can help optimize your parking by :</h4>
                  <h6><span  role="img" aria-labelledby="car">🚗</span> Increasing your parking capacity</h6>
                  <p>Our customized solutions help provide a more efficient parking strategy and allow your drivers to park and retrieve vehicles more efficiently.</p>
                  <h6><span  role="img" aria-labelledby="car">🚗</span>Enabling you to better control your parking spaces</h6>
                  <p>We help you categorize and park cars in a more organized way to enable faster delivery to the guests e.g., older customers, differently-abled customers, pregnant women, etc.</p>
                  <h6><span  role="img" aria-labelledby="car">🚗</span>Providing a safer solution to your guests</h6>
                  <p>Guest access will be restricted to parking lots and will help you assure your guests a safer experience patronizing your business</p>
                  <h6><span  role="img" aria-labelledby="car">🚗</span>Offering convenience and luxury to your customer</h6>
                  <p>We help you provide a premium service, making a lasting first experience for your customers. </p>
                  <h6><span  role="img" aria-labelledby="car">🚗</span>Delivering an eco-friendlier option</h6>
                  <p>We offer the world’s first ticketless parking solution to add a touch of convenience along with creating a sustainable option for the future.</p>
                  <p>Our dynamic team will deliver a customized and creative solution to all your parking needs and our robust solutions will help you provide a seamless parking experience for your customer.</p>
                  <p>India is getting digitalised, we are happy to contribute and accelerate the process.</p>
                  <p>   <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/logo.png"  alt="gaidologo" /></p>
                </div>
                <div className="pt-5">
                  <p>Categories:  <a href={() => false}>Parking</a>, <a href={() => false}>@Valet</a>  Tags: <a href={() => false}>#gaido</a>, <a href={() => false}>#hyderabad</a></p>
                </div>
              </div>
              {/* END main-content */}
              <div className="col-md-12 col-lg-4 sidebar">
                {/* END sidebar-box */}
                <div className="sidebar-box">
                  <div className="bio text-center">
                       <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/person_1.jpg" alt=" Placeholder" className="img-fluid" />
                    <div className="bio-body">
                      <h2>Meagan Smith</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem facilis sunt repellendus excepturi beatae porro debitis voluptate nulla quo veniam fuga sit molestias minus.</p>
                      <p><a href={() => false} className="btn btn-primary btn-sm">Read my bio</a></p>
                      <p className="social">
                        <a href={() => false} className="p-2"><span className="fa fa-facebook" /></a>
                        <a href={() => false} className="p-2"><span className="fa fa-twitter" /></a>
                        <a href={() => false} className="p-2"><span className="fa fa-instagram" /></a>
                        <a href={() => false} className="p-2"><span className="fa fa-youtube-play" /></a>
                      </p>
                    </div>
                  </div>
                </div>
                {/* END sidebar-box */}  
                <div className="sidebar-box">
                  <h3 className="heading">Popular Posts</h3>
                  <div className="post-entry-sidebar">
                    <ul>
                      <li>
                        <a href>
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_1.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                            <div className="post-meta">
                              <span className="mr-2">March 15, 2018 </span> •
                              <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href>
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_1.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                            <div className="post-meta">
                              <span className="mr-2">March 15, 2018 </span> •
                              <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href>
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_1.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                            <div className="post-meta">
                              <span className="mr-2">March 15, 2018 </span> •
                              <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* END sidebar-box */}
                <div className="sidebar-box">
                  <h3 className="heading">Categories</h3>
                  <ul className="categories">
                    <li><a href={() => false}>Courses <span>(12)</span></a></li>
                    <li><a href={() => false}>News <span>(22)</span></a></li>
                    <li><a href={() => false}>Design <span>(37)</span></a></li>
                    <li><a href={() => false}>HTML <span>(42)</span></a></li>
                    <li><a href={() => false}>Web Development <span>(14)</span></a></li>
                  </ul>
                </div>
                {/* END sidebar-box */}
                <div className="sidebar-box">
                  <h3 className="heading">Tags</h3>
                  <ul className="tags">
                    <li><a href={() => false}>Travel</a></li>
                    <li><a href={() => false}>Adventure</a></li>
                    <li><a href={() => false}>Food</a></li>
                    <li><a href={() => false}>Lifestyle</a></li>
                    <li><a href={() => false}>Business</a></li>
                    <li><a href={() => false}>Freelancing</a></li>
                    <li><a href={() => false}>Travel</a></li>
                    <li><a href={() => false}>Adventure</a></li>
                    <li><a href={() => false}>Food</a></li>
                    <li><a href={() => false}>Lifestyle</a></li>
                    <li><a href={() => false}>Business</a></li>
                    <li><a href={() => false}>Freelancing</a></li>
                  </ul>
                </div>
              </div>
              {/* END sidebar */}
            </div>
          </div>
        </section>
        <section className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="mb-3 ">Related Post</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <a href={() => false} className="a-block d-flex align-items-center height-md" style={{backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_2.jpg")})'}}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="category">Lifestyle</span>
                      <span className="mr-2">March 15, 2018 </span> •
                      <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                    </div>
                    <h3>There’s a Cool New Way for Men to Wear Socks and Sandals</h3>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href={() => false} className="a-block d-flex align-items-center height-md" style={{backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_3.jpg")})'}}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="category">Travel</span>
                      <span className="mr-2">March 15, 2018 </span> •
                      <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                    </div>
                    <h3>There’s a Cool New Way for Men to Wear Socks and Sandals</h3>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href={() => false} className="a-block d-flex align-items-center height-md" style={{backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_4.jpg")})'}}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="category">Food</span>
                      <span className="mr-2">March 15, 2018 </span> •
                      <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                    </div>
                    <h3>There’s a Cool New Way for Men to Wear Socks and Sandals</h3>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* END section */}
        <footer className="site-footer">
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-5">
                <h3>We Are</h3>
                <p><strong>Gaido Technologies Pvt. Ltd.</strong> is a Hyderabad based Smart Parking Management company.
                  We serve the following parking industry segments – Airports, Municipalities, Hospitals, Hotels, Shopping centers, Educational Institutes, Business Centers, and Sports and Exhibition Venues, with custom tailored and turn-key solutions.</p>
              </div>
              <div className="col-md-7 ml-auto">
                <div className="row">
                  <div className="col-md-7">
                    <h3>Latest Post</h3>
                    <div className="post-entry-sidebar">
                      <ul>
                        <li>
                          <a href>
                               <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_6.jpg" alt=" placeholder" className="mr-4" />
                            <div className="text">
                              <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                              <div className="post-meta">
                                <span className="mr-2">March 15, 2018 </span> •
                                <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href>
                               <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_3.jpg" alt=" placeholder" className="mr-4" />
                            <div className="text">
                              <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                              <div className="post-meta">
                                <span className="mr-2">March 15, 2018 </span> •
                                <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-1" />
                  <div className="col-md-4">
                    <div className="mb-5">
                      <h3>Quick Links</h3>
                      <ul className="list-unstyled">
                        <li><a href={() => false}>About Us</a></li>
                        <li><a href={() => false}>Travel</a></li>
                        <li><a href={() => false}>Adventure</a></li>
                        <li><a href={() => false}>Courses</a></li>
                        <li><a href={() => false}>Categories</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center" style={{color: '#fff'}}>
                Copyright © All rights reserved | Developed<i className="fa fa-heart-o" aria-hidden="true" /> by <a href="/"  target="_blank" rel="noopener noreferrer">Gaido Technologies Pvt. Ltd.</a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Blogsingle;