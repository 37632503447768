import vistor from './img/VisitorManagementSystem.png'
const visi="./img/VisitorManagementSystem.png"
export const productdata = [

    {
        productId: 1,
        product: "Barrier Gate",
        image: "https://gaido.s3.ap-south-1.amazonaws.com/img/BarrierGate.png",
        specs: ["Make : Italy, UK and India",
            "Mechanism : Hydraulic/Electro-mechanical" ,
            "Length : Up to 6 meters",
            "Usage/Application : Residential & Commercial",
            "",
            "Contact us for more details"	]
    },
    {
        productId: 2,
        product: "Automatic Boom Barrier With LPR",
        image: "https://gaido.s3.ap-south-1.amazonaws.com/img/AutomaticBoomBarrierwithLPR.png",
        specs: ["Boom Barrier Make : Italy, UK and India",
           " Length : Up to 6 meters",
            "Usage/Application : Residential & Commercial",
            "Features :	Online Dashboard",
            "Latest Technology"	,
            "Contact us for more details",	]
    },
    {
        productId: 3,
        product: "Boom Barrier with Fast-Tag Entry",
        image: "https://gaido.s3.ap-south-1.amazonaws.com/img/BoomBarrierwithFast-TagEntry.png",
        specs: ["Boom Barrier Make : Italy, UK and India",
            "Length : Up to 6 meters",
            "Usage/Application : Residential Communities",
            "Features : Integrate Existing Fast-Tags of residents",
            "Latest Technology"	,
            "Contact us for more details"	]
    },
    {
        productId: 4,
        product: "Automatic Sliding Gate Opener",
        image: "https://gaido.s3.ap-south-1.amazonaws.com/img/SlidingGateOpener.png",
        specs: ["Length	Up to 20 meters",
           " Usage/Application : Residential Gates/Industrial Gates",
            "Automations : Call to Open/RFID Technology/WiFi Open-Close",
            "",
            "",
            "Contact us for more details"	]
    },
    {
        productId: 5,
        product: "Automatic Swing Gate Opener",
        image: "https://gaido.s3.ap-south-1.amazonaws.com/img/SwingGateOpener.png",
        specs: ["Length : Up to 3 meters per leaf",
            "Usage/Application : Residential Gates/Industrial Gates",
            "Automations : Call to Open/RFID Technology/WiFi Open-Close",
            "",
            "",
            "Contact us for more details"	]
    },
    {
        productId: 6,
        product: "RFID Car Parking System",
        image: "https://gaido.s3.ap-south-1.amazonaws.com/img/RFIDCarParkingSystem.png",
        specs: ["Usage/Application : Residential Gates/Industrial Gates",
        "Intergration :	With any Automatic Boom Barrier",
        "Entry/Exit Access : Residents/Employees Access Card",
        "","",
        "Contact us for more details"	]
    },



    {
        productId: 7,
        product: "Number Plate Reader Camera",
        image: "https://gaido.s3.ap-south-1.amazonaws.com/img/NumberPlateReaderCamera.png",
        specs: ["Usage /Application : Residential Gates/Industrial Gates",
        "Features :	Track Vehicle Movement",
        "View Data : Online Dashboard",
        "",
        "",
        "Contact us for more details"	]

    },
    {
        productId: 8,
        product: "Digital Valet Car Parking System ",
        image: "https://gaido.s3.ap-south-1.amazonaws.com/img/ValetParkingSystem.png",
        specs: ["Usage/Application : Malls, Events, Hotels, Restaurant, etc",
        "Used by : Valet Person",
        "Features :	Customers receive SMS on Mobile",
        "",
        "",
        "Contact us for more details",	]
    },
    {
        productId: 9,
        product: "Visitor Management System",
        image: "https://gaido.s3.ap-south-1.amazonaws.com/img/VisitorManagementSystem.png",
        specs: ["Make : India",
       
        "Features :	Online Dashboard",
        "Usage/Application : Residential & Commercial",
        "",
        "",
        "Contact us for more details"	]
    },





];