import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';

class Municipalities extends Component {

    render() {
        return (
            <div>
              <Helmet>
        <title>Municipalities Boom Barriers | Gaido Technologies | Barrier Gate </title>
        <meta property="og:title" content="Municipalities Boom Barriers | Gaido Technologies | Barrier Gate" />
        <meta property="og:url" content="https://www.gaido.in/offerings/municipalities" />
      </Helmet>
                <Header  /> 
                <div className="inner-banner3 municipalities">
          <div className="row">
            <div className="col-md-5">
              <div className="hid-img">
                   <img alt ="gaidologos"src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/muncipalities2.jpg" style={{width: '100%'}} />
              </div>
              <h2>Municipalities</h2>
              <p>Throughout the globe, municipalities are working relentlessly to deliver on the high
                expectations of their citizens. Every city requirement is different, which is what makes
                operating municipalities so complex and therefore requires solutions that meet their
                specific needs....</p>
              <p className="read-more"><a href="#about"> READ MORE <i className="fa fa-plus" /> </a></p>
            </div>
          </div>
        </div>
        {/*==========================
    Solutions Section
  ============================*/}
        <section id="about" className="f-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 content offerings2">
                <h1 className="hd2"> Offering for Municipalities</h1>
                <p>Throughout the globe, municipalities are working relentlessly to deliver on the high
                  expectations of their citizens. Every city requirement is different, which is what makes
                  operating municipalities so complex and therefore requires solutions that meet their
                  specific needs.
                </p>
                <p>With a rise in the population these complexities are also rising and to tackle these
                  complexities one requires a highly reliable, scalable and extremely robust system. Gaido
                  Technologies understands these complexities and designs specific solutions to cater to your
                  distinctive needs. Not only does it help lowering management costs and raising revenue
                  potential, it also helps in improving the citizen’s experience
                </p>
                <p>No matter how large the requirement is, our analytics platform helps you get a Hawkeye
                  view of the entire system. With its highly scalable Cloud-based SaaS product,
                  particularly customizable for based on your needs and requirements, Gaido Technologies is
                  capable of providing solutions that will help your locality’s parking facility accomplish
                  maximum security, profitability, management, and control.
                </p>
              </div>
              <p className="read-more" style={{margin: "65px 65px 0px 65px"}}><a href="/products"> VIEW PRODUCTS <i className="fa fa-plus" /> </a></p>

            </div>
          </div>

        </section>
        <Footer />
        <a href={() => false} className="back-to-top"><i className="fa fa-chevron-up" /></a>
       
            </div>
        );
    }
}

export default Municipalities;