import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MailIcon from '@material-ui/icons/Mail';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import "./blog.css"
import { addDoc, collection } from "firebase/firestore";
import { db, auth } from "../firebaseConfig";
import { ref, uploadBytes, getDownloadURL, } from "firebase/storage";
import { signOut } from 'firebase/auth';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Spinner } from 'reactstrap';


export default class addblog extends Component {
  constructor(props) {
    super(props);
    this.postsCollectionRef = collection(db, "blogs");
    this.state = {
      modalOpen: false,
      randomnumbber: Math.floor(100000 + Math.random() * 900000),
      typenumber: "",
      falgerror: false,
      falgerror1: false,
      falgerror2: false,
      falgerror3: false,
      loader: false,
      name: "",
      email: '',
      contact: "",
      title: "",
      description: "",
      shortdes: "",
      date: "",
      author: "Gaido Technologies",
      authoruid: "",
      comments: "",
      imageurl: "",
      imageUpload: null,
      editorState: EditorState.createEmpty(),
      desc: [],
      loading: false,
      commentscount:"",
      view:"",
      likes:""

    }
  }


  componentDidMount() {
    window.addEventListener('loader', this.handleNetworkChange);
  }
  componentWillUnmount() {
    window.removeEventListener('loader', this.handleNetworkChange);
  }
  handleNetworkChange = () => {
    this.setState({ modalOpen: false })
    this.setState({ loader: false });
  }


  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  createPost = async () => {
    this.setState({
      loading: true
    });

    let body = {
      author: this.state.author,
      authoruid: auth.currentUser.uid,
      authorcommet: this.state.comments,
      date: this.state.date,
      description: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
      desc: convertToRaw(this.state.editorState.getCurrentContent()),
      shorttext: this.state.shortdes,
      imageurl: this.state.imageurl,
      title: this.state.title,
      comments: this.state.commentscount,
      views: this.state.view,
      likes: this.state.likes,
    }
    
    try {
      await addDoc(this.postsCollectionRef, body);
      this.setState({
        loading: false
      });
  
      this.props.history.push("/admin/postedblogs")
    } catch (e) {
      this.setState({
        loading: false
      });
      alert(e)
    }

  };

  getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  handleFileInputChange = e => {
    let file = null;

    file = e.target.files[0];

    this.getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        this.setState({
          imageurl: result,

        });
      })
      .catch(err => {
        console.log(err);
      });


  };


  logOut() {
    signOut(auth).then(() => {
      localStorage.clear();
      window.location.pathname = "/blog";
    });
  }
  render() {
    return (
      <div>
        <Dialog
          open={this.state.modalOpen}
          // TransitionComponent={Transition}
          keepMounted
          onClose={() => this.setState({ modalOpen: false })}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {""}
            <IconButton aria-label="close" style={{
              position: 'absolute',
              right: 0,
              top: 0,
            }} onClick={() => this.setState({ modalOpen: false })}>
              <CancelOutlinedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent >
            <DialogContentText id="alert-dialog-slide-description" >
              <div>
                <div className="col-md-12" style={{ display: "flex", alignItems: "center", justifyContent: 'space-evenly', }}>
                  <h2>One Tap Connect</h2>
                </div>
                <div className="col-md-12" style={{ display: "flex", alignItems: "left", justifyContent: 'space-evenly', }}>
                  <div className="col-md-4">
                    <div style={{ cursor: "pointer" }}>
                      <a
                        href="https://api.whatsapp.com/send?phone=917373735668"
                        className="pushable" target="_blank" rel="noopener noreferrer">
                        <span class="front"><WhatsAppIcon style={{ fontSize: 125, color: "#4FCE5D", boxShadow: " 0px 0px 10px 10px rgba(79,206,93, 0.2)" }} /> </span> <br />
                      </a>

                    </div>
                  </div>
                  <div className="col-md-4">
                    <div style={{ cursor: "pointer" }}>
                      <a href="tel:+91 7373735668" className='btn ' target="_blank" rel="noopener noreferrer">
                        <PhoneCallbackIcon style={{ fontSize: 125, color: "#66bb6a", boxShadow: " 0px 0px 10px 10px rgba(79,206,93, 0.2)" }} />
                      </a>
                      {/* <p><> */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div style={{ cursor: "pointer" }}>
                      <a href="mailto:info@gaido.in?subject=Gaido Request Call Back&body" className='btn ' target="_blank" rel="noopener noreferrer">
                        <MailIcon style={{ fontSize: 125, color: "#26c6da", boxShadow: " 0px 0px 10px 10px rgba(79,206,93, 0.2)" }} />
                      </a>
                    </div>
                  </div>
                </div>
                <div>
                  <br />
                  <h3 style={{ textAlign: "center" }}>or</h3>
                </div>               {this.state.loader === true ? <div style={{
                  position: "absolute",
                  right: "300px", top: "196px",

                }}><i className="fa-spin fas fa-sync" style={{ fontSize: 30, color: "#1bc760" }}></i></div> : ''}
                {this.state.falgerror ? <p style={{ color: "red", fontSize: 15 }}>Invalid captcha code !</p> : ""}
                {this.state.falgerror1 ? <p style={{ color: "red", fontSize: 15 }}>Please try again later !</p> : ""}
                {this.state.falgerror2 ? <p style={{ color: "red", fontSize: 15 }}>Mobile number must be 10 digits  !</p> : ""}
                {this.state.falgerror3 ? <p style={{ color: "red", fontSize: 15 }}>Please fill in all required fields. !</p> : ""}
                {this.state.falgerror4 ? <p style={{ color: "red", fontSize: 15 }}>Invalid email address !</p> : ""}
                {this.state.falgerror5 ? <p style={{ color: "red", fontSize: 15 }}>Name Only letters are allowed!</p> : ""}
                <div className="bottom-pop-form">
                  <h6 style={{ marginLeft: 20, textAlign: "center" }}>Request a Call Back</h6>
                  <iframe scrolling="no" allowtransparency="true" src="https://forms.zohopublic.in/sales80/form/ContactUs/formperma/o3sKh3PHKyQ5GmkDmlz4rY0NM2edU5IF_pl9MYAjDoA?zf_rszfm=1" id="gform" width="520" height="980" >Loading…</iframe>

                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>


        <header id="header" style={{ height: '66px', backgroundColor: "#E5E4D7" }}>


          <div id="logo" className="pull-left">
            <h1><a href="/" className="scrollto">   <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/logo.png" alt="Gaido Technologies" /></a></h1>
          </div>
          <nav id="nav-menu-container">
            <ul className="nav-menu">

              <li>
                <div className="wrap" id="wrapbutton" >
                  <button className="button"
                    onClick={this.logOut}
                  >Logout</button>
                </div>
                <div className="wrap-mobile-button" id="wrapbutton-mobile">
                  <button className="button" id="myBtn" onClick={this.logOut}>Logout</button>
                </div>
              </li>
            </ul>
          </nav>

        </header>
        <div className="createPostPage">


          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="cpContainer">
                <b> CREATE A BlOG</b>
              </div>
              <br></br>
              <div className="posform">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                    <div class="form-floating mb-3">
                      <input type="text" class="form-control" id="floatingInput" placeholder=" * Blog Title" onChange={(event) => {
                        this.setState({ title: event.target.value });
                      }} required />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                    <div class="form-floating mb-3">
                      <input type="date" class="form-control" id="floatingInput" placeholder="Date (DD-MM-YYYY)" onChange={(event) => {
                        this.setState({ date: event.target.value });
                      }} required />
                    </div>
                  </div>
                </div>


                <div className="row">
                  {/* <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                    <div class="form-floating mb-3">
                      <input type="text" class="form-control" id="floatingInput" placeholder="* Author Comments" onChange={(event) => {
                        this.setState({ comments: event.target.value });
                      }} required />
                    </div>
                  </div> */}
                  <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                    <div class="form-floating mb-3">
                      <input type="file" placeholder="Blog Image" required onChange={this.handleFileInputChange}
                      />
                    </div>
                  </div>

                </div>


            
                <div className="row">
                  <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3">
                    <div class="form-floating mb-3">
                      <input type="text" class="form-control" id="floatingInput" placeholder="* Comments" onChange={(event) => {
                        this.setState({ commentscount: event.target.value });
                      }} required />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3">
                    <div class="form-floating mb-3">
                      <input type="text" class="form-control" id="floatingInput" placeholder="* Views" onChange={(event) => {
                        this.setState({ view: event.target.value });
                      }} required />
                    </div>
                  </div>  <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3">
                    <div class="form-floating mb-3">
                      <input type="text" class="form-control" id="floatingInput" placeholder="* Likes" onChange={(event) => {
                        this.setState({ likes: event.target.value });
                      }} required />
                    </div>
                  </div>
                </div>
                <div className="row">
                  < div className="col-lg-12 col-sm-6 col-md-6 col-xs-6 ">
                    <div class="form-floating mb-3">
                      <input type="text" class="form-control" id="floatingInput" placeholder="* Short Description" onChange={(event) => {
                        this.setState({ shortdes: event.target.value });
                      }} required />

                    </div>
                  </div>
                </div>



                <div className="row" style={{ marginTop: "20px" }}>
                  <div style={styles.editor} className="col-12">
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={this.onEditorStateChange}
                    />
                    {/* <textarea
                                    disabled
                                    value={(convertToRaw(this.state.editorState.getCurrentContent()))}
                                ></textarea> */}
                  </div>
                </div>
                {this.state.loading ? <div className="row" style={{ marginTop: "20px" ,marginLeft: "20px" }}><Spinner animation="border" role="status">
                  <span className="visually-hidden"></span>
                </Spinner></div> : ""}
                <div className='row' style={{ marginTop: "20px" }}>

                  {this.state.title !== "" && this.state.imageurl !== "" && this.state.date !== "" && this.state.editorState !== "" && this.state.shortdes !== "" && this.state.commentscount !== "" ?
                    <div className='col-lg-3 col-sm-2 col-md-2 col-xs-2'>

                      <button type="button" class="btn btn-primary" onClick={this.createPost}> Post </button></div> :
                    <div className='col-lg-3 col-sm-2 col-md-2 col-xs-2'>

                      <button type="button" class="btn btn-secondary"  > Post </button></div>}


                  <div className='col-lg-3 col-sm-2 col-md-2 col-xs-2'>
                    <button type="button" class="btn btn-secondary" onClick={() => { (window.location.pathname = "/admin/postedblogs") }} >Cancel</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-lg-6" style={{ marginTop: 40 }}>
              <label><b>Preview:</b></label>

              <div className="row" style={styles.view}>
                <div dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())) }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const styles = {
  editor: {
    border: '1px solid gray',

    minHeight: '6em',
    backgroundColor: 'white'
  },
  view: {
    border: '1px solid gray',
    minWidth: "40em",
    minHeight: '10em',
    backgroundColor: 'white',
    padding: 10,
    margin: "0px 0px 0px 0px"
  }
};