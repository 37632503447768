import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer';
import DropusLIne from '../GlobalConsants/DropusLIne'
import { Helmet } from 'react-helmet';

class ColudBasedAnalyticsPlatfrom extends Component {

  render() {
    return (
      <div>
         <Helmet>
        <title>Cloud-Based Analytics | Software | Gaido Technologies | Barrier Gate </title>
        <meta property="og:title" content="Cloud-Based Analytics | Software | Gaido Technologies | Barrier Gate" />
        <meta property="og:url" content="https://www.gaido.in/solutions/cloud-based-analytics-platform" />
      </Helmet>
        <Header />
        <div className="inner-banner">
          <h2>Cloud-Based Analytics Platform</h2>
        </div>
        <section id="about" className="wow fadeInLeft">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-4">
                <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/cloud.jpg" alt="Gaido Technologies" style={{ width: '100%', marginTop: 15 }} />
              </div>
              <div className="col-lg-8 content">
                <p>Smart IoT based solutions enable you to capture data. As a part of this solution we are able to provide you with smart insights form user activity that will help you become more efficient and take data-driven decisions. </p>
                <p>We provide a comprehensive cloud-based platform with analytics in the form of easy to understand visualizations and interactive widgets to manage and process the information on a real-time basis. The platform not only takes inputs but also provides smart recommendations using machine learning algorithms. <b>The Gaido Analytics platform provides you with, new insights with actionable intelligence, real-time status of permits and payments, real-time updates on the working of devices and equips you to understand how your parking space is utilized, increase staff visibility and automate and schedule regular reports based on your convenience.</b> </p>
              </div>
              <div className="col-lg-12 content">
                <p>This solution can be applied to various industry sectors. Some of the most common are Corporate Offices, Airports, Hotels, Hospitals, IT Parks, Sports and Exhibition Venues, Shopping Centers, Factories, Industries, Municipalities, Educational Institutes and Parking Lots.</p>

              </div>
            </div>
            <div className='row'>
              <div className='col-lg-5'></div>
              <div className='col-lg-7'>
                <p className="read-more" style={{ margin: "20px 65px 65px 0px" }}><a href="/products"> VIEW PRODUCTS <i className="fa fa-plus" /> </a></p>
              </div>
            </div>
          </div>
        </section>
        <section id="about3" className="wow fadeInLeft">
          <div className="container">
            <div className="section-header">
              <h3>Use Cases</h3>
              <p>Select an industry segment to know more about the application of this solution</p>
            </div>
          </div>
          <div className="row offering-border">
            <div className="col-lg-3 col-sm-6 col-md-6 col-xs-6 offerings">
              <a href="../../offerings/business-centers" style={{ textDecoration: "none" }}>
                <div className="icon-solution">
                  <i className="fa fa-briefcase" />
                </div>
                <div className="text-solution">
                  <h5>Business Centers</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6 offerings">
              <a href="../../offerings/airports" style={{ textDecoration: "none" }}>
                <div className="icon-solution">
                  <i className="fa fa-plane" />
                </div>
                <div className="text-solution">
                  <h5>Airports</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 offerings">
              <a href="../../offerings/hotels" style={{ textDecoration: "none" }}>
                <div className="icon-solution">
                  <i className="fa fa-building" />
                </div>
                <div className="text-solution">
                  <h5>Hotels</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 offerings">
              <a href="../../offerings/hospitals" style={{ textDecoration: "none" }}>
                <div className="icon-solution">
                  <i className="fa fa-plus" />
                </div>
                <div className="text-solution">
                  <h5>Hospitals</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 offerings">
              <a href="../../offerings/sports-exhibitions" style={{ textDecoration: "none" }}>
                <div className="icon-solution">
                  <i className="fa fa-futbol-o" />
                </div>
                <div className="text-solution">
                  <h5>Sports and Exhibition Venues</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 offerings">
              <a href="../../offerings/shopping-centers" style={{ textDecoration: "none" }}>
                <div className="icon-solution">
                  <i className="fa fa-shopping-bag" />
                </div>
                <div className="text-solution">
                  <h5>Shopping Centers</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 offerings">
              <a href="../../offerings/municipalities" style={{ textDecoration: "none" }}>
                <div className="icon-solution">
                  <i className="fa fa-university" />
                </div>
                <div className="text-solution">
                  <h5>Municipalities</h5>
                </div>
              </a>
            </div>

            <div className="col-lg-3 offerings">
              <a href="../../offerings/educational-institutes" style={{ textDecoration: "none" }}>
                <div className="icon-solution">
                  <i className="fa fa-graduation-cap" />
                </div>
                <div className="text-solution">
                  <h5>Educational Institutes</h5>
                </div>
              </a>
            </div>
          </div>
        </section>{/* #offerings */}
        {/*==========================
      Our Advantages Section
    ============================*/}
        <section id="portfolio" className="wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp', animation: "mymove 5s infinite" }}>
          <div className="container">
            <div className="section-header">
              <h2>Advantages</h2>
              <p>We continuously work on ensuring that our solutions provide these benefits </p>
            </div>
          </div>
          <div className="container">
            <div className="row blog">
              <div className="col-md-12">
                <div id="blogCarousel" className="carousel slide" data-ride="carousel">
                  <ol className="carousel-indicators">
                    <li data-target="#blogCarousel" data-slide-to={0} className="active" />
                    <li data-target="#blogCarousel" data-slide-to={1} />
                  </ol>
                  {/* Carousel items */}
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/park.png" /></div>
                          <h4 className="title"><a href={() => false}>Maximize Security</a></h4>
                          <ul>
                            <li><i className="icon ion-ios-checkmark-outline" /> Real-time parking lot monitoring</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Authorized access</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Real-time Alert notification</li>
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon2.png" /></div>
                          <h4 className="title"><a href={() => false}>Maximize Profitability</a></h4>
                          <ul>
                            <li><i className="icon ion-ios-checkmark-outline" /> Reduce operating costs</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Increase revenue by smart recommendation</li>
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon3.png" /></div>
                          <h4 className="title"><a href={() => false}>Maximize Management</a></h4>
                          <ul>
                            <li><i className="icon ion-ios-checkmark-outline" /> Minimize human error</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Efficient organization of parking</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Data driven decision making</li>
                          </ul>
                        </div>
                      </div>
                      {/*.row*/}
                    </div>
                    {/*.item*/}
                    <div className="carousel-item">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon4.png" /></div>
                          <h4 className="title"><a href={() => false}>Maximize Control</a></h4>
                          <ul>
                            <li><i className="icon ion-ios-checkmark-outline" /> Real-time information and alerts</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Dynamic pricing options</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Authorized entry &amp;  Role based asses control</li>
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon6.png" /></div>
                          <h4 className="title"><a href={() => false}>Maximum Integration</a></h4>
                          <ul>
                            <li><i className="icon ion-ios-checkmark-outline" /> Integrate existing parking equipment</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Integrate existing software</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Integrate existing mobile application</li>
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon7.png" /></div>
                          <h4 className="title"><a href={() => false}>Maximum Comprehension</a></h4>
                          <ul>
                            <li><i className="icon ion-ios-checkmark-outline" /> Complex data in simple form</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Interactive dashboards</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Suggestions backed by data</li>
                          </ul>
                        </div>
                      </div>
                      {/*.row*/}
                    </div>
                    {/*.item*/}
                  </div>
                  {/*.carousel-inner*/}
                </div>
                {/*.Carousel*/}
              </div>
            </div>
          </div>
        </section>{/* #portfolio */}
        <DropusLIne />
        <Footer />
        <a href={() => false} className="back-to-top"><i className="fa fa-chevron-up" /></a>
        <div className="bts-popup" role="alert">
          <div className="bts-popup-container">
            <h2>One Tap Connect</h2>
            <div className="col-md-12 row">
              <div className="col-md-4">
                <a href="https://api.whatsapp.com/send?phone=917373735668" target="_blank" rel="noopener noreferrer">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/whatsapp.png" /></a>
                <p className="Wtapp">Whatsapp</p>
              </div>
              <div className="col-md-4">
                <a href="tel:+91 7373735668" target="_blank" rel="noopener noreferrer">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/call.png" /></a>
                <p className="Wtapp">Call</p>
              </div>
              <div className="col-md-4">
                <a href="mailto:info@gaido.in?subject=Gaido Request Call Back&body">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/email.png" /></a>
                <p className="Wtapp">Email</p>
              </div>
            </div>
            <div className="bottom-pop-form">
              <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/callback.png" className="img-responsive" width={200} />
              <form method="POST" action="true"
                id="EnquiryForm">
                <div className="row">
                  <div className="col-md-12">
                    <div className="control-group">
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Your Name" name="name" id="name" required />
                        <p className="help-block" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="controls">
                        <input type="text" className="form-control" placeholder="Your Contact" name="mobile" id="mobile" required />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="controls">
                        <input type="email" className="form-control" placeholder="Your Email" name="email" id="email" required />
                      </div>
                    </div>
                  </div>
                  <div id="success"> </div>
                  {/* For success/fail messages */}
                  <div style={{ textAlign: 'center', width: '100%', marginBottom: '10px' }}>
                    <button type="button" className="btn btn-primary" >Send</button>
                  </div>
                </div>
              </form>
            </div>
            <a href="#0" className="bts-popup-close img-replace">Close</a>
          </div>
        </div>

      </div>
    );
  }
}

export default ColudBasedAnalyticsPlatfrom;