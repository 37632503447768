import React, { Component } from 'react';

class category extends Component {
  render() {
    return (
      <div>
          <header role="banner">
          <div className="top-bar">
            <div className="container">
              <div className="row">
                <div className="col-9 social">
                  <a href={() => false}><span className="fa fa-twitter" /></a>
                  <a href={() => false}><span className="fa fa-facebook" /></a>
                  <a href={() => false}><span className="fa fa-instagram" /></a>
                  <a href={() => false}><span className="fa fa-youtube-play" /></a>
                  <a href={() => false}><span className="fa fa-vimeo" /></a>
                  <a href={() => false}><span className="fa fa-snapchat" /></a>
                </div>
                <div className="col-3 search-top">
                  {/* <a href={() => false}><span class="fa fa-search"></span></a> */}
                  <form action="#" className="search-top-form">
                    <span className="icon fa fa-search" />
                    <input type="text" id="s" placeholder="Type keyword to search..." />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="container logo-wrap">
            <div className="row pt-5">
              <div className="col-12 text-center">
                <a className="absolute-toggle d-block d-md-none" data-toggle="collapse" href="#navbarMenu" role="button" aria-expanded="false" aria-controls="navbarMenu"><span className="burger-lines" /></a>
                <h1 className="site-logo"><a href="/blog">Balita</a></h1>
              </div>
            </div>
          </div>
          <nav className="navbar navbar-expand-md  navbar-light bg-light">
            <div className="container">
              <div className="collapse navbar-collapse" id="navbarMenu">
                <ul className="navbar-nav mx-auto">
                  <li className="nav-item">
                    <a className="nav-link" href="/blog">Home</a>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle active" href="/blog/category" id="dropdown04" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Travel</a>
                    <div className="dropdown-menu" aria-labelledby="dropdown04">
                      <a className="dropdown-item" href="/blog/category">Asia</a>
                      <a className="dropdown-item" href="/blog/category">Europe</a>
                      <a className="dropdown-item" href="/blog/category">Dubai</a>
                      <a className="dropdown-item" href="/blog/category">Africa</a>
                      <a className="dropdown-item" href="/blog/category">South America</a>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="/blog/category" id="dropdown05" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Categories</a>
                    <div className="dropdown-menu" aria-labelledby="dropdown05">
                      <a className="dropdown-item" href="/blog/category">Lifestyle</a>
                      <a className="dropdown-item" href="/blog/category">Food</a>
                      <a className="dropdown-item" href="/blog/category">Adventure</a>
                      <a className="dropdown-item" href="/blog/category">Travel</a>
                      <a className="dropdown-item" href="/blog/category">Business</a>
                    </div>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/blog/about">About</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/blog/contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav></header>
        {/* END header */}
        <section className="site-section">
          <div className="container">
            <div className="row mb-4">
              <div className="col-md-6">
                <h2 className="mb-4">Category: Food</h2>
              </div>
            </div>
            <div className="row blog-entries">
              <div className="col-md-12 col-lg-8 main-content">
                <div className="row mb-5 mt-5">
                  <div className="col-md-12">
                    <div className="post-entry-horzontal">
                      <a href={() => false}>
                        <div className="image " data-animate-effect="fadeIn" style={{backgroundImage: 'url(https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_10.jpg)'}} />
                        <span className="text">
                          <div className="post-meta">
                            <span className="category">Travel</span>
                            <span className="mr-2">March 15, 2018 </span> •
                            <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                          </div>
                          <h2>There’s a Cool New Way for Men to Wear Socks and Sandals</h2>
                        </span>
                      </a>
                    </div>
                    {/* END post */}
                    <div className="post-entry-horzontal">
                      <a href={() => false}>
                        <div className="image " data-animate-effect="fadeIn" style={{backgroundImage: 'url(https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_11.jpg)'}} />
                        <span className="text">
                          <div className="post-meta">
                            <span className="category">Lifestyle</span>
                            <span className="mr-2">March 15, 2018 </span> •
                            <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                          </div>
                          <h2>There’s a Cool New Way for Men to Wear Socks and Sandals</h2>
                        </span>
                      </a>
                    </div>
                    {/* END post */}
                    <div className="post-entry-horzontal">
                      <a href={() => false}>
                        <div className="image " data-animate-effect="fadeIn" style={{backgroundImage: 'url(https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_12.jpg)'}} />
                        <span className="text">
                          <div className="post-meta">
                            <span className="category">Food</span>
                            <span className="mr-2">March 15, 2018 </span> •
                            <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                          </div>
                          <h2>There’s a Cool New Way for Men to Wear Socks and Sandals</h2>
                        </span>
                      </a>
                    </div>
                    {/* END post */}
                    <div className="post-entry-horzontal">
                      <a href={() => false}>
                        <div className="image " data-animate-effect="fadeIn" style={{backgroundImage: 'url(https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_9.jpg)'}} />
                        <span className="text">
                          <div className="post-meta">
                            <span className="category">Travel</span>
                            <span className="mr-2">March 15, 2018 </span> •
                            <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                          </div>
                          <h2>There’s a Cool New Way for Men to Wear Socks and Sandals</h2>
                        </span>
                      </a>
                    </div>
                    {/* END post */}
                    <div className="post-entry-horzontal">
                      <a href={() => false}>
                        <div className="image " data-animate-effect="fadeIn" style={{backgroundImage: 'url(https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_8.jpg)'}} />
                        <span className="text">
                          <div className="post-meta">
                            <span className="category">Lifestyle</span>
                            <span className="mr-2">March 15, 2018 </span> •
                            <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                          </div>
                          <h2>There’s a Cool New Way for Men to Wear Socks and Sandals</h2>
                        </span>
                      </a>
                    </div>
                    {/* END post */}
                    <div className="post-entry-horzontal">
                      <a href={() => false}>
                        <div className="image " data-animate-effect="fadeIn" style={{backgroundImage: 'url(https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_7.jpg)'}} />
                        <span className="text">
                          <div className="post-meta">
                            <span className="category">Food</span>
                            <span className="mr-2">March 15, 2018 </span> •
                            <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                          </div>
                          <h2>There’s a Cool New Way for Men to Wear Socks and Sandals</h2>
                        </span>
                      </a>
                    </div>
                    {/* END post */}
                    <div className="post-entry-horzontal">
                      <a href={() => false}>
                        <div className="image " data-animate-effect="fadeIn" style={{backgroundImage: 'url(https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_6.jpg)'}} />
                        <span className="text">
                          <div className="post-meta">
                            <span className="category">Travel</span>
                            <span className="mr-2">March 15, 2018 </span> •
                            <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                          </div>
                          <h2>There’s a Cool New Way for Men to Wear Socks and Sandals</h2>
                        </span>
                      </a>
                    </div>
                    {/* END post */}
                    <div className="post-entry-horzontal">
                      <a href={() => false}>
                        <div className="image " data-animate-effect="fadeIn" style={{backgroundImage: 'url(https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_5.jpg)'}} />
                        <span className="text">
                          <div className="post-meta">
                            <span className="category">Lifestyle</span>
                            <span className="mr-2">March 15, 2018 </span> •
                            <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                          </div>
                          <h2>There’s a Cool New Way for Men to Wear Socks and Sandals</h2>
                        </span>
                      </a>
                    </div>
                    {/* END post */}
                    <div className="post-entry-horzontal">
                      <a href={() => false}>
                        <div className="image " data-animate-effect="fadeIn" style={{backgroundImage: 'url(https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_4.jpg)'}} />
                        <span className="text">
                          <div className="post-meta">
                            <span className="category">Food</span>
                            <span className="mr-2">March 15, 2018 </span> •
                            <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                          </div>
                          <h2>There’s a Cool New Way for Men to Wear Socks and Sandals</h2>
                        </span>
                      </a>
                    </div>
                    {/* END post */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <nav aria-label="Page navigation" className="text-center">
                      <ul className="pagination">
                        <li className="page-item  active"><a className="page-link" href={() => false}>Prev</a></li>
                        <li className="page-item"><a className="page-link" href={() => false}>1</a></li>
                        <li className="page-item"><a className="page-link" href={() => false}>2</a></li>
                        <li className="page-item"><a className="page-link" href={() => false}>3</a></li>
                        <li className="page-item"><a className="page-link" href={() => false}>Next</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              {/* END main-content */}
              <div className="col-md-12 col-lg-4 sidebar">
                <div className="sidebar-box search-form-wrap">
                  <form action="#" className="search-form">
                    <div className="form-group">
                      <span className="icon fa fa-search" />
                      <input type="text" className="form-control" id="s" placeholder="Type a keyword and hit enter" />
                    </div>
                  </form>
                </div>
                {/* END sidebar-box */}
                <div className="sidebar-box">
                  <div className="bio text-center">
                       <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/person_1.jpg" alt=" Placeholder" className="img-fluid" />
                    <div className="bio-body">
                      <h2>Meagan Smith</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem facilis sunt repellendus excepturi beatae porro debitis voluptate nulla quo veniam fuga sit molestias minus.</p>
                      <p><a href={() => false} className="btn btn-primary btn-sm">Read my bio</a></p>
                      <p className="social">
                        <a href={() => false} className="p-2"><span className="fa fa-facebook" /></a>
                        <a href={() => false} className="p-2"><span className="fa fa-twitter" /></a>
                        <a href={() => false} className="p-2"><span className="fa fa-instagram" /></a>
                        <a href={() => false} className="p-2"><span className="fa fa-youtube-play" /></a>
                      </p>
                    </div>
                  </div>
                </div>
                {/* END sidebar-box */}  
                <div className="sidebar-box">
                  <h3 className="heading">Popular Posts</h3>
                  <div className="post-entry-sidebar">
                    <ul>
                      <li>
                        <a href>
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_2.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                            <div className="post-meta">
                              <span className="mr-2">March 15, 2018 </span> •
                              <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href>
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_4.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                            <div className="post-meta">
                              <span className="mr-2">March 15, 2018 </span> •
                              <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href>
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_12.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                            <div className="post-meta">
                              <span className="mr-2">March 15, 2018 </span> •
                              <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* END sidebar-box */}
                <div className="sidebar-box">
                  <h3 className="heading">Categories</h3>
                  <ul className="categories">
                    <li><a href={() => false}>Courses <span>(12)</span></a></li>
                    <li><a href={() => false}>News <span>(22)</span></a></li>
                    <li><a href={() => false}>Design <span>(37)</span></a></li>
                    <li><a href={() => false}>HTML <span>(42)</span></a></li>
                    <li><a href={() => false}>Web Development <span>(14)</span></a></li>
                  </ul>
                </div>
                {/* END sidebar-box */}
                <div className="sidebar-box">
                  <h3 className="heading">Tags</h3>
                  <ul className="tags">
                    <li><a href={() => false}>Travel</a></li>
                    <li><a href={() => false}>Adventure</a></li>
                    <li><a href={() => false}>Food</a></li>
                    <li><a href={() => false}>Lifestyle</a></li>
                    <li><a href={() => false}>Business</a></li>
                    <li><a href={() => false}>Freelancing</a></li>
                    <li><a href={() => false}>Travel</a></li>
                    <li><a href={() => false}>Adventure</a></li>
                    <li><a href={() => false}>Food</a></li>
                    <li><a href={() => false}>Lifestyle</a></li>
                    <li><a href={() => false}>Business</a></li>
                    <li><a href={() => false}>Freelancing</a></li>
                  </ul>
                </div>
              </div>
              {/* END sidebar */}
            </div>
          </div>
        </section>
        <footer className="site-footer">
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-4">
                <h3>Paragraph</h3>
                <p>
                     <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_1.jpg" alt=" placeholder" className="img-fluid" />
                </p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi, accusantium optio unde perferendis eum illum voluptatibus dolore tempora, consequatur minus asperiores temporibus reprehenderit.</p>
              </div>
              <div className="col-md-6 ml-auto">
                <div className="row">
                  <div className="col-md-7">
                    <h3>Latest Post</h3>
                    <div className="post-entry-sidebar">
                      <ul>
                        <li>
                          <a href>
                               <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_6.jpg" alt=" placeholder" className="mr-4" />
                            <div className="text">
                              <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                              <div className="post-meta">
                                <span className="mr-2">March 15, 2018 </span> •
                                <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href>
                               <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_3.jpg" alt=" placeholder" className="mr-4" />
                            <div className="text">
                              <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                              <div className="post-meta">
                                <span className="mr-2">March 15, 2018 </span> •
                                <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href>
                               <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_4.jpg" alt=" placeholder" className="mr-4" />
                            <div className="text">
                              <h4>There’s a Cool New Way for Men to Wear Socks and Sandals</h4>
                              <div className="post-meta">
                                <span className="mr-2">March 15, 2018 </span> •
                                <span className="ml-2"><span className="fa fa-comments" /> 3</span>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-1" />
                  <div className="col-md-4">
                    <div className="mb-5">
                      <h3>Quick Links</h3>
                      <ul className="list-unstyled">
                        <li><a href={() => false}>About Us</a></li>
                        <li><a href={() => false}>Travel</a></li>
                        <li><a href={() => false}>Adventure</a></li>
                        <li><a href={() => false}>Courses</a></li>
                        <li><a href={() => false}>Categories</a></li>
                      </ul>
                    </div>
                    <div className="mb-5">
                      <h3>Social</h3>
                      <ul className="list-unstyled footer-social">
                        <li><a href={() => false}><span className="fa fa-twitter" /> Twitter</a></li>
                        <li><a href={() => false}><span className="fa fa-facebook" /> Facebook</a></li>
                        <li><a href={() => false}><span className="fa fa-instagram" /> Instagram</a></li>
                        <li><a href={() => false}><span className="fa fa-vimeo" /> Vimeo</a></li>
                        <li><a href={() => false}><span className="fa fa-youtube-play" /> Youtube</a></li>
                        <li><a href={() => false}><span className="fa fa-snapchat" /> Snapshot</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                Copyright © All rights reserved | This template is made with <i className="fa fa-heart-o" aria-hidden="true" /> by <a href="https://colorlib.com"  target="_blank" rel="noopener noreferrer">Colorlib</a>
                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default category;