import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import '@brainhubeu/react-carousel/lib/style.css';
import './product.css'
import "@brainhubeu/react-carousel/lib/style.css";
import Carousel from './Carousel';
import CarouselItem from './CarouselItem';
import DropusLIne from '../GlobalConsants/DropusLIne';
import Testimonials from '../Testimonials/Testimonials';
import { videodata } from './Videosdata'
import { Button } from 'bootstrap';
import { Helmet } from 'react-helmet';
class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            videoname: "",
            url: ""

        }


    }
    onChange(val) {
        this.setState({ value: val });
    }
    componentDidMount() {
        document.getElementById("sideheader").style.display = "none"
    }
    render() {
        return (
            <div>
            <Helmet>
        <title>Products - Boom Barriers in Hyderabad | Boom Gate, Gate Motor | FAAC | ANPR</title>
        <meta property="og:title" content="Products - Boom Barriers in Hyderabad | Boom Gate, Gate Motor | FAAC | ANPR" />
        <meta property="og:url" content="https://www.gaido.in/products" />
      </Helmet>
                <div>
                    <Header />
                    <div className="inner-banner" style={{ marginBottom: 30 }}>
                        <h2>Most Trusted Supplier across INDIA</h2>
                    </div>
                </div>

                <div>
                    <section className='product'>

                        <div className="section-header">
                            <h2><br />Products</h2>
                        </div>
                        {/* <Carousel /> */}
                        <CarouselItem />


                    </section>
                </div>

                <hr></hr>
                <section>
                    <div className="container">
                        <div className="section-header">
                            <h2>Videos</h2>
                        </div>
                        <div className='row'>


                            <div className="col-lg-3 col-sm-6 col-md-6 col-xs-6 " style={{ padding: 10, alignContent: 'center' }}>



                                <div class="card mb-2">
                                    <iframe className='card-img-top' id="Geeks3" width="255" height="220"
                                        src=
                                        "https://www.youtube.com/embed/ABpAjplPCkk"
                                        frameborder="0" allowfullscreen >
                                    </iframe>
                                    <div class="card-body">
                                        <h5 class="card-title">Sliding Gate Motors</h5>
                                        <br />
                                        <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#exampleModal" > Get Quote for Product </button>
                                    </div>

                                </div>

                            </div>

                            <div>
                                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                                    <div class="modal-dialog modal-xl" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header"  >



                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div className="row">
                                                    <div className='col-lg-6 col-sm-12 col-md-12 col-xs-12 show-large'>
                                                        <h4> <b> Sliding Gate Motors</b></h4>
                                                        Sold By - Gaido Technologies Private Limited, Hyderabad, Telangana<br></br><br></br>

                                                        <iframe className='card-img-top' id="Geeks3" height="350" width="200"
                                                            src=
                                                            "https://www.youtube.com/embed/ABpAjplPCkk?autoplay=1"
                                                            frameborder="0">
                                                        </iframe>
                                                    </div>
                                                    <div className='col-lg-6 col-sm-12 col-md-12 col-xs-12'>

                                                        <iframe src="https://forms.zohopublic.in/sales80/form/KnowMore/formperma/Bxd9ffNXHg4LXDPgA4AQ3WezARUlyFtl56X2IBlfNoI?zf_rszfm=1" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                                                    </div>

                                                </div>          </div>
                                            <div class="modal-footer">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-sm-6 col-md-6 col-xs-6 " style={{ padding: 10, alignContent: 'center' }}>


                                <div class="card mb-2">
                                    <iframe className='card-img-top' id="Geeks3" width="255" height="220"
                                        src=
                                        "https://www.youtube.com/embed/SwyLH0Kw2Ww"
                                        frameborder="0" allowfullscreen>
                                    </iframe>
                                    <div class="card-body">
                                        <h5 class="card-title">ANPR System</h5>
                                        <br />

                                        <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#exampleModal1"> Get Quote for Product </button>

                                    </div>

                                </div>
                            </div>
                            <div>
                                <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                                    <div class="modal-dialog modal-xl" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header"  >



                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div className="row">
                                                    <div className='col-lg-6 col-sm-12 col-md-12 col-xs-12 show-large'>
                                                        <h4>  ANPR System</h4>
                                                        Sold By - Gaido Technologies Private Limited, Hyderabad, Telangana<br></br><br></br>
                                                        <iframe className='card-img-top' id="Geeks3" height="400"
                                                            src=
                                                            "https://www.youtube.com/embed/SwyLH0Kw2Ww?autoplay=1"
                                                            frameborder="0" >
                                                        </iframe>
                                                    </div><div className='col-lg-6 col-sm-12 col-md-12 col-xs-12'>

                                                        <iframe src="https://forms.zohopublic.in/sales80/form/KnowMore/formperma/Bxd9ffNXHg4LXDPgA4AQ3WezARUlyFtl56X2IBlfNoI?zf_rszfm=1" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="modal-footer">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-3 col-sm-6 col-md-6 col-xs-6 " style={{ padding: 10, alignContent: 'center' }}>


                                <div class="card mb-2">
                                    <iframe className='card-img-top' id="Geeks3" width="255" height="220"
                                        src=
                                        "https://www.youtube.com/embed/PruOQYGZyrM"
                                        frameborder="0" allowfullscreen>
                                    </iframe>
                                    <div class="card-body">
                                        <h5 class="card-title">FAAC Automatic Boom Barrier </h5>

                                        <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#exampleModal2"> Get Quote for Product </button>

                                    </div>

                                </div>

                            </div>

                            <div>
                                <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                                    <div class="modal-dialog modal-xl" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header"  >



                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div className="row">
                                                    <div className='col-lg-6 col-sm-12 col-md-12 col-xs-12 show-large'>
                                                        <h4>FAAC Automatic Boom Barrier</h4>
                                                        Sold By - Gaido Technologies Private Limited, Hyderabad, Telangana<br></br><br></br>
                                                        <iframe className='card-img-top' id="Geeks3" height="400"
                                                            src=
                                                            "https://www.youtube.com/embed/PruOQYGZyrM?autoplay=1"
                                                            frameborder="0">
                                                        </iframe>
                                                    </div><div className='col-lg-6 col-sm-12 col-md-12 col-xs-12'>

                                                        <iframe src="https://forms.zohopublic.in/sales80/form/KnowMore/formperma/Bxd9ffNXHg4LXDPgA4AQ3WezARUlyFtl56X2IBlfNoI?zf_rszfm=1" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="modal-footer">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-md-6 col-xs-6 " style={{ padding: 10, alignContent: 'center' }}>


                                <div class="card mb-2">
                                    <iframe className='card-img-top' id="Geeks3" width="255" height="220"
                                        src=
                                        "https://www.youtube.com/embed/h8Qqp6-sJOw"
                                        frameborder="0" allowfullscreen>
                                    </iframe>
                                    <div class="card-body">
                                        <h5 class="card-title">Automatic Gate Motor</h5>
                                        <br />
                                        <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#exampleModal3"> Get Quote for Product </button>


                                    </div>

                                </div>
                            </div>
                            <div>
                                <div class="modal fade" id="exampleModal3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                                    <div class="modal-dialog modal-xl" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header"  >



                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div className="row">
                                                    <div className='col-lg-6 col-sm-12 col-md-12 col-xs-12 show-large'>
                                                        <h4>Automatic Gate Motor</h4>
                                                        Sold By - Gaido Technologies Private Limited, Hyderabad, Telangana<br></br><br></br>
                                                        <iframe className='card-img-top' id="Geeks3" height="400"
                                                            src=
                                                            "https://www.youtube.com/embed/h8Qqp6-sJOw?autoplay=1"
                                                            frameborder="0" >
                                                        </iframe>
                                                    </div><div className='col-lg-6 col-sm-12 col-md-12 col-xs-12'>

                                                        <iframe src="https://forms.zohopublic.in/sales80/form/KnowMore/formperma/Bxd9ffNXHg4LXDPgA4AQ3WezARUlyFtl56X2IBlfNoI?zf_rszfm=1" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="modal-footer">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="more2" style={{ padding: 10 }}>
                                <a href="#more" style={{ color: "blue" }} onClick={() => {
                                    document.getElementById("more").style.display = "Inline";
                                    document.getElementById("more2").style.display = "none";


                                }}>view more videos --&gt;</a>
                            </div>
                        </div>

                        <div id="more" style={{ display: "none" }}>
                            <div className='row' >
                                <div className="col-lg-3 col-sm-6 col-md-6 col-xs-6 " style={{ padding: 10, alignContent: 'center' }}>


                                    <div class="card mb-2">
                                        <iframe className='card-img-top' id="Geeks3" width="255" height="220"
                                            src=
                                            "https://www.youtube.com/embed/MP0pdt0PlRo"
                                            frameborder="0" allowfullscreen>
                                        </iframe>
                                        <div class="card-body">
                                            <h5 class="card-title">FAAC Automatic Boom Barrier</h5>
                                            <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#exampleModal4"> Get Quote for Product </button>

                                        </div>

                                    </div>
                                </div>
                                <div>
                                    <div class="modal fade" id="exampleModal4" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                                        <div class="modal-dialog modal-xl" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header"  >



                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div className="row">
                                                        <div className='col-lg-6 col-sm-12 col-md-12 col-xs-12 show-large'>
                                                            <h4>FAAC Automatic Boom Barrier</h4>
                                                            Sold By - Gaido Technologies Private Limited, Hyderabad, Telangana<br></br><br></br>
                                                            <iframe className='card-img-top' id="Geeks3" height="400"
                                                                src=
                                                                "https://www.youtube.com/embed/MP0pdt0PlRo?autoplay=1"
                                                                frameborder="0" >
                                                            </iframe>
                                                        </div><div className='col-lg-6 col-sm-12 col-md-12 col-xs-12'>

                                                            <iframe src="https://forms.zohopublic.in/sales80/form/KnowMore/formperma/Bxd9ffNXHg4LXDPgA4AQ3WezARUlyFtl56X2IBlfNoI?zf_rszfm=1" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="modal-footer">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-md-6 col-xs-6 " style={{ padding: 10, alignContent: 'center' }}>


                                    <div class="card mb-2">
                                        <iframe className='card-img-top' id="Geeks3" width="255" height="220"
                                            src=
                                            "https://www.youtube.com/embed/5JHNWWtfxZY"
                                            frameborder="0" allowfullscreen>
                                        </iframe>
                                        <div class="card-body">
                                            <h5 class="card-title">RFID Car Parking System</h5>
                                            <br />
                                            <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#exampleModal5"> Get Quote for Product </button>

                                        </div>

                                    </div>
                                </div>
                                <div>
                                    <div class="modal fade" id="exampleModal5" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                                        <div class="modal-dialog modal-xl" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header"  >



                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div className="row">
                                                        <div className='col-lg-6 col-sm-12 col-md-12 col-xs-12 show-large'>
                                                            <h4>RFID Car Parking System</h4>
                                                            Sold By - Gaido Technologies Private Limited, Hyderabad, Telangana<br></br><br></br>
                                                            <iframe className='card-img-top' id="Geeks3" height="400"
                                                                src=
                                                                "https://www.youtube.com/embed/5JHNWWtfxZY?autoplay=1"
                                                                frameborder="0" >
                                                            </iframe>
                                                        </div><div className='col-lg-6 col-sm-12 col-md-12 col-xs-12'>

                                                            <iframe src="https://forms.zohopublic.in/sales80/form/KnowMore/formperma/Bxd9ffNXHg4LXDPgA4AQ3WezARUlyFtl56X2IBlfNoI?zf_rszfm=1" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="modal-footer">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-md-6 col-xs-6 " style={{ padding: 10, alignContent: 'center' }}>


                                    <div class="card mb-2">
                                        <iframe className='card-img-top' id="Geeks3" width="255" height="220"
                                            src=
                                            "https://www.youtube.com/embed/kUoWbp5Z6Bs"
                                            frameborder="0" allowfullscreen>
                                        </iframe>
                                        <div class="card-body">
                                            <h5 class="card-title">Phone call Controller</h5>
                                            <br />

                                            <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#exampleModal6"> Get Quote for Product </button>

                                        </div>

                                    </div>
                                </div>
                                <div>
                                    <div class="modal fade" id="exampleModal6" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                                        <div class="modal-dialog modal-xl" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header"  >



                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div className="row">
                                                        <div className='col-lg-6 col-sm-12 col-md-12 col-xs-12 show-large'>
                                                            <h4>Phone call Controller</h4>
                                                            Sold By - Gaido Technologies Private Limited, Hyderabad, Telangana<br></br><br></br>
                                                            <iframe className='card-img-top' id="Geeks3" height="400"
                                                                src=
                                                                "https://www.youtube.com/embed/kUoWbp5Z6Bs?autoplay=1"
                                                                frameborder="0">
                                                            </iframe>
                                                        </div><div className='col-lg-6 col-sm-12 col-md-12 col-xs-12'>

                                                            <iframe src="https://forms.zohopublic.in/sales80/form/KnowMore/formperma/Bxd9ffNXHg4LXDPgA4AQ3WezARUlyFtl56X2IBlfNoI?zf_rszfm=1" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-md-6 col-xs-6 " style={{ padding: 10, alignContent: 'center' }}>


                                    <div class="card mb-2">
                                        <iframe className='card-img-top' id="Geeks3" width="255" height="220"
                                            src=
                                            "https://www.youtube.com/embed/ONkvS374s-Y"
                                            frameborder="0" allowfullscreen>
                                        </iframe>
                                        <div class="card-body">
                                            <h5 class="card-title">Valet Car Parking System</h5>
                                            <button type="button" className="btn btn-secondary" data-toggle="modal" data-target="#exampleModal7"> Get Quote for Product </button>

                                        </div>

                                    </div>
                                </div>

                                <div>
                                    <div class="modal fade" id="exampleModal7" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                                        <div class="modal-dialog modal-xl" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header"  >



                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div className="row">
                                                        <div className='col-lg-6 col-sm-12 col-md-12 col-xs-12 show-large'>
                                                            <h4>Valet Car Parking System</h4>
                                                            Sold By - Gaido Technologies Private Limited, Hyderabad, Telangana<br></br><br></br>
                                                            <iframe className='card-img-top' id="Geeks3" height="400"
                                                                src=
                                                                "https://www.youtube.com/embed/ONkvS374s-Y?autoplay=1"
                                                                frameborder="0"  >
                                                            </iframe>
                                                        </div>
                                                        <div className='col-lg-6 col-sm-12 col-md-12 col-xs-12'>

                                                            <iframe src="https://forms.zohopublic.in/sales80/form/KnowMore/formperma/Bxd9ffNXHg4LXDPgA4AQ3WezARUlyFtl56X2IBlfNoI?zf_rszfm=1" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* <div className="section-header">
                        <h2>Videos</h2>
                    </div>
                    <div style={{ paddingLeft: 50 }}>
                        {videodata.map((data, key) => {
                            return (

                                    <div style={{ padding: 5, alignContent: 'center', display: "inline-flex" }}>

                                        <div key={key}>


                                            <div class="card mb-2">
                                                <iframe className='card-img-top' id="Geeks3" width="255" height="220"
                                                    src=
                                                    {`${data.url}`}
                                                    frameborder="0" allowfullscreen >
                                                </iframe>
                                                <div class="card-body">
                                                    <h5 class="card-title">{data.video}</h5>
                                                    <br />
                                                    <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#exampleModal"
                                                        onClick={() => {
                                                            this.setState({
                                                                videoname: data.video,
                                                                url: data.url
                                                            })
                                                        }}

                                                    > Get Quote for Product </button>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                            );

                        })}
                    </div>
                    <div>
                        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                            <div class="modal-dialog modal-xl" role="document">
                                <div class="modal-content">
                                    <div class="modal-header"  >



                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div className="row">
                                            <div className='col-lg-6 col-sm-12 col-md-12 col-xs-12'>
                                                <h4>{this.state.videoname}</h4>
                                                Sold By - Gaido Technologies Private Limited, Hyderabad, Telangana<br></br><br></br>
                                                <iframe className='card-img-top' id="Geeks3" height="400"
                                                    src=
                                                    {this.state.url}
                                                    frameborder="0">
                                                </iframe>
                                            </div><div className='col-lg-6 col-sm-12 col-md-12 col-xs-12'>

                                                <iframe src="https://forms.zohopublic.in/sales80/form/KnowMore/formperma/Bxd9ffNXHg4LXDPgA4AQ3WezARUlyFtl56X2IBlfNoI?zf_rszfm=1" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> */}


                </section>
                <section >

                    <div className="section-header" >
                        <h2 style={{fontSize:"30px"}}>Clients Who Believed in Gaido</h2>
                    </div>
                    <div style={{ paddingRight: 20, paddingLeft: 20 }} >
                        <div className='row'>                    <div className='col-lg-3 col-sm-3 col-md-3 col-xs-3'>
</div>
                    <div className='col-lg-6 col-sm-6 col-md-6 col-xs-6'>

                        <img src={require("../ClientImage.png")} alt="Gaido technologies" style={{ height: "100%", width: '100%' }} />
                        </div>
                        </div>

                      



                    </div>

                </section>
                <section id="partner-network" style={{ backGroundColor: "#393939" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 content mobilepartner" >
                                <div className="text" style={{ padding: "95px 0px 21px 0px" }}>
                                    <h3 style={{ color: "#fff" }}>PARTNER NETWORK</h3>
                                </div>
                                <p style={{
                                    textAlign: 'justify', lineHeight: "24px",
                                    fontFamilty: "Montserrat", color: "#fff"
                                }}>Solving parking issues across India, we are on a mission to bring high-quality and high-performance Parking Management Hardware and enable it with our state of the art software to all the cities in India. Our partners are our strength, and have played a key role in empowering us to take our solution to cities (tier 1, tire 2 & tire 3 cities) PAN India & transform the car parking experience one parking lot at a time.</p>
                            </div>
                            <div className="col-lg-6 content">
                                <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/gaidoIndiamap.png" alt="Gaido technologies" className="img-responsive" style={{ width: '100%' }} />
                            </div>
                        </div>
                    </div>
                </section>
                <DropusLIne />
                <Testimonials />

                {/* <section >
                    <div style={{ backgroundColor: "gray", }}>
                        <div className="row" style={{ padding: 80}} >

                            <div className='col-lg-2 text-justify' >
                                <img src={require("./img/bluecar1.png")} alt="Gaido technologies" style={{ height:200, justifyItems: 'center', borderLeft: "10px solid white", borderRight: "10px solid white", borderBottom: "10px solid white" }} />
                            </div>
                            <div className='col-lg-2 text-justify' >
                                <img src={require("./img/bluecar1.png")} alt="Gaido technologies" style={{ height:200,justifyItems: 'center', borderLeft: "10px solid white", borderRight: "10px solid white", borderBottom: "10px solid white" }} />
                            </div>
                            <div className='col-lg-2 text-justify' >
                                <img src={require("./img/bluecar1.png")} alt="Gaido technologies" style={{ height:200,justifyItems: 'center',borderLeft: "10px solid white", borderRight: "10px solid white", borderBottom: "10px solid white" }} />
                            </div>
                             <div className='col-lg-2 text-justify' >
                                <img src={require("./img/bluecar1.png")} alt="Gaido technologies" style={{ height:200,justifyItems: 'center', borderRight: "10px solid white", borderBottom: "10px solid white" }} />
                            </div>
                            

                            <div className='col-lg-2' style={{ height: 180, borderLeft: "10px solid white", borderRight: "10px solid white" }}></div>
                            <div className='col-lg-2' style={{ height: 180, borderRight: "10px solid white", }}></div>
                            <div className='col-lg-2' style={{ height: 180, borderRight: "10px solid white", }}></div>
                            <div className='col-lg-2' style={{ height: 180, borderRight: "10px solid white", }}></div>
                            <div className='col-lg-2' style={{ height: 180, borderRight: "10px solid white" }}></div>
                        </div>

                    </div>
 
                </section> */}

                <Footer />
                {/* 
                <div  class='support col-lg-6 col-sm-12 col-md-12 col-xs-12' style={{marginTop:100}}>
                <a className="back-to-top"> <button class="call-support show-mobile " style={{float:'-moz-initial',position:'relative'}}>Call Support</button></a>

                   
                </div> */}
                {/* <a class="float show-mobile"  style={{ width: "100%" }}>
                    <button class=" btn-secondary"  data-toggle="modal" data-target="#example" style={{ textAlignlign: "center", marginTop: 10, border: "none", width: 300, height: 40 }} >Get Quote</button>
                </a>

                <div >
                <div class="modal fade" id="example" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document" >
                        <div class="modal-content"  >
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Connect Us</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{ padding: 20 }}>
                                <div className='row'>
                                 

                                    <div className='col-lg-6 col-sm-6 col-md-6 col-xs-6'  >
                                        <br />
                                        <iframe class="embed-responsive-item"
                                            src="https://forms.zohopublic.in/sales80/form/KnowMore/formperma/Bxd9ffNXHg4LXDPgA4AQ3WezARUlyFtl56X2IBlfNoI?zf_rszfm=1" style={{ textAlign: "center" }} width="100%" height="500" frameborder="0" marginheight="0" marginwidth="">Loading…</iframe>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div> */}
            </div >
        );
    }
}

export default Products;