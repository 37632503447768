import React, { Component } from 'react';
class Advanatages extends Component {

    render() {
        return (
            <section id="portfolio" className="wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
              <div className="container">
              <div className="section-header">
                <h2>Advantages</h2>
                <p>We continuously work on ensuring that our solutions provide these benefits </p>
              </div>
            </div>
                <div className="container">
                    <div className="row blog">
                        <div className="col-md-12">
                            <div id="blogCarousel" className="carousel slide" data-ride="carousel">
                                <ol className="carousel-indicators">
                                    <li data-target="#blogCarousel" data-slide-to={0} className="active" />
                                    <li data-target="#blogCarousel" data-slide-to={1} />
                                </ol>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="row">
                                            <div className="col-md-4 wow fadeInLeft adv">
                                                <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/park.png" /></div>
                                                <h4 className="title"><a href={() => false}>Maximize Security</a></h4>
                                                <ul>
                                                    <li><i className="icon ion-ios-checkmark-outline" /> Real-time parking lot monitoring</li>
                                                    <li><i className="icon ion-ios-checkmark-outline" /> Authorized access</li>
                                                    <li><i className="icon ion-ios-checkmark-outline" /> Real-time Alert notification</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 wow fadeInDown adv">
                                                <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon2.png" /></div>
                                                <h4 className="title"><a href={() => false}>Maximize Profitability</a></h4>
                                                <ul>
                                                    <li><i className="icon ion-ios-checkmark-outline" /> Reduce operating costs</li>
                                                    <li><i className="icon ion-ios-checkmark-outline" /> Increase revenue by smart recommendation</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 wow fadeInDown adv">
                                                <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon3.png" /></div>
                                                <h4 className="title"><a href={() => false}>Maximize Management</a></h4>
                                                <ul>
                                                    <li><i className="icon ion-ios-checkmark-outline" /> Minimize human error</li>
                                                    <li><i className="icon ion-ios-checkmark-outline" /> Efficient organization of parking</li>
                                                    <li><i className="icon ion-ios-checkmark-outline" /> Data driven decision making</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="row">
                                            <div className="col-md-4 wow fadeInDown adv">
                                                <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon8.png" /></div>
                                                <h4 className="title"><a href={() => false}>Maximize Quality and Control</a></h4>
                                                <ul>
                                                    <li><i className="icon ion-ios-checkmark-outline" /> Industry leading design</li>
                                                    <li><i className="icon ion-ios-checkmark-outline" /> Highly durable materials</li>
                                                    <li><i className="icon ion-ios-checkmark-outline" /> Timely maintenance and support</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 wow fadeInDown adv">
                                                <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon3.png" /></div>
                                                <h4 className="title"><a href={() => false}>Maximize Management</a></h4>
                                                <ul>
                                                    <li><i className="icon ion-ios-checkmark-outline" /> Minimize human error</li>
                                                    <li><i className="icon ion-ios-checkmark-outline" /> Efficient organization of parking</li>
                                                    <li><i className="icon ion-ios-checkmark-outline" /> Data driven decision making</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 wow fadeInDown adv">
                                                <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon5.png" /></div>
                                                <h4 className="title"><a href={() => false}>Maximize Customer Experience</a></h4>
                                                <ul>
                                                    <li><i className="icon ion-ios-checkmark-outline" /> Reduces frustration and chaos</li>
                                                    <li><i className="icon ion-ios-checkmark-outline" /> Seamless parking experience</li>
                                                    <li><i className="icon ion-ios-checkmark-outline" /> Additional information for end user</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Advanatages;