import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer';
class Team extends Component {
    componentDidMount(){
        document.getElementById("floatbutton").style.display="none"
      }
    render() {
        return (
            <div>
                <Header />
                <div className="inner-banner">
                    <h2>The Team</h2>
                </div>
                <div className="section-header" style={{ visibility: "hidden" }}>
                    <h2 >Our Solutions</h2>
                </div>
                <section id="team" className="wow fadeInLeft our-webcoderskull padding-lg" style={{ marginTop: 45 }}>
                    <div className=" container">
                        <div className="section-header">
                            <h2>Senior leadership</h2>
                        </div>
                        <ul className="row team">
                            <li className="col-12 col-md-6 col-lg-3">
                                <div className="cnt-block equal-hight" >
                                    <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/coo.png" className="img-responsive" />
                                    <div className="overlay">
                                        <div className="text">
                                            <h3>Rajesh Jain</h3>
                                        </div>
                                    </div>
                                    <p style={{ textAlign: 'center' }}>Director and COO</p>
                                </div>
                            </li>
                            <li className="col-12 col-md-6 col-lg-3">
                                <div className="cnt-block equal-hight" >
                                    <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/cto.png" className="img-responsive" />
                                    <div className="overlay">
                                        <div className="text">
                                            <h3>Rambabu Addala</h3>
                                        </div>
                                    </div>
                                    <p style={{ textAlign: 'center' }}>CTO</p>
                                </div>
                            </li>
                            <li className="col-12 col-md-6 col-lg-3">
                                <div className="cnt-block equal-hight" >
                                    <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/ceo.png" className="img-responsive" />
                                    <div className="overlay">
                                        <div className="text">
                                            <h3>Kapil Jain</h3>
                                        </div>
                                    </div>
                                    <p style={{ textAlign: 'center' }}>Founder &amp; CEO</p>
                                </div>
                            </li>
                            <li className="col-12 col-md-6 col-lg-3">
                                <div className="cnt-block equal-hight" >
                                    <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/software.png" className="img-responsive" />
                                    <div className="overlay">
                                        <div className="text">
                                            <h3>Srinivasa Rao </h3>
                                        </div>
                                    </div>
                                    <p style={{ textAlign: 'center' }}>Software Engineer </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}
export default Team;