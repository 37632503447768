import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer';  
import { Helmet } from 'react-helmet';
class Hotels extends Component {


    render() {
        return (
            <div>
<Helmet>
        <title>Hotels Boom Barriers | ANPR | LPR | High Security | Gaido Technologies Gate </title>
        <meta property="og:title" content="Hotels Boom Barriers | ANPR | LPR | High Security | Gaido Technologies Gate" />
        <meta property="og:url" content="https://www.gaido.in/offerings/hotels" />
      </Helmet>
                <Header /> 
                 <div className="inner-banner3 hotel">
          <div className="row">
            <div className="col-md-5">
              <div className="hid-img">
                   <img alt ="gaidologos"src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/hotel2.jpg" style={{width: '100%'}} />
              </div>
              <h2>Hotels</h2>
              <p>You’ve done it all - Clean and tidy rooms, world-class amenities, the best marketing to
                maximize occupancy, a profitable bar and restaurant. Please ensure that you don’t miss the
                stars because of an inefficient parking lot. Nowadays, guests do not expect to pay for
                parking when arriving at a hotel....</p>
              <p className="read-more"><a href="#about"> READ MORE <i className="fa fa-plus" /> </a></p>
            </div>
          </div>
        </div>
        {/*==========================
    Solutions Section
  ============================*/}
        <section id="about" className="f-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 content offerings2">
                <h1 className="hd2"> Offering for Hotels</h1>
                <p>You’ve done it all - Clean and tidy rooms, world-class amenities, the best marketing to
                  maximize occupancy, a profitable bar and restaurant. Please ensure that you don’t miss the
                  stars because of an inefficient parking lot. Nowadays, guests do not expect to pay for
                  parking when arriving at a hotel. Paid parking is simple but may affect your customer’s
                  overall experience. But as a business, you need to make some money out of your parking
                  lot.
                </p>
                <p>Gaido Technologies helps you achieve these goals providing a parking system that can help
                  you generate that additional revenue and simultaneously providing your customers a
                  pleasant parking experience. Even better and more convenient is Valet Parking – Give your
                  customers the option to drive in and drop their keys. Why bother looking for a parking
                  space and have trouble remembering the precise location. Our valet management feature
                  eliminates the hassle of using physical tickets and provides an additional layer of security to
                  valet parking management.
                </p>
                <p>Additionally, implementing our smart parking solution into your infrastructure shall enable
                  you to take data-driven decisions in optimizing the parking lot operations. With its highly
                  scalable Cloud-based SaaS product, particularly customizable to cater to the needs of hotels,
                  Gaido Technologies is capable of providing solutions that will help your hotel’s parking
                  facility accomplish maximum security, profitability, management, and control.
                </p>
              </div>
              <p className="read-more" style={{margin: "65px 65px 0px 65px"}}><a href="/products"> VIEW PRODUCTS <i className="fa fa-plus" /> </a></p>

            </div>
          </div>

        </section>
        <Footer />
        <a href={() => false} className="back-to-top"><i className="fa fa-chevron-up" /></a>
            </div>
        );
    }
}

export default Hotels;