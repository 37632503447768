import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MailIcon from "@material-ui/icons/Mail";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import IconButton from "@material-ui/core/IconButton";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";
import faaclogo from "../src/FaacLogo.png";
import { green } from "@material-ui/core/colors";
import faccImage from  "./img-01.png"

// import LoaderComponent from './GlobalConsants/LoaderComponent'
// import axios from 'axios'

class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      randomnumbber: Math.floor(100000 + Math.random() * 900000),
      typenumber: "",
      falgerror: false,
      falgerror1: false,
      falgerror2: false,
      falgerror3: false,
      loader: false,
      name: "",
      email: "",
      contact: "",
    };
  }

  componentDidMount() {
    window.addEventListener("loader", this.handleNetworkChange);
  }
  componentWillUnmount() {
    window.removeEventListener("loader", this.handleNetworkChange);
  }
  handleNetworkChange = () => {
    this.setState({ modalOpen: false });
    this.setState({ loader: false });
  };
  render() {
    return (
      <>
       
        <Dialog
          open={this.state.modalOpen}
          // TransitionComponent={Transition}
          keepMounted
          onClose={() => this.setState({ modalOpen: false })}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {""}
            <IconButton
              aria-label="close"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
              onClick={() => this.setState({ modalOpen: false })}
            >
              <CancelOutlinedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div>
                <div
                  className="col-md-12"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <h2>One Tap Connect</h2>
                </div>
                <div
                  className="col-md-12"
                  style={{
                    display: "flex",
                    alignItems: "left",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div className="col-md-4">
                    <div style={{ cursor: "pointer" }}>
                      <a
                        href="https://api.whatsapp.com/send?phone=917373735668"
                        className="pushable"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span class="front">
                          <WhatsAppIcon
                            style={{
                              fontSize: 125,
                              color: "#4FCE5D",
                              boxShadow:
                                " 0px 0px 10px 10px rgba(79,206,93, 0.2)",
                            }}
                          />{" "}
                        </span>{" "}
                        <br />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div style={{ cursor: "pointer" }}>
                      <a
                        href="tel:+91 7373735668"
                        className="btn "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <PhoneCallbackIcon
                          style={{
                            fontSize: 125,
                            color: "#66bb6a",
                            boxShadow:
                              " 0px 0px 10px 10px rgba(79,206,93, 0.2)",
                          }}
                        />
                      </a>
                      {/* <p><> */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div style={{ cursor: "pointer" }}>
                      <a
                        href="mailto:info@gaido.in?subject=Gaido Request Call Back&body"
                        className="btn "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <MailIcon
                          style={{
                            fontSize: 125,
                            color: "#26c6da",
                            boxShadow:
                              " 0px 0px 10px 10px rgba(79,206,93, 0.2)",
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div>
                  <br />
                  <h3 style={{ textAlign: "center" }}>or</h3>
                </div>{" "}
                {this.state.loader === true ? (
                  <div
                    style={{
                      position: "absolute",
                      right: "300px",
                      top: "196px",
                    }}
                  >
                    <i
                      className="fa-spin fas fa-sync"
                      style={{ fontSize: 30, color: "#1bc760" }}
                    ></i>
                  </div>
                ) : (
                  ""
                )}
                {this.state.falgerror ? (
                  <p style={{ color: "red", fontSize: 15 }}>
                    Invalid captcha code !
                  </p>
                ) : (
                  ""
                )}
                {this.state.falgerror1 ? (
                  <p style={{ color: "red", fontSize: 15 }}>
                    Please try again later !
                  </p>
                ) : (
                  ""
                )}
                {this.state.falgerror2 ? (
                  <p style={{ color: "red", fontSize: 15 }}>
                    Mobile number must be 10 digits !
                  </p>
                ) : (
                  ""
                )}
                {this.state.falgerror3 ? (
                  <p style={{ color: "red", fontSize: 15 }}>
                    Please fill in all required fields. !
                  </p>
                ) : (
                  ""
                )}
                {this.state.falgerror4 ? (
                  <p style={{ color: "red", fontSize: 15 }}>
                    Invalid email address !
                  </p>
                ) : (
                  ""
                )}
                {this.state.falgerror5 ? (
                  <p style={{ color: "red", fontSize: 15 }}>
                    Name Only letters are allowed!
                  </p>
                ) : (
                  ""
                )}
                <div className="bottom-pop-form">
                  <h6 style={{ marginLeft: 20, textAlign: "center" }}>
                    Request a Call Back
                  </h6>
                  <iframe
                    scrolling="no"
                    allowtransparency="true"
                    src="https://forms.zohopublic.in/sales80/form/ContactUs/formperma/o3sKh3PHKyQ5GmkDmlz4rY0NM2edU5IF_pl9MYAjDoA?zf_rszfm=1"
                    id="gform"
                    width="520"
                    height="980"
                  >
                    Loading…
                  </iframe>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      
       
        <header id="header" style={{ height: "105px" }}>
        <div>
          <div className="row faccheader" style={{backgroundColor:"#393939",color:'white',marginBottom:"15px"}}>
            <div className="col-md-8">
              <span>Authorized Distributor &amp; Service Partner for </span> &nbsp;&nbsp;&nbsp;&nbsp;
              <img src={faccImage} style={{height:25}} />
            </div>
            <div class="col-md-4">
           
            </div>
          </div>
        </div>
        <div>
       
        </div>
          <div className="sticky-container" id="sideheader">
            <ul className="sticky">
              <li>
                <a
                  href="https://www.facebook.com/gaidoindia"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <img
                    width={32}
                    height={32}
                    title="Facebook"
                    alt="Facebook"
                    src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/fb1.png"
                  />
                  <p>Facebook</p>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/gaidoindia"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <img
                    width={32}
                    height={32}
                    title="Twitter"
                    alt="Twitter"
                    src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/tw1.png"
                  />
                  <p>Twitter</p>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/gaidoindia/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <img
                    width={32}
                    height={32}
                    title="Linkedin"
                    alt="Linkedin"
                    src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/li1.png"
                  />
                  <p>Linkedin</p>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/gaidoindia/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <img
                    width={32}
                    height={32}
                    title="Instagram"
                    alt="Instagram"
                    src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/ins1.png"
                  />
                  <p>Instagram</p>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UClA2PlrSEZ5iJuoy4YXaBkQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <img
                    width={32}
                    height={32}
                    title="Youtube"
                    alt="Youtube"
                    src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/youtube.png"
                  />
                  <p>Youtube</p>
                </a>
              </li>
              {/* <li>
                <a href="https://www.indiamart.com/gaidotechnologies/" target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Youtube" alt="Youtube" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/indiamart.png" />
                  <p>indiamart</p>
                </a>
              </li> */}
            </ul>
          </div>
          <div id="logo" className="pull-left">
            <h1>
              <a href="/" className="scrollto">
                {" "}
                <img
                  src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/logo.png"
                  alt="Gaido Technologies"
                />
              </a>
            </h1>
          </div>
          <nav id="nav-menu-container">
            <ul className="nav-menu">
              <li className="menu-active">
                <a href="/" style={{ textDecoration: "none" }}>
                  Home
                </a>
              </li>
              <li>
                <a href="/products" style={{ textDecoration: "none" }}>
                  Products
                </a>{" "}
              </li>
              <li className="menu-has-children dropdown">
                <a
                  href="/faac"
                  className="dropbtn"
                  style={{ textDecoration: "none" }}
                >
                  Partners
                </a>
                <ul className="mb-nnav">
                  <li>
                    <a href="/faac/aboutus">
                      <i className="fa fa-rss-square" /> About FAAC
                    </a>
                  </li>
                  <li>
                    <a href="/faac/products">
                      <i className="fa fa-arrow-circle-right" />
                      FAAC Products
                    </a>
                  </li>
                  {/* <li><a href="/solutions/valet-parking-system"><i className="fa fa-car" /> Valet Parking System</a></li>
                  <li><a href="/solutions/entry-gate-system"><i className="fa fa-search" /> Entry Gate System</a>
                  </li>
                  <li><a href="/solutions/cloud-based-analytics-platform"><i className="fa fa-line-chart" /> Cloud-Based
                    Analytics Platform</a></li> */}
                </ul>
                <div className="dropdown-content">
                  <div className="row">
                    <div className="column">
                      <img src={faaclogo} alt="faac" />
                      {/* <h5>Solutions</h5><br /> */}
                      <p>
                        <b>Gaido Technologies </b> is Now a Distributor for{" "}
                        <b>FAAC </b> Range of Products{" "}
                      </p>
                    </div>
                    <div className="column">
                      <ul>
                        <li>
                          <a href="/faac/aboutus">About FAAC</a>
                        </li>
                        <li>
                          <a href="/faac/products">FAAC Products</a>
                        </li>
                      </ul>
                    </div>
                    <div className="column">
                      <ul>
                        {/* <li><a href="/solutions/parking-guidance-system"><i className="fa fa-arrow-circle-right" />Parking
                          Guidance System</a></li>
                        <li><a href="/solutions/cloud-based-analytics-platform"><i className="fa fa-line-chart" /> Cloud-Based
                          Analytics Platform</a></li> */}
                      </ul>
                    </div>
                    <div className="column">
                      {/* <ul>
                        <li><a href="/solutions/valet-parking-system"><i className="fa fa-car" />Valet Parking System</a></li>
                      </ul> */}
                    </div>
                  </div>
                </div>
              </li>

              <li className="menu-has-children dropdown">
                <a
                  href="/solutions"
                  className="dropbtn"
                  style={{ textDecoration: "none" }}
                >
                  Solutions
                </a>
                <ul className="mb-nnav">
                  <li>
                    <a href="/solutions/ticketless-parking-management">
                      <i className="fa fa-rss-square" /> Ticketless Parking
                      Management
                    </a>
                  </li>
                  <li>
                    <a href="/solutions/parking-guidance-system">
                      <i className="fa fa-arrow-circle-right" />
                      Parking Guidance system
                    </a>
                  </li>
                  <li>
                    <a href="/solutions/valet-parking-system">
                      <i className="fa fa-car" /> Valet Parking System
                    </a>
                  </li>
                  <li>
                    <a href="/solutions/entry-gate-system">
                      <i className="fa fa-search" /> Entry Gate System
                    </a>
                  </li>
                  <li>
                    <a href="/solutions/cloud-based-analytics-platform">
                      <i className="fa fa-line-chart" /> Cloud-Based Analytics
                      Platform
                    </a>
                  </li>
                </ul>
                <div className="dropdown-content">
                  <div className="row">
                    <div className="column">
                      <h5>Solutions</h5>
                      <br />
                      <p>
                        Gaido technologies provides tailor-made solutions based
                        on the client's unique requirements.
                      </p>
                      <p>We provide the following solutions:</p>
                      <br />
                    </div>
                    <div className="column">
                      <ul>
                        <li>
                          <a href="/solutions/ticketless-parking-management">
                            <i className="fa fa-rss-square" /> Ticketless
                            Parking Management
                          </a>
                        </li>
                        <li>
                          <a href="/solutions/entry-gate-system">
                            <i className="fa fa-search" /> Entry Gate System
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="column">
                      <ul>
                        <li>
                          <a href="/solutions/parking-guidance-system">
                            <i className="fa fa-arrow-circle-right" />
                            Parking Guidance System
                          </a>
                        </li>
                        <li>
                          <a href="/solutions/cloud-based-analytics-platform">
                            <i className="fa fa-line-chart" /> Cloud-Based
                            Analytics Platform
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="column">
                      <ul>
                        <li>
                          <a href="/solutions/valet-parking-system">
                            <i className="fa fa-car" />
                            Valet Parking System
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              <li className="menu-has-children dropdown">
                <a
                  href="/offerings"
                  className="dropbtn"
                  style={{ textDecoration: "none" }}
                >
                  Offerings{" "}
                </a>
                <ul className="mb-nnav">
                  <li>
                    <a href="/offerings/airports">
                      <i className="fa fa-plane" /> Airports
                    </a>
                  </li>
                  <li>
                    <a href="/offerings/business-centers">
                      <i className="fa fa-briefcase" /> Business Centers
                    </a>
                  </li>
                  <li>
                    <a href="/offerings/educational-institutes">
                      <i className="fa fa-graduation-cap" /> Educational
                      Institutes
                    </a>
                  </li>
                  <li>
                    <a href="/offerings/hospitals">
                      <i className="fa fa-plus" /> Hospitals
                    </a>
                  </li>
                  <li>
                    <a href="/offerings/hotels">
                      <i className="fa fa-building" /> Hotels
                    </a>
                  </li>
                  <li>
                    <a href="/offerings/municipalities">
                      <i className="fa fa-university" /> Municipalities
                    </a>
                  </li>
                  <li>
                    <a href="/offerings/residential-area">
                      <i className="fa fa-home" /> Residential Area
                    </a>
                  </li>
                  <li>
                    <a href="/offerings/shopping-centers">
                      <i className="fa fa-shopping-bag" /> Shopping Centers
                    </a>
                  </li>
                  <li>
                    <a href="/offerings/sports-exhibitions">
                      <i className="fa fa-futbol-o" /> Sports and Exhibition
                      Venues
                    </a>
                  </li>
                </ul>
                <div className="dropdown-content">
                  <div className="row">
                    <div className="column">
                      <h5>Offerings For</h5>
                      <br />
                      <p>
                        Gaido technologies provides tailor-made solutions based
                        on the client's unique requirements.
                      </p>
                      <p>We serve the following parking industry segments</p>
                    </div>
                    <div className="column">
                      <ul>
                        <li>
                          <a href="/offerings/airports">
                            <i className="fa fa-plane" /> Airports
                          </a>
                        </li>
                        <li>
                          <a href="/offerings/business-centers">
                            <i className="fa fa-briefcase" /> Business Centers
                          </a>
                        </li>
                        <li>
                          <a href="/offerings/educational-institutes">
                            <i className="fa fa-graduation-cap" /> Educational
                            Institutes
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="column">
                      <ul>
                        <li>
                          <a href="/offerings/hospitals">
                            <i className="fa fa-plus" /> Hospitals
                          </a>
                        </li>
                        <li>
                          <a href="/offerings/hotels">
                            <i className="fa fa-building" /> Hotels
                          </a>
                        </li>
                        <li>
                          <a href="/offerings/municipalities">
                            <i className="fa fa-university" /> Municipalities
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="column">
                      <ul>
                        <li>
                          <a href="/offerings/residential-area">
                            <i className="fa fa-home" /> Residential Area
                          </a>
                        </li>
                        <li>
                          <a href="/offerings/shopping-centers">
                            <i className="fa fa-shopping-bag" /> Shopping
                            Centers
                          </a>
                        </li>
                        <li>
                          <a href="/offerings/sports-exhibitions">
                            <i className="fa fa-futbol-o" /> Sports and
                            Exhibition Venues
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>

              {/* <li className="menu-active"><a rel="noopener noreferrer" href="https://www.indiamart.com/gaidotechnologies/" target="_blank" style={{ textDecoration: "none" }}>Online Store</a></li> */}
              <li>
                <a href="/about-company" style={{ textDecoration: "none" }}>
                  About Us
                </a>{" "}
              </li>
              <li>
                <a
                  href="/blog"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  Blog
                </a>
              </li>
              <li>
                <div className="wrap" id="wrapbutton">
                  <button
                    className="button"
                    onClick={() => {
                      this.setState({
                        modalOpen: true,
                        email: "",
                        contact: "",
                        name: "",
                      });
                    }}
                  >
                    Get in Touch
                  </button>
                </div>
                <div className="wrap-mobile-button" id="wrapbutton-mobile">
                  <button className="button" id="myBtn">
                    Get in Touch
                  </button>
                </div>
              </li>
            </ul>
          </nav>
          <div
            class="modal fade"
            id="myModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <div className="col-sm-12">
                    <h6>One Tap Connect</h6>
                  </div>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div className="row">
                    <div className="col-sm-10">
                      <iframe
                        scrolling="no"
                        allowtransparency="true"
                        src="https://forms.zohopublic.in/sales80/form/ContactUs/formperma/o3sKh3PHKyQ5GmkDmlz4rY0NM2edU5IF_pl9MYAjDoA?zf_rszfm=1"
                        id="gform"
                        width="100%"
                        height="980"
                      >
                        Loading…
                      </iframe>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default withRouter(header);
