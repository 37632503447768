import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer';
import GaidoEdge from '../GaidoEdge/GaidoEdge'

class Solutionscomponent extends Component {
  render() {
    return (
      <div>
      <Header />
      <div className="inner-banner">
        <h2>SOLUTIONS</h2>
      </div>
        <section id="services">
          <div className="sol-container">
            <div  style={{visibility: "hidden" }}>
              <h2 >Our Solutions</h2>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <div className="box wow fadeInLeft">
                  <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/Ticketless.jpg" /></div>
                  <h4 className="title"><a href="solutions/ticketless-parking-management/">Ticketless Parking Management</a></h4>
                  <p className="description">Artificial intelligence based ANPR technology is used to manage boom barriers, making
                      your parking lot a generation smarter.</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="box wow fadeInLeft" data-wow-delay="0.2s">
                  <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/parking-guidance.jpg" /></div>
                  <h4 className="title"><a href="solutions/parking-guidance-system/">Parking Guidance System</a></h4>
                  <p className="description">Convenient and Visual guidance, having close to 100% accuracy, is deployed in your
                parking lot for a world-class customer experience.</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="box wow fadeInRight">
                  <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/valet.jpg" /></div>
                  <h4 className="title"><a href="solutions/valet-parking-system/">Valet Parking System</a></h4>
                  <p className="description">State of the art technology replaces the conventional paper-ticket based Valet System
                to provide a highly secure and hassle-free experience to customers. </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="box wow fadeInRight" data-wow-delay="0.2s">
                  <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/boom-barriergate.jpg" /></div>
                  <h4 className="title"><a href="solutions/entry-gate-system/">Entry Gate System</a></h4>
                  <p className="description">Vision-based optical scanning and LPR technology is used to securely track any
                vehicle, parked in the lot, on-demand.</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="box wow fadeInRight">
                  <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/cloud.jpg" /></div>
                  <h4 className="title"><a href="solutions/cloud-based-analytics-platform/">Cloud-Based Analytics Platform</a>
                  </h4>
                  <p className="description">Combining Data and Technology to provide a comprehensive cloud-based platform that
                aids parking lot operators with analytics...</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <GaidoEdge />
        <Footer />
      </div>
    );
  }
}

export default Solutionscomponent;