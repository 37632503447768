import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer';   
class Offerings extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="inner-banner">
          <h2>Offerings</h2>
        </div>
        <section id="about" style={{padding:"20px 0 5px 0"}} className="wow fadeInLeft">
          <div className="container">
            <div className="row text-center">
              <p className="text-center" style={{width: '100%'}}><b>Gaido Technologies Pvt. Ltd. is a Hyderabad based Smart Parking
                  Management company. </b></p>
              <p className="text-center" style={{width: '100%'}}><em>We serve the following parking industry segments – Airports,
                  Municipalities, Hospitals, Hotels, Shopping centers, Educational Institutes, Business Centers, and Sports
                  and Exhibition Venues, with custom tailored and turn-key solutions. </em></p>
            </div>
          </div>
          <div className="row offering-border">
            <div className="col-lg-4 col-sm-4 offering">
              <a href="/offerings/airports">
                <div className="icon-solution">
                  <i className="fa fa-plane" />
                </div>
                <div className="text-solution">
                  <h5>Airports</h5>
                  <p>Irrespective of how busy an airport is, travelers always want to be on time... </p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-4 offering">
              <a href="/offerings/business-centers">
                <div className="icon-solution">
                  <i className="fa fa-briefcase" />
                </div>
                <div className="text-solution">
                  <h5>Business Centers</h5>
                  <p>Parking is often referred to as a trivial chore – the start and finish of an experience...</p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-4 offering">
              <a href="/offerings/educational-institutes">
                <div className="icon-solution">
                  <i className="fa fa-graduation-cap" />
                </div>
                <div className="text-solution">
                  <h5>Educational Institutes</h5>
                  <p>With strong growth in student attendance and ever-escalating pressure to minimize the carbon footprint...
                  </p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-4 offering">
              <a href="/offerings/hospitals">
                <div className="icon-solution">
                  <i className="fa fa-plus" />
                </div>
                <div className="text-solution">
                  <h5>Hospitals</h5>
                  <p>Every health facility is different, unique in its own way. While some have simple procedural
                    formalities...</p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-4 offering">
              <a href="/offerings/hotels">
                <div className="icon-solution">
                  <i className="fa fa-building" />
                </div>
                <div className="text-solution">
                  <h5>Hotels</h5>
                  <p>You’ve done it all - Clean and tidy rooms, world-class amenities, the best marketing...</p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-4 offering">
              <a href="/offerings/municipalities">
                <div className="icon-solution">
                  <i className="fa fa-university" />
                </div>
                <div className="text-solution">
                  <h5>Municipalities</h5>
                  <p>Throughout the globe, municipalities are working relentlessly to deliver on the high expectations...</p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-4 offering">
              <a href="/offerings/residential-area">
                <div className="icon-solution">
                  <i className="fa fa-home" />
                </div>
                <div className="text-solution">
                  <h5>Residential Area</h5>
                  <p>The urban agglomeration is constantly growing. Following the rising populations...</p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-4 offering">
              <a href="/offerings/shopping-centers">
                <div className="icon-solution">
                  <i className="fa fa-shopping-bag" />
                </div>
                <div className="text-solution">
                  <h5>Shopping Centers</h5>
                  <p>For many companies, parking management basically means making sure the ticket machines ...</p>
                </div>
              </a>
            </div>
            <div className="col-lg-4 col-sm-4 offering">
              <a href="/offerings/sports-exhibitions">
                <div className="icon-solution">
                  <i className="fa fa-futbol-o" />
                </div>
                <div className="text-solution">
                  <h5>Sports and Exhibition Venues</h5>
                  <p>Every year major sporting events and Exhibition Venues draw thousands of fans to stadiums...</p>
                </div>
              </a>
            </div>
          </div>
        </section>
        <Footer />
            </div>
        );
    }
}

export default Offerings;