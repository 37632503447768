import React, { Component } from 'react'
import { auth } from '../firebaseConfig'
import { signOut } from "firebase/auth";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';

import firebaseServices from './firebaseServices';

export default class postblog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      randomnumbber: Math.floor(100000 + Math.random() * 900000),
      typenumber: "",
      falgerror: false,
      falgerror1: false,
      falgerror2: false,
      falgerror3: false,
      loader: false,
      name: "",
      email: '',
      contact: "",
      posts: []

    }
  }


  componentDidMount() {
    window.addEventListener('loader', this.handleNetworkChange);
  }
  componentWillUnmount() {
    window.removeEventListener('loader', this.handleNetworkChange);
  }
  handleNetworkChange = () => {
    this.setState({ modalOpen: false })
    this.setState({ loader: false });
  }
  componentDidMount() {
    this.getBlogs();
  }
  
  logOut() {
    signOut(auth).then(() => {
      localStorage.clear();
      window.location.pathname = "/admin/login";
    });
  }



  getBlogs = async () => {
    const data = await firebaseServices.getAllBlogs();
    var blog = []
    blog.push(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))

    this.setState({ posts: blog })

  };

  deleteHandler = async (id) => {
    await firebaseServices.deleteBlog(id);
    alert("deleted")
    window.location.reload();

  };
  // deletePost = async (id) => {
  //   const postDoc = doc(db, "posts", id);
  //   await deleteDoc(postDoc);
  // };


  render() {
    return (
      <div>

        <Dialog
          open={this.state.modalOpen}
          // TransitionComponent={Transition}
          keepMounted
          onClose={() => this.setState({ modalOpen: false })}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {""}
            <IconButton aria-label="close" style={{
              position: 'absolute',
              right: 0,
              top: 0,
            }} onClick={() => this.setState({ modalOpen: false })}>
              <CancelOutlinedIcon />
            </IconButton>
          </DialogTitle>  
       
          <DialogActions>

          </DialogActions>
        </Dialog>


        <header id="header " style={{ height: '66px' }}>

          {/* <div className="sticky-container" id="sideheader">
            <ul className="sticky">
              <li>
                <a href="https://www.facebook.com/gaidoindia" target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Facebook" alt="Facebook" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/fb1.png" />
                  <p>Facebook</p>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/gaidoindia" target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Twitter" alt="Twitter" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/tw1.png" />
                  <p>Twitter</p>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/gaidoindia/" target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Linkedin" alt="Linkedin" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/li1.png" />
                  <p>Linkedin</p>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/gaidoindia/" target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Instagram" alt="Instagram" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/ins1.png" />
                  <p>Instagram</p>
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UClA2PlrSEZ5iJuoy4YXaBkQ" target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Youtube" alt="Youtube" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/youtube.png" />
                  <p>Youtube</p>
                </a>
              </li> */}
              {/* <li>
      <a href="https://www.indiamart.com/gaidotechnologies/" target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Youtube" alt="Youtube" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/indiamart.png" />
        <p>indiamart</p>
      </a>
    </li> */}
{/* 
            </ul>
          </div> */}
          <div id="logo" className="pull-left" style={{margin:10}}>
            <h1><a href="/" className="scrollto">   <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/logo.png" alt="Gaido Technologies" /></a></h1>
          </div>
          <nav id="nav-menu-container">
            <ul className="nav-menu">

              <li>
                <div className="wrap" id="wrapbutton" >
                  <button className="button"
                    onClick={this.logOut}
                  >Logout</button>
                </div>
                <div className="wrap-mobile-button" id="wrapbutton-mobile">
                  <button className="button" id="myBtn" onClick={this.logOut}>Logout</button>
                </div>
              </li>
            </ul>
          </nav>
        </header>
        <div class="row" style={{ marginTop: '66px', width: "100%" }}>
        <div className="col-1"></div>
          <div className="col-2"><b>BLOG Details: </b></div>
        </div>
        <div className="row">
        <div class="col-10">
        </div>
        <div class="col-2" >
            <button type="button" color="primary" class="btn btn-primary" onClick={() => {
              this.props.history.push('/admin/addblog')
            }}>Add New</button>

          </div>
          </div>
          <div className="row">
            <div className="col-1"></div>
            <div className="col-10">
        <table class="table table-bordered" style={{ marginTop: '20px' }} >
          <thead>
            <tr>
              <th scope="col">S.No</th>

              <th scope="col">Date</th>
              <th scope="col">Blog Image</th>
              <th scope="col">Blog Title</th>

              <th scope="col">Author</th>
              <th scope="col" >Action</th>




            </tr>
          </thead>
          <tbody>

            {this.state.posts.map((doc, i) => {
              return (

                doc.map((item, i) => {
                  return (<tr key={i}>
                    <td>{i + 1}</td>

                    <td>{doc[i].date}</td>
                    <td><img src={`${doc[i].imageurl}`} alt="blog" width="50px" height="50px" /></td>
                    <td>{doc[i].title}</td>

                    <td>{doc[i].author}</td>
                    <td>
                      <i className="fa-solid fa-pencil" onClick={() => {
                      this.props.history.push({
                        pathname: '/admin/editblog', state: {
                          propdata: doc[i],
                          desc:doc[i].desc
                        }
                      })
                    }} ></i>
                     &nbsp;&nbsp;&nbsp;<i class="fa-solid fa-trash" onClick={() => this.deleteHandler(doc[i].id)}></i></td>


                  </tr>)
                })
              );
            })}
          </tbody>
        </table>
        </div>
        </div>
        <div class="row">
          <div class="col-5">

          </div>
          <div class="col-5">

          </div>
          
        </div>
      </div>
    )
  }
}
