import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';
class SportsAndExhibitionVenues extends Component {

    render() {
        return (
            <div>
              <Helmet>
        <title>Sports Exhibitions Boom Barriers | Gaido Technologies | Barrier Gate </title>
        <meta property="og:title" content="Sports Exhibitions Boom Barriers | Gaido Technologies | Barrier Gate" />
        <meta property="og:url" content="https://gaido.in/offerings/sports-exhibitions" />
      </Helmet>
                <Header /> 
                <div className="inner-banner3 sports-exhibitions">
          <div className="row">
            <div className="col-md-5">
              <div className="hid-img">
                   <img alt ="gaidologos"src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/sport2.jpg" style={{width: '100%'}} />
              </div>
              <h2>Sports and Exhibition Venues</h2>
              <p>Every year major sporting events and exhibitions draw thousands of fans to stadiums,
                exhibition grounds, showcase arenas, and sporting fields. Firstly, parking fee is one of the
                important sources of revenue collection and is often managed in the conventional way by
                having a work-force collect fees manually and give out paper-tickets in return....</p>
              <p className="read-more"><a href="#about"> READ MORE <i className="fa fa-plus" /> </a></p>
            </div>
          </div>
        </div>
        {/*==========================
    Solutions Section
  ============================*/}
        <section id="about" className="f-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 content offerings2">
                <h1 className="hd2"> Offering for Sports and Exhibition Venues</h1>
                <p>Every year major sporting events and exhibitions draw thousands of fans to stadiums,
                  exhibition grounds, showcase arenas, and sporting fields. Firstly, parking fee is one of the
                  important sources of revenue collection and is often managed in the conventional way by
                  having a work-force collect fees manually and give out paper-tickets in return. Secondly,
                  Parking at these venues is challenging due to congestion and lack of adequate space. Gaido
                  Technologies offers a number of solutions that overcome such complex parking issues at
                  event venues. While our Ticketless Management System helps reduce congestion and
                  queueing, personalized message signs and Parking Guidance Systems help drivers navigate
                  into the right direction by simply directing them to an available slot identified with green
                  lights on the LED indicators...
                </p>
                <p>Better yet, offer Valet Parking – Give your visitors the option to drive in and drop their keys.
                  Why bother about cruising through the parking lot and searching for an available spot.
                  When one is at an event space, we understand what their priorities are, so we do the trivial
                  tasks for you. Our valet management feature eliminates the hassle of using physical tickets
                  and provides an additional layer of security to valet parking management.
                </p>
                <p>With its highly scalable Cloud-based SaaS product, particularly customizable for solving
                  complex parking related issues at events, Gaido Technologies is capable of helping control
                  staff costs, provide accurate space count information, and ensure a positive experience to
                  your customers. Our solutions will help your event’s parking accomplish maximum security,
                  profitability, management and control.
                </p>
              </div>
              <p className="read-more" style={{margin: "65px 65px 0px 65px"}}><a href="/products"> VIEW PRODUCTS <i className="fa fa-plus" /> </a></p>

            </div>
          </div>

        </section>
        <Footer />
        <a href={() => false} className="back-to-top"><i className="fa fa-chevron-up" /></a>             
            </div>
        );
    }
}

export default SportsAndExhibitionVenues;