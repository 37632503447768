import React, { Component } from 'react'

export default class Feedback extends Component {
  render() {
    return (
      <div>
        <div class="visme_d" data-title="Untitled Project" data-url="76nwqmky-untitled-project?fullPage=true" data-domain="forms" data-full-page="true" data-min-height="100vh" data-form-id="41821"></div>
      </div>
    )
  }
}
