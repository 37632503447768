import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import "./dropusLine.css"

class DropusLIne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      randomnumbber: Math.floor(100000 + Math.random() * 900000),
      typenumber: "",
      falgerror: false,
      falgerror1: false,
      falgerror2: false,
      falgerror3: false,
      loader: false,
      name: "",
      email: '',
      message: "",
      mobile: "",
      modalOpen: false
    }
  }
  componentDidMount(){
 
  }


  render() {
    
    return (
      <div>
        <main id="main">
          <section id="contact" className="wow fadeInUp" >
            <div className="container" >
              <div className="section-header">
                <h2>Gaido Connect</h2>
              </div>
              <div className="row contact-info ">
              <div className='col-lg-3 col-sm-3 col-md-3 col-xs-3' ></div>
              <div className='col-lg-6 col-sm-12 col-md-12 col-xs-12' >
                  <iframe scrolling="no" allowtransparency = "true"  src="https://forms.zohopublic.in/sales80/form/ContactUs/formperma/o3sKh3PHKyQ5GmkDmlz4rY0NM2edU5IF_pl9MYAjDoA?zf_rszfm=1"  width="100%" height="980" >Loading…</iframe>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default withRouter(DropusLIne);