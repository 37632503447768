import React, { Component } from "react";
import Header from "../../header";
import "../faac.css";
const productImage = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/104680.jpg";
const B680Hinstallazione = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/B680Hinstallazione.jpg";
const B680ControlUnit = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/63000128.jpg";
const RedCabinet = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/416016.jpg";
const BlueCabinet = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/416017.jpg";
const whiteCabinet = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/416018.jpg";
const GreyCabinet = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/416019.jpg";
const StainLessSteelCabinet = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/416020.jpg";
const pivotroundbeams = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/428175.jpg";
const beambracketpivot = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/428163.jpg";
const SSpring = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/63000169.jpg";
const BreakSensor = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/390828.jpg";
const pocketandbalancedspring = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/428436.jpg";
const pocketandbalancedLspring = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/428437.jpg";
const foundationplate = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/490139.jpg";
const skirt2m = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/428441.jpg";
const skirt3m = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/428441.jpg";
const fork = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/737621.jpg";
const ArticulatedKit = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/428444.jpg";
const IntigratedTrafficlight = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/410022.jpg";
const beamLightconnection = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/390992.jpg";
const luminouscord = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/390993.jpg";
const batteryKit = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/390923.jpg";
const Xbatconnectionkit = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/428444.jpg";
const adhesiveReflector = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/490117.jpg";
const releaseKey = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/713002.jpg";
const releaseLock = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/71209101.jpg";
const antivandalvalve = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/401069.jpg";
const antipanicunit = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/401051.jpg";
const ExamplePdf = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/B680H/B680Hfolder_EN_Rev17_LowRes.pdf";

export default class B680HybridBarrier extends Component {
  constructor(props) {
    super(props);
  }

  toggle = (id, id2) => {
    var n = document.getElementById(id);
    if (n.style.display != "none") {
      n.style.display = "none";
      document.getElementById(id2).setAttribute("aria-expanded", "false");
    } else {
      n.style.display = "";
      document.getElementById(id2).setAttribute("aria-expanded", "true");
    }
  };
  render() {
    return (
      <div>
          <Header />
        <div className="faacbarriers"  style={{marginTop:100}}>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3">
              <img
                className="card-img-top"
                src={productImage}
                // //onClick={()=>{ history.push('/automations-for-gates')}}
                // //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                style={{ cursor: "pointer" }}
                alt="Product1"
              />
            </div>
            <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
              <div
                className="col-lg-12 col-sm-12 col-md-12 col-xs-12"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "50px" }}>B680H</h3>
                <h4 style={{ fontSize: "20px" }}>
                  Automatic 24V Hybrid Barrier
                </h4>
              </div>
              <div className="row">
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "initial", margin: 20 }}
                >
                  <p style={{ fontSize: "12px" }}>Max beam length</p>
                  <b>2 – 8 m</b>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "initial", margin: 20 }}
                >
                  <p style={{ fontSize: "12px" }}>Opening time</p>
                  <b>1,5 – 6 s</b>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "initial", margin: 20 }}
                >
                  <p style={{ fontSize: "12px" }}>Use frequency</p>
                  <b>Continuous use</b>
                </div>
              </div>
              <div className="row">
              <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3">
                <a
                  href={ExamplePdf}
                  download="B680Hfolder_EN_Rev17_LowRes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button style={{ marginLeft: 30 , marginTop: 30 ,borderColor: "#cecece", borderRadius: 3}} >Download Broucher</button>
                </a>
                <br/>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      backgroundColor: "#35965c",
                      height: 30,
                      width:"100%",
                      margin: 20 ,
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
                  </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

            <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
              <ul style={{ listStyleType: "disc" }}>
                <li class="Bullet_List">
                  <span>
                    2,000,000 cycles thanks to the Brushless + Hydraulic motor
                    and <b>“unlimited” springs</b>
                  </span>
                </li>
                <li class="Bullet_List">
                  <span>
                    Removable cabinet available in 4 colours or stainless steel.
                  </span>
                </li>
                <li class="Bullet_List">
                  <span>
                    High logistic optimization: a single barrier model suitable
                    for all lengths.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink1"
                onClick={(e) => this.toggle("navbar", "TLink1")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Family models</span>
                </h5>
              </a>
              <div id="navbar" style={{ display: "none" }}>
                <div class="table-3">
                  <table>
                    <thead>
                      <tr>
                        <th colspan="1" rowspan="1">
                          <p>
                            <span>Model</span>
                          </p>
                        </th>
                        <th colspan="1" rowspan="1">
                          <p>
                            <span>Item code</span>
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>B680H</span>
                          </p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>104680</span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{ marginTop: "0px", marginBottom: "15px" }}>
                  <h5
                    class="title-heading-left fusion-responsive-typography-calculated"
                    style={{ fontSize: 20, lineHeight: 1.2 }}
                    data-fontsize="20"
                    data-lineheight="24px"
                  >
                    <span>Packs</span>
                  </h5>
                </div>
                <div
                  class="fusion-text fusion-text-6 Models__Package_Description"
                  // style="transform:translate3d(0,0,0);"
                >
                  <p>
                    <span class="Highlight">B680H </span>
                    <span class="Package">&nbsp;</span>
                    <span class="Highlight">includes</span>
                    <span class="Highlight">:</span>
                    <span>&nbsp;</span>
                    <span>
                      barrier cabinet with electronic equipment and absolute
                      encoder, hydraulic drive transmission system, installation
                      accessories, triangular release key. DOES NOT include beam
                      bracket, balancing spring (sold together with another
                      code) and the cabinet{" "}
                    </span>
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink2"
                onClick={(e) => this.toggle("dec2", "TLink2")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Dimensions and technical specifications</span>
                </h5>
              </a>
              <div id="dec2" style={{ display: "none" }}>
                <div class="table-4">
                  <table>
                    <thead>
                      <tr>
                        <th contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Model</span>
                          </p>
                        </th>
                        <th contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>B680H</span>
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Power supply voltage</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Switching: 100 ÷ 240V~ 50/60 Hz</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Electric motor</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Brushless 36V </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Motor rotation speed</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>1000 ÷ 6000 rpm</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Max. power</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>240 W</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Motor-pump unit capacity</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>3.2 lpm</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Material type</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Steel</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Type of treatment</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>
                              100 micron protective primer + polyester painting
                              or stainless steel cabinet
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Encoder</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Magnetic absolute encoder</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Type of deceleration</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Electronic – via encoder</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Type of beam</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Round</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Operating ambient temperature</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>-20°C ÷ +55°C</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Protection class</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>IP56 (TÜV certified)</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Weight</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>85 Kg (65 Kg pillar + 20 Kg cabinet)</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Type of oil</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>FAAC HP OIL</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Dimensions (LxDxH)</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>469 x 279 x 1100 mm</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Beam max length</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>2 ÷ 8 m</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Opening time</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>1,5 s (90° – 2m) – 6 s (90° – 8m)</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Use frequency</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>100%</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Electronic equipment</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>E680</span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>(*) data referred to 230V~ 50Hz.</p>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink3"
                onClick={(e) => this.toggle("dec3", "TLink3")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Example of a typical installation</span>
                </h5>
              </a>
              <div id="dec3" style={{ display: "none" }}>
                <div>
                  <img
                    decoding="async"
                    src={B680Hinstallazione}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                </div>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                    <table>
                      <thead>
                        <tr>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Ref</span>
                            </p>
                          </th>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Q.ty</span>
                            </p>
                          </th>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Description</span>
                            </p>
                          </th>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Code</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>B680H Barrier</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>104680</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Red cabinet RAL 3020</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>416016</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Foundation plate</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>490139</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Pocket and balancing L spring</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>428437</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Plug in receiver 1 channel RP 433 SLH</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>787852</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Joint L</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>428616</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Initial beam L – Length 4 m</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>428048</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Final Beam L – Length 4.3 m</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>428051</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>3</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Adhesive reflector kit</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>490117</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Round beam light connection kit S/L</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>390992</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Luminous cord 11 m pack</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>390993</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>4</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Integrated flashing traffic light</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>410033</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>5</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Key operated button XK10B</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>401304</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>5</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Lock with key</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>71275101</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>6</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Pair of photocells XP 20 BD</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>785103</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>6</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Column (Max H 628 mm)</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>401039</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>6</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Foundation plate for column</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>737637</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>3</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>XLED flashing light</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>410029</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Adjustable fork for beam support</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>428806</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Support plate for fork</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>737621</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2 channels transmitter XT2 433 SLH LR</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>787007</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink4"
                onClick={(e) => this.toggle("dec4", "TLink4")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Electronic control units</span>
                </h5>
              </a>
              <div id="dec4" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={B680ControlUnit}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      E680 electronic control unit{" "}
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      63000714
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink10"
                onClick={(e) => this.toggle("dec10", "TLink10")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Cabinets</span>
                </h5>
              </a>
              <div id="dec10" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={RedCabinet}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>Red cabinet RAL 3020</p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      416016
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={BlueCabinet}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>Cabinet Blue RAL 5011</p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      416017
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={whiteCabinet}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Cabinet White RAL 9010
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      416018
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={GreyCabinet}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>Cabinet Grey RAL 9006</p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      416019
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={StainLessSteelCabinet}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Stainless steel cabinet
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      416020
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink11"
                onClick={(e) => this.toggle("dec11", "TLink11")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Pivoting round beams and specific accessories</span>
                </h5>
              </a>
              <div id="dec11" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={pivotroundbeams}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>Pivoting round beams</p>
                    <table>
                      <thead>
                        <tr>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Length (mm)</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Item code</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>3.000</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428176</span>
                            </p>
                          </td>
                        </tr>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={SSpring}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      S Spring (required for pivoting round beams)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      63000169
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={beambracketpivot}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Beam bracket for pivot round beam – (STAINLESS STEEL)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      428163
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={BreakSensor}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Break-in sensor for pivoting round beams
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      390828
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink12"
                onClick={(e) => this.toggle("dec12", "TLink12")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Beams S: up to 5 m passage width</span>
                </h5>
              </a>
              <div id="dec12" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={pocketandbalancedspring}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Pocket and balancing S spring
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      428436
                    </p>
                  </div>

                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <table>
                      <thead>
                        <tr>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>
                                Accessories for round beams S based on the
                                effective span of the passage
                              </span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Lights</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Skirt</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>End foot</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>up to 4.5 m</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>X</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>X</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>X</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>from 4.5 m to 5 m</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>X</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>
                                from 4.5 m to 5 m modular (with collar assembly)
                              </span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <p>Whole beams S</p>
                    <table>
                      <thead>
                        <tr>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Model</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Item code</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Whole Beam S 2.3 m</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428045</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Whole Beam S 3.3 m</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428042</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Whole Beam S 4.3 m</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428043</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Whole Beam S 5.3 m</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428044</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <p>Modular beams S</p>
                    <table>
                      <thead>
                        <tr>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Model</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Item code</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Joint S</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428615</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Initial Beam S 2.3 m</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428045</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Initial Beam S 3.3 m</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428042</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Final Beam S 2 m</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428046</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink13"
                onClick={(e) => this.toggle("dec13", "TLink13")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Beams L: from 5 m up to 8 m passage width</span>
                </h5>
              </a>
              <div id="dec13" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={pocketandbalancedLspring}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Pocket and balancing L spring
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      428437
                    </p>
                  </div>

                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <table>
                      <thead>
                        <tr>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>
                                Accessories for round beams L based on the
                                effective span of the passage
                              </span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Lights</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Skirt</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>End foot</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>from 5 m to 7 m</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>X</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>X</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>X</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>from 7 m to 7.5 m</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>X</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>X (without end foot)</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>X (without skirt)</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>from 7.5 m to 8 m</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>X</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>X</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <p>Whole beams L</p>
                    <table>
                      <thead>
                        <tr>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Model</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Item code</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Whole Beam L 5.3 m</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428047</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <p>Modular beams L</p>
                    <table>
                      <thead>
                        <tr>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Model</span>
                            </p>
                          </th>
                          <th contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Item code</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Joint L</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428616</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Initial Beam L 4 m</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428048</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Final Beam L 2.3 m</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428049</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Final Beam L 3.3 m</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428050</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>Final Beam L 4.3 m</span>
                            </p>
                          </td>
                          <td contenuto="None" colspan="1" rowspan="1">
                            <p>
                              <span>428051</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink5"
                onClick={(e) => this.toggle("dec5", "TLink5")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Common accessories for all applications</span>
                </h5>
              </a>
              <div id="dec5" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={foundationplate}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>Foundation plate</p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      490139
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={skirt2m}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>Skirt kit length 2 m</p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      428441
                    </p>
                  </div>
                  <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >  
                  <img
                    decoding="async"
                    src={skirt3m}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>Skirt kit length 3 m</p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    428446
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={fork}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>Support plate for fork</p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    737621
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={ArticulatedKit}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>
                    Articulated kit for round beam S (max 4 m)
                  </p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    428444
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={IntigratedTrafficlight}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>
                    Integrated flashing traffic light
                  </p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    410033
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={beamLightconnection}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>
                    Beam light connection kit
                  </p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    390992
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={luminouscord}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>Luminous cord 12 m pack</p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    3920993
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={batteryKit}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>
                    XBAT 24 emergency battery kit
                  </p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    390923
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={Xbatconnectionkit}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>
                    XBAT connection kit (to be ordered with 390923)
                  </p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    390080
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={adhesiveReflector}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>
                    Adhesive reflector kit (6 pcs.)
                  </p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    490117
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={releaseKey}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>
                    Supplementary triangular release key (10 pcs. pack)
                  </p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    713002
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={releaseLock}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>
                    Release lock with customised key
                  </p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    71209101-10
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={antivandalvalve}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>
                    Anti-vandal valve protects the hydraulic system if the beam
                    is forced
                  </p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    401069
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={antipanicunit}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>Anti-panic unit</p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    401051
                  </p>
                </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>

          <hr style={{ borderColor: "#cecece" }}></hr>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
        </div>
        <div
              class="modal fade"
              id="exampleModal10"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Product Details
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{ padding: 20 }}>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                        <iframe
                          scrolling="no"
                          src="https://forms.zohopublic.in/sales80/form/ContactUs/formperma/o3sKh3PHKyQ5GmkDmlz4rY0NM2edU5IF_pl9MYAjDoA?zf_rszfm=1"
                          id="gform"
                          width="100%"
                          height="990"
                          frameborder="0"
                          marginheight="0"
                          marginwidth="0"
                        >
                          Loading…
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
      </div>
    );
  }
}
