import React, { Component } from 'react';

class Whatisstartupindia extends Component {
  render() {
    return (
      <div>
        <header role="banner">
          <div className="top-bar">
            <div className="container">
              <div className="row">
                <div className="col-6">
                  <a href="/">   <img  className="Gaidoimage"   src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/logo.png" alt="Gaido Technologies Pvt. Ltd." /></a>
                </div>
                <div className="col-6 text-right">
                  <a href="/blog">   <img   className="blogimage" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/blog.png" alt="Gaido Technologies Pvt. Ltd." /></a>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* END header */}
        <section className="site-section py-lg">
          <div className="container">
            <div className="row blog-entries">
              <div className="col-md-12 col-lg-8 main-content">
                <h1 className="mb-4">  STARTUP INDIA- If you don’t register, you cannot claim the benefits</h1>
                <div className="post-meta">
                  <span className="mr-2">April 14, 2020 </span> 
                </div>
                <div className="post-content-body">
                  <p>From online education to food delivery services, all the start-ups are killing it with their modern solutions to our urban problems.</p>
                  <p>India saw a sudden wave of start-ups shaking up the nation with their modern solutions and providing a lot of jobs to unemployed and enthusiastic youngsters in 2014. </p>
                  <div className="row mb-5">
                    <div className="col-md-12 mb-4 ">
                         <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/blog11.jpg" alt=" placeholder" className="img-fluid" />
                    </div>
                  </div>
                  <p>In 2016, the government of India launched the start-up India scheme. It is a novel initiative to promote young entrepreneurs with funding, Guidance and incubation opportunities. A start up fund of 10,000 crores was accumulated as this movement began.</p>
                  <h6>What exactly is Start-up India?</h6>
                  <p>It is an initiative taken by the Indian government to promote young entrepreneurs and generate employment simultaneously. It aimed towards building self-employment for sole proprietors.</p>
                  <h6>And guess what?</h6>
                  <p>It was a huge success! Around 15,472 start-ups are recognized under Start up India that generated around 1, 48,897 jobs!!</p>
                  <p>With the start-up India Movement all the lucid dreams of entrepreneurs have gotten a ray of hope and the motivation to do something outside the box and come up with practical solutions to theoretical problems!</p>
                  <h6>Here’s how you register with Start-up India:</h6>
                  <p>1. Make sure your company is registered as a Private Limited Company, Partnership firm, or a Limited Liability Partnership.</p>
                  <p>2.	Register on the Start-up India website</p>
                  <p>3.	Upload documents such as LOR, Certificate of Incorporation &amp; Business Description in PDF</p>
                  <p>4.	Register your GST number</p>
                  <p>5.	Self-certify that you fulfil the given conditions</p>
                  <p>6.	Get a recognition number.</p>
                  <p>It is literally as simple as that!</p>
                  <p>Gaido Technologies is a start-up based out of Hyderabad and is aspiring to reach great heights in the field of smart parking.</p>
                  <p> Check out the modern solutions we provide to the urban problems at <a href="/"  target="_blank" rel="noopener noreferrer">www.gaido.in</a></p>
                  <p><a href="/"  target="_blank" rel="noopener noreferrer">   <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/logo.png"  alt="gaidologo" /></a></p>
                </div>
                <div className="pt-5">
                  <p className="categories">Categories:  <a href={() => false}>call, Pointers on meetings, Meeting tips , Client call</a><br />  Tags: <a href={() => false}>#smartparking #sharing #parkingshare #WEB #parkit #technology #innovation #smart #mobilit #mobility #parkingsystems #instaparking #car #newproject #parking #parkingmanagement #parkingsolution #parkingsystem #smartcity #digitalindia #DigitalIndia #DigitalIndiaSummit #IndiaFightsCorona #India #Education #Covid19 #Lockdown #Webinar #Webinars</a></p>
                </div>
              </div>
              {/* END main-content */}
              <div className="col-md-12 col-lg-4 sidebar">
                <div className="sidebar-box">
                  <h3 className="heading">Popular On Gaido</h3>
                  <div className="post-entry-sidebar">
                    <ul>
                      <li>
                        <a href="/blog/gaido-tackles-parking-issues-in-metropolitan-cities">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_26.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>Gaido tackles parking issues in Metropolitan Cities</h4>
                            <div className="post-meta">
                              <span className="mr-2">May 07, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/blog/how-to-park-like-a-pro">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_25.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>How to park like a pro? (Parking Hacks!!)</h4>
                            <div className="post-meta">
                              <span className="mr-2">May 07, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/blog/a-step-into-the-feature-of-valet-parking">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_7.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>A step into the future of valet parking</h4>
                            <div className="post-meta">
                              <span className="mr-2">April 08, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/blog/is-working-for-a-statup-right-after-graduation-a-good-idea">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_4.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>Is working for a startup the right thing to do after graduation?</h4>
                            <div className="post-meta">
                              <span className="mr-2">April 07, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/blog/how-easy-is-it-to-integrate-digital-valet-parking">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_5.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>How easy is it to integrate digital valet parking?</h4>
                            <div className="post-meta">
                              <span className="mr-2">April 07, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/blog/3-steps-for-a-successful-first-sales-meeting">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_9.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>3 pointers for a successful first sales meeting</h4>
                            <div className="post-meta">
                              <span className="mr-2">April 06, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sidebar-box col-md-12">
                  <h3 className="heading">Twitter Tweets</h3>
                  <a className="twitter-timeline" data-height={250} data-theme="dark" href="https://twitter.com/gaidoindia?ref_src=twsrc%5Etfw">Tweets by gaidoindia</a> 
                </div>
                <div className="sidebar-box col-md-12">
                  <h3 className="heading">Facebook Feeds</h3>
                  <iframe title="videotag" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fgaidoindia%2F&tabs=timeline&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=172398636729300" height={250} style={{border: 'none', overflow: 'hidden'}} scrolling="no" frameBorder={0} allowtransparency="true" allow="encrypted-media" /> 
                </div>
              </div>
              {/* END sidebar */}
              <div className="author col-md-12 col-lg-8" style={{padding: '0px'}}>
                <div className="author-slider">
                  <h2 className="auth-head text-center"><br />Author</h2>
                  <div className="col-md-12 row">
                    <div className="col-md-2 auth_img">
                         <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/person_1.jpg" alt=" Placeholder" />
                    </div>
                    <div className="col-md-10">
                      <h2 className="hhdd">Siddharth Kotamraju <a href="mailto:sales@gaido.in" className="p-2"><span className="fa fa-envelope-o" /></a></h2>
                    </div>
                  </div>
                  <p>Having real time experience in providing smart solutions using powerful analytics, I believe smart parking is the next big thing! We at Gaido, thrive to provide sustainable solutions that are eco-friendly and user friendly. Check out this space for updates on smart parking and new innovations that make this world a better place!</p>
                </div>
                <p className="disclaimer"><strong>Disclaimer:-</strong><br /><i> All the information on this website - <a href="/"  target="_blank" rel="noopener noreferrer">www.gaido.in</a> - is published in good faith and for general information purpose only.<a href="/blog/disclaimer"  target="_blank" rel="noopener noreferrer">Click Here</a></i></p>
              </div>
            </div>
          </div>
        </section>
        <footer className="site-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <h3>About Us</h3>
                <p><strong>Gaido Technologies Pvt. Ltd.</strong> is a Hyderabad based Smart Parking Management company.
                  We serve the following parking industry segments – Airports, Municipalities, Hospitals, Hotels, Shopping centers, Educational Institutes, Business Centers, and Sports and Exhibition Venues, with custom tailored and turn-key solutions.</p>
              </div>
              <div className="col-md-7 ml-auto">
                <div className="row">
                  <div className="col-md-6">
                    <h3>Latest Post</h3>
                    <div className="post-entry-sidebar">
                      <ul>
                        <li>
                          <a href="/blog/a-step-into-the-feature-of-valet-parking">
                               <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_1.jpg" alt=" placeholder" className="mr-4" />
                            <div className="text">
                              <h4>A step into the future of valet parking</h4>
                              <div className="post-meta">
                                <span className="mr-2">April 06,2020 </span> 
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="/blog/the-next-big-thing-in-parking-is-here">
                               <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_2.jpg" alt=" placeholder" className="mr-4" />
                            <div className="text">
                              <h4>The next big thing in parking is here!</h4>
                              <div className="post-meta">
                                <span className="mr-2">April 06,2020 </span> 
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-1" />
                  <div className="col-md-5">
                    <div className="mb-5">
                      <h3>Quick Links</h3>
                      <ul className="list-unstyled">
                        <li><a href="/about-company/"  target="_blank" rel="noopener noreferrer">About Us</a></li>
                        <li><a href="/blog">Blogs</a></li>
                        <li><a href="/gaido-connect/"  target="_blank" rel="noopener noreferrer">Contact Us</a></li>
                        <li><a href="/blog/disclaimer">Disclaimer</a></li>
                      </ul>
                      <h3>Follow Us</h3>
                      <ul className="social-nav">
                        <li>
                          <a href="https://www.facebook.com/gaidoindia"  target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Facebook" alt="Facebook" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/fb1.png" />
                          </a>
                        </li>
                        <li>
                          <a href="https://twitter.com/gaidoindia"  target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Twitter" alt="Twitter" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/tw1.png" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/company/gaidoindia/"  target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Linkedin" alt="Linkedin" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/li1.png" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.pinterest.com/gugaliya0755/"  target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Pinterest" alt="Pinterest" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/pin1.png" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/gaidoindia/"  target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Instagram" alt="Instagram" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/ins1.png" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center" style={{color: '#fff'}}>
                Copyright Gaido.2019 All Rights Reserved
              </div>
            </div>
          </div>

        </footer>
      </div>
    );
  }
}

export default Whatisstartupindia;