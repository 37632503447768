import React, { Component } from 'react'
import Footer from '../Footer';
import Header from '../header';;


export default class PlateReaderCamera extends Component {
    render() {
        return (

            <div>
                <Header/>

                PlateReaderCamera

                <Footer />

            </div>
        )
    }
}
