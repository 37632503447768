import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer'
import { Helmet } from 'react-helmet';

class Airports extends Component {
  render() {
    return (
      <div>
         <Helmet>
        <title>Airports Boom Barriers | Gaido Technologies | Barrier Gate </title>
        <meta property="og:title" content="Airports Boom Barriers | Gaido Technologies | Barrier Gate" />
        <meta property="og:url" content="https://www.gaido.in/offerings/airports" />
      </Helmet>
        <Header /> 
        <div className="inner-banner3">
          <div className="row">
            <div className="col-md-5">
              <div className="hid-img">
                   <img alt ="gaidologos"src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/airport.jpg" style={{ width: '100%' }} />
              </div>
              <h2>Airports</h2>
              <p>Irrespective of how busy an airport is, travelers always want to be on time. Well, finding an available
              parking space to park one’s car and waiting in long queues at the entry and exit points can be a major
              challenge.While our Ticketless Management System helps reduce congestion and queueing, personalized message
                signs... </p>
              <p className="read-more"><a href="#about"> READ MORE <i className="fa fa-plus" /> </a></p>
            </div>
          </div>
        </div>
        <section id="about" className="f-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 content offerings2">
                <h1 className="hd2"> Offering for Airports</h1>
                <p>Irrespective of how busy an airport is, travelers always want to be on time. Well, finding an
                available parking space to park one’s car and waiting in long queues at the entry and exit
                points can be a major challenge. While our Ticketless Management System helps reduce
                congestion and queueing, personalized message signs and Parking Guidance Systems help
                drivers navigate into the right direction by simply directing them to an available slot
                identified with green lights on the LED indicators.
                </p>
                <p>Better yet, offer Valet Parking – Give your customers the option to drive in and drop their
                keys. Why bother about cruising through the parking lot and searching for an available spot.
                Park pleasantly before heading off into happy and relaxing holidays. Our valet management
                feature eliminates the hassle of using physical tickets and provides an additional layer of
                security to valet parking management.
                </p>
                <p>At airports and stations, parking is a large revenue generator after landing fees and travel
                tickets. Getting it right is therefore critical to the user’s experience. Gaido Technologies is
                offering a wide range of customizable solutions to help you provide a world-class customer
                experience. With its highly scalable Cloud-based SaaS product, particularly customizable for
                the airport sector, Gaido Technologies is capable of providing solutions that will help your
                airport’s parking facility accomplish maximum security, profitability, management, and
                control.
                </p>
              </div>
              <p className="read-more" style={{margin: "65px 65px 0px 65px"}}><a href="/products"> VIEW PRODUCTS <i className="fa fa-plus" /> </a></p>

            </div>
          </div>
         

        </section>
        <Footer />
        <a href={() => false} className="back-to-top"><i className="fa fa-chevron-up" /></a>
      </div>
    );
  }
}

export default Airports;