import React, { Component } from "react";
import "../../faac.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Header from "../../../header";
import { Helmet } from 'react-helmet';


const product1 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGatesProducts/C4000i_boxed.jpg";
const product2 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGatesProducts/109780_boxed.jpg";
const product3 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGatesProducts/109781_boxed.jpg";
const product4 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGatesProducts/109320_boxed.jpg";
const product5 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGatesProducts/109321_boxed.jpg";
const product6 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGatesProducts/109776_boxed.jpg";
const product7 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGatesProducts/109837_boxed.jpg";
const product8 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGatesProducts/109896_boxed.jpg";
const product9 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGatesProducts/109897_boxed.jpg";
const product10 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGatesProducts/109885_boxed.jpg";
const product11 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGatesProducts/109903_boxed.jpg";


export default class AutomationSlidingGates extends Component {
  render() {
    return (
      <div>
        <Helmet>
        <title>Automatic Gate Opener | Sliding Gate Motor | Automation, Remote open | FAAC </title>
        <meta property="og:title" content="Automatic Gate Opener | Sliding Gate Motor | Automation, Remote open | FAAC" />
        <meta property="og:url" content="https://www.gaido.in/underground-operatots" />
      </Helmet>
        <div>
          <Header />
        </div>

        <div>
          <section className="product" style={{ marginTop: 100 }}>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-9 col-sm-9 col-md-9 col-xs-9">
                <h1
                  style={{
                    position: "relative",
                    margin: "20px",
                    fontSize: "35px",
                    lineHeight: "1",
                    dataLineheight: "40px",
                  }}
                >
                  <b> Automated sliding gate systems</b>
                </h1>
                <p
                  style={{
                    margin: "20px",
                    fontSize: "15px",
                    display: "inline-block",
                  }}
                >
                  <span>
                    FAAC automated electric sliding gates leave the way free,
                    courtesy of the easy, safe movement of the leaf.
                    <br></br>
                    Automated sliding gates can be made in different materials,
                    shapes and sizes.
                    <br></br>
                    In general terms, there are two main criteria for choosing
                    an automated system for a sliding gate: the weight of the
                    leaf and the type of use.
                  </span>
                </p>
              </div>
            </div>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/SlidingGateC4000I">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product1}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product1"
                  />
                </a>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/SlidingGateC4000I">C4000I</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>Concealed gearmotor 24V</h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max. leaf weight</p>
                <b>400 Kg</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-1 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max speed</p>
                <b>16 m/min</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-1 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Type of use</p>
                <b>Residential</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/SlidingGate740">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product2}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product2"
                  />
                </a>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/SlidingGate740">740</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>230V Gearmotor</h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max. leaf weight</p>
                <b>500 Kg</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-1 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max speed</p>
                <b>12 m/min</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-1 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>30%</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/SlidingGate741">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product3}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product3"
                  />
                </a>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/SlidingGate741">741</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>230V Gearmotor</h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max. leaf weight</p>
                <b>900 Kg</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-1 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max speed</p>
                <b>12 m/min</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-1 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>40%</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>{" "}
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/SlidingGateC720">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product4}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product3"
                  />
                </a>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/SlidingGateC720">C720</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>Low voltage 24V gearmotor</h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max. leaf weight</p>
                <b>400 Kg</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-1 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max speed</p>
                <b>16 m/min</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-1 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>Continuous use</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>{" "}
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/SlidingGateC721">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product5}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product5"
                  />
                </a>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
              </div>
             
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/SlidingGateC721"> C721</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>Low voltage 24V gearmotor</h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max. leaf weight</p>
                <b>800 Kg</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-1 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max speed</p>
                <b>18 m/min</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-1 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>Continuous use</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/SlidingGate746ER">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product6}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product6"
                  />
                </a>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
              </div>

              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/SlidingGate746ER">746 E R</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>230V Gearmotor</h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max. leaf weight</p>
                <b>400 – 600 Kg</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-1 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max speed</p>
                <b>9,6 – 12 m/min.</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-1 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>70%</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/SlidingGate844ER">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product7}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product7"
                  />
                </a>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
              </div>

              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/SlidingGate844ER"> 844 E R</a>
                </h3>

                <h4 style={{ fontSize: "15px" }}>230V Gearmotor</h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max. leaf weight</p>
                <b>1,800 Kg</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-1 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max speed</p>
                <b>9,5 – 12 m/min.</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-1 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>70%</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/SlidingGate844ER3PH">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product8}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="8"
                  />
                </a>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/SlidingGate844ER3PH">844 ER 3PH</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>400V Gearmotor</h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max. leaf weight</p>
                <b>1,600 – 2,200 Kg</b>
              </div>

              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>70%</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/SlidingGate844RReversible">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product9}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product9"
                  />
                </a>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
              </div>

              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/SlidingGate844RReversible">844 R Reversible</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>230V Gearmotor</h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max. leaf weight</p>
                <b>1000 Kg</b>
              </div>

              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>70%</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/SlidingGate844MC3PH">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product10}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product5"
                  />
                </a>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
              </div>

              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/SlidingGate844MC3PH"> 884 MC 3PH</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>400V Gearmotor</h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max. leaf weight</p>
                <b>3,500 Kg</b>
              </div>

              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>50 – 100%</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/SlidingGateC851">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product11}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product11"
                  />
                </a>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/SlidingGateC851">C851</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>230V Gearmotor</h4>
              </div>

              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max. leaf weight</p>
                <b>1,800 Kg</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-1 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max speed</p>
                <b>40 m/min</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-1 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>100%</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
          </section>
        </div>
        <div
              class="modal fade"
              id="exampleModal10"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Product Details
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{ padding: 20 }}>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                        <iframe
                          scrolling="no"
                          src="https://forms.zohopublic.in/sales80/form/ContactUs/formperma/o3sKh3PHKyQ5GmkDmlz4rY0NM2edU5IF_pl9MYAjDoA?zf_rszfm=1"
                          id="gform"
                          width="100%"
                          height="990"
                          frameborder="0"
                          marginheight="0"
                          marginwidth="0"
                        >
                          Loading…
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        <footer id="footer"></footer>
      </div>
    );
  }
}
