import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';

class Hospitals extends Component {
 

    render() {
        return (
            <div>
<Helmet>
        <title>Hospitals Boom Barriers | Gaido Technologies | Barrier Gate</title>
        <meta property="og:title" content="Hospitals Boom Barriers | Gaido Technologies | Barrier Gate" />
        <meta property="og:url" content="https://www.gaido.in/offerings/hospitals" />
      </Helmet>
                  <Header /> 
                  <div className="inner-banner3 hospital">
          <div className="row">
            <div className="col-md-5">
              <div className="hid-img">
                   <img alt ="gaidologos"src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/hospital2.jpg" style={{width: '100%'}} />
              </div>
              <h2>Hospitals</h2>
              <p>Every health facility is different, unique in its own way. While some have simple procedural
                formalities, others follow complex protocols. Commensurate with the case, Gaido
                Technologies is capable to offer specific solutions that are highly adaptable to meet ...</p>
              <p className="read-more"><a href="#about"> READ MORE <i className="fa fa-plus" /> </a></p>
            </div>
          </div>
        </div>
        {/*==========================
    Solutions Section
  ============================*/}
        <section id="about" className="f-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 content offerings2">
                <h1 className="hd2"> Offering for Hospitals</h1>
                <p>Every health facility is different, unique in its own way. While some have simple procedural
                  formalities, others follow complex protocols. Commensurate with the case, Gaido
                  Technologies is capable to offer specific solutions that are highly adaptable to meet
                  customer’s requirements. Most importantly, our focus is to ensure that the patient or their
                  family do not experience any delay due to parking.
                </p>
                <p>Efficient parking management is achieved using Ticketless Management System, to reduce
                  congestion and queueing and Parking Guidance Systems, to guide the drivers. It’s working is
                  pretty simple, personalized message signs help drivers navigate into the right direction and
                  LED indicators direct them to an available slot identified with green lights.
                </p>
                <p>Better yet, offer Valet Parking – Give your customers the option to drive in and drop their
                  keys. Why bother about cruising through the parking lot and searching for an available spot.
                  When you are at the hospital, we understand what your priorities are, so we’ve got you
                  covered. Our valet management feature eliminates the hassle of using physical tickets and
                  provides an additional layer of security to valet parking management.
                </p>
                <p>Parking at hospitals is critical and managing it can be complex. With its highly scalable
                  Cloud-based SaaS product, particularly customizable for health facilities, Gaido Technologies
                  is capable of providing solutions that will help your hospital’s parking facility accomplish
                  maximum security, profitability, management, and control.
                </p>
              </div>
              <p className="read-more" style={{margin: "65px 65px 0px 65px"}}><a href="/products"> VIEW PRODUCTS <i className="fa fa-plus" /> </a></p>

            </div>
          </div>

        </section>
       <Footer />
       <a href={() => false} className="back-to-top"><i className="fa fa-chevron-up" /></a>
            </div>
        );
    }
}

export default Hospitals;