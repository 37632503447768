import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer';
class PartnerNetWork extends Component {
    componentDidMount(){
        document.getElementById("floatbutton").style.display="none"
      }
    render() {
        return (
            <div>
                <Header />
                <div className="inner-banner">
                    <h2>Partner Network</h2>
                </div>
                <div className="section-header" style={{ visibility: "hidden" }}>
                    <h2 >Our Solutions</h2>
                </div>
                <section id="about2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 content">
                                <img src="https://gaidolpr.s3.ap-south-1.amazonaws.com/lprdata/Gaido+India+Map.png" alt="Gaido technologies" className="img-responsive" style={{ width: '100%', marginTop: '50px' }} />
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}
export default PartnerNetWork;