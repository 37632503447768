import React, { Component } from "react";
import "../../faac.css";
import Header from "../../../header";
const productImage = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SwingGateS800HENC/108720.jpg";
const Installation = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SwingGateS800HENC/S800Hinstallazione.jpg";
const ElectronicCU1 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SwingGateS800HENC/790284.jpg";
const emergencybattery = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SwingGateS800HENC/390926.jpg";
const Foundationbox = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SwingGateS800HENC/490112.jpg";
const StainlessBox = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SwingGateS800HENC/490113.jpg";
const Hydraulicreleasedevice = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SwingGateS800HENC/390035.jpg";
const spinedJointGroup = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SwingGateS800HENC/390972.jpg";
const releaselock = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SwingGateS800HENC/71209101.jpg";
const s800weledShoe = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SwingGateS800HENC/490187.jpg";
const ExamplePdf = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SwingGateS800HENC/9900100304_S800H_ENC_EN_ Rev10-LOWRES.pdf";

export default class SwingGateS800HENC extends Component {
  constructor(props) {
    super(props);
  }

  toggle = (id, id2) => {
    var n = document.getElementById(id);
    if (n.style.display != "none") {
      n.style.display = "none";
      document.getElementById(id2).setAttribute("aria-expanded", "false");
    } else {
      n.style.display = "";
      document.getElementById(id2).setAttribute("aria-expanded", "true");
    }
  };
  render() {
    return (
      <div>
        <Header />
        <div className="faacbarriers" style={{ marginTop: 100 }}>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3">
              <img
                className="card-img-top"
                src={productImage}
                // //onClick={()=>{ history.push('/automations-for-gates')}}
                // //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                style={{ cursor: "pointer" }}
                alt="Product1"
              />
            </div>
            <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
              <div
                className="col-lg-12 col-sm-12 col-md-12 col-xs-12"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "50px" }}>S800H ENC</h3>
                <h4 style={{ fontSize: "20px" }}>
                  24V Underground hydraulic operator
                </h4>
              </div>
              <div className="row">
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "initial", margin: 20 }}
                >
                  <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                  <b>2 – 4 m</b>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "initial", margin: 20 }}
                >
                  <p style={{ fontSize: "12px" }}>Max weight</p>
                  <b>800 Kg</b>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "initial", margin: 20 }}
                >
                  <p style={{ fontSize: "12px" }}>Use frequency </p>
                  <b>Continuous use</b>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3">
                  <a
                    href={ExamplePdf}
                    download="9900100304_S800H_ENC_EN_ Rev10-LOWRES"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      style={{
                        marginLeft: 30,
                        marginTop: 30,
                        borderColor: "#cecece",
                        borderRadius: 3,
                      }}
                    >
                      Download Broucher
                    </button>
                  </a>
                  <br />
                  <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      margin: 20,
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  >
                    Get Quote
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

            <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
              <ul style={{ listStyleType: "disc" }}>
                <li class="Bullet_List">
                  <span>
                    Underground operator with hydraulic technology 24V.
                  </span>
                </li>
                <li class="Bullet_List">
                  <span>
                    Absolute magnetic encoder (for ENC models) with integrated
                    BUS 2easy (non polarized two wires connection).
                  </span>
                </li>
                <li class="Bullet_List">
                  <span>
                    Integrated Positive Stops, possibility to avoid mechanical
                    stops installation. FAAC Patent.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink1"
                onClick={(e) => this.toggle("navbar", "TLink1")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Family models</span>
                </h5>
              </a>
              <div id="navbar" style={{ display: "none" }}>
                <div class="table-3">
                  <table>
                    <thead>
                      <tr>
                        <th colspan="1" rowspan="1">
                          <p>
                            <span>Model</span>
                          </p>
                        </th>
                        <th colspan="1" rowspan="1">
                          <p>
                            <span>Item code</span>
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>S800H ENC CBAC 100°</span>
                          </p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>108720</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>S800H ENC SBW 100°</span>
                          </p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>108724</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>S800H ENC CBAC 180°</span>
                          </p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>108722</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>S800H ENC SBW 180°</span>
                          </p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>108725</span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{ marginTop: "0px", marginBottom: "15px" }}>
                  <h5
                    class="title-heading-left fusion-responsive-typography-calculated"
                    style={{ fontSize: 20, lineHeight: 1.2 }}
                    data-fontsize="20"
                    data-lineheight="24px"
                  >
                    <span>Packs</span>
                  </h5>
                </div>
                <div
                  class="fusion-text fusion-text-6 Models__Package_Description"
                  // style="transform:translate3d(0,0,0);"
                >
                  <p>
                    <span class="Highlight">S800H ENC </span>
                    <span class="Package">&nbsp;</span>
                    <span class="Highlight">includes</span>
                    <span class="Highlight">:</span>
                    <span>&nbsp;</span>
                    <span>
                      a hydraulic operator with hydraulic lock in
                      opening/closing (CBAC), without lock (SBW) an absolute
                      magnetic encoder, a release and adjusting key.
                      <br></br>
                      The foundation box includes: a foundation box in
                      cataphoresis treated aluminium with anti-rotation pin, a
                      cover in polished stainless steel.
                    </span>
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink2"
                onClick={(e) => this.toggle("dec2", "TLink2")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Dimensions and technical specifications</span>
                </h5>
              </a>
              <div id="dec2" style={{ display: "none" }}>
                <div class="table-4">
                  <table>
                    <thead>
                      <tr>
                        <th contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Model</span>
                          </p>
                        </th>
                        <th contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>S800H ENC CBAC 100°</span>
                          </p>
                        </th>
                        <th contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>S800H ENC SBW 100°</span>
                          </p>
                        </th>
                        <th contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>S800H ENC CBAC 180°</span>
                          </p>
                        </th>
                        <th contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>S800H ENC SBW 180°</span>
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Power supply voltage</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>220-240V~ 50/60 Hz</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Electric motor</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>With brushes 24V</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Max. power</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>60 W</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Max. angular speed</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>
                              5.5°/s (with E024S) – 8.2°/s (with E124)
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Max. leaf weight</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>800 Kg</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Operating ambient temperature</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>-20°C ÷ +55°C</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Protection class</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>IP67</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Weight</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>14 Kg</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>15 Kg</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Use frequency</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>Continuous use</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Max. leaf width</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>2 m</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>4 m</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>2 m</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>4 m</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Type of oil</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>FAAC HP OIL</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Dimensions (LxDxH)</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>
                              Operator 380 x 220 x 160 – foundation box 507 x
                              283 x 200 mm
                            </span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>
                              operator 450 x 220 x 160 – foundation box 507 x
                              283 x 200 mm
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Electronic equipment</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="4" rowspan="1">
                          <p>
                            <span>Not included</span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>(*) data referred to 230V~ 50Hz.</p>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink3"
                onClick={(e) => this.toggle("dec3", "TLink3")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Example of a typical installation</span>
                </h5>
              </a>
              <div id="dec3" style={{ display: "none" }}>
                <div>
                  <img
                    decoding="async"
                    src={Installation}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                </div>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                    <table>
                      <thead>
                        <tr>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Ref</span>
                            </p>
                          </th>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Q.ty</span>
                            </p>
                          </th>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Description</span>
                            </p>
                          </th>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Code</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>S800H ENC CBAC 100°</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>108720</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Foundation box</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>490112</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>
                                Hydraulic release device ( to be used with the
                                code 390972) for operators CBAC or SBW
                              </span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>390035</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>
                                Splined joint group kit for CBAC or SBW
                                operators (to be combined with code 390035)
                              </span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>390972</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>XLED flashing light</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>410029</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>3</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Key operated button XK10B</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>401304</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>3</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Lock with key</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>71275101</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>4</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>
                                E124 Electronic control unit with enclosure
                              </span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>790284</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>4</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>XF 433 MHz receiver</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>787831</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>5</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Pair of photocells XP 20 BD</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>785103</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>5</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Low column H50</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>401070</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>5</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Foundation plate for column</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>737100</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2 channels transmitter XT2 433 SLH LR</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>787007</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink4"
                onClick={(e) => this.toggle("dec4", "TLink4")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Electronic control units</span>
                </h5>
              </a>
              <div id="dec4" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={ElectronicCU1}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      E124 Electronic control unit with enclosure
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      790284
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink5"
                onClick={(e) => this.toggle("dec5", "TLink5")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span> Accessories</span>
                </h5>
              </a>
              <div id="dec5" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={emergencybattery}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Emergency battery support kit (specific for E124)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      390926
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={Foundationbox}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>Foundation box</p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      490112
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={StainlessBox}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Stainless steel foundation box
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      490113
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={Hydraulicreleasedevice}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Hydraulic release device ( to be used with the code
                      390972) for operators CBAC or SBW
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      390035
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={spinedJointGroup}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Splined joint group kit for CBAC or SBW operators
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      390972
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={releaselock}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Release lock with customised key
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      71209101-10
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={s800weledShoe}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      S800 welded shoe kit (welding according to UNI EN ISO
                      15614-1)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      490187
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>

          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
        </div>
        <div
          class="modal fade"
          id="exampleModal10"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Product Details
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ padding: 20 }}>
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <iframe
                      scrolling="no"
                      src="https://forms.zohopublic.in/sales80/form/ContactUs/formperma/o3sKh3PHKyQ5GmkDmlz4rY0NM2edU5IF_pl9MYAjDoA?zf_rszfm=1"
                      id="gform"
                      width="100%"
                      height="990"
                      frameborder="0"
                      marginheight="0"
                      marginwidth="0"
                    >
                      Loading…
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
