import React, { useEffect, useState } from 'react';
import { Switch, Route, } from 'react-router-dom';
import Home from './Home';
import AboutCompany from './about-company/AboutCompany';
import Team from './about-company/Team';
import PartnerNetWork from './about-company/PartnerNetWork';
import GaidoConnect from './gaido-connect/GaidoConnect';
import Solutions from './solutions/Solutions';
import Offerings from './offerings/Offerings';
import Airports from './offerings/Airports';
import BusinessCenters from './offerings/BusinessCenters';
import Hospitals from './offerings/Hospitals';
import Hotels from './offerings/Hotels';
import Municipalities from './offerings/Municipalities';
import EducationalInstitutes from './offerings/EducationalInstitutes';
import ResidentialArea from './offerings/ResidentialArea';
import ShoppingCenters from './offerings/ShoppingCenters';
import SportsAndExhibitionVenues from './offerings/SportsAndExhibitionVenues';
import TicketlessParkingManagment from './solutions/TicketlessParkingManagment';
import ParkingGuidenceSystem from './solutions/ParkingGuidenceSystem';
import ValetparkingSystem from './solutions/ValetparkingSystem';
import VehicleTrackingSystem from './solutions/VehicleTrackingSystem';
import ColudBasedAnalyticsPlatfrom from './solutions/ColudBasedAnalyticsPlatfrom';
import Blog from './blog/index';
import Stepsforasuccessfulfirstsalesmeeting from './blog/3-steps-for-a-successful-first-sales-meeting';
import Astepintothefeatureofvaletparking from './blog/a-step-into-the-feature-of-valet-parking';
import About from './blog/about';
import Blogsingle from './blog/blog-single';
import Category from './blog/category';
import Contact from './blog/contact';
import Disclaimer from './blog/disclaimer';
import Doesitreallymatterwhereyousitinameeting from './blog/does-it-really-matter-where-you-sit-in-a-meeting';
import Facebookinvests57billioninjio from './blog/facebook-invests-5.7-billion-in-jio';
import Gaidotacklesparkingissuesinmetropolitancities from './blog/gaido-tackles-parking-issues-in-metropolitan-cities';
import Howdidiimprovemyefficiencyworkingfromhome from './blog/how-did-i-improve-my-efficiency-working-from-home';
import Howeasyisittointegratedigitalvaletparking from './blog/how-easy-is-it-to-integrate-digital-valet-parking';
import Howistheparkingindustryaffectedbycovid19 from './blog/how-is-the-parking-industry-affected-by-covid-19';
import Howtoensureyourcarisfineduringthelockdown from './blog/how-to-ensure-your-car-is-fine-during-the-lockdown';
import Howtoincreasethesecuritywithoutincreasingstaff from './blog/how-to-increase-the-security-without-increasing-staff';
import Howtoparklikeapro from './blog/how-to-park-like-a-pro';
import Isworkingforastatuprightaftergraduationagoodidea from './blog/is-working-for-a-statup-right-after-graduation-a-good-idea';
import Newtechnologiesandtheirimpactonthefuture from './blog/new-technologies-and-their-impact-on-the-future';
import Onethingyoucandotolurecustomersafterthelockdown from './blog/one-thing-you-can-do-to-lure-customers-after-the-lockdown';
import Postlockdownhowtorunyourbusiness from './blog/post-lockdown-how-to-run-your-business';
import Thenextbigthinginparkingishere from './blog/the-next-big-thing-in-parking-is-here';
import Theftsincommunityincreasepostcovid19outbreak from './blog/thefts-in-community-increase-post-covid-19-outbreak';
import Wekeepourconsumerhappy from './blog/we-keep-our-consumer-happy';
import Whatisstartupindia from './blog/what-is-startup-india';
import Whatisthetelanganaparkingpolicy from './blog/what-is-the-telangana-parking-policy';
import ThanksEnquiry from './ThanksEnquiry';
import Car from './blog/Cars'
import Products from './products/Products';
import PlateReaderCamera from './products/PlateReaderCamera';
import ReactGA from 'react-ga';
import ThankYou from './ThankYou';
import Privacy from './privacy';
import Login from './Admin/Login';
import PostBlog from './Admin//postblog';
import AddBlog from './Admin/addblog';
import EditBlog from './Admin/EditBlog'
import PrivateRoute from './PrivateRoute';
import Dynamic from './blog/Dynamic';
import SignUp from './Admin/SignIn';
import LogIn from './Admin/LoginForm';
import FaccProducts from './FAAC/products'
import FaacBarriers from './FAAC/FaccBarriers/FaacBarriers';
import FaacGates from './FAAC/FacGateMotors/FaacGates';
import B614AutomaticBarrier24V from './FAAC/FaccBarriers/B614AutomaticBarrier24V';
import BPR615AutomaticBarrier230V from './FAAC/FaccBarriers/BPR615AutomaticBarrier230V';
import Standard620Barrier from './FAAC/FaccBarriers/Standard620Barrier';
import Rapid620Barrier from './FAAC/FaccBarriers/Rapid620Barrier';
import B680HybridBarrier from './FAAC/FaccBarriers/B680HybridBarrier';
import ExternalSwingMotors from './FAAC/FacGateMotors/ExternalSwingGate/ExternalSwingMotors';
import Faac391gates from './FAAC/FacGateMotors/ExternalSwingGate/Faac391gates';
import Faac390230vGates from './FAAC/FacGateMotors/ExternalSwingGate/Faac390230vGates';
import Faac412gates from './FAAC/FacGateMotors/ExternalSwingGate/Faac412gates';
import Faac413230vgates from './FAAC/FacGateMotors/ExternalSwingGate/Faac413230vgates';
import Faac415230vgates from './FAAC/FacGateMotors/ExternalSwingGate/Faac415230vgates';
import Faac41524vgates from './FAAC/FacGateMotors/ExternalSwingGate/Faac41524vgates';
import Faacs418gates from './FAAC/FacGateMotors/ExternalSwingGate/Faacs418gates';
import Faac402gates from './FAAC/FacGateMotors/ExternalSwingGate/Faac402gates';
import Faacs450hgates from './FAAC/FacGateMotors/ExternalSwingGate/Faacs450hgates';
import Faac422pedgates from './FAAC/FacGateMotors/ExternalSwingGate/Faac422pedgates';
import Faac400gates from './FAAC/FacGateMotors/ExternalSwingGate/Faac400gates';
import SlidingGateOperators from './FAAC/FacGateMotors/SlidingGateOperators/SlidingGateOperators';
import SwingGate770N230V from './FAAC/FacGateMotors/SlidingGateOperators/SwingGate770N230V';
import SwingGate770N24V from './FAAC/FacGateMotors/SlidingGateOperators/SwingGate770N24V';
import SwingGateS800HENC from './FAAC/FacGateMotors/SlidingGateOperators/SwingGateS800HENC';
import SwingGateS800ENC from './FAAC/FacGateMotors/SlidingGateOperators/SwingGateS800ENC';
import SwingGateS2500l from './FAAC/FacGateMotors/SlidingGateOperators/SwingGateS2500l';
import AutomationSlidingGates from './FAAC/FacGateMotors/AutomationSlidingGates/AutomationSlidingGates';
import SlidingGateC4000I from './FAAC/FacGateMotors/AutomationSlidingGates/SlidingGateC4000I';
import SlidingGate740 from './FAAC/FacGateMotors/AutomationSlidingGates/SlidingGate740';
import SlidingGate741 from './FAAC/FacGateMotors/AutomationSlidingGates/SlidingGate741';
import SlidingGateC720 from './FAAC/FacGateMotors/AutomationSlidingGates/SlidingGateC720';
import SlidingGateC721 from './FAAC/FacGateMotors/AutomationSlidingGates/SlidingGateC721';
import SlidingGate746ER from './FAAC/FacGateMotors/AutomationSlidingGates/SlidingGate746ER';
import SlidingGate844ER from './FAAC/FacGateMotors/AutomationSlidingGates/SlidingGate844ER';
import SlidingGate844ER3PH from './FAAC/FacGateMotors/AutomationSlidingGates/SlidingGate844ER3PH';
import SlidingGate844RReversible from './FAAC/FacGateMotors/AutomationSlidingGates/SlidingGate844RReversible';
import SlidingGate844MC3PH from './FAAC/FacGateMotors/AutomationSlidingGates/SlidingGate844MC3PH';
import SlidingGateC851 from './FAAC/FacGateMotors/AutomationSlidingGates/SlidingGateC851';
import FaacIndex from './FAAC/FaacIndex';
import AboutUs from './FAAC/AboutUs';
import Feedback from './Feedback';


function App() {
  const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"));
  // useEffect(()=>{
  //     ReactGA.initialize('UA-213736737-1')
  //     ReactGA.pageview(window.location.pathname + window.location.search);
  //     console.log(window.location.pathname + window.location.search)
  //   },[])
  
  return (
    <>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/thanks-enquiry" component={ThanksEnquiry} />
        <Route path="/thank-you-for-registering" component={ThankYou} />
        <Route path="/privacy" component={Privacy} />


        <Route path="/solutions/ticketless-parking-management" component={TicketlessParkingManagment} />
        <Route path="/solutions/parking-guidance-system" component={ParkingGuidenceSystem} />
        <Route path="/solutions/valet-parking-system" component={ValetparkingSystem} />
        <Route path="/solutions/entry-gate-system" component={VehicleTrackingSystem} />
        <Route path="/solutions/cloud-based-analytics-platform" component={ColudBasedAnalyticsPlatfrom} />
        <Route path="/offerings/airports" component={Airports} />
        <Route path="/offerings/business-centers" component={BusinessCenters} />
        <Route path="/offerings/hospitals" component={Hospitals} />
        <Route path="/offerings/hotels" component={Hotels} />
        <Route path="/offerings/municipalities" component={Municipalities} />
        <Route path="/offerings/educational-institutes" component={EducationalInstitutes} />
        <Route path="/offerings/residential-area" component={ResidentialArea} />
        <Route path="/offerings/shopping-centers" component={ShoppingCenters} />
        <Route path="/offerings/sports-exhibitions" component={SportsAndExhibitionVenues} />
        <Route path="/solutions" component={Solutions} />
        <Route path="/offerings" component={Offerings} />
        <Route path="/about-company#team" exact component={Team} />
        <Route path="/about-company#partner-network" exact component={PartnerNetWork} />
        <Route path="/gaido-connect" component={GaidoConnect} />
        <Route path="/blog/3-steps-for-a-successful-first-sales-meeting" component={Stepsforasuccessfulfirstsalesmeeting} />
        <Route path="/blog/a-step-into-the-feature-of-valet-parking" component={Astepintothefeatureofvaletparking} />
        <Route path="/blog/about" component={About} />
        <Route path="/blog/blog-single" component={Blogsingle} />
        <Route path="/blog/category" component={Category} />
        <Route path="/blog/contact" component={Contact} />
        <Route path="/blog/disclaimer" exact component={Disclaimer} />
        <Route path="/blog/car-flood-water-in-rain-What-to-do" component={Car} />
        <Route path="/blog/does-it-really-matter-where-you-sit-in-a-meeting" component={Doesitreallymatterwhereyousitinameeting} />
        <Route path="/blog/facebook-invests-5.7-billion-in-jio" component={Facebookinvests57billioninjio} />
        <Route path="/blog/gaido-tackles-parking-issues-in-metropolitan-cities" component={Gaidotacklesparkingissuesinmetropolitancities} />
        <Route path="/blog/how-did-i-improve-my-efficiency-working-from-home" component={Howdidiimprovemyefficiencyworkingfromhome} />
        <Route path="/blog/how-easy-is-it-to-integrate-digital-valet-parking" component={Howeasyisittointegratedigitalvaletparking} />
        <Route path="/blog/how-is-the-parking-industry-affected-by-covid-19" component={Howistheparkingindustryaffectedbycovid19} />
        <Route path="/blog/how-to-ensure-your-car-is-fine-during-the-lockdown" component={Howtoensureyourcarisfineduringthelockdown} />
        <Route path="/blog/how-to-increase-the-security-without-increasing-staff" component={Howtoincreasethesecuritywithoutincreasingstaff} />
        <Route path="/blog/how-to-park-like-a-pro" component={Howtoparklikeapro} />
        <Route path="/blog/is-working-for-a-statup-right-after-graduation-a-good-idea" component={Isworkingforastatuprightaftergraduationagoodidea} />
        <Route path="/blog/new-technologies-and-their-impact-on-the-future" component={Newtechnologiesandtheirimpactonthefuture} />
        <Route path="/blog/one-thing-you-can-do-to-lure-customers-after-the-lockdown" component={Onethingyoucandotolurecustomersafterthelockdown} />
        <Route path="/blog/post-lockdown-how-to-run-your-business" component={Postlockdownhowtorunyourbusiness} />
        <Route path="/blog/the-next-big-thing-in-parking-is-here" component={Thenextbigthinginparkingishere} />
        <Route path="/blog/thefts-in-community-increase-post-covid-19-outbreak" component={Theftsincommunityincreasepostcovid19outbreak} />
        <Route path="/blog/we-keep-our-consumer-happy" component={Wekeepourconsumerhappy} />
        <Route path="/blog/what-is-startup-india" component={Whatisstartupindia} />
        <Route path="/blog/what-is-the-telangana-parking-policy" component={Whatisthetelanganaparkingpolicy} />
        <Route path="/solutions" component={Solutions} />
        <Route path="/offerings" component={Offerings} />
        <Route path="/blog" component={Blog} />
        <Route path="/about-company" exact component={AboutCompany} />
        <Route path="/products" exact component={Products} />
        <Route path="/product/platereadercamera" component={PlateReaderCamera} />
        {/* <Route path="/admin/login" component={Login} /> */}
        <Route path="/admin/login" component={LogIn} />
        <Route path="/admin/signup" component={SignUp} />
        <Route path="/blogs/:blogid" component={Dynamic} exact />
        <PrivateRoute path="/admin/postedblogs" component={PostBlog} />
        {/*  <Route path="/admin/addblog" component={AddBlog} /> */}
        <PrivateRoute path="/admin/editblog" component={EditBlog} />
        <PrivateRoute path="/admin/addblog" component={AddBlog} />
        <PrivateRoute path="/admin/addblog" component={AddBlog} />
        <Route path="/feedbackform" exact component={Feedback} />



        { /* FACC Routes */}
        <Route path="/faac" component={FaacIndex} exact />
        <Route path="/faac/aboutus" component={AboutUs}  exact/>

        <Route path="/faac/products" component={FaccProducts} />
        <Route path="/automations-for-barriers" component={FaacBarriers} exact/>
        <Route path="/B614AutomaticBarrier24V" component={B614AutomaticBarrier24V} exact/>
        <Route path="/615BPRAutomaticBarrier230V" component={BPR615AutomaticBarrier230V} exact/>
        <Route path="/620StandardAutomaticBarrier230V" component={Standard620Barrier} exact/>
        <Route path="/620RapidAutomaticBarrier230V" component={Rapid620Barrier} exact/>
        <Route path="/B680HAutomatic24VHybridBarrier" component={B680HybridBarrier} exact/>


 
        <Route path="/automations-for-gates" component={FaacGates} exact/>
        <Route path="/external-swing-gate-operators" component={ExternalSwingMotors} exact/>
        <Route path="/faac391gates" component={Faac391gates} exact/>
        <Route path="/faac390230vgates" component={Faac390230vGates} exact/>
        <Route path="/faac412gates" component={Faac412gates} exact/>
        <Route path="/faac413230vgates" component={Faac413230vgates} exact/>
        <Route path="/faac415230vgates" component={Faac415230vgates} exact/>
        <Route path="/faac41524vgates" component={Faac41524vgates} exact/>
        <Route path="/faacS418gates" component={Faacs418gates} exact/>
        <Route path="/faac402gates" component={Faac402gates} exact/>
        <Route path="/faacS450hgates" component={Faacs450hgates} exact/>
        <Route path="/faac422pedgates" component={Faac422pedgates} exact/>
        <Route path="/faac400gates" component={Faac400gates} exact/>

        <Route path="/underground-operatots" component={AutomationSlidingGates} exact/>
        <Route path="/SlidingGateC4000I" component={SlidingGateC4000I} exact/>
        <Route path="/SlidingGate740" component={SlidingGate740} exact/>
        <Route path="/SlidingGate741" component={SlidingGate741} exact/>
        <Route path="/SlidingGateC720" component={SlidingGateC720} exact/>
        <Route path="/SlidingGateC721" component={SlidingGateC721} exact/>
        <Route path="/SlidingGate746ER" component={SlidingGate746ER} exact/>
        <Route path="/SlidingGate844ER" component={SlidingGate844ER} exact/>
        <Route path="/SlidingGate844ER3PH" component={SlidingGate844ER3PH} exact/>
        <Route path="/SlidingGate844RReversible" component={SlidingGate844RReversible} exact/>
        <Route path="/SlidingGate844MC3PH" component={SlidingGate844MC3PH} exact/>
        <Route path="/SlidingGateC851" component={SlidingGateC851} exact/>
        


        <Route path="/sliding-gate-operators" component={SlidingGateOperators} exact/>
        <Route path="/SwingGate770N230V" component={SwingGate770N230V} exact/>
        <Route path="/SwingGate770N24V" component={SwingGate770N24V} exact/>
        <Route path="/SwingGateS800HENC" component={SwingGateS800HENC} exact/>
        <Route path="/SwingGateS800ENC" component={SwingGateS800ENC} exact/>
        <Route path="/SwingGateS2500l" component={SwingGateS2500l} exact/>










      </Switch>
    </>
  );
}

export default App;
