import React, { Component } from 'react';
import Header from './header';
class ThanksEnquiry extends Component {
    render() {
        return (
            <div >
                <Header />
                <div className="innerbannerthanks">
                    <h2 style={{
                        textTransform: "none",
                        fontWeight: "400px"
                    }}> Thanks! Your guide is on the way.</h2>
                    <div className="row text-center">
                        <p className="text-center" style={{ width: '100%', color: "#fff" }}><b>We sent the guide to the email you provided if you don't receive it.check your spam or junk folder and verify that you entered a valid email address </b></p>
                    </div>
                </div>
                <section id="aboutthanks">
                <div className="container">
                    <div className="section-header">
                        <h2>Check out some of our latest blog posts</h2>
                    </div>
                </div>
                </section>
              
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <a href="/blog/how-to-increase-the-security-without-increasing-staff" className="a-block d-flex align-items-center height-md" style={{ backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_28.jpg")' }}>
                                <div className="text">
                                    <div className="post-meta">
                                        <span className="mr-2">June 13, 2020 </span>
                                    </div>
                                    <h3>How to increase the security without increasing the staff?</h3>
                                    <div className="post-meta">
                                        <span className="ml-2"><span className="fa fa-comments" /> 121</span>
                                        <span className="ml-2"><span className="fa fa-eye" /> 444</span>
                                        <span className="ml-2"><span className="fa fa-thumbs-o-up" /> 92</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <a href="/blog/thefts-in-community-increase-post-covid-19-outbreak" className="a-block d-flex align-items-center height-md" style={{ backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_27.jpg")' }}>
                                <div className="text">
                                    <div className="post-meta">
                                        <span className="mr-2">June 13, 2020 </span>
                                    </div>
                                    <h3>Thefts in community increase post covid-19 outbreak! Here’s what you can do..!</h3>
                                    <div className="post-meta">
                                        <span className="ml-2"><span className="fa fa-comments" /> 121</span>
                                        <span className="ml-2"><span className="fa fa-eye" /> 444</span>
                                        <span className="ml-2"><span className="fa fa-thumbs-o-up" /> 92</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <a href="/blog/how-is-the-parking-industry-affected-by-covid-19" className="a-block d-flex align-items-center height-md" style={{ backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_29.jpg")' }}>
                                <div className="text">
                                    <div className="post-meta">
                                        <span className="mr-2">May 16, 2020 </span>
                                    </div>
                                    <h3>How is the parking industry affected by COVID 19?</h3>
                                    <div className="post-meta">
                                        <span className="ml-2"><span className="fa fa-comments" /> 121</span>
                                        <span className="ml-2"><span className="fa fa-eye" /> 444</span>
                                        <span className="ml-2"><span className="fa fa-thumbs-o-up" /> 92</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ThanksEnquiry;