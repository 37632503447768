import React, { Component } from "react";
import "../faac.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Header from "../../header";
import { Helmet } from 'react-helmet';

const B614Product = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/B614 Automatic barrier 24v.jpg";
const BPR615Product = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/615BPR Automatic barrier 230V.jpg";
const S620product = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/620 Standard Automatic Barrier 230V.jpg";
const R620product = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/620 Rapid Automatic Barrier 230V.jpg";
const B680HProduct = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/FaacProductImages/B680H Automatic 24V Hybrid Barrier.jpg";

export default class FaacBarriers extends Component {
  render() {
    return (
      <div>
        <Helmet>
        <title>FAAC Boom Barrier | FAAC | B614 | B620 B680 | Hydraulic, Electromechanical </title>
        <meta property="og:title" content="FAAC Boom Barrier | FAAC | B614 | B620 B680 | Hydraulic, Electromechanical" />
        <meta property="og:url" content=" https://www.gaido.in/automations-for-barriers " />
      </Helmet>
        <div>
          <Header />
        </div>

        <div>
          <section className="product" style={{ marginTop: 100 }}>
            <div className="col-lg-9 col-sm-6 col-md-6 col-xs-6">
              <h1
                style={{
                  position: "relative",
                  margin: "20px",
                  fontSize: "35px",
                  lineHeight: "1",
                  dataLineheight: "40px",
                }}
              >
                <b> Automation systems for vehicle barriers and boom gates</b>
              </h1>
              <p
                style={{
                  margin: "20px",
                  fontSize: "15px",
                  display: "inline-block",
                }}
              >
                <span>
                  FAAC automatic vehicle barriers and boom gates represent the
                  simplest and most reliable solution for managing entrances or
                  spaces in communal condominium areas, business access areas,
                  industrial zones, motorway tool areas or wherever the flow of
                  vehicles needs to be regulated, or where parking solutions are
                  required.
                </span>
              </p>
            </div>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2">
                <a href="/B614AutomaticBarrier24V">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={B614Product}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product1"
                  />
                </a>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
              </div>
              <div
                className="col-lg-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/B614AutomaticBarrier24V">B614</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>Automatic barrier 24V</h4>
              </div>
              <div
                className="col-lg-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max beam length</p>
                <b>5 m</b>
              </div>
              <div
                className="col-lg-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Opening time</p>
                <b>&#60; 2 s (80° with 3 m beam)</b>
              </div>
              <div
                className="col-lg-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>Continuous use</b>
              </div>
            </div>
            <hr
              style={{ margin: 20, borderColor: "#cecece", borderWidth: 5 }}
            ></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2">
                <a href="/615BPRAutomaticBarrier230V">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={BPR615Product}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product1"
                  />
                </a>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
              </div>
              <div
                className="col-lg-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/615BPRAutomaticBarrier230V">615BPR</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>Automatic Barrier 230V</h4>
              </div>
              <div
                className="col-lg-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max beam length</p>
                <b>5 m</b>
              </div>
              <div
                className="col-lg-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Opening time</p>
                <b>2,5 – 5,7 s</b>
              </div>
              <div
                className="col-lg-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>40% – 50%</b>
              </div>
            </div>
            <hr
              style={{ margin: 20, borderColor: "#cecece", borderWidth: 5 }}
            ></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2">
                <a href="/620StandardAutomaticBarrier230V">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={S620product}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product1"
                  />
                </a>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
              </div>

              <div
                className="col-lg-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/620StandardAutomaticBarrier230V">620 Standard</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>Automatic Barrier 230V</h4>
              </div>
              <div
                className="col-lg-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max beam length</p>
                <b>4 – 5 m</b>
              </div>
              <div
                className="col-lg-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Opening time</p>
                <b>3,5 – 4,5 s</b>
              </div>
              <div
                className="col-lg-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>70%</b>
              </div>
            </div>
            <hr
              style={{ margin: 20, borderColor: "#cecece", borderWidth: 5 }}
            ></hr>{" "}
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2">
                <a href="/620RapidAutomaticBarrier230V">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={R620product}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product1"
                  />
                </a>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
              </div>
              <div
                className="col-lg-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/620RapidAutomaticBarrier230V">620 Rapid</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>Automatic Barrier 230V</h4>
              </div>
              <div
                className="col-lg-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max beam length</p>
                <b>3 – 3,8 m</b>
              </div>
              <div
                className="col-lg-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Opening time</p>
                <b>2 – 3 s</b>
              </div>
              <div
                className="col-lg-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>Continuous use</b>
              </div>
            </div>
            <hr
              style={{ margin: 20, borderColor: "#cecece", borderWidth: 5 }}
            ></hr>{" "}
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2">
                <a href="/B680HAutomatic24VHybridBarrier">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={B680HProduct}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product1"
                  />
                </a>
                 <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
              </div>
              <div
                className="col-lg-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/B680HAutomatic24VHybridBarrier">B680H</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>
                  Automatic 24V Hybrid Barrier
                </h4>
              </div>
              <div
                className="col-lg-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max beam length</p>
                <b>2 – 8 m</b>
              </div>
              <div
                className="col-lg-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Opening time</p>
                <b>1,5 – 6 s</b>
              </div>
              <div
                className="col-lg-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>Continuous use</b>
              </div>
            </div>
            <hr
              style={{ margin: 20, borderColor: "#cecece", borderWidth: 5 }}
            ></hr>
          </section>
        </div>
        <div
              class="modal fade"
              id="exampleModal10"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Product Details
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{ padding: 20 }}>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                        <iframe
                          scrolling="no"
                          src="https://forms.zohopublic.in/sales80/form/ContactUs/formperma/o3sKh3PHKyQ5GmkDmlz4rY0NM2edU5IF_pl9MYAjDoA?zf_rszfm=1"
                          id="gform"
                          width="100%"
                          height="990"
                          frameborder="0"
                          marginheight="0"
                          marginwidth="0"
                        >
                          Loading…
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        <footer id="footer"></footer>
      </div>
    );
  }
}
