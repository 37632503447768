import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import product1 from "../FaacProductImages/EXTERNAL_SWING_GATES_OPERATORS.jpg";
import product2 from "../FaacProductImages/SLIDING_GATES_OPERATORS.jpg";
import product3 from "../FaacProductImages/UNDERGROUND_OPERATORS.jpg";
import Header from "../../header";
import { Helmet } from 'react-helmet';

export default function FaacGates() {
  let history = useHistory();

  return (
    <div>
      <div>
      <Helmet>
        <title>FAAC Boom Barrier India | Authorised Distributor | Hyderabad | Gate Motor </title>
        <meta property="og:title" content="FAAC Boom Barrier India | Authorised Distributor | Hyderabad | Gate Motor" />
        <meta property="og:url" content="https://www.gaido.in/faac/products" />
      </Helmet>
        <Header />
        <div
          className="inner-banner"
          style={{ marginBottom: 30, textAlign: "center" }}
        >
          <h2>Most Trusted Supplier across INDIA</h2>
        </div>
      </div>

      <div>
        <section className="product">
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

            <div className="col-lg-9 col-sm-6 col-md-6 col-xs-6">
              <h1
                style={{
                  position: "relative",
                  margin: "20px",
                  fontSize: "35px",
                  lineHeight: "1",
                  dataLineheight: "40px",
                }}
              >
                <b> Automation Systems for Sliding and Swing Gates</b>
              </h1>
              <p
                style={{
                  margin: "20px",
                  fontSize: "15px",
                  display: "inline-block",
                }}
              >
                <span>
                  For more than 50 years, FAAC has been producing automation
                  systems for gates. A wide range of products to transform your
                  gate into a FAAC electric gate. Automated swing gates,
                  underground, external or sliding. Quality, safety and ease of
                  use and installation have always been the main features of all
                  FAAC automation systems for gates.
                </span>
              </p>
            </div>
          </div>
          <div className="row" style={{ margin: "50px" }}>
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div
              className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
              style={{ alignContent: "center", display: "inline" }}
            >
              <div className="card mb-2">
                <img
                  className="card-img-top"
                  width="255"
                  height="250"
                  src={product2}
                  //  onClick={FaacBarriers}
                  onClick={() => {
                    history.push("/underground-operatots");
                  }}
                  style={{ cursor: "pointer" }}
                  alt="Product2"
                />
                <div className="card-body" style={{ textAlign: "center" }}>
                  <h5 className="card-title">Automation for Sliding Gates</h5>
                  <br />
                  <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  >
                    Get Quote
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
              style={{ alignContent: "center", display: "inline" }}
            >
              <div className="card mb-2">
                <img
                  className="card-img-top"
                  width="255"
                  height="250"
                  src={product1}
                  onClick={() => {
                    history.push("/external-swing-gate-operators");
                  }}
                  //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                  style={{ cursor: "pointer" }}
                  alt="Product1"
                />
                <div className="card-body">
                  <h5 className="card-title" style={{ textAlign: "center" }}>
                    External Automations for Swing-Leaf Gates
                  </h5>
                  <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  >
                    Get Quote
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
              style={{ alignContent: "center", display: "inline" }}
            >
              <div className="card mb-2">
                <img
                  className="card-img-top"
                  width="255"
                  height="250"
                  src={product3}
                  onClick={() => {
                    history.push("/sliding-gate-operators");
                  }}
                  //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                  style={{ cursor: "pointer" }}
                  alt="Product1"
                />
                <div className="card-body">
                  <h5 className="card-title" style={{ textAlign: "center" }}>
                    Automations for Swing Leaves with Underground Motor
                  </h5>
                  <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      marginTop: 10,
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  >
                    Get Quote
                  </button>
                </div>
              </div>
            </div>
          
          </div>
        </section>
      </div>
      <div
        class="modal fade"
        id="exampleModal10"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Product Details
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: 20 }}>
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                  <iframe
                    scrolling="no"
                    src="https://forms.zohopublic.in/sales80/form/ContactUs/formperma/o3sKh3PHKyQ5GmkDmlz4rY0NM2edU5IF_pl9MYAjDoA?zf_rszfm=1"
                    id="gform"
                    width="100%"
                    height="990"
                    frameborder="0"
                    marginheight="0"
                    marginwidth="0"
                  >
                    Loading…
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer id="footer"></footer>
    </div>
  );
}
