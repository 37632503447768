import React, { Component } from 'react';

class Doesitreallymatterwhereyousitinameeting extends Component {
  render() {
    return (
      <div>
        <header role="banner">
          <div className="top-bar">
            <div className="container">
              <div className="row">
                <div className="col-6">
                  <a href="/">   <img  className="Gaidoimage"   src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/logo.png" alt="Gaido Technologies Pvt. Ltd." /></a>
                </div>
                <div className="col-6 text-right">
                  <a href="/blog">   <img   className="blogimage" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/blog.png" alt="Gaido Technologies Pvt. Ltd." /></a>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* END header */}
        <section className="site-section py-lg">
          <div className="container">
            <div className="row blog-entries">
              <div className="col-md-12 col-lg-8 main-content">
                <h1 className="mb-4"> Does it really matter where you sit in a meeting?</h1>
                <div className="post-meta">
                  <span className="mr-2">April 07, 2020 </span> 
                </div>
                <div className="post-content-body">
                  <p> Yes it does. Where you sit in a meeting has the equal importance as what you speak in a meeting. Especially if the meeting is with a potential client and you are trying to close a sale.</p>
                  <p>All the companies irrespective of their magnitude have a sales team that brings business to the company. For a start-up a sales team is very vital as their performance directly affects the Growth and development of a company.</p>
                  <p>I have been working with Gaido for quite some time now and there are a lot of intrinsic things that I learnt over the past few months. Working with the founder &amp; CEO and reporting directly to him has given me a lot of exposure and in the process I have learned a few things that are intangible.</p>
                  <p>It is practically not possible to pen them down as there are a lot of things that come with real-time experience, but I’ll give it a shot and try to pass on to you as much as possible: </p>
                  <div className="row mb-5">
                    <div className="col-md-12 mb-4 ">
                         <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/blog9.jpg" alt=" placeholder" className="img-fluid" />
                    </div>
                  </div>
                  <h6>1.	It is really important to choose a right seat in the meeting </h6>
                  <p>Sitting adjacent to the client increases the chances of closure as opposed to sitting across the table, as sitting adjacent has a lot of impact unconsciously and psychologically feels like you are on the same team and sitting across the table denotes you are on opposite sides and whatever you pitch is knocked out of the park.</p>
                  <h6>2.	Being right on time is late! </h6>
                  <p>You need to be on time for a meeting and by on time you mean at least 10 mins early! If you are looking to provide a solution for your client it is important to understand the current process and come up with something that interests your client. To be able to do that, you need to be early and catch the vibe of the place. You need to assess the place and understand what string to pull at the right time.  That is only possible when you’ve done your homework and be on time for the meeting.</p>
                  <p>For instance, I had a meeting set up with a restaurant chain and I reached the place 10 mins early to gauge the environment. They had a tree that was going through the restaurant and looks like it's been preserved with good care. So I flipped my pitch to be as eco-friendly as possible. It helped me convert the sale and maintain a good relation with the client. (By the way, check out our eco-friendly valet parking system at  <a href="/"  target="_blank" rel="noopener noreferrer">www.gaido.in</a>) </p>
                  <div className="row mb-5">
                    <div className="col-md-12 mb-4 ">
                         <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/blog10.jpg" alt=" placeholder" className="img-fluid" />
                    </div>
                  </div>
                  <h6>3.	Be prepared </h6>
                  <p>To close a sale you need to be prepared with all the questions that might pop up on the client’s side. You need to be confident enough to face all the questions, so be prepared about what you are going to sell and how it will benefit the client. What feature would they be most interested in? </p>
                  <p>Always have a smile and give a firm handshake because first impressions last long! </p>
                  <p> If you would like to join the team, contact us with the info on our website:  <a href="/"  target="_blank" rel="noopener noreferrer">www.gaido.in</a></p>
                  <p><a href="/"  target="_blank" rel="noopener noreferrer">   <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/logo.png"  alt="gaidologo" /></a></p>
                </div>
                <div className="pt-5">
                  <p className="categories">Categories:  <a href={() => false}>call, Pointers on meetings, Meeting tips , Client call</a><br />  Tags: <a href={() => false}>#smartparking #sharing #parkingshare #WEB #parkit #technology #innovation #smart #mobilit #mobility #parkingsystems #instaparking #car #newproject #parking #parkingmanagement #parkingsolution #parkingsystem #smartcity #digitalindia #DigitalIndia #DigitalIndiaSummit #IndiaFightsCorona #India #Education #Covid19 #Lockdown #Webinar #Webinars</a></p>
                </div>
              </div>
              {/* END main-content */}
              <div className="col-md-12 col-lg-4 sidebar">
                <div className="sidebar-box">
                  <h3 className="heading">Popular On Gaido</h3>
                  <div className="post-entry-sidebar">
                    <ul>
                      <li>
                        <a href="/blog/gaido-tackles-parking-issues-in-metropolitan-cities">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_26.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>Gaido tackles parking issues in Metropolitan Cities</h4>
                            <div className="post-meta">
                              <span className="mr-2">May 07, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/blog/how-to-park-like-a-pro">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_25.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>How to park like a pro? (Parking Hacks!!)</h4>
                            <div className="post-meta">
                              <span className="mr-2">May 07, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/blog/a-step-into-the-feature-of-valet-parking">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_7.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>A step into the future of valet parking</h4>
                            <div className="post-meta">
                              <span className="mr-2">April 08, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/blog/is-working-for-a-statup-right-after-graduation-a-good-idea">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_4.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>Is working for a startup the right thing to do after graduation?</h4>
                            <div className="post-meta">
                              <span className="mr-2">April 07, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/blog/how-easy-is-it-to-integrate-digital-valet-parking">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_5.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>How easy is it to integrate digital valet parking?</h4>
                            <div className="post-meta">
                              <span className="mr-2">April 07, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/blog/3-steps-for-a-successful-first-sales-meeting">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_9.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>3 pointers for a successful first sales meeting</h4>
                            <div className="post-meta">
                              <span className="mr-2">April 06, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sidebar-box col-md-12">
                  <h3 className="heading">Twitter Tweets</h3>
                  <a className="twitter-timeline" data-height={250} data-theme="dark" href="https://twitter.com/gaidoindia?ref_src=twsrc%5Etfw">Tweets by gaidoindia</a> 
                </div>
                <div className="sidebar-box col-md-12">
                  <h3 className="heading">Facebook Feeds</h3>
                  <iframe title="videotag" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fgaidoindia%2F&tabs=timeline&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=172398636729300" height={250} style={{border: 'none', overflow: 'hidden'}} scrolling="no" frameBorder={0} allowtransparency="true" allow="encrypted-media" /> 
                </div>
              </div>
              {/* END sidebar */}
              <div className="author col-md-12 col-lg-8" style={{padding: '0px'}}>
                <div className="author-slider">
                  <h2 className="auth-head text-center"><br />Author</h2>
                  <div className="col-md-12 row">
                    <div className="col-md-2 auth_img">
                         <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/person_1.jpg" alt=" Placeholder" />
                    </div>
                    <div className="col-md-10">
                      <h2 className="hhdd">Siddharth Kotamraju <a href="mailto:sales@gaido.in" className="p-2"><span className="fa fa-envelope-o" /></a></h2>
                    </div>
                  </div>
                  <p>Having real time experience in providing smart solutions using powerful analytics, I believe smart parking is the next big thing! We at Gaido, thrive to provide sustainable solutions that are eco-friendly and user friendly. Check out this space for updates on smart parking and new innovations that make this world a better place!</p>
                </div>
                <p className="disclaimer"><strong>Disclaimer:-</strong><br /><i> All the information on this website - <a href="/"  target="_blank" rel="noopener noreferrer">www.gaido.in</a> - is published in good faith and for general information purpose only.<a href="/blog/disclaimer"  target="_blank" rel="noopener noreferrer">Click Here</a></i></p>
              </div>
            </div>
          </div>
        </section>
        <footer className="site-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <h3>About Us</h3>
                <p><strong>Gaido Technologies Pvt. Ltd.</strong> is a Hyderabad based Smart Parking Management company.
                  We serve the following parking industry segments – Airports, Municipalities, Hospitals, Hotels, Shopping centers, Educational Institutes, Business Centers, and Sports and Exhibition Venues, with custom tailored and turn-key solutions.</p>
              </div>
              <div className="col-md-7 ml-auto">
                <div className="row">
                  <div className="col-md-6">
                    <h3>Latest Post</h3>
                    <div className="post-entry-sidebar">
                      <ul>
                        <li>
                          <a href="/blog/a-step-into-the-feature-of-valet-parking">
                               <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_1.jpg" alt=" placeholder" className="mr-4" />
                            <div className="text">
                              <h4>A step into the future of valet parking</h4>
                              <div className="post-meta">
                                <span className="mr-2">April 06,2020 </span> 
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="/blog/the-next-big-thing-in-parking-is-here">
                               <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_2.jpg" alt=" placeholder" className="mr-4" />
                            <div className="text">
                              <h4>The next big thing in parking is here!</h4>
                              <div className="post-meta">
                                <span className="mr-2">April 06,2020 </span> 
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-1" />
                  <div className="col-md-5">
                    <div className="mb-5">
                      <h3>Quick Links</h3>
                      <ul className="list-unstyled">
                        <li><a href="/about-company"  target="_blank" rel="noopener noreferrer">About Us</a></li>
                        <li><a href="/blog">Blogs</a></li>
                        <li><a href="/gaido-connect"  target="_blank" rel="noopener noreferrer">Contact Us</a></li>
                        <li><a href="/blog/disclaimer">Disclaimer</a></li>
                      </ul>
                      <h3>Follow Us</h3>
                      <ul className="social-nav">
                        <li>
                          <a href="https://www.facebook.com/gaidoindia"  target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Facebook" alt="Facebook" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/fb1.png" />
                          </a>
                        </li>
                        <li>
                          <a href="https://twitter.com/gaidoindia"  target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Twitter" alt="Twitter" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/tw1.png" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/company/gaidoindia/"  target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Linkedin" alt="Linkedin" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/li1.png" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.pinterest.com/gugaliya0755/"  target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Pinterest" alt="Pinterest" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/pin1.png" />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/gaidoindia/"  target="_blank" rel="noopener noreferrer">   <img width={32} height={32} title="Instagram" alt="Instagram" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimageshttps://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/ins1.png" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center" style={{color: '#fff'}}>
                Copyright Gaido.2019 All Rights Reserved
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default  Doesitreallymatterwhereyousitinameeting ;