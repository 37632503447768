import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';

class BusinessCenters extends Component {
  

    render() {
        return (
            <div>
 <Helmet>
        <title>Business Centers Boom Barriers | Gaido Technologies | Barrier Gate </title>
        <meta property="og:title" content="Business Centers Boom Barriers | Gaido Technologies | Barrier Gate" />
        <meta property="og:url" content="https://www.gaido.in/offerings/business-centers" />
      </Helmet>

                <Header />
                <div className="inner-banner3 business">
          <div className="row">
            <div className="col-md-5">
              <div className="hid-img">
                   <img alt ="gaidologos"src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/business2.jpg" style={{width: '100%'}} />
              </div>
              <h2>Business Centers</h2>
              <p>Parking is often referred to as a trivial chore – the start and finish of an experience, be it a
                trip to a business meeting or a usual workday. If the start of the trip is troublesome and
                difficult, the whole experience can be affected,...</p>
              <p className="read-more"><a href="#about"> READ MORE <i className="fa fa-plus" /> </a></p>
            </div>
          </div>
        </div>
        {/*==========================
    Solutions Section
  ============================*/}
        <section id="about" className="f-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 content offerings2">
                <h1 className="hd2"> Offering for Business Centers</h1>
                <p>Parking is often referred to as a trivial chore – the start and finish of an experience, be it a
                  trip to a business meeting or a usual workday. If the start of the trip is troublesome and
                  difficult, the whole experience can be affected, which has a knock-on effect on the business
                  at large. Therefore, simple and fast automated handling is an advantage, providing flexibility
                  and user-friendliness to the driver.
                </p>
                <p>Every day, parking lots at business centers register a high frequency of drivers that are
                  usually employees, visitors and guests. While managing and satisfying regular users is a
                  critical challenge, providing access to only authorized persons is also of great importance.
                  Clever parking solutions provided by Gaido Technologies can help you to cover these needs.
                </p>
                <p>For regular employees and visitors contactless and hands-free access management using
                  our Ticketless Management System is the apt solution. Pre-authorized entry for others
                  against a fee or free of charge is up to you. Additionally, implementing our smart parking
                  solution into your infrastructure, makes parking a more pleasurable experience for your
                  work family. Intuitive and efficient solutions, reliability and trust are a key part of our
                  business. With its highly scalable Cloud-based SaaS product, particularly customizable for
                  business centers, Gaido Technologies is capable of providing solutions that will help your
                  business’s parking facility accomplish maximum security, profitability, management and
                  control.
                </p>
              </div>
              <p className="read-more" style={{margin: "65px 65px 0px 65px"}}><a href="/products"> VIEW PRODUCTS <i className="fa fa-plus" /> </a></p>

            </div>
          </div>

        </section>
        <Footer />
        <a href={() => false} className="back-to-top"><i className="fa fa-chevron-up" /></a>
            </div>
        );
    }
}

export default BusinessCenters;