import React, { Component } from "react";
import Header from "../header";
import "../FAAC/faacIndex.css";
import { Helmet } from 'react-helmet';

export default class FaacIndex extends Component {
  render() {
    return (
      <div>
        <Helmet>
        <title>FAAC Boom Barrier India | Authorised Distributor | Hyderabad | Gate Motor</title>
        <meta property="og:title" content="FAAC Boom Barrier India | Authorised Distributor | Hyderabad | Gate Motor" />
        <meta property="og:url" content="https://www.gaido.in/faac" />
      </Helmet>
        <Header />
        <div className="landingpage1">
          <div className="row ">
            <div className="col-lg-7 col-sm-7 col-md-7 col-xs-7"></div>
            <div className="col-lg-5 col-sm-5 col-md-5 col-xs-5">
              <div className="rightheading">
                {/* <h1>ALL OUR NUMBERS MEAN WE’RE NUMBER ONE.</h1> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-2 col-sm-2 col-md-2 col-xs-2"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10 ">
              <ul className="counts">
                <li className="lists">
                  {/* <div
                    class="card"
                    style={{ width: "185px", height: 40, textAlign: "center" }}
                  >
                    <ul>
                      <li style={{ backgroundColor: "red" }}>An item</li>
                      <li>A second item</li>
                    </ul>
                  </div> */}
                  <a href="#" data-cmp-ab="2">
                    <img
                      width="185"
                      height="102"
                      src="https://faacindia.com/wp-content/uploads/2014/12/Faac-50-anni-eng-185x102.png"
                      class="attachment-thumbnail_185x102 size-thumbnail_185x102"
                      alt=""
                      decoding="async"
                      srcset="https://faacindia.com/wp-content/uploads/2014/12/Faac-50-anni-eng-185x102.png 185w, https://faacindia.com/wp-content/uploads/2014/12/Faac-50-anni-eng-300x167.png 300w, https://faacindia.com/wp-content/uploads/2014/12/Faac-50-anni-eng.png 378w"
                      sizes="(max-width: 185px) 100vw, 185px"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                  </a>{" "}
                </li>
                <li className="lists">
                  <a href="#" data-cmp-ab="2">
                    <img
                      width="185"
                      height="102"
                      src="https://faacindia.com/wp-content/uploads/2014/12/original-faac-75-brevetti-eng.png20170314-26865-vnr6tw-185x102.png"
                      class="attachment-thumbnail_185x102 size-thumbnail_185x102"
                      alt=""
                      decoding="async"
                      srcset=""
                      sizes="(max-width: 185px) 100vw, 185px"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                  </a>{" "}
                </li>
                <li className="lists">
                  <a href="#" data-cmp-ab="2">
                    <img
                      width="185"
                      height="102"
                      src="https://faacindia.com/wp-content/uploads/2014/12/Faac-2400-persone-eng-185x102.png"
                      class="attachment-thumbnail_185x102 size-thumbnail_185x102"
                      alt=""
                      decoding="async"
                      srcset="https://faacindia.com/wp-content/uploads/2014/12/Faac-2400-persone-eng-185x102.png 185w, https://faacindia.com/wp-content/uploads/2014/12/Faac-2400-persone-eng-300x167.png 300w, https://faacindia.com/wp-content/uploads/2014/12/Faac-2400-persone-eng.png 378w"
                      sizes="(max-width: 185px) 100vw, 185px"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                  </a>{" "}
                </li>
                <li className="lists">
                  <a href="#" data-cmp-ab="2">
                    <img
                      width="185"
                      height="102"
                      src="https://faacindia.com/wp-content/uploads/2014/12/Faac-80-distributori-eng-185x102.png"
                      class="attachment-thumbnail_185x102 size-thumbnail_185x102"
                      alt=""
                      decoding="async"
                      srcset="https://faacindia.com/wp-content/uploads/2014/12/Faac-80-distributori-eng-185x102.png 185w, https://faacindia.com/wp-content/uploads/2014/12/Faac-80-distributori-eng-300x167.png 300w, https://faacindia.com/wp-content/uploads/2014/12/Faac-80-distributori-eng.png 378w"
                      sizes="(max-width: 185px) 100vw, 185px"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                  </a>{" "}
                </li>
                <li className="lists">
                  <a href="#" data-cmp-ab="2">
                    <img
                      width="185"
                      height="102"
                      src="https://faacindia.com/wp-content/uploads/2019/03/Faac-34-aziende-commerciali-eng-185x102.png"
                      class="attachment-thumbnail_185x102 size-thumbnail_185x102"
                      alt=""
                      decoding="async"
                      srcset="https://faacindia.com/wp-content/uploads/2019/03/Faac-34-aziende-commerciali-eng-185x102.png 185w, https://faacindia.com/wp-content/uploads/2019/03/Faac-34-aziende-commerciali-eng-300x167.png 300w, https://faacindia.com/wp-content/uploads/2019/03/Faac-34-aziende-commerciali-eng.png 378w"
                      sizes="(max-width: 185px) 100vw, 185px"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>

          <div className="white-block ">
            <p>Numbers count, and how.</p>
            <p>
              Our milestone 50 years of business, for example, lead us to head
              towards even more ambitious aims and increasingly more significant
              results.
            </p>
            <p>
              For us, having reached them, and for all those who will be able to
              improve their lives thanks to FAAC technology, which, getting back
              to the figures, boasts the highest number of patents: 42
            </p>
            <p>
              Gate automation originates from the intuition of our founder who
              first designed it and developed it. And together with us, remains
              in first place worldwide. And in our hearts.
            </p>
          </div>
        </div>

        <div className="sector2">
          <div className="row ">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

            <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                  <img
                    style={{ margin: "5px" }}
                    src={require("../FAAC/FaacProductImages/img-133.jpg")}
                  ></img>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5 col-sm-5 col-md-5 col-xs-5"></div>
                <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1">
                  <img
                    style={{ margin: "5px" }}
                    src={require("../FAAC/FaacProductImages/img-134.jpg")}
                  ></img>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
                  <img
                    style={{ margin: "5px" }}
                    src={require("../FAAC/FaacProductImages/img-135.jpg")}
                  ></img>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 col-md-4 col-xs-4 ">
              <h1>SOME DREAMS BECOME REALITY UNDER THE SAME SKY FAAC</h1>
              <p>
                FAAC pride goes beyond Italian manufacturing: actually, it’s
                even closer to home. The dream which first opened the doors of
                automation to all originated in the same land that has expanded
                worldwide technological excellence.
              </p>
              <div className="white-block2">
                <h2
                //data-fontsize="16"
                //   style="--fontSize: 16; line-height: 1;"
                //   data-lineheight="16px"
                >
                  We like to think that it is no coincidence.
                </h2>
                <p>
                  We emphasise that the determination of certain dreamers, here
                  in Emilia, has always found fertile ground as well as people
                  who can imagine anything and have tireless arms, always aiming
                  at improving the work that has just been completed.
                </p>
                <p>
                  Clearly, the technology made in Emilia consists of research,
                  quality and innovation but especially of dreams … by people
                  who are on the ball.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="sector3">
          <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">    
              </div>
              <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
              </div>
            </div>
          <div className="row">
            {/* <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1 "></div> */}
            <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">    
              </div>
              <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 ">
              </div>

            <div className="col-lg-5 col-sm-5 col-md-5 col-xs-5 ">

              <div className="white-block3">
              <h1>A GROUP, MANY NUMBERS, ONE GOAL.</h1>
              <br/>

                <h2
                //data-fontsize="16"
                //   style="--fontSize: 16; line-height: 1;"
                //   data-lineheight="16px"
                >
                  We like to think that it is no coincidence.
                </h2>
                <p>
                  1,800 people, 32 subsidiaries in 5 continents, over 80
                  official distributors worldwide: these figures sketch out a
                  business in constant evolution: the FAAC group.
                </p>
                <p>
                  While the technological heart continues to beat in Bologna,
                  FAAC has concentrated the group’s electronics manufacturing in
                  Dublin, creating the necessary synergies to offer products
                  according to FAAC parameters: innovative, reliable and
                  unbeatable.
                </p>
              </div>
            </div>
            <div className="col-lg-7 col-sm-7 col-md-7 col-xs-7">
              <img
                className="img-fluid "
                // style={{margin:"5px"}}
                src={require("../FAAC/FaacProductImages/img-127.jpg")}
              ></img>
            </div>
          </div>
        </div>
        <footer id="footer"></footer>

      </div>
    );
  }
}

{
  /* <div>
<Header />
</div>
<div className="landingpage1">
<div className="row">
  <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10"></div>
  <div className="col-lg-2 col-sm-2 col-md-2 col-xs-2">

  <div className="rightheading">
    <h1>ALL OUR NUMBERS MEAN WE’RE NUMBER ONE.</h1>
  </div>
</div>
</div>
<div className="row">
  <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
  <div className="col-lg-11 col-sm-11 col-md-11 col-xs-11">
    <ul>
      <li>
        <div class="card" style={{ width: "185px",height:40,textAlign:"center" }}>
        <ul>
          <li style={{backgroundColor:"red"}}>An item</li>
          <li >A second item</li>
        </ul>
    </div>
        <a href="#" data-cmp-ab="2">
          <img
            width="185"
            height="102"
            src="https://faacindia.com/wp-content/uploads/2014/12/Faac-50-anni-eng-185x102.png"
            class="attachment-thumbnail_185x102 size-thumbnail_185x102"
            alt=""
            decoding="async"
            srcset="https://faacindia.com/wp-content/uploads/2014/12/Faac-50-anni-eng-185x102.png 185w, https://faacindia.com/wp-content/uploads/2014/12/Faac-50-anni-eng-300x167.png 300w, https://faacindia.com/wp-content/uploads/2014/12/Faac-50-anni-eng.png 378w"
            sizes="(max-width: 185px) 100vw, 185px"
            data-cmp-ab="2"
            data-cmp-info="10"
          />
        </a>{" "}
      </li>
      <li>
        <a href="#" data-cmp-ab="2">
          <img
            width="185"
            height="102"
            src="https://faacindia.com/wp-content/uploads/2014/12/original-faac-75-brevetti-eng.png20170314-26865-vnr6tw-185x102.png"
            class="attachment-thumbnail_185x102 size-thumbnail_185x102"
            alt=""
            decoding="async"
            srcset=""
            sizes="(max-width: 185px) 100vw, 185px"
            data-cmp-ab="2"
            data-cmp-info="10"
          />
        </a>{" "}
      </li>
      <li>
        <a href="#" data-cmp-ab="2">
          <img
            width="185"
            height="102"
            src="https://faacindia.com/wp-content/uploads/2014/12/Faac-2400-persone-eng-185x102.png"
            class="attachment-thumbnail_185x102 size-thumbnail_185x102"
            alt=""
            decoding="async"
            srcset="https://faacindia.com/wp-content/uploads/2014/12/Faac-2400-persone-eng-185x102.png 185w, https://faacindia.com/wp-content/uploads/2014/12/Faac-2400-persone-eng-300x167.png 300w, https://faacindia.com/wp-content/uploads/2014/12/Faac-2400-persone-eng.png 378w"
            sizes="(max-width: 185px) 100vw, 185px"
            data-cmp-ab="2"
            data-cmp-info="10"
          />
        </a>{" "}
      </li>
      <li>
        <a href="#" data-cmp-ab="2">
          <img
            width="185"
            height="102"
            src="https://faacindia.com/wp-content/uploads/2014/12/Faac-80-distributori-eng-185x102.png"
            class="attachment-thumbnail_185x102 size-thumbnail_185x102"
            alt=""
            decoding="async"
            srcset="https://faacindia.com/wp-content/uploads/2014/12/Faac-80-distributori-eng-185x102.png 185w, https://faacindia.com/wp-content/uploads/2014/12/Faac-80-distributori-eng-300x167.png 300w, https://faacindia.com/wp-content/uploads/2014/12/Faac-80-distributori-eng.png 378w"
            sizes="(max-width: 185px) 100vw, 185px"
            data-cmp-ab="2"
            data-cmp-info="10"
          />
        </a>{" "}
      </li>
      <li>
        <a href="#" data-cmp-ab="2">
          <img
            width="185"
            height="102"
            src="https://faacindia.com/wp-content/uploads/2019/03/Faac-34-aziende-commerciali-eng-185x102.png"
            class="attachment-thumbnail_185x102 size-thumbnail_185x102"
            alt=""
            decoding="async"
            srcset="https://faacindia.com/wp-content/uploads/2019/03/Faac-34-aziende-commerciali-eng-185x102.png 185w, https://faacindia.com/wp-content/uploads/2019/03/Faac-34-aziende-commerciali-eng-300x167.png 300w, https://faacindia.com/wp-content/uploads/2019/03/Faac-34-aziende-commerciali-eng.png 378w"
            sizes="(max-width: 185px) 100vw, 185px"
            data-cmp-ab="2"
            data-cmp-info="10"
          />
        </a>{" "}
      </li>
    </ul>
  </div>
</div>

<div class="white-block ">
  <p>Numbers count, and how.</p>
  <p>
    Our milestone 50 years of business, for example, lead us to head
    towards even more ambitious aims and increasingly more significant
    results.
  </p>
  <p>
    For us, having reached them, and for all those who will be able to
    improve their lives thanks to FAAC technology, which, getting back
    to the figures, boasts the highest number of patents: 42
  </p>
  <p>
    Gate automation originates from the intuition of our founder who
    first designed it and developed it. And together with us, remains
    in first place worldwide. And in our hearts.
  </p>
  <p>
    <br></br>
  </p>
</div>
</div> */
}
