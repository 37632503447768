import { db } from "../firebaseConfig";
import {
    collection,
    getDocs,
    getDoc,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
} from "firebase/firestore";
const blogCollectionRef = collection(db, "blogs")
class BlogService {
    addBlog = (newBlog) => {
        return addDoc(blogCollectionRef, newBlog);
    };
    updateBlog = (id, updateBlog) => {
        const blogdoc = doc(db, "blogs", id);
        return updateDoc(blogdoc, updateBlog);
    };
  
    deleteBlog = (id) => {
        const blogdoc =doc(db, "blogs", id); 
            return deleteDoc(blogdoc)
    };
    getAllBlogs=()=>{
        return getDocs(blogCollectionRef)
    }
    getBlog=(id)=>{
        const blogdoc=doc(db,"blogs",id)
        return getDoc(blogdoc)
    }
}
export default new BlogService(); 