import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer';
import DropusLIne from '../GlobalConsants/DropusLIne'
import { Helmet } from 'react-helmet';

class ParkingGuidenceSystem extends Component {

  render() {
    return (
      <div>
        <Helmet>
        <title>Parking Guidance System | Gaido Technologies | Car Park </title>
        <meta property="og:title" content="Parking Guidance System | Gaido Technologies | Car Park" />
        <meta property="og:url" content="https://www.gaido.in/solutions/parking-guidance-system" />
      </Helmet>
        <Header />
        <div className="inner-banner">
          <h2>Parking Guidance system</h2>
        </div>
        {/*==========================
    Solutions Section
  ============================*/}
        <section id="about" className="wow fadeInLeft">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-4">
                <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/parking-guidance.jpg" alt="Gaido Technologies" style={{ width: '100%', marginTop: 15 }} />
              </div>
              <div className="col-lg-8 content">
                <p>This system, having upwards of 99% accuracy,provides convenient and visual guidanceto visitors in your parking lot. The Intelligent Parking Guidance system guides the driver from the roads around the facility, through the process of selecting which parking area to use, to the floor with available parking, then to the aisle with the available parking, and finally to the empty parking spot.</p>
                <p>Each parking spot is installed with a “Ultrasonic Sensor” to detect its parking status. This innovative sensor has a built-in LED indicator to display vacancy and occupancy with simple to understand color coding. Compared with traditional two-piece sensor and LED indicator lights, the sensor we provide, will reduce installation material and labor cost.<b> The visible, high luminosity, LED indicator and LED Guidance Display inside the parking lot will assist drivers to find the closest parking spot available.</b></p>
              </div>
              <div className="col-lg-12 content">
                <p>This solution can be applied to various industry sectors. Some of the most common are Gated Communities, Shopping Malls, Public Parking Lots, Paid Parking Lots, Hospitals, University Campuses.</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-5'></div>
              <div className='col-lg-6'>
                <p className="read-more" style={{ margin: "20px 65px 65px 0px" }}><a href="/products"> VIEW PRODUCTS <i className="fa fa-plus" /> </a></p>
              </div>
          
          </div>
          </div>
         
        </section>{/* #about */}

        {/*==========================
    Offerings Section
  ============================*/}
        <section id="about3" className="wow fadeInLeft">
          <div className="container">
            <div className="section-header">
              <h3>Use Cases</h3>
              <p>Select an industry segment to know more about the application of this solution</p>
            </div>
          </div>
          <div className="row offering-border">
            <div className="col-lg-4 offerings">
              <a href="../../offerings/shopping-centers" style={{ textDecoration: "none" }}>
                <div className="icon-solution">
                  <i className="fa fa-shopping-bag" />
                </div>
                <div className="text-solution">
                  <h5>Shopping Centers</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-4 offerings">
              <a href="../../offerings/hospitals" style={{ textDecoration: "none" }}>
                <div className="icon-solution">
                  <i className="fa fa-plus" />
                </div>
                <div className="text-solution">
                  <h5>Hospitals</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-4 offerings">
              <a href="../../offerings/educational-institutes" style={{ textDecoration: "none" }}>
                <div className="icon-solution">
                  <i className="fa fa-graduation-cap" />
                </div>
                <div className="text-solution">
                  <h5>Educational Institutes</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-4 offerings">
              <a href="../../offerings/residential-area" style={{ textDecoration: "none" }}>
                <div className="icon-solution">
                  <i className="fa fa-home" />
                </div>
                <div className="text-solution">
                  <h5>Residential Area</h5>
                </div>
              </a>
            </div>

          </div>
        </section>{/* #offerings */}
        {/*==========================
      Our Advantages Section
    ============================*/}
        <section id="portfolio" className="wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
          <div className="container">
            <div className="section-header">
              <h2>Advantages</h2>
              <p>We continuously work on ensuring that our solutions provide these benefits </p>
            </div>
          </div>
          <div className="container">
            <div className="row blog">
              <div className="col-md-12">
                <div id="blogCarousel" className="carousel slide" data-ride="carousel">
                  <ol className="carousel-indicators">
                    <li data-target="#blogCarousel" data-slide-to={0} className="active" />
                    <li data-target="#blogCarousel" data-slide-to={1} />
                  </ol>
                  {/* Carousel items */}
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon2.png" /></div>
                          <h4 className="title"><a href={() => false}>Maximize Profitability</a></h4>
                          <ul>
                            <li><i className="icon ion-ios-checkmark-outline" /> Reduce operating costs</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Increase revenue by smart recommendation</li>
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon3.png" /></div>
                          <h4 className="title"><a href={() => false}>Maximize Management</a></h4>
                          <ul>
                            <li><i className="icon ion-ios-checkmark-outline" /> Minimize human error</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Efficient organization of parking</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Data driven decision making</li>
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon5.png" /></div>
                          <h4 className="title"><a href={() => false}>Maximize Customer Experience</a></h4>
                          <ul>
                            <li><i className="icon ion-ios-checkmark-outline" /> Reduces frustration and chaos</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Seamless parking experience</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Additional information for end user</li>
                          </ul>
                        </div>
                      </div>
                      {/*.row*/}
                    </div>
                    {/*.item*/}
                    <div className="carousel-item">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon8.png" /></div>
                          <h4 className="title"><a href={() => false}>Maximize Quality and Control</a></h4>
                          <ul>
                            <li><i className="icon ion-ios-checkmark-outline" /> Industry leading design</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Highly durable materials</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Timely maintenance and support</li>
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon3.png" /></div>
                          <h4 className="title"><a href={() => false}>Maximize Management</a></h4>
                          <ul>
                            <li><i className="icon ion-ios-checkmark-outline" /> Minimize human error</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Efficient organization of parking</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Data driven decision making</li>
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon5.png" /></div>
                          <h4 className="title"><a href={() => false}>Maximize Customer Experience</a></h4>
                          <ul>
                            <li><i className="icon ion-ios-checkmark-outline" /> Reduces frustration and chaos</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Seamless parking experience</li>
                            <li><i className="icon ion-ios-checkmark-outline" /> Additional information for end user</li>
                          </ul>
                        </div>
                      </div>
                      {/*.row*/}
                    </div>
                    {/*.item*/}
                  </div>
                  {/*.carousel-inner*/}
                </div>
                {/*.Carousel*/}
              </div>
            </div>
          </div>
        </section>{/* #portfolio */}
        <DropusLIne />
        <Footer />
        <a href={() => false} className="back-to-top"><i className="fa fa-chevron-up" /></a>
        <div className="bts-popup" role="alert">
          <div className="bts-popup-container">
            <h2>One Tap Connect</h2>
            <div className="col-md-12 row">
              <div className="col-md-4">
                <a href="https://api.whatsapp.com/send?phone=917373735668" target="_blank" rel="noopener noreferrer">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/whatsapp.png" /></a>
                <p className="Wtapp">Whatsapp</p>
              </div>
              <div className="col-md-4">
                <a href="tel:+91 7373735668" target="_blank" rel="noopener noreferrer">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/call.png" /></a>
                <p className="Wtapp">Call</p>
              </div>
              <div className="col-md-4">
                <a href="mailto:info@gaido.in?subject=Gaido Request Call Back&body">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/email.png" /></a>
                <p className="Wtapp">Email</p>
              </div>
            </div>
            <div className="bottom-pop-form">
              <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/callback.png" className="img-responsive" width={200} />
              <form method="POST" action="true"
                id="EnquiryForm">
                <div className="row">
                  <div className="col-md-12">
                    <div className="control-group">
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Your Name" name="name" id="name" required />
                        <p className="help-block" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="controls">
                        <input type="text" className="form-control" placeholder="Your Contact" name="mobile" id="mobile" required />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="controls">
                        <input type="email" className="form-control" placeholder="Your Email" name="email" id="email" required />
                      </div>
                    </div>
                  </div>
                  <div id="success"> </div>
                  {/* For success/fail messages */}
                  <div style={{ textAlign: 'center', width: '100%', marginBottom: '10px' }}>
                    <button type="button" className="btn btn-primary" >Send</button>
                  </div>
                </div>
              </form>
            </div>
            <a href="#0" className="bts-popup-close img-replace">Close</a>
          </div>
        </div>

      </div>
    );
  }
}

export default ParkingGuidenceSystem;