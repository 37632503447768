import React, { Component } from 'react';
class GaidEdge extends Component {

    render() {
        return (
            <section id="portfolio">
            <div className="container">
              <div className="section-header">
                <h2>The Gaido Edge</h2>
                <p>We continuously work on ensuring that our solutions provide these benefits </p>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row justify-content-around">
                <div className="col-md-3  wow fadeInRight adv">
                  <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/park.png" /></div>
                  <h4 className="title"><a href={() => false}>Maximize Security</a></h4>
                  <ul >
                    <li><i className="icon ion-ios-checkmark-outline" /> Real-time parking lot monitoring</li>
                    <li><i className="icon ion-ios-checkmark-outline" /> Authorized access</li>
                    <li><i className="icon ion-ios-checkmark-outline" /> Real-time Alert notification</li>
                  </ul>
                </div>
                <div className="col-md-3  wow fadeInRight adv">
                   <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon2.png" /></div>
                  <h4 className="title"><a href={() => false}>Maximize Profitability</a></h4>
                  <ul style={{marginLeft:19}}>
                    <li><i className="icon ion-ios-checkmark-outline" /> Reduce operating costs</li>
                    <li><i className="icon ion-ios-checkmark-outline" /> Increase revenue by smart recommendation</li>
                  </ul>
                </div>
                <div className="col-md-3  wow fadeInLeft adv">
                  <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon3.png" /></div>
                  <h4 className="title"><a href={() => false}>Maximize Management</a></h4>
                  <ul >
                    <li><i className="icon ion-ios-checkmark-outline" /> Minimize human error</li>
                    <li><i className="icon ion-ios-checkmark-outline" /> Efficient organization of parking</li>
                    <li><i className="icon ion-ios-checkmark-outline" /> Data driven decision making</li>
                  </ul>
                </div>
                <div className="col-md-3  wow fadeInDown adv">
                  <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon4.png" /></div>
                  <h4 className="title"><a href={() => false}>Maximize Control</a></h4>
                  <ul >
                    <li><i className="icon ion-ios-checkmark-outline" /> Real-time information and alerts</li>
                    <li><i className="icon ion-ios-checkmark-outline" /> Dynamic pricing options</li>
                    <li><i className="icon ion-ios-checkmark-outline" /> Authorized entry only</li>
                    <li><i className="icon ion-ios-checkmark-outline" /> Role based asses control</li>
                  </ul>
                </div>
              </div>
              <div className="row justify-content-around">
                <div className="col-md-3  wow fadeInUp adv">
                  <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon5.png" /></div>
                  <h4 className="title"><a href={() => false}>Maximize Customer Experience</a></h4>
                  <ul >
                    <li><i className="icon ion-ios-checkmark-outline" /> Reduces frustration and chaos</li>
                    <li><i className="icon ion-ios-checkmark-outline" /> Seamless parking experience</li>
                    <li><i className="icon ion-ios-checkmark-outline" /> Additional information for end user</li>
                  </ul>
                </div>
                <div className="col-md-3  wow fadeInRight adv">
                  <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon6.png" /></div>
                  <h4 className="title"><a href={() => false}>Maximum Integration</a></h4>
                  <ul >
                    <li><i className="icon ion-ios-checkmark-outline" /> Integrate existing parking equipment</li>
                    <li><i className="icon ion-ios-checkmark-outline" /> Integrate existing software</li>
                    <li><i className="icon ion-ios-checkmark-outline" /> Integrate existing mobile application</li>
                  </ul>
                </div>
                <div className="col-md-3  wow fadeInDown adv">
                  <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon7.png" /></div>
                  <h4 className="title"><a href={() => false}>Maximum Comprehension</a></h4>
                  <ul >
                    <li><i className="icon ion-ios-checkmark-outline" /> Complex data in simple form</li>
                    <li><i className="icon ion-ios-checkmark-outline" /> Interactive dashboards</li>
                    <li><i className="icon ion-ios-checkmark-outline" /> Suggestions backed by data</li>
                  </ul>
                </div>
                <div className="col-md-3  wow fadeInLeft adv">
                  <div className="icon">   <img alt="gaidologos" src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/icon8.png" /></div>
                  <h4 className="title"><a href={() => false}>Maximize Quality and Control</a></h4>
                  <ul >
                    <li><i className="icon ion-ios-checkmark-outline" /> Industry leading design</li>
                    <li><i className="icon ion-ios-checkmark-outline" /> Highly durable materials</li>
                    <li><i className="icon ion-ios-checkmark-outline" /> Timely maintenance and support</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        );
    }
}

export default GaidEdge;