import React, { Component } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import firebaseServices from '../Admin/firebaseServices';
import { Link } from 'react-router-dom';
 import dateFormat from 'dateformat';

 import { Helmet } from 'react-helmet';

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    }
  }
  componentDidMount() {
    this.getBlogs();
  }
  getBlogs = async () => {
    const data = await firebaseServices.getAllBlogs();
    var blog = []
    blog.push(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))

    this.setState({ posts: blog })

  };
  render() {
    return (
      <div>
        <Helmet>
        <title>Blogs | Gaido Technologies | What is Boom Barrier | ANPR | Why | How </title>
        <meta property="og:title" content="Blogs | Gaido Technologies | What is Boom Barrier | ANPR | Why | How" />
        <meta property="og:url" content="https://www.gaido.in/blog" />
      </Helmet>
        <header id="header" style={{ height: '66px' }}>
         
          <div className="top-bar">
            <div className="container">
              <div className="row">
                <div className="col-5">
                  <a href="/">   <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/logo.png" className="Gaidoimage" alt="Gaido Technologies Pvt. Ltd." /></a>
                </div>
                {/* <div className="col-6 text-right">
                  <a href="/blog">   <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/blog.png" className="blogimage" alt="Gaido Technologies Pvt. Ltd." /></a>
                </div> */}
                <div className="col-3">
                </div>
                <div className="col-4 text-right">
                  <nav id="nav-menu-container">
                    <ul className="nav-menu">
                      <li><a href="/products" style={{ textDecoration: "none" }}>Products</a> </li>
                      <li><a href="/admin/addblog" style={{ textDecoration: "none" }}>Post Blog</a> </li>

                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section className="site-section pt-5">
          <div className="row">
            <div className="col-md-12 carsole-blog" style={{
              // display: "flex",
              alignItems: 'center',
              justifyContent: 'center',
              height: "100%",
            }}>
              <Carousel arrows infinite>
                <div className="owl-carousel owl-theme home-slider owl-loaded owl-drag backgroun-Image-contain">
                  <a href="/blog/how-to-increase-the-security-without-increasing-staff" className="a-block d-flex align-items-center height-lg backgroung-carsole " style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_28.jpg")' }}>
                    <div className="text ">
                      <div className="post-meta">
                        <span className="mr-2">June 13, 2020 </span>
                      </div>
                      <h3>How to increase the security without increasing the staff?</h3>
                      <p>Corporate tech parks and commercial complexes have multi-tenants and it becomes difficult to control
                        the access and occupancy of the vehicles that are being parked. </p>
                    </div>
                  </a>
                </div>
                <div className="owl-carousel owl-theme home-slider owl-loaded owl-drag">
                  <a href="/blog/how-is-the-parking-industry-affected-by-covid-19" className="a-block d-flex align-items-center height-lg backgroung-carsole" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_29.jpg")' }}>
                    <div className="text ">
                      <div className="post-meta">
                        <span className="mr-2">May 16, 2020 </span>
                      </div>
                      <h3>Thefts in community increase post covid-19 outbreak! Here’s what you can do..!</h3>
                      <p>As per the government’s order, people need to wear a mask to stop transmitting/ catching the virus.
                        Most security guards will not be able to differentiate between a resident and a non-resident. This is
                        a big threat to your society. </p>
                    </div>
                  </a>
                </div>
                <div className="owl-carousel owl-theme home-slider owl-loaded owl-drag">
                  <a href="/blog/how-is-the-parking-industry-affected-by-covid-19" className="a-block d-flex align-items-center height-lg backgroung-carsole" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_29.jpg")' }}>
                    <div className="text ">
                      <div className="post-meta">
                        <span className="mr-2">May 16, 2020 </span>
                      </div>
                      <h3>How is the parking industry affected by COVID 19?</h3>
                      <p>The global Pandemic has caused a series of events affecting the people and the businesses. All the
                        travel related and parking related businesses are put on hold as people are quarantining and staying
                        put in their homes.</p>
                    </div>
                  </a>
                </div>

                <div className="owl-carousel owl-theme home-slider owl-loaded owl-drag">
                  <a href="/blog/how-to-park-like-a-pro" className="a-block d-flex align-items-center height-lg backgroung-carsole" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_25.jpg")' }}>
                    <div className="text ">
                      <div className="post-meta">
                        <span className="mr-2">May 07, 2020 </span>
                      </div>
                      <h3>How to park like a pro? (Parking Hacks!!)</h3>
                      <p>Post the CoVid-19 lockdown ends all the commercial complexes and businesses will be swamped with
                        people that are quarantining right now.</p>
                    </div>
                  </a>
                </div>
                <div className="owl-carousel owl-theme home-slider owl-loaded owl-drag">
                  <a href="/blog/a-step-into-the-feature-of-valet-parking" className="a-block d-flex align-items-center height-lg backgroung-carsole" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_7.jpg")' }}>
                    <div className="text ">
                      <div className="post-meta">
                        <span className="mr-2">April 08, 2020 </span>
                      </div>
                      <h3>A step into the future of valet parking</h3>
                      <p>Valet parking is a parking service offered by some hotels, restaurants, stores and other businesses
                        and events.</p>
                    </div>
                  </a>
                </div>
                <div className="owl-carousel owl-theme home-slider owl-loaded owl-drag">
                  <a href="/blog/is-working-for-a-statup-right-after-graduation-a-good-idea" className="a-block d-flex align-items-center height-lg backgroung-carsole" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_4.jpg")' }}>
                    <div className="text ">
                      <div className="post-meta">
                        <span className="mr-2">April 07, 2020 </span>
                      </div>
                      <h3> Is working for a startup the right thing to do after graduation?</h3>
                      <p>Start-ups are really taking off around the world, especially in India at great speeds. There are over
                        50,000 start-ups in India right now and the number just keeps rising. </p>
                    </div>
                  </a>
                </div>
                <div className="owl-carousel owl-theme home-slider owl-loaded owl-drag">
                  <a href="/blog/how-easy-is-it-to-integrate-digital-valet-parking" className="a-block d-flex align-items-center height-lg backgroung-carsole" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_5.jpg")' }}>
                    <div className="text ">
                      <div className="post-meta">
                        <span className="mr-2">April 07, 2020 </span>
                      </div>
                      <h3>How easy is it to integrate digital valet parking?</h3>
                      <p>Gaido technologies has introduced a digital valet management platform through which the customer
                        receives an SMS instead of a physical valet token. </p>
                    </div>
                  </a>
                </div>
                <div className="owl-carousel owl-theme home-slider owl-loaded owl-drag">
                  <a href="/blog/3-steps-for-a-successful-first-sales-meeting" className="a-block d-flex align-items-center height-lg backgroung-carsole" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_9.jpg")' }}>
                    <div className="text ">
                      <div className="post-meta">
                        <span className="mr-2">April 08, 2020 </span>
                      </div>
                      <h3>3 pointers for a successful first sales meeting</h3>
                      <p>Converting a new prospect into your client can be a tough job. You need to understand that all the
                        prospects inevitably have a distrust towards the person trying to make a sale as they may be busy or
                        merely not interested in what you sell. </p>
                    </div>
                  </a>
                </div>
              </Carousel>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {/* <div className="col-md-6 col-lg-4">
                <a href="/blog/how-to-increase-the-security-without-increasing-staff" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_28.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">June 13, 2020 </span>
                    </div>
                    <h3>How to increase the security without increasing the staff?</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 121</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 444</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 92</span>
                    </div>

                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/thefts-in-community-increase-post-covid-19-outbreak" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_27.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">June 13, 2020 </span>
                    </div>
                    <h3>Thefts in community increase post covid-19 outbreak! Here’s what you can do..!</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 121</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 444</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 92</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/how-is-the-parking-industry-affected-by-covid-19" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_29.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">May 16, 2020 </span>
                    </div>
                    <h3>How is the parking industry affected by COVID 19?</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 121</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 444</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 92</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/post-lockdown-how-to-run-your-business" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_20.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">May 13, 2020 </span>
                    </div>
                    <h3>Post lockdown: How to run your business?</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 52</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 75</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 92</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/gaido-tackles-parking-issues-in-metropolitan-cities" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_26.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">May 07, 2020 </span>
                    </div>
                    <h3>Gaido tackles parking issues in Metropolitan Cities</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 47</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 49</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 44</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/how-to-park-like-a-pro" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_25.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">May 07, 2020 </span>
                    </div>
                    <h3>How to park like a pro? (Parking Hacks!!)</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 78</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 88</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 77</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/how-did-i-improve-my-efficiency-working-from-home" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_21.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">April 29, 2020 </span>
                    </div>
                    <h3>Improve efficiency by working from home- here’s how!</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 85</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 77</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 58</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/facebook-invests-5.7-billion-in-jio" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_18.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">April 22, 2020 </span>
                    </div>
                    <h3>Facebook invests $5.7 billion in JIO</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 50</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 50</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 50</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/how-to-ensure-your-car-is-fine-during-the-lockdown" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_17.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">April 22, 2020 </span>
                    </div>
                    <h3> You are safe during lockdown, what about your car? Here’s what you need to do immediately!</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 102</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 100</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 100</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/one-thing-you-can-do-to-lure-customers-after-the-lockdown" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_16.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">April 17, 2020 </span>
                    </div>
                    <h3>One thing you can do to lure customers post lockdown?</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 98</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 100</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 99</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/what-is-the-telangana-parking-policy" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_12.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">April 14, 2020 </span>
                    </div>
                    <h3>How can the Telangana Parking Policy help you?</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 87</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 90</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 85</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/what-is-startup-india" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_13.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">April 14, 2020 </span>
                    </div>
                    <h3> STARTUP INDIA- If you don’t register, you cannot claim the benefits </h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 77</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 85</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 45</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/new-technologies-and-their-impact-on-the-future" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_14.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">April 14, 2020 </span>
                    </div>
                    <h3>New technologies and their impact on the future!</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 554</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 650</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 400</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/a-step-into-the-feature-of-valet-parking" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_7.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">April 08, 2020 </span>
                    </div>
                    <h3>A step into the future of valet parking</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 111</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 99</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 88</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/the-next-big-thing-in-parking-is-here" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_2.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">April 08, 2020 </span>
                    </div>
                    <h3>The next big thing in parking is here!</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 210</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 108</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 188</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/we-keep-our-consumer-happy" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_3.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">April 07, 2020 </span>
                    </div>
                    <h3>We keep our Consumer Happy</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 95</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 105</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 66</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/is-working-for-a-statup-right-after-graduation-a-good-idea" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_4.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">April 07, 2020 </span>
                    </div>
                    <h3> Is working for a startup the right thing to do after graduation?</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 83</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 96</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 22</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/how-easy-is-it-to-integrate-digital-valet-parking" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_5.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">April 07, 2020 </span>
                    </div>
                    <h3> How easy is it to integrate digital valet parking?</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 70</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 84</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 11</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/does-it-really-matter-where-you-sit-in-a-meeting" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_8.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">April 07, 2020 </span>
                    </div>
                    <h3>Does it really matter where you sit in a meeting?</h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 40</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 40</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 30</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/3-steps-for-a-successful-first-sales-meeting" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_9.jpg")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">April 08, 2020 </span>
                    </div>
                    <h3>3 pointers for a successful first sales meeting </h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 110</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 110</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 50</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-6 col-lg-4">
                <a href="/blog/car-flood-water-in-rain-What-to-do" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url("https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/pic")' }}>
                  <div className="text">
                    <div className="post-meta">
                      <span className="mr-2">October 13, 2020 </span>
                    </div>
                    <h3>Cars flooded in rain water? What to do? </h3>
                    <div className="post-meta">
                      <span className="ml-2"><span className="fa fa-comments" /> 25</span>
                      <span className="ml-2"><span className="fa fa-eye" /> 40</span>
                      <span className="ml-2"><span className="fas fa-thumbs-up" /> 28</span>
                    </div>
                  </div>
                </a>
              </div> */}

              {this.state.posts.map((doc, i) => {
                return (

                  doc.map((item, i) => {
                    var blogid = item.id
                    return (
                      <div className="col-md-6 col-lg-4">
                        <Link to={"/blogs/" + blogid} href="#title" className="a-block d-flex align-items-center height-md" style={{ textDecoration: "none", backgroundImage: 'url(' + doc[i].imageurl + ')' }}>
                          <div className="text">
                            <div className="post-meta">
                              <span className="mr-2">{dateFormat(doc[i].date, "mmm d, yyyy")}</span>
                            </div>
                            <h3>{item.title}</h3>
                            <div className="post-meta">
                              <span className="ml-2"><span className="fa fa-comments" />{doc[i].comments}</span>
                              <span className="ml-2"><span className="fa fa-eye" />{doc[i].views}</span>
                              <span className="ml-2"><span className="fas fa-thumbs-up" />{doc[i].likes}</span>
                              <span className="ml-4"></span>
                              <span className="ml-4"></span>
                              <span className="ml-4"></span>
                              <span className="ml-4"> </span>

                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  })
                );
              })}

            </div>
          </div>
        </section>
        {/* END section */}
        <section className="site-section py-sm">
          <div className="container">
            <div className="row blog-entries">
              {/* <div className="col-md-12 col-lg-1 main-content"> */}
              {/* <div className="row"> */}
              {/* <div className="col-md-12 text-center"> */}
              {/* <nav aria-label="Page navigation" className="text-center"> */}
              {/* <ul className="pagination"> */}
              {/* <li className="page-item  active"><a className="page-link" href={() => false}>Prev</a></li> */}
              {/* <li className="page-item"><a className="page-link" href={() => false}>1</a></li> */}
              {/* <li className="page-item"><a className="page-link" href={() => false}>2</a></li> */}
              {/* <li className="page-item"><a className="page-link" href={() => false}>3</a></li> */}
              {/* <li className="page-item"><a className="page-link" href={() => false}>Next</a></li> */}
              {/* </ul> */}
              {/* </nav> */}
              {/* </div> */}
              {/* </div> */}
              {/* </div> */}
              {/* END main-content */}
              <div className="col-md-12 col-lg-12 sidebar row">
                {/* <div className="sidebar-box col-md-6">
                  <h3 className="heading">Twitter Tweets</h3>
                  <a className="twitter-timeline" data-height={450} data-theme="dark" href="https://twitter.com/gaidoindia?ref_src=twsrc%5Etfw">Tweets by gaidoindia</a>
                </div> */}
                <div className="sidebar-box col-md-6">
                  <h3 className="heading">Twitter Tweets</h3>
                  <a className="twitter-timeline" data-height="450" data-theme="dark"
                    href="https://twitter.com/gaidoindia?ref_src=twsrc%5Etfw">Tweets by gaidoindia</a>


                </div>
                <div className="sidebar-box col-md-6">
                  <h3 className="heading">Facebook Feeds</h3>
                  <iframe title="videotag" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fgaidoindia%2F&tabs=timeline&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=172398636729300" height={450} style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameBorder={0} allowtransparency="true" allow="encrypted-media" />
                </div>
                {/* END sidebar-box */}
              </div>
              {/* END sidebar */}
            </div>
          </div>
        </section>
        <footer className="site-footer">
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-5">
                <h3>About Us</h3>
                <p><strong>Gaido Technologies Pvt. Ltd.</strong> is a Hyderabad based Smart Parking Management company.
                  We serve the following parking industry segments – Airports, Municipalities, Hospitals, Hotels, Shopping
                  centers, Educational Institutes, Business Centers, and Sports and Exhibition Venues, with custom tailored
                  and turn-key solutions.</p>
              </div>
              <div className="col-md-7 ml-auto">
                <div className="row">
                  <div className="col-md-7">
                    <h3>Latest Post</h3>
                    <div className="post-entry-sidebar">
                      <ul>
                        <li>
                          <a href="/blog/a-step-into-the-feature-of-valet-parking">
                            <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_1.jpg" alt=" placeholder" className="mr-4" />
                            <div className="text">
                              <h4>A step into the future of valet parking</h4>
                              <div className="post-meta">
                                <span className="mr-2">April 06,2020 </span>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="/blog/the-next-big-thing-in-parking-is-here">
                            <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_2.jpg" alt=" placeholder" className="mr-4" />
                            <div className="text">
                              <h4>The next big thing in parking is here!</h4>
                              <div className="post-meta">
                                <span className="mr-2">April 06,2020 </span>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-1" />
                  <div className="col-md-4">
                    <div className="mb-5">
                      <h3>Quick Links</h3>
                      <ul className="list-unstyled">
                        <li><a href="/about-company/" target="_blank" rel="noopener noreferrer">About Us</a></li>
                        <li><a href="/blog">Blogs</a></li>
                        <li><a href="/gaido-connect/" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
                        <li><a href="/blog/disclaimer">Disclaimer</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center" style={{ color: '#fff' }}>
                Copyright Gaido.2019 All Rights Reserved
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default index;
