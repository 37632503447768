import React, { Component } from 'react';

class Whatisthetelanganaparkingpolicy extends Component {
  render() {
    return (
      <div>
           <header role="banner">
          <div className="top-bar">
            <div className="container">
              <div className="row">
                <div className="col-6">
                  <a href="/">   <img  className="Gaidoimage"   src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/logo.png" alt="Gaido Technologies Pvt. Ltd." /></a>
                </div>
                <div className="col-6 text-right">
                  <a href="/blog">   <img   className="blogimage" src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/blog.png" alt="Gaido Technologies Pvt. Ltd." /></a>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* END header */}
        <section className="site-section py-lg">
          <div className="container">
            <div className="row blog-entries">
              <div className="col-md-12 col-lg-8 main-content">
                <h1 className="mb-4"> Cars flooded in rain water? What to do?  </h1>
                <div className="post-meta">
                  <span className="mr-2">October 13, 2020 </span> 
                </div>
                <div className="post-content-body">
                  <p>Heavy rains often result in flooding in basement parking lots of apartment complexes and commercial spaces. These car parking spaces are an important component of modern infrastructure and need to be designed accordingly</p>
                  <h6>But what should you do if your car is immersed into such water? </h6>
                  <div className="row mb-5">
                    <div className="col-md-12 mb-4 ">
                         <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/Picture2.png" alt=" placeholder" className="img-fluid" />
                    </div>
                  </div>
                  <h6>Here are a few tips that will help you in making the right decisions:</h6>
                  <h6> DO NOT START YOUR CAR! </h6>
                  <p> It may be tempting to turn the key/push button to see if your car still works, but if there is water in the engine, attempting to start it could damage it beyond repair. </p>
                  <p>Follow the rest of the steps below before you attempt to drive your car again.</p>
                  <h6>CALL YOUR INSURANCE COMPANY </h6>
                  <p>Irrespective of the extent of the flood damage, you should call your insurance company, as flood damage is generally covered by comprehensive (fire and theft) insurance, so even if you don't have collision coverage, you may be covered for whatever repairs or replacements you'll encounter.  </p>
                  <p> Quick tip: Start the process ASAP as the insurance company would be flooded with claims.</p>
                  <h6>  INSPECT THE CONDITION OF YOUR CAR</h6>
                  <p>Mud and debris usually leave a waterline on the car, inside as well as out. Most insurance companies will consider the car totalled (damaged beyond economically-reasonable repair) if water reaches to the bottom of the dashboard or higher. If this is the case, then have your car towed to a mechanic for a professional assessment.</p>
                  <p>Remember, these repairs are likely to be covered by your comprehensive insurance.</p>
                  <h6> CHECK AROUND THE WHEELS/TYRES </h6>
                  <p>Before attempting to drive the car, set the parking brake and look for debris lodged around the wheels, brakes, and underbody. Even a small stick or clump of dried mud can make the car shimmy and shake once you get it up to speed. </p>
                  <p> Remember, these repairs are likely to be covered by your comprehensive insurance.</p>
                  <h6>CHECK THE OIL AND AIR CLEANER </h6>
                  <p>If you see droplets of water on the dipstick or the level of the oil is high, or if the air filter has water in it, do not attempt to start the engine. Have it towed to a mechanic to have the water cleared and the fluids changed.</p>
                  <p>If necessary, take a high-spray hose and clean in and around the wheels thoroughly, but be careful not to wet the engine and interior.</p>
                  <h6> CHECK ELECTRICAL SYSTEMS </h6>
                  <p>Lastly, check everything electrical. Start your car, then move through each electrical component one by one: Headlights, indicator signals, air conditioning, stereo, power locks, windows and seats, and interior lights. If you note anything even slightly amiss—including the way the car runs or the gear shifts—that could be a sign of electrical trouble.  </p>
                  <p>Take the car to a mechanic, and remember that the damage may be covered by insurance.</p>
                  <h6> DRY THE INTERIOR </h6>
                  <p>Start by opening the doors and windows and putting towels on the floor to soak up water, but you should plan on replacing anything that got wet, including carpets, floor mats, door panels, seat padding, and upholstery. </p>
                  <p> Remember, these repairs are likely to be covered by your comprehensive insurance.</p>
                  <h6> DRY THE INTERIOR </h6>
                  <p>Start by opening the doors and windows and putting towels on the floor to soak up water, but you should plan on replacing anything that got wet, including carpets, floor mats, door panels, seat padding, and upholstery. </p>
                  <p> Remember, these repairs are likely to be covered by your comprehensive insurance.</p>
                  <p>
                  That's it! These preventive measures before you start your car may take some of your time but, will save more trouble. Check your oil as often as you like. Once a month or so is good for a car that is in decent shape. Taking your car for a service regularly is also a good idea as experts will take a look and give you suggestions accordingly.
                  </p>
                  <h6>
                  Follow this space for updates related to parking in India.
                  </h6>
                  <p>
                  We at Gaido would love to provide you with information and solutions pertaining to the parking and would ask you to follow us on social media<a href="mailto:sales@gaido.in" className="p-2">@gaidoindia</a>for latest updates and innovations in our Industry.
                  </p>
                  <p><a href="/"  target="_blank" rel="noopener noreferrer">   <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/logo.png"  alt="gaidologo" /></a></p>
                </div>
                <div className="pt-5">
                  <p className="categories">Categories:  <a href={() => false}>call, Pointers on meetings, Meeting tips , Client call</a><br />  Tags: <a href={() => false}>#smartparking #sharing #parkingshare #WEB #parkit #technology #innovation #smart #mobilit #mobility #parkingsystems #instaparking #car #newproject #parking #parkingmanagement #parkingsolution #parkingsystem #smartcity #digitalindia #DigitalIndia #DigitalIndiaSummit #IndiaFightsCorona #India #Education #Covid19 #Lockdown #Webinar #Webinars</a></p>
                </div>
              </div>
              {/* END main-content */}
              <div className="col-md-12 col-lg-4 sidebar">
                <div className="sidebar-box">
                  <h3 className="heading">Popular On Gaido</h3>
                  <div className="post-entry-sidebar">
                    <ul>
                      <li>
                        <a href="/blog/gaido-tackles-parking-issues-in-metropolitan-cities">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_26.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>Gaido tackles parking issues in Metropolitan Cities</h4>
                            <div className="post-meta">
                              <span className="mr-2">May 07, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/blog/how-to-park-like-a-pro">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_25.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>How to park like a pro? (Parking Hacks!!)</h4>
                            <div className="post-meta">
                              <span className="mr-2">May 07, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/blog/a-step-into-the-feature-of-valet-parking">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_7.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>A step into the future of valet parking</h4>
                            <div className="post-meta">
                              <span className="mr-2">April 08, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/blog/is-working-for-a-statup-right-after-graduation-a-good-idea">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_4.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>Is working for a startup the right thing to do after graduation?</h4>
                            <div className="post-meta">
                              <span className="mr-2">April 07, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/blog/how-easy-is-it-to-integrate-digital-valet-parking">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_5.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>How easy is it to integrate digital valet parking?</h4>
                            <div className="post-meta">
                              <span className="mr-2">April 07, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/blog/3-steps-for-a-successful-first-sales-meeting">
                             <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/img_9.jpg" alt=" placeholder" className="mr-4" />
                          <div className="text">
                            <h4>3 pointers for a successful first sales meeting</h4>
                            <div className="post-meta">
                              <span className="mr-2">April 06, 2020 </span>
                            </div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="sidebar-box col-md-12">
                  <h3 className="heading">Twitter Tweets</h3>
                  <a className="twitter-timeline" data-height={250} data-theme="dark" href="https://twitter.com/gaidoindia?ref_src=twsrc%5Etfw">Tweets by gaidoindia</a> 
                </div>
                <div className="sidebar-box col-md-12">
                  <h3 className="heading">Facebook Feeds</h3>
                  <iframe title="videotag" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fgaidoindia%2F&tabs=timeline&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=172398636729300" height={250} style={{border: 'none', overflow: 'hidden'}} scrolling="no" frameBorder={0} allowtransparency="true" allow="encrypted-media" /> 
                </div>
              </div>
              {/* END sidebar */}
              <div className="author col-md-12 col-lg-8" style={{padding: '0px'}}>
                <div className="author-slider">
                  <h2 className="auth-head text-center"><br />Author</h2>
                  <div className="col-md-12 row">
                    <div className="col-md-2 auth_img">
                         <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/blogimages/person_1.jpg" alt=" Placeholder" />
                    </div>
                    <div className="col-md-10">
                      <h2 className="hhdd">Siddharth Kotamraju <a href="mailto:sales@gaido.in" className="p-2"><span className="fa fa-envelope-o" /></a></h2>
                    </div>
                  </div>
                  <p>Having real time experience in providing smart solutions using powerful analytics, I believe smart parking is the next big thing! We at Gaido, thrive to provide sustainable solutions that are eco-friendly and user friendly. Check out this space for updates on smart parking and new innovations that make this world a better place!</p>
                </div>
                <p className="disclaimer"><strong>Disclaimer:-</strong><br /><i> All the information on this website - <a href="/"  target="_blank" rel="noopener noreferrer">www.gaido.in</a> - is published in good faith and for general information purpose only.<a href="/blog/disclaimer"  target="_blank" rel="noopener noreferrer">Click Here</a></i></p>
              </div>
            </div>
          </div>
        </section>
        <footer id="footer">
                    <div className="row px-sm-5">
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-6 col-sm-2 border-rg">
                                    <div className="mb-4">
                                        <h5 className="h5 font-weight-bold color-blue-2">
                                            <a  href="/about-company" >Company</a>
                                            </h5>
                                         <ul className="footer-social list-unstyled">
                                          <li className="footer-item"><a className="footer-link font-14" href="/about-company">About Us</a>
                                            </li>
                                            <li className="footer-item">
                                                <a className="footer-link font-14" href="/about-company">The team</a>
                                            </li>
                                            <li className="footer-item">
                                                <a className="footer-link font-14" href="/gaido-connect">Contact Us</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4 border-rg">
                                    <div className="mb-4">
                                        <h5 className="h5 font-weight-bold color-blue-2"><a href="/solutions">Solutions</a></h5>
                                        <ul className="footer-social list-unstyled">
                                            <li className="footer-item">
                                                <a className="footer-link font-14" href="/solutions/ticketless-parking-management">Ticketless Parking
                        Management</a>
                                            </li>
                                            <li className="footer-item">
                                                <a className="footer-link font-14" href="/solutions/parking-guidance-system">Parking Guidance
                        system</a>
                                            </li>
                                            <li className="footer-item">
                                                <a className="footer-link font-14" href="/solutions/valet-parking-system">Valet Parking system</a>
                                            </li>
                                            <li className="footer-item">
                                                <a className="footer-link font-14" href="/solutions/entry-gate-system">Entry Gate System</a>
                                            </li>
                                            <li className="footer-item">
                                                <a className="footer-link font-14" href="/solutions/cloud-based-analytics-platform">Cloud-Based
                        Analytics Platform</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-2">
                                    <div className="mb-4 offe">
                                        <h5 className="h5 font-weight-bold color-blue-2 "><a href="/offerings">Offerings</a></h5>
                                        <ul className="footer-social list-unstyled">
                                            <li className="footer-item">
                                                <a className="footer-link font-14" href="/offerings/airports">Airports</a>
                                            </li>
                                            <li className="footer-item">
                                                <a className="footer-link font-14" href="/offerings/business-centers">Business Centers</a>
                                            </li>
                                            <li className="footer-item">
                                                <a className="footer-link font-14" href="/offerings/hospitals">Hospitals</a>
                                            </li>
                                            <li className="footer-item">
                                                <a className="footer-link font-14" href="/offerings/hotels">Hotels</a>
                                            </li>
                                            <li className="footer-item">
                                                <a className="footer-link font-14" href="/offerings/municipalities">Municipalities</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4  border-rg">
                                    <div className="mb-4 cdc">
                                        <h5 className="h5 font-weight-bold color-blue-2 "><a href="/offerings"><br /></a></h5>
                                        <ul className="footer-social list-unstyled">
                                            <li className="footer-item">
                                                <a className="footer-link font-14" href="/offerings/educational-institutes">Educational Institutes</a>
                                            </li>
                                            <li className="footer-item">
                                                <a className="footer-link font-14" href="/offerings/residential-area">Residential Area</a>
                                            </li>
                                            <li className="footer-item">
                                                <a className="footer-link font-14" href="/offerings/shopping-centers">Shopping Centers</a>
                                            </li>
                                            <li className="footer-item">
                                                <a className="footer-link font-14" href="/offerings/sports-exhibitions">Sports and Exhibition
                        Venues</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-12 col-sm-12 footer-offices">
                                    <div className="mb-4">
                                        <h5 className="h5 font-weight-bold color-blue-2"><a href="/gaido-connect">Corporate Head Office</a></h5>
                                        <ul className="footer-social list-unstyled">
                                            <li className="footer-item font-14">#682, 3rd Floor, Babukhan Rasheed Plaza, Road No. 36, Jubilee Hills,
                      Hyderabad, TS -500033 INDIA</li><br />
                                            <li className="footer-item font-14"><a href="mailto:info@gaido.in"><i className="fa fa-envelope-o" style={{ paddingRight: '10px' }} />info@gaido.in</a></li><br />
                                            <li className="footer-item font-14"><a href="tel:+91 7373735668"><i className="fa fa-phone" style={{ paddingRight: '10px' }} /> +91 7373735668</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <hr style={{ borderColor: '#ACC1D4' }} />
                        </div>
                    </div>
                    <div className="copyright">
                        © Copyright <strong> Gaido</strong>.2021 All Rights Reserved
                 </div>
                </footer>
      </div>
    );
  }
}

export default Whatisthetelanganaparkingpolicy;