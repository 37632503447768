import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer';
import DropusLIne from '../GlobalConsants/DropusLIne'
import Advantages from '../GaidoEdge/Advantages'
import { Helmet } from 'react-helmet';

class TicketlessParkingManagment extends Component {

  render() {
    return (
      <div>
         <Helmet>
        <title>Ticketless Parking Management | Gaido Technologies </title>
        <meta property="og:title" content="Ticketless Parking Management | Gaido Technologies" />
        <meta property="og:url" content="https://www.gaido.in/solutions/ticketless-parking-management" />
      </Helmet>
        <Header />
        <div className="inner-banner">
          <h2>Ticketless Management System</h2>
        </div>
        <section id="about" className="wow fadeInLeft">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-4">
                <img src="https://gaidoweb.s3.us-east-2.amazonaws.com/img/Ticketless.jpg" alt="Gaido Technologies" style={{ width: '100%' ,marginTop:15}} />
              </div>
              <div className="col-lg-8 content">
                <p>In this system, artificial intelligence based ANPR(Automatic Number Plate Recognition) technology is used to manage boom barriers and gates, making your parking lot a generation smarter.</p>
                <p>The system requires us to install a ANPR enabled smart camera at both the entry and exit lane of the parking lot. The ANPR cameras, on arrival and exit of a vehicle, captures the license plate number of the vehicle thereby allowing the vehicle to enter or exit without the need for one to stop at the entry/exit point.</p>
                <p>This system can be configured to allow/deny the vehicle entering / leaving the parking lot, allowing the facility to have at most security and pre-approved access only.</p>
              </div>
              <div className="col-lg-12 content">
                <p>Furthermore, <b>this process allows the drivers to enter and exit without having the hassle of using a ticket or physically verifying themselves.</b> This overall enables the flow in the parking lot to move smoothly and at a pace that is relatively faster by a significant rate.</p>
                <p>This solution can be applied to various industry sectors. Some of the most common are IT parks, corporate offices, coworking spaces, public parking lots, paid parking lots, shopping malls, Residential apartments, Hospitals, government facilities, university campuses.</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-5'></div>
              <div className='col-lg-6'>
                <p className="read-more" style={{ margin: "20px 65px 65px 0px" }}><a href="/products"> VIEW PRODUCTS <i className="fa fa-plus" /> </a></p>
              </div>
          
          </div>
          </div>
        </section>

        <section id="about3" className="wow fadeInLeft">
          <div className="container">
            <div className="section-header">
              <h3>Use Cases</h3>
              <p>Select an industry segment to know more about the application of this solution</p>
            </div>
          </div>
          <div className="row offering-border">
            <div className="col-lg-3 offerings">
              <a href="../../offerings/business-centers" style={{ textDecoration: "none" }}>
                <div className="icon-solution">
                  <i className="fa fa-briefcase" />
                </div>
                <div className="text-solution">
                  <h5>Business Centers</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 offerings">
              <a href="../../offerings/shopping-centers" style={{ textDecoration: "none" }}>
                <div className="icon-solution">
                  <i className="fa fa-shopping-bag" />
                </div>
                <div className="text-solution">
                  <h5>Shopping Centers</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 offerings">
              <a href="../../offerings/residential-area" style={{ textDecoration: "none" }}>
                <div className="icon-solution">
                  <i className="fa fa-home" />
                </div>
                <div className="text-solution">
                  <h5>Residential Area</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 offerings">
              <a href="../../offerings/hospitals" style={{ textDecoration: "none" }}>
                <div className="icon-solution">
                  <i className="fa fa-plus" />
                </div>
                <div className="text-solution" >
                  <h5>Hospitals</h5>
                </div>
              </a>
            </div>
            <div className="col-lg-3 offerings">
              <a href="../../offerings/educational-institutes" style={{ textDecoration: "none" }}>
                <div className="icon-solution">
                  <i className="fa fa-graduation-cap" />
                </div>
                <div className="text-solution">
                  <h5>Educational Institutes</h5>
                </div>
              </a>
            </div>
          </div>
        </section>
        <Advantages />
        <DropusLIne />
        <Footer />
        <a href={() => false} className="back-to-top"><i className="fa fa-chevron-up" /></a>
      </div>
    );
  }
}
export default TicketlessParkingManagment;