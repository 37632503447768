import React from 'react'
import { Redirect, Route } from 'react-router-dom'
const PrivateRoute = ({ component: Component, isAuth, role, ...rest }) => {
  let isLoggedIn;
  let checkrole;
  if (window.localStorage.getItem('isAuth')) {
    
    isLoggedIn = true
  } else {
    isLoggedIn = false
  }


  return (
    <Route
      {...rest}
    
      render={props =>
        isLoggedIn ? (
          <Component {...props} key={localStorage.getItem('isAuth')} />
        ) : (
          <Redirect to="/admin/login"/>
        )
      }
    />
  )
}

export default PrivateRoute