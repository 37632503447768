import React, { Component } from "react";
import "../../faac.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Header from "../../../header";
import { Helmet } from 'react-helmet';


const product1 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SildingGateProducts/106752_boxed.jpg";
const product2 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SildingGateProducts/106752_boxed.jpg";
const product3 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SildingGateProducts/108720_boxed.jpg";
const product4 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SildingGateProducts/108800_boxed.jpg";
const product5 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SildingGateProducts/S2500I_boxed.jpg";

export default class SlidingGateOperators extends Component {
  render() {
    return (
      <div>
        <Helmet>
        <title>Automatic Sliding Gate Operator | Quick Service | Remote, Automation | FAAC </title>
        <meta property="og:title" content="Automatic Sliding Gate Operator | Quick Service | Remote, Automation | FAAC" />
        <meta property="og:url" content="https://www.gaido.in/sliding-gate-operators" />
      </Helmet>
        <div>
          <Header />
        </div>

        <div>
          <section className="product" style={{ marginTop: 100 }}>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-9 col-sm-9 col-md-9 col-xs-9">
                <h1
                  style={{
                    position: "relative",
                    margin: "20px",
                    fontSize: "35px",
                    lineHeight: "1",
                    dataLineheight: "40px",
                  }}
                >
                  <b>Underground automated swing gate systems</b>
                </h1>
                <p
                  style={{
                    margin: "20px",
                    fontSize: "15px",
                    display: "inline-block",
                  }}
                >
                  <span>
                    FAAC motors for underground swing gates unite the concepts
                    of practicality with the aesthetic appeal that is typical of
                    gates located in buildings of particular artistic or
                    historical value.
                    <br />
                    This type of automation for swing gates enables the visual
                    impact of the gate itself to be maintained, whilst
                    simultaneously offering the comfort associated with
                    automatic gates.
                  </span>
                </p>
              </div>
            </div>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/SwingGate770N230V">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product1}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product1"
                  />
                </a>
                <button
                  class=" btn-secondary"
                  data-toggle="modal"
                  data-target="#exampleModal10"
                  style={{
                    marginTop: 10,
                    backgroundColor: "#35965c",
                    height: 30,
                    width: "100%",
                    color: "white",
                    border: "1px solid white",
                    borderRadius: 50,
                  }}
                >
                  Get Quote
                </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/SwingGate770N230V">770N 230V</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>
                  Underground electromechanical operator
                </h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                <b>2 – 3,5 m (with electric lock)</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max weight</p>
                <b>500 Kg</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>80</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/SwingGate770N24V">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product2}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product2"
                  />
                </a>
                <button
                  class=" btn-secondary"
                  data-toggle="modal"
                  data-target="#exampleModal10"
                  style={{
                    marginTop: 10,
                    backgroundColor: "#35965c",
                    height: 30,
                    width: "100%",
                    color: "white",
                    border: "1px solid white",
                    borderRadius: 50,
                  }}
                >
                  Get Quote
                </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/SwingGate770N24V"> 770N 24V</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>
                  Underground electromechanical operator
                </h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                <b>2 – 3,5 m (with electric lock)</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max weight</p>
                <b>500 Kg</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>Continuous use</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/SwingGateS800HENC">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product3}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product3"
                  />
                </a>
                <button
                  class=" btn-secondary"
                  data-toggle="modal"
                  data-target="#exampleModal10"
                  style={{
                    marginTop: 10,
                    backgroundColor: "#35965c",
                    height: 30,
                    width: "100%",
                    color: "white",
                    border: "1px solid white",
                    borderRadius: 50,
                  }}
                >
                  Get Quote
                </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/SwingGateS800HENC">S800H ENC</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>
                  24V Underground hydraulic operator
                </h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                <b>2 – 4 m</b>
              </div>

              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max weight</p>
                <b>800 Kg</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>Continuous use</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>{" "}
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/SwingGateS800ENC">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product4}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product3"
                  />
                </a>
                <button
                  class=" btn-secondary"
                  data-toggle="modal"
                  data-target="#exampleModal10"
                  style={{
                    marginTop: 10,
                    backgroundColor: "#35965c",
                    height: 30,
                    width: "100%",
                    color: "white",
                    border: "1px solid white",
                    borderRadius: 50,
                  }}
                >
                  Get Quote
                </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/SwingGateS800ENC"> S800 ENC</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>
                  230V Underground hydraulic operator{" "}
                </h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                <b>2 – 4 m</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max weight</p>
                <b>800 Kg</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency </p>
                <b>45 – 30</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>{" "}
            <div className="row" style={{ margin: 20 }}>
              <div className="col-lg-2 col-sm-3 col-md-3 col-xs-3">
                <a href="/SwingGateS2500l">
                  <img
                    className="card-img-top"
                    width="200"
                    height="200"
                    src={product5}
                    //onClick={()=>{ history.push('/automations-for-gates')}}
                    //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                    style={{ cursor: "pointer" }}
                    alt="Product5"
                  />
                </a>
                <button
                  class=" btn-secondary"
                  data-toggle="modal"
                  data-target="#exampleModal10"
                  style={{
                    marginTop: 10,
                    backgroundColor: "#35965c",
                    height: 30,
                    width: "100%",
                    color: "white",
                    border: "1px solid white",
                    borderRadius: 50,
                  }}
                >
                  Get Quote
                </button>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "45" }}>
                  <a href="/SwingGateS2500l"> S2500I</a>
                </h3>
                <h4 style={{ fontSize: "15px" }}>
                  24V Electromechanical operator
                </h4>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                <b>2 – 2,5 m (with electric lock)</b>
              </div>
              <div
                className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <p style={{ fontSize: "12px" }}>Max. leaf weight</p>
                <b>250 kg</b>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
            <div
              class="modal fade"
              id="exampleModal10"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Product Details
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{ padding: 20 }}>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                        <iframe
                          scrolling="no"
                          src="https://forms.zohopublic.in/sales80/form/ContactUs/formperma/o3sKh3PHKyQ5GmkDmlz4rY0NM2edU5IF_pl9MYAjDoA?zf_rszfm=1"
                          id="gform"
                          width="100%"
                          height="990"
                          frameborder="0"
                          marginheight="0"
                          marginwidth="0"
                        >
                          Loading…
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ borderColor: "#cecece", borderWidth: 5 }}></hr>
          </section>
        </div>
        <footer id="footer"></footer>
      </div>
    );
  }
}
