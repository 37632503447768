import React, { Component } from 'react';
import Header from '../header';
import Footer from '../Footer';
// import axios from 'axios';
// import LoaderComponent from '../GlobalConsants/LoaderComponent'
import { Helmet } from 'react-helmet';


class GaidoConnect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            randomnumbber: Math.floor(100000 + Math.random() * 900000),
            typenumber: "",
            falgerror: false,
            falgerror1: false,
            falgerror2: false,
            loader: false,
            falgerror3: false,
            name: "",
            email: '',
            message: "",
            modalOpen: false
        }
    }
    componentDidMount() {
        window.addEventListener('loader', this.handleNetworkChange);
    }
    componentWillUnmount() {
        window.removeEventListener('loader', this.handleNetworkChange);
    }
    handleNetworkChange = () => {
        this.setState({ loader: false });
    }
    render() {
        return (
            <div>
                <Helmet>
        <title>Connect with Gaido | Boom Barrier | Sliding Gate | ANPR | RFID | Automation</title>
        <meta property="og:title" content="Connect with Gaido | Boom Barrier | Sliding Gate | ANPR | RFID | Automation" />
        <meta property="og:url" content="https://gaido.in/gaido-connect" />
      </Helmet>
                <Header />
                <div className="inner-banner">
                    <h2> Gaido Connect </h2>
                </div>
                <section id="contact" className="wow fadeInUp">
                    <div className="container">
                        <div className="section-header" style={{ visibility: "hidden" }}>
                            <h2>Gaido Connect</h2>
                        </div>
                        <div className="row contact-info  " >
                            <div className='col-lg-3'></div>
                            <div className='col-lg-6 col-sm-12 col-md-12 col-xs-12'>
                            <iframe scrolling="no"  src="https://forms.zohopublic.in/sales80/form/ContactUs/formperma/o3sKh3PHKyQ5GmkDmlz4rY0NM2edU5IF_pl9MYAjDoA?zf_rszfm=1" id ="gform" width="100%" height="990" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                        </div>
                        </div>
                       
                    </div>
                </section>
                <iframe title="videotag" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.481909864123!2d78.3988787143539!3d17.436633805973653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91f051dd281f%3A0x5b0c424523df2634!2sGaido%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1566908995748!5m2!1sen!2sin"
                    width="100%" height={450} frameBorder={0} style={{ border: 0 }} allowFullScreen />
                <Footer />
            </div>
        );
    }
}

export default GaidoConnect;