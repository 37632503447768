import React, { Component } from 'react';
import Header from './header';
// import ReactGA from 'react-ga';

class ThankYou extends Component {
    // componentDidMount(){
    //     ReactGA.ga('event', 'conversion', {'send_to': 'AW-316089466/2hb0CMr35aYDEPrI3JYB'});
    // }
    render() {
        return (
            <div >
                <Header />
                <div>
                    <section style={{ backgroundColor: "#35965c", color: "white", marginBottom: 30, padding: 60, textAlign: "center", fontSize: 20, height: "100%" }} >
                        <div className="container">
                            <div >
                            <img src="https://media.giphy.com/media/ehz3LfVj7NvpY8jYUY/giphy.gif" alt="this slowpoke moves"  width="150" style={{paddingTop:20}} />

                                <h1><b>Thank You!</b></h1>

                            </div>
                            <div>
                                <h4>Thanks for contacting us. We will be in touch with you shortly. </h4>
                            </div>
                        </div>
                    </section>
                </div>
                <section style={{  marginBottom: 30, paddingTop: 50,paddingBottom:20, textAlign: "center", fontSize: 20 }} >
                    <h3>Meanwhile...<br/>
                        You can take a look at our recent installations.</h3>
                </section>
                
                <div className="container" style={{paddingBottom:50}}>
                    <div className="row">
                        <div className="col-md-6 col-lg-4" >
                            <iframe className='card-img-top' id="Geeks3" width="255" height="260"
                                src=
                                "https://www.youtube.com/embed/lpnqQQ9V64A"
                                frameborder="0" allowfullscreen >
                            </iframe>
                            <p style={{textAlign:"center"}}><b>Boom Barrier <br></br> with <br></br>Fast-Tag Based Automation</b></p>

                        </div>
                        <div className="col-md-6 col-lg-4">
                            <iframe className='card-img-top' id="Geeks3" width="255" height="260"
                                src=
                                "https://www.youtube.com/embed/xyZF8kxAwIs"
                                frameborder="0" allowfullscreen >
                            </iframe>
                            <p style={{textAlign:"center"}}><b>Sliding Gate Motor</b></p>

                            
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <iframe className='card-img-top' id="Geeks3" width="255" height="260"
                                src=
                                "https://www.youtube.com/embed/SwyLH0Kw2Ww"
                                frameborder="0" allowfullscreen >
                            </iframe>
                            <p style={{textAlign:"center"}}><b>Boom Barrier <br></br>with <br></br>Number Plate Recognition Camera</b></p>

                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ThankYou;