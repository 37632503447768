import React, { Component } from "react";
import Header from "../../../header";
const ExamplePdf = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGate746ER/9908482004_DEPLIANT_Sliding_Leaf_Gates_GB_Rev23_042017_LRES.pdf";
const productImage = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGate746ER/109776.jpg";
const Installation = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGate746ER/746ERinstallazione.jpg";
const electronicboard = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGate746ER/7909212.jpg";
const plate = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGate746ER/737816.jpg";
const screws = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGate746ER/390178.jpg";
const releaselock = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGate746ER/71275101.jpg";
const Galvanisedrack30by12 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGate746ER/490122.jpg";
const Galvanisedrack30by8 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGate746ER/490123.jpg";
const Galvanisedrack30by12mechanical = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGate746ER/490124.jpg";
const Galvanisedrack30by8mechanical = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGate746ER/490125.jpg";
const nylonrack = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGate746ER/4901204.jpg";
const pinionZ20 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGate746ER/719167.jpg";
const pinionZ16 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/SlidingGate746ER/719130.jpg";



export default class SlidingGate746ER extends Component {
  constructor(props) {
    super(props);
  }

  toggle = (id, id2) => {
    var n = document.getElementById(id);
    if (n.style.display != "none") {
      n.style.display = "none";
      document.getElementById(id2).setAttribute("aria-expanded", "false");
    } else {
      n.style.display = "";
      document.getElementById(id2).setAttribute("aria-expanded", "true");
    }
  };
  render() {
    return (
      <div>
          <Header />
        <div className="faacbarriers" style={{marginTop:100}}>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3">
              <img
                className="card-img-top"
                src={productImage}
                // //onClick={()=>{ history.push('/automations-for-gates')}}
                // //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
                style={{ cursor: "pointer" }}
                alt="Product1"
              />
            </div>
            <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
              <div
                className="col-lg-12 col-sm-12 col-md-12 col-xs-12"
                style={{ textAlign: "initial", margin: 10 }}
              >
                <h3 style={{ fontSize: "50px" }}>746 E R</h3>
                <h4 style={{ fontSize: "20px" }}>230V Gearmotor</h4>
              </div>
              <div className="row">
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "initial", margin: 20 }}
                >
                  <p style={{ fontSize: "12px" }}>Max. leaf weight</p>
                  <b>400 – 600 Kg</b>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "initial", margin: 20 }}
                >
                  <p style={{ fontSize: "12px" }}>Max speed</p>
                  <b>9,6 – 12 m/min.</b>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "initial", margin: 20 }}
                >
                  <p style={{ fontSize: "12px" }}>Use frequency</p>
                  <b>70%</b>
                </div>
              </div>
              <div className="row">
              <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3">
                <a
                  href={ExamplePdf}
                  download="9908482004_DEPLIANT_Sliding_Leaf_Gates_GB_Rev23_042017_LRES"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button style={{ marginLeft: 30 , marginTop: 30 ,borderColor: "#cecece", borderRadius: 3}} >Download Broucher</button>
                </a>
                <br/>
                <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      backgroundColor: "#35965c",
                      height: 30,
                      width:"100%",
                      margin: 20 ,
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  
                  >
                    Get Quote
                  </button>
                  </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

            <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
              <ul style={{ listStyleType: "disc" }}>
                <li class="Bullet_List">
                  <span>
                    Maximum anti-crushing safety by coupling the twin-disk
                    oil-bath clutch with the electronic device and encoder.
                  </span>
                </li>
                <li class="Bullet_List">
                  <span>Magnetic limit switch.</span>
                </li>
                <li class="Bullet_List">
                  <span>
                    Designed for height and side adjustable fastening.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink1"
                onClick={(e) => this.toggle("navbar", "TLink1")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Family models</span>
                </h5>
              </a>
              <div id="navbar" style={{ display: "none" }}>
                <div class="table-3">
                  <table>
                    <thead>
                      <tr>
                        <th colspan="1" rowspan="1">
                          <p>
                            <span>Model</span>
                          </p>
                        </th>
                        <th colspan="1" rowspan="1">
                          <p>
                            <span>Item code</span>
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>746 E R Z16</span>
                          </p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>109776</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>746 E R Z20</span>
                          </p>
                        </td>
                        <td colspan="1" rowspan="1">
                          <p>
                            <span>109773</span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style={{ marginTop: "0px", marginBottom: "15px" }}>
                  <h5
                    class="title-heading-left fusion-responsive-typography-calculated"
                    style={{ fontSize: 20, lineHeight: 1.2 }}
                    data-fontsize="20"
                    data-lineheight="24px"
                  >
                    <span>Packs</span>
                  </h5>
                </div>
                <div
                  class="fusion-text fusion-text-6 Models__Package_Description"
                  // style="transform:translate3d(0,0,0);"
                >
                  <p>
                    <span class="Highlight">
                      746 E R Z16 and ER Z20, designed for on-rack applications{" "}
                    </span>
                    <span class="Package">&nbsp;</span>
                    <span class="Highlight">includes</span>
                    <span class="Highlight">:</span>
                    <span>&nbsp;</span>
                    <span>
                      a gearmotor with pinion and 780D control unit, limit
                      switch magnets, release key, covering carters.
                    </span>
                  </p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink2"
                onClick={(e) => this.toggle("dec2", "TLink2")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Dimensions and technical specifications</span>
                </h5>
              </a>
              <div id="dec2" style={{ display: "none" }}>
                <div class="table-4">
                  <table>
                    <thead>
                      <tr>
                        <th contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Model</span>
                          </p>
                        </th>
                        <th contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>746 E R Z16</span>
                          </p>
                        </th>
                        <th contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>746 E R Z20</span>
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Power supply voltage</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>220-240V~ 50/60 Hz</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Electric motor</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>Asynchronous single phase</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Max. power</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>400 W</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Max. torque</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>26 Nm (*)</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Max expressed force</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>810 N (*)</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>650 N (*)</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Reduction ratio</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>1:30</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Max. leaf width</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>40 m</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>50 m</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Leaf speed</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>9.6 m/min</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>12 m/min</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Speed adjustment and motor control</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>No</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Limit switch</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>Magnetic</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Pinion</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Z16 module 4</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Z20 module 4</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Encoder</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>Magnetic encoder</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Force adjustment</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>
                              Twin-disk clutch in oil-bath and via board
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Operating ambient temperature</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>-20°C ÷ +55°C</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Thermal protection</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>120°C</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Protection class</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>IP44</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Weight</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>14 Kg</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Type of oil</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>FAAC HP OIL</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Dimensions (LxDxH)</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>277 x 155 x 337 mm</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Max. leaf weight</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>600 Kg</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>400 Kg</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Use frequency</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>70%</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td contenuto="None" colspan="1" rowspan="1">
                          <p>
                            <span>Electronic equipment</span>
                          </p>
                        </td>
                        <td contenuto="None" colspan="2" rowspan="1">
                          <p>
                            <span>780D incorporated</span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>(*) data referred to 230V~ 50Hz.</p>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink3"
                onClick={(e) => this.toggle("dec3", "TLink3")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Example of a typical installation</span>
                </h5>
              </a>
              <div id="dec3" style={{ display: "none" }}>
                <div>
                  <img
                    decoding="async"
                    src={Installation}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                </div>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                    <table>
                      <thead>
                        <tr>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Ref</span>
                            </p>
                          </th>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Q.ty</span>
                            </p>
                          </th>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Description</span>
                            </p>
                          </th>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>Code</span>
                            </p>
                          </th>
                          <th colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>
                                746 E R Z16 Gearmotor with incorporated
                                electronic control unit and encoder
                              </span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>109776</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>·</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>
                                Foundation plate with lateral and height
                                adjustments (6 pc. pack)
                              </span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>737816</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>4</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>
                                Galvanised rack 30×12 mod. 4 including weld-on
                                fittings (4 pcs pack, 1 m each)
                              </span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>490122</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Plug in receiver 1 channel RP 433 SLH</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>787852</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>·</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>XLED flashing light</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>410029</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>·</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>3</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Key operated button XK10</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>401302</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>.</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>3</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Lock with key</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>71275101</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>.</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>4</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>Pair of photocells XP 30</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>785105</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>·</span>
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>&nbsp;</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>1</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>2 channels transmitter XT2 433 SLH LR</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>787007</span>
                            </p>
                          </td>
                          <td colspan="1" rowspan="1">
                            <p>
                              <span>·</span>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>

          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink4"
                onClick={(e) => this.toggle("dec4", "TLink4")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    {" "}
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Electronic control units</span>
                </h5>
              </a>
              <div id="dec4" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={electronicboard}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      780D electronic control unit (incorporated in automation)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      63000710{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink6"
                onClick={(e) => this.toggle("dec6", "TLink6")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Accessories</span>
                </h5>
              </a>
              <div id="dec6" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={plate}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Foundation plate with lateral and height adjustments
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      737816
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={releaselock}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Lock with custom key (1-10)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      71275101-10
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={screws}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Kit of 4 fastening screws to be screwed in (package of 4
                      kits) to be combined with 1 m of 490333
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      390178
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink5"
                onClick={(e) => this.toggle("dec5", "TLink5")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Racks</span>
                </h5>
              </a>
              <div id="dec5" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={Galvanisedrack30by12}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Galvanised rack 30×12 mod. 4 including weld-on fittings (4
                      pcs pack, 1 m each)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      490122
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={Galvanisedrack30by8}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Galvanised rack 30×8 mod. 4 including weld-on fittings (4
                      pcs pack, 1 m each)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      490123
                    </p>
                  </div>

                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={Galvanisedrack30by12mechanical}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Galvanised rack 30×12 mod. 4 including mechanical fittings
                      (4 pcs pack, 1 m each)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      490124
                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={Galvanisedrack30by8mechanical}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      Galvanised rack 30×8 mod. 4 including mechanical fittings
                      (4 pcs pack, 1 m each)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      490125
                    </p>
                  </div>

                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={nylonrack}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                      30×20 mod.4 nylon rack with steel core (4 pcs pack, 1 m
                      each)
                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      490333
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>
          <div className="row">
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
            <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
              <a
                aria-expanded="true"
                id="TLink7"
                onClick={(e) => this.toggle("dec7", "TLink7")}
              >
                <h5
                  data-fontsize="18"
                  data-lineheight="26.46px"
                  style={{ marginBottom: 5 }}
                >
                  <button
                    type="button"
                    style={{
                      backgroundColor: "black",
                      height: 30,
                      width: 30,
                      alignItems: "center",
                      borderRadius: 5,
                    }}
                  >
                    <i
                      class="fa-solid fa-angle-down"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span>Pinions</span>
                </h5>
              </a>
              <div id="dec7" style={{ display: "none" }}>
                <div
                  className="row"
                  style={{
                    marginTop: "0px",
                    marginBottom: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={pinionZ20}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                    Pinion Z20 for rack


                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      719167


                    </p>
                  </div>
                  <div
                    className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      decoding="async"
                      src={pinionZ16}
                      width={200}
                      height={200}
                      class="img-responsive"
                      data-cmp-ab="2"
                      data-cmp-info="10"
                    />
                    <p style={{ textAlign: "center" }}>
                    Pinion Z16 for rack


                    </p>
                    <p
                      style={{
                        backgroundColor: "#ebebeb",
                        textAlign: "center",
                      }}
                    >
                      719130
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#cecece" }}></hr>

          <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
        </div>
        <div
              class="modal fade"
              id="exampleModal10"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Product Details
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{ padding: 20 }}>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                        <iframe
                          scrolling="no"
                          src="https://forms.zohopublic.in/sales80/form/ContactUs/formperma/o3sKh3PHKyQ5GmkDmlz4rY0NM2edU5IF_pl9MYAjDoA?zf_rszfm=1"
                          id="gform"
                          width="100%"
                          height="990"
                          frameborder="0"
                          marginheight="0"
                          marginwidth="0"
                        >
                          Loading…
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    );
  }
}
