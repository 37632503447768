import React, { Component } from 'react'
import Header from '../../../header';
const productImage = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/Faac413gates/104413.jpg";
const Installation = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/Faac413gates/413230Vinstallazione.jpg";
const ElectronicCU1 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/Faac413gates/790076.jpg";
const ElectronicCU2 = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/Faac413gates/790077.jpg";
const EnclouserModLCu = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/Faac413gates/720118.jpg";
const EnclousermodECU = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/Faac413gates/720119.jpg";
const EnclousermodLMCU = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/Faac413gates/720309.jpg";
const plate = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/Faac413gates/737604.jpg";
const SafeCoderBus = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/Faac413gates/404040.jpg";
const BusXibInterface = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/Faac413gates/790062.jpg";
const ExamplePdf = "https://gaido-webimages.s3.eu-north-1.amazonaws.com/FAAC_Images/Faac413gates/9908483004_DEPLIANT_Swing_Leaf_Gates_GB_Rev27_062022 LowRes.pdf"
export default class Faac413230vgates extends Component {
  constructor(props) {
    super(props);
  }

  toggle = (id, id2) => {
    var n = document.getElementById(id);
    if (n.style.display != "none") {
      n.style.display = "none";
      document.getElementById(id2).setAttribute("aria-expanded", "false");
    } else {
      n.style.display = "";
      document.getElementById(id2).setAttribute("aria-expanded", "true");
    }
  };
  render() {
    return (
      <div>
        <Header />
      <div className="faacbarriers" style={{marginTop:100}}>
        <div className="row">
          <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
          <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3">
            <img
              className="card-img-top"
              src={productImage}
              // //onClick={()=>{ history.push('/automations-for-gates')}}
              // //onClick={browserHistory.push('/faac/products/automations-for-barriers')}
              style={{ cursor: "pointer" }}
              alt="Product1"
            />
          </div>
          <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
            <div
              className="col-lg-12 col-sm-12 col-md-12 col-xs-12"
              style={{ textAlign: "initial", margin: 10 }}
            >
              <h3 style={{ fontSize: "50px" }}>413 230V</h3>
              <h4 style={{ fontSize: "20px" }}>
              230V Electromechanical operator
              </h4>
            </div>
            <div className="row">
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 20 }}
              >
                <p style={{ fontSize: "12px" }}>Max width single leaf</p>
                <b>1,8 – 2,5 m (with electric lock)
</b>
              </div>
              <div
                className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                style={{ textAlign: "initial", margin: 20 }}
              >
                <p style={{ fontSize: "12px" }}>Use frequency</p>
                <b>30%</b>
              </div>
            </div>
            <div className="row">
            <div className="col-lg-3 col-sm-3 col-md-3 col-xs-3">
                  <a
                    href={ExamplePdf}
                    download="9908483004_DEPLIANT_Swing_Leaf_Gates_GB_Rev27_062022 LowRes"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      style={{
                        marginLeft: 30,
                        marginTop: 30,
                        borderColor: "#cecece",
                        borderRadius: 3,
                      }}
                    >
                      Download Broucher
                    </button>
                  </a>
                  <br />
                  <button
                    class=" btn-secondary"
                    data-toggle="modal"
                    data-target="#exampleModal10"
                    style={{
                      backgroundColor: "#35965c",
                      height: 30,
                      width: "100%",
                      margin: 20,
                      color: "white",
                      border: "1px solid white",
                      borderRadius: 50,
                    }}
                  >
                    Get Quote
                  </button>
                </div>
            </div>
          </div>
        </div>
        <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
        <div className="row">
          <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>

          <div className="col-lg-6 col-sm-6 col-md-6 col-xs-6">
            <ul style={{ listStyleType: "disc" }}>
              <li class="Bullet_List">
                <span>
                “Off axis” thrust operator.
                </span>
              </li>
              <li class="Bullet_List">
                <span>Opening and closing mechanical stops included.
</span>
              </li>
              <li class="Bullet_List">
                <span>
                Horizontal exit for cables to allow near to ground installation (low installation).
                </span>
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
        <div className="row">
          <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
          <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
            <a
              aria-expanded="true"
              id="TLink1"
              onClick={(e) => this.toggle("navbar", "TLink1")}
            >
              <h5
                data-fontsize="18"
                data-lineheight="26.46px"
                style={{ marginBottom: 5 }}
              >
                <button
                  type="button"
                  style={{
                    backgroundColor: "black",
                    height: 30,
                    width: 30,
                    alignItems: "center",
                    borderRadius: 5,
                  }}
                >
                  {" "}
                  <i
                    class="fa-solid fa-angle-down"
                    style={{ color: "white", fontSize: "15px" }}
                  ></i>
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span>Family models</span>
              </h5>
            </a>
            <div id="navbar" style={{ display: "none" }}>
              <div class="table-3">
              <table >
<thead>
<tr>
<th colspan="1" rowspan="1">
<p><span>Model</span></p>
</th>
<th colspan="1" rowspan="1">
<p><span>Item code</span></p>
</th>
</tr>
</thead>
<tbody>
<tr>
<td colspan="1" rowspan="1">
<p><span>413 230V</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>104413</span></p>
</td>
</tr>
</tbody>
</table>
              </div>
              <div style={{ marginTop: "0px", marginBottom: "15px" }}>
                <h5
                  class="title-heading-left fusion-responsive-typography-calculated"
                  style={{ fontSize: 20, lineHeight: 1.2 }}
                  data-fontsize="20"
                  data-lineheight="24px"
                >
                  <span>Packs</span>
                </h5>
              </div>
              <div
                class="fusion-text fusion-text-6 Models__Package_Description"
                // style="transform:translate3d(0,0,0);"
              >
                <p>
                413 230V includes: an electromechanical operator, installation accessories, a release key, a 400V 6.3μF thrust capacitor.
<br></br>The LS version includes the electric limit switches.


</p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ borderColor: "#cecece" }}></hr>
        <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
        <div className="row">
          <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
          <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
            <a
              aria-expanded="true"
              id="TLink2"
              onClick={(e) => this.toggle("dec2", "TLink2")}
            >
              <h5
                data-fontsize="18"
                data-lineheight="26.46px"
                style={{ marginBottom: 5 }}
              >
                <button
                  type="button"
                  style={{
                    backgroundColor: "black",
                    height: 30,
                    width: 30,
                    alignItems: "center",
                    borderRadius: 5,
                  }}
                >
                  {" "}
                  <i
                    class="fa-solid fa-angle-down"
                    style={{ color: "white", fontSize: "15px" }}
                  ></i>
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span>Dimensions and technical specifications</span>
              </h5>
            </a>
            <div id="dec2" style={{ display: "none" }}>
              <div class="table-4">
              <table>
<thead>
<tr>
<th contenuto="None" colspan="1" rowspan="1">
<p><span>Model</span></p>
</th>
<th contenuto="None" colspan="1" rowspan="1">
<p><span>413 230V</span></p>
</th>
</tr>
</thead>
<tbody>
<tr>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Power supply voltage</span></p>
</td>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>220-240V~ 50/60 Hz</span></p>
</td>
</tr>
<tr>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Electric motor</span></p>
</td>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Asynchronous single phase</span></p>
</td>
</tr>
<tr>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Max. power</span></p>
</td>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>250 W</span></p>
</td>
</tr>
<tr>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Max expressed force</span></p>
</td>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>2000 N (*)</span></p>
</td>
</tr>
<tr>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Rod max speed</span></p>
</td>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>16 mm/s (*)</span></p>
</td>
</tr>
<tr>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Rod travel</span></p>
</td>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>300 mm (350 mm without mechanical stops)</span></p>
</td>
</tr>
<tr>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Max. leaf opening angle</span></p>
</td>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>110°</span></p>
</td>
</tr>
<tr>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Operating ambient temperature</span></p>
</td>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>-20°C ÷ + 55°C</span></p>
</td>
</tr>
<tr>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Thermal protection</span></p>
</td>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>140°C</span></p>
</td>
</tr>
<tr>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Protection class</span></p>
</td>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>IP54</span></p>
</td>
</tr>
<tr>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Weight</span></p>
</td>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>7.8 Kg</span></p>
</td>
</tr>
<tr>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Use frequency</span></p>
</td>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>S3 – 30%</span></p>
</td>
</tr>
<tr>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Max. leaf width</span></p>
</td>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>1,8 m (2,5 with electric lock)</span></p>
</td>
</tr>
<tr>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Mounting brackets</span></p>
</td>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Rear attachment to be welded/screwed – front attachment to be welded</span></p>
</td>
</tr>
<tr>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Dimensions (LxDxH)</span></p>
</td>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>777 x 101 x 152 mm</span></p>
</td>
</tr>
<tr>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Electronic equipment</span></p>
</td>
<td contenuto="None" colspan="1" rowspan="1">
<p><span>Not included</span></p>
</td>
</tr>
</tbody>
</table>
              </div>
              <p>(*) data referred to 230V~ 50Hz.</p>
            </div>
          </div>
        </div>
        <hr style={{ borderColor: "#cecece" }}></hr>

        <div className="row">
          <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
          <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
            <a
              aria-expanded="true"
              id="TLink3"
              onClick={(e) => this.toggle("dec3", "TLink3")}
            >
              <h5
                data-fontsize="18"
                data-lineheight="26.46px"
                style={{ marginBottom: 5 }}
              >
                <button
                  type="button"
                  style={{
                    backgroundColor: "black",
                    height: 30,
                    width: 30,
                    alignItems: "center",
                    borderRadius: 5,
                  }}
                >
                  {" "}
                  <i
                    class="fa-solid fa-angle-down"
                    style={{ color: "white", fontSize: "15px" }}
                  ></i>
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span>Example of a typical installation</span>
              </h5>
            </a>
            <div id="dec3" style={{ display: "none" }}>
              <div>
                <img
                  decoding="async"
                  src={Installation}
                  class="img-responsive"
                  data-cmp-ab="2"
                  data-cmp-info="10"
                />
              </div>
              <div
                className="row"
                style={{
                  marginTop: "0px",
                  marginBottom: "15px",
                  marginTop: "20px",
                }}
              >
                <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                <table>
<thead>
<tr>
<th colspan="1" rowspan="1">
<p><span>Ref</span></p>
</th>
<th colspan="1" rowspan="1">
<p><span>Q.ty</span></p>
</th>
<th colspan="1" rowspan="1">
<p><span>Description</span></p>
</th>
<th colspan="1" rowspan="1">
<p><span>Code</span></p>
</th>
<th colspan="1" rowspan="1">
<p><span>&nbsp;</span></p>
</th>
</tr>
</thead>
<tbody>
<tr>
<td colspan="1" rowspan="1">
<p><span>2</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>1</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>XLED flashing light</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>410029</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>·</span></p>
</td>
</tr>
<tr>
<td colspan="1" rowspan="1">
<p><span>3</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>1</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>Key operated button XK10B</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>401304</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>·</span></p>
</td>
</tr>
<tr>
<td colspan="1" rowspan="1">
<p><span>3</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>1</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>Lock with key</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>71275101</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>·</span></p>
</td>
</tr>
<tr>
<td colspan="1" rowspan="1">
<p><span>4</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>1</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>E045S control unit</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>790077</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>.</span></p>
</td>
</tr>
<tr>
<td colspan="1" rowspan="1">
<p><span>4</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>1</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>Enclosure mod. E for electronic control units</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>720119</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>·</span></p>
</td>
</tr>
<tr>
<td colspan="1" rowspan="1">
<p><span>4</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>1</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>XF 433 MHz receiver</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>787831</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>·</span></p>
</td>
</tr>
<tr>
<td colspan="1" rowspan="1">
<p><span>5</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>1</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>Pair of photocells XP 20 BD</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>785103</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>·</span></p>
</td>
</tr>
<tr>
<td colspan="1" rowspan="1">
<p><span>&nbsp;</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>1</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>2 channels transmitter XT2 433 SLH LR</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>787007</span></p>
</td>
<td colspan="1" rowspan="1">
<p><span>·</span></p>
</td>
</tr>
</tbody>
</table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ borderColor: "#cecece" }}></hr>
        <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>

        <div className="row">
          <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
          <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
            <a
              aria-expanded="true"
              id="TLink4"
              onClick={(e) => this.toggle("dec4", "TLink4")}
            >
              <h5
                data-fontsize="18"
                data-lineheight="26.46px"
                style={{ marginBottom: 5 }}
              >
                <button
                  type="button"
                  style={{
                    backgroundColor: "black",
                    height: 30,
                    width: 30,
                    alignItems: "center",
                    borderRadius: 5,
                  }}
                >
                  {" "}
                  <i
                    class="fa-solid fa-angle-down"
                    style={{ color: "white", fontSize: "15px" }}
                  ></i>
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span>Electronic control units</span>
              </h5>
            </a>
            <div id="dec4" style={{ display: "none" }}>
              <div
                className="row"
                style={{
                  marginTop: "0px",
                  marginBottom: "15px",
                  marginTop: "20px",
                }}
              >
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={ElectronicCU1}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>E145S control unit</p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    790076
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={ElectronicCU2}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>E045S control unit</p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    790078
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ borderColor: "#cecece" }}></hr>
        <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>

        <div className="row">
          <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1"></div>
          <div className="col-lg-10 col-sm-10 col-md-10 col-xs-10">
            <a
              aria-expanded="true"
              id="TLink5"
              onClick={(e) => this.toggle("dec5", "TLink5")}
            >
              <h5
                data-fontsize="18"
                data-lineheight="26.46px"
                style={{ marginBottom: 5 }}
              >
                <button
                  type="button"
                  style={{
                    backgroundColor: "black",
                    height: 30,
                    width: 30,
                    alignItems: "center",
                    borderRadius: 5,
                  }}
                >
                  <i
                    class="fa-solid fa-angle-down"
                    style={{ color: "white", fontSize: "15px" }}
                  ></i>
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span> Accessories</span>
              </h5>
            </a>
            <div id="dec5" style={{ display: "none" }}>
              <div
                className="row"
                style={{
                  marginTop: "0px",
                  marginBottom: "15px",
                  marginTop: "20px",
                }}
              >
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={EnclouserModLCu}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>
                    Enclosure mod. L for electronic control units
                  </p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    720118
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={EnclousermodECU}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>
                    Enclosure mod. E for electronic control units
                  </p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    720119
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={EnclousermodLMCU}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>
                    Enclosure mod. LM for electronic control units
                  </p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    720309
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={plate}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>Plate to be walled</p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    737604
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={SafeCoderBus}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>
                    SAFEcoder (BUS magnetic absolute encoder) for 412 operator
                    (FAAC Patent)
                  </p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    404040
                  </p>
                </div>
                <div
                  className="col-lg-3 col-sm-3 col-md-3 col-xs-3"
                  style={{ textAlign: "center" }}
                >
                  <img
                    decoding="async"
                    src={BusXibInterface}
                    width={200}
                    height={200}
                    class="img-responsive"
                    data-cmp-ab="2"
                    data-cmp-info="10"
                  />
                  <p style={{ textAlign: "center" }}>
                    BUS XIB interface (for E045 or E045S or E024S boards with
                    photocell no BUS)
                  </p>
                  <p
                    style={{
                      backgroundColor: "#ebebeb",
                      textAlign: "center",
                    }}
                  >
                    790062
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ borderColor: "#cecece" }}></hr>

        <hr style={{ borderColor: "#cecece", borderWidth: 10 }}></hr>
      </div>
      <div
              class="modal fade"
              id="exampleModal10"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Product Details
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body" style={{ padding: 20 }}>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                        <iframe
                          scrolling="no"
                          src="https://forms.zohopublic.in/sales80/form/ContactUs/formperma/o3sKh3PHKyQ5GmkDmlz4rY0NM2edU5IF_pl9MYAjDoA?zf_rszfm=1"
                          id="gform"
                          width="100%"
                          height="990"
                          frameborder="0"
                          marginheight="0"
                          marginwidth="0"
                        >
                          Loading…
                        </iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </div>   
     )
  }
}
