import React, { Component } from 'react';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
} from "firebase/auth";
import { auth } from "../firebaseConfig";
import { Alert } from 'reactstrap';
export default class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: '',
            loading: false
        }

    }

    login = async () => {
        try {
            const user = await signInWithEmailAndPassword(
                auth,
                this.state.email,
                this.state.password
            );

            localStorage.setItem("isAuth", true);
            this.props.history.push("/admin/postedblogs");
        } catch (error) {
            this.setState({ loading: true, error: error.message });
            setTimeout(() => { this.setState({ loading: false})}, 3000);
        }
    };
    render() {
        return (
            <div className="loginPage">
                <div className="container" ></div>
                <div className="container">

                    <div className="row">

                        <div className='col-lg-4 col-sm-12 col-md-12 col-xs-12'></div>
                        <div className='col-lg-4 col-sm-12 col-md-12 col-xs-12'>
                            {this.state.loading ? <Alert color="danger">
                                {this.state.error}
                            </Alert> : ""}
                            <h3>Sign In</h3>
                            <div className="mb-3">
                                <label>Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    onChange={(event) => {
                                        this.setState({ email: event.target.value });
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Enter password"
                                    onChange={(event) => {
                                        this.setState({ password: event.target.value });
                                    }}
                                />
                            </div>
                            {/* <div className="mb-3">
                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customCheck1"
                                />
                                <label className="custom-control-label" htmlFor="customCheck1">
                                    Remember me
                                </label>
                            </div>
                        </div> */}
                            <div className="d-grid">
                                <button type="submit" className="btn btn-primary" onClick={this.login}>
                                    Login   
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}